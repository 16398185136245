export var inboxList = [
    {
        id: '1',
        letterOpened: 'false',
        title: '01. Title here: Re-submit your application! Closed Letter Badge with BLINKING red dot',
        description: "Description: Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '10 Jan 2022',
        messageAction: '',
    },
    {
        id: '2',
        letterOpened: 'false',
        title: '02. Re-submit your application! Closed Letter Badge with BLINKING red dot',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '9 Jan 2022',
        messageAction: '',
    },
    {
        id: '3',
        letterOpened: 'false',
        title: '03. Re-submit your application! Closed Letter Badge with BLINKING red dot',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '8 Jan 2022',
        messageAction: '',
    },
    {
        id: '4',
        letterOpened: 'false',
        title: '04. Re-submit your application! Closed Letter Badge with BLINKING red dot',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '7 Jan 2022',
        messageAction: '',
    },
    {
        id: '5',
        letterOpened: 'false',
        title: '05. Re-submit your application! Closed Letter Badge with BLINKING red dot',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '6 Jan 2022',
        messageAction: '',
    },
    {
        id: '6',
        letterOpened: 'false',
        title: '06. Re-submit your application! Closed Letter Badge with BLINKING red dot',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '5 Jan 2022',
        messageAction: '',
    },
    {
        id: '7',
        letterOpened: 'true',
        title: '07. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '3 Jan 2022',
        messageAction: '',
    },
    {
        id: '8',
        letterOpened: 'true',
        title: '08. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '3 Jan 2022',
        messageAction: '',
    },
    {
        id: '9',
        letterOpened: 'true',
        title: '09. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '3 Jan 2022',
        messageAction: '',
    },
    {
        id: '10',
        letterOpened: 'true',
        title: '10. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '3 Jan 2022',
        messageAction: '',
    },
    {
        id: '11',
        letterOpened: 'true',
        title: '11. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '1 Jan 2022',
        messageAction: '',
    },
    {
        id: '12',
        letterOpened: 'true',
        title: '12. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '1 Jan 2022',
        messageAction: '',
    },
    {
        id: '13',
        letterOpened: 'true',
        title: '13. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '1 Jan 2022',
        messageAction: '',
    },
    {
        id: '14',
        letterOpened: 'true',
        title: '14. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '1 Jan 2022',
        messageAction: '',
    },
    {
        id: '15',
        letterOpened: 'true',
        title: '15. Re-submit your application! Opened Letter Badge',
        description: "Hi User, we have some clarifications with you on application MSI - 20200210 - 1930P - U. Please review ...",
        date: '1 Jan 2022',
        messageAction: '',
    },
]