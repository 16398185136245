import Formio from "formiojs/Formio";

const sanitizeOptions = {
	allowedTags: [],
	allowedAttrs: [],
};

export function sanitizeObject(obj) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				obj[key] = sanitizeObject(obj[key]);
			} else if (typeof obj[key] === 'string') {
				obj[key] = Formio.Utils.sanitize(obj[key], sanitizeOptions);
			}
		}
	}
	return obj;
}

export function TablePaginationWithSelectBoxAndSelectIcon() {
	return   {
	  "& .MuiInputBase-root": {
		minWidth: "unset",
	  },
	  "& .MuiSelect-select": {
		border: "1px solid #EAECF0",
		borderRadius: "2px",
		marginTop: "-15px",
	  },
	  "& .MuiSelect-icon": {
		marginTop: "-8px",
	  },
	  "& .MuiTablePagination-actions": {
		display: "none",
	  },
	  "& .MuiTablePagination-displayedRows::before": {
		content: '"Showing "',
	  },
	}
}

export function DirectUserToIdpWebsite() {
	const newWindow = window.open('https://www.mpa.gov.sg/maritime-singapore/what-maritime-singapore-offers/programmes-to-support-your-maritime-business/industry-digital-plan', '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}