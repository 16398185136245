import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '767px',
        padding: '24px',
        borderRadius: '10px'
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        '& li': {
            listStyle: 'disc',
        },
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        justifyContent: 'space-between'
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '28px !important' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 32,
                        top: 28,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ContactUsErrorDialog({ open, setOpen }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title"
                variant='h3'
                onClose={handleClose}>
                Submission unsuccessful, please try again later.
            </BootstrapDialogTitle>
            <DialogContent /*dividers*/>
                <Typography gutterBottom
                    className='univers-55roman-normal-mine-shaft-16px'>

                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}
                    className='button-large univers-65-bold-white-16px'
                >
                    OK
                </Button>
            </DialogActions>
        </BootstrapDialog>
    </>)
}
