import { Grid, Typography } from "@mui/material";
import IDPCard from '../cards/IDPCard'
import WHTCard from '../cards/WHTCard'
import MSICard from '../cards/MSICard'

export default function OrganizationBoth() {
    return (
        <>
            <Typography mb={1} variant='h3'>Our Recommendation</Typography>

            <br />
            <br />
            <Typography variant='h5'>
                Based on your selection, the following MPA grant(s) and incentive(s) may be applicable for your project:
            </Typography>
            <br />
            <Grid container spacing={4}>

            <Grid item>
                <IDPCard />
            </Grid>
            <Grid item>
                <WHTCard />
            </Grid>
            <Grid item>
                <MSICard />
            </Grid>

            </Grid>
        </>
    )
}
