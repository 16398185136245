import { Typography } from "@mui/material";
import table1 from "../../../Images/table1.png";
import table2 from "../../../Images/table2.png";

function WHTPage() {
  const ApplicationExemption = process.env.PUBLIC_URL + '/docs/omp-documents-files/wt_ip_fl_pdf_221113_172154.pdf';
  return (
    <>
      <Typography variant="h3">Withholding Tax Exemption</Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">    
      <Typography variant="h4" sx={{ textDecoration: "underline" }}>
      Transition to One Maritime Portal 
        </Typography>
        <br />
        <strong>
          1. What is the cut-off date for submission of current excel format of the Self-declaration form (SDF) via email?
        </strong>
        <br />
        <br />
        <div className="IDPText">
        15 January 2024. Excel SDFs submitted via email on or after 16 January 2024 will be regarded as invalid.
          <br />
          <br />
          <br />
        </div>

        <strong>
          2. Why is there a black-out period for submission of SDF to MPA?
        </strong>
        <br />
        <br />
        <div className="IDPText">
        MSI companies will not be able to submit any SDFs to MPA during the black-out period from 16 to 21 January 2024 (both dates inclusive).  This is to facilitate the transfer of existing records and data, as well as provide sufficient time for data verification by internal teams within MPA.
        <br />
          <br />
As the SDF submission deadline falls on the 15th of the month following the first relevant payment due date, the black-out period has been scheduled to commence after 15 January 2024 to minimise the impact on taxpayers.
          <br />
          <br />
          <br />
        </div>
     
        <strong>
          3. When can I start submitting SDF again?
        </strong>
        <br />
        <br />
        <div className="IDPText">
        You may submit SDF via OMP from 22 January 2024.
          <br />
          <br />
          <br />
        </div>

        <strong>
          4. Are the Excel SDFs submitted before the launch of OMP still valid?
        </strong>
        <br />
        <br />
        <div className="IDPText">
        Yes. The Excel SDFs submitted by 15 January 2024 remain valid as long as there are no change(s) to the information submitted.
          <br />
          <br />
          <br />
        </div>

        <strong>
          5. Will the Excel SDFs submitted before 16 January 2024 be migrated to OMP? 
        </strong>
        <br />
        <br />
        <div className="IDPText">
        Yes. You may view the records by logging in to OMP.
        <br />
For submissions made before 16 January 2024 that require clarifications / amendments, MPA will contact you via email regarding the clarifications / amendments before migrating the information to OMP.

<br/>Please <b><u>DO NOT</u></b> submit a new SDF in OMP for missing records.
          <br />
          <br />
          <br />
        </div>

        <strong>
          6. I do not have a CorpPass account. Will I still be able to access OMP?
        </strong>
        <br />
        <br />
        <div className="IDPText">
        No. Please follow the CorpPass user guides to create a CorpPass account and add OMP as an e-Service.
          <br />
          <br />
          <br />
        </div>

        <strong>
          7. Is the SDF in OMP different from the Excel SDF?
        </strong>
        <br />
        <br />
        <div className="IDPText">
        The information required is largely the same. However, the form has been redesigned to make it more intuitive. Auto-population and validations are also in place for a faster and smoother submission. Please refer to the OMP User Guide for more information.
          <br />
          <br />
          <br />
        </div>

        <strong>
          8. How do I amend my SDF after submission?
        </strong>
        <br />
        <br />
        <div className="IDPText">
        Amendments to an SDF submitted can only be made in OMP going forward via Change Requests – please refer to the OMP User Guide for further details.
        <br />
MPA may still reach out to you to seek clarifications on the SDF submitted via email prior to the requesting the necessary action(s) to be made in OMP.
          <br />
          <br />
          <br />
        </div>

        <strong>
          9. Who may I contact if there are technical issues with OMP?
        </strong>
        <br />
        <br />
        <div className="IDPText">
        You may send an email to <a href="mailto:wht@mpa.gov.sg">wht@mpa.gov.sg</a> or contact Angella Chan at 6375 1760 / Lin Jinyan at 6375 1773.
         <br />
          <br />
          <br />
        </div>


      <Typography variant="h4" sx={{ textDecoration: "underline" }}>
        General
      </Typography>
      <br />
         
        <strong>
          1. Will the company be notified of the outcome of the submission?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Under the withholding tax exemption regime, withholding tax exemption
          is granted based on the applicant’s self-declaration and the borrower
          will enjoy withholding tax exemption (effective from the relevant date
          stated in the form) as long as it can comply with all the terms and
          conditions as stated in the form. No confirmation / approval will be
          issued by MPA.
        </div>
        <br />
        <br />
        <br />
        <strong>
          2. What is the effective date of withholding tax exemption?
        </strong>
        <br />
        <br />
        <div className="IDPTextNormal">
          The withholding tax exemption will only be granted with effect
          <br /> from the <u>earlier</u> of:
          <br />
          - Date of submission of the form; or
          <br />
          - Date of earliest relevant payment made in the month prior to the
          deadline for submitting the form.
          <br />
          <br />
          <u>To illustrate:</u>
          <br />
          The earliest date of payment under the financing arrangement is 5 Oct
          2022. The application was submitted on 10 Oct 2022. The withholding
          tax exemption will be granted with effect from 5 Oct 2022 (i.e.
          earlier of the 2 dates), subject to meeting all the other terms and
          conditions of the form.
          <br />
          <br />
          For{" "}
          <b>
            foreign-flagged vessels of MSI-AIS / MSI-ML(Ship) award recipients
          </b>
          , the effective date of the withholding tax exemption shall be no
          earlier than the <u>Borrower’s award</u> commencement date and no
          later than the last day the Borrower’s award status.
          <br />
          <br />
          For{" "}
          <b>
            <u>non</u> MSI-AIS / MSI-ML(Ship) companies
          </b>
          , the interest and qualifying related payments incurred for vessel
          lengthening and / or vessel conversion works will be granted
          withholding tax exemption <u>only after the vessel</u> is registered
          or provisionally registered as a Singapore-flagged vessel and the
          exemption will apply on a prospective basis, i.e. payments incurred
          before the qualifying period of withholding tax exemption{" "}
          <b>will not be covered</b>.
        </div>
        <br />
        <br />
        <br />
        <strong>
          3. Can the company apply for withholding tax exemption when the vessel
          is still under construction (i.e. before registration of Singapore
          flag)?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Yes. The Company may submit the duly completed form, signed financing
          and other relevant agreement(s) as well as documentary evidence (where
          required) by the 15th of the month following the first relevant
          payment due date to the non-resident via the One Maritime Portal.
        </div>
        <br />
        <br />
        <br />
        <strong>4. What is the deadline for submitting the form?</strong>
        <br />
        <br />
        <div className="IDPText">
          The form and supporting documents i.e. signed financing and other
          relevant agreement(s) as well as documentary evidence (where required)
          should be submitted by 15th of the month following the first relevant
          payment due date to the non-resident.
          <br />
          <br />
          <u>To illustrate:</u>
          <br />
          If the earliest date of payment under the financing arrangement is 5
          Oct 2022, the form and supporting documents should be submitted by 15
          Nov 2022.
        </div>
        <br />
        <br />
        <br />
        <strong>
          5. The company’s financing arrangement does not fall squarely within
          the conditions stated in the form / wishes to appeal for the waiver of
          withholding tax on retrospective interest and related payments made.
          How should I proceed?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          If the company would like to seek a waiver for withholding tax on
          non-qualifying or retrospective interest / related payments, an
          application would need to be made to the Ministry of Finance (MOF).
          The company may submit directly to MOF for the waiver (application
          form can be found{" "}
          <a
            href={ApplicationExemption}
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          ). Alternatively, if you have submitted a self-declaration form
          relating to the same loan agreement via the One Maritime Portal, you
          may select ‘Appeal to MOF’ from the relevant submitted
          self-declaration form via the portal. MPA will assist the company to
          surface the request to MOF when all the required information are
          received.
          <br />
          <br />
          Please note that the application will be subject to assessment by the
          MOF on a case-by-case basis. Pending MOF’s decision, please ensure
          that the company has e-file and make payment, inclusive of late
          payment penalties (if any), to the Inland Revenue Authority of
          Singapore (IRAS). In the event that the withholding tax exemption is
          granted by MOF, the company may seek a refund from IRAS.
        </div>
        <br />
        <br />
        <br />
        <strong>
          6. Can I submit a single application for more than one financing
          arrangements?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. Please make separate submission for <u>each</u> financing
          arrangement obtained.
          <br />
          <br />
          <br />
        </div>
        <strong>
          7. How long will the withholding tax exemption be applicable for?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          The withholding tax exemption will apply for the full tenure of the
          financing arrangement and cease when:
          <br />
          <br />
          <div class="click-zoom">
            <label>
              <input type="checkbox" />
              <img src={table1} className="WHTTable1Img imgZoom" alt="WHT" />
            </label>
          </div>
          <br />
          <br />
          For MSI-AIS / MSI-ML(Ship) entities, the qualifying financing
          arrangements will enjoy withholding tax exemption till the end of the
          tenure, even if the tenure extends beyond their MSI-AIS / MSI-ML(Ship)
          award period.
        </div>
        <strong>
          <br />
          <br />
          <br />
          8. Do we have to submit a self-declaration form every time we made a
          qualifying interest / related payment?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Companies are not required to submit the form if the relevant
          payment(s) are already exempted from withholding tax previously.
          However, if there are (i) changes in the terms and conditions of the
          financing agreement; or (ii) subsequent discovery of incorrect
          declaration of information in the previous form submitted, a change
          request is required to be submitted via the portal. You should select
          ‘Change Request’ from the relevant submitted self-declaration form via
          the portal.
          <br />
          <br />
          <br />
        </div>
        <strong>
          9. I am required to make a fee payment (e.g. arranger fee) before the
          signed financing agreement is available. Will the fee payment be
          covered under the self-declaration form for the signed financing
          agreement?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Yes, if:
          <br />
          (i) the fee payment will be stated in the signed financing agreement;
          and <br />
          (ii) the company submits the self-declaration form using the signed
          financing agreement by 15th of the month following the fee payment due
          date. Please note that this is subject to fulfilling all the terms and
          conditions of the form.
          <br />
          However, if any of the above conditions will not be met (e.g. signed
          financing agreement will not be available by the due date), please
          submit a self-declaration form using the{" "}
          <b>signed firm offer letter </b>
          first. Please note that the acceptance of the firm offer letter is
          only for the purpose of fees payable before the financing agreement is
          signed. The company will still be required to submit a separate
          self-declaration form together with the signed financing agreement to
          cover the interest and related payments under the financing agreement.
          <br />
          <br />
          <br />
        </div>
        <strong>
          10. Is it necessary to withhold tax on the qualifying related payments
          collected by a facility agent (based outside Singapore) on the account
          of the lenders (based in Singapore)?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No, as the fees are merely collected by the facility agent on behalf
          of the lenders based in Singapore. However, facility agency fees paid
          by the borrower to the non-resident agent for services rendered in
          Singapore would attract withholding tax.
          <br />
          <br />
          <br />
        </div>
        <strong>
          11. The company obtained a loan from a foreign lender where the
          interest and related fee payments may be tax exempted under the
          relevant Double Tax Agreement (DTA). Is there still a need to submit
          the self-declaration form?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          If the company has established it can qualify for treaty benefits
          under a DTA, there is no requirement to submit the self-declaration
          form to MPA. However, please note that in the event that the DTA claim
          is disputed and where a self-declaration form was not submitted by the
          due date, a company would not be able to seek recourse under the
          withholding tax exemption regime.
          <br />
          <br />
          <br />
        </div>
        <strong>
          12. If there is a change in interest rates subsequent to the
          submission of the form, do I need to submit an amendment form?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Yes, if the change is due to an amendment to the financing agreement.
          <br />
          No, if the change is due to fluctuations in floating rate used to
          determine the interest rates.
          <br />
          <br />
          <br />
        </div>
        <strong>
          13. Would the Inland Revenue Authority of Singapore (IRAS) be directly
          informed by MPA regarding the withholding tax exemption if the
          application is successful? Otherwise, does the company / applicant
          need to inform IRAS on the withholding tax exemption (e.g. via the
          filing of the Form IR37)?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          The company / applicant is not required to inform IRAS on the
          submission of the withholding tax exemption as MPA will update IRAS
          accordingly.
          <br />
          <br />
          <br />
        </div>
        <strong>
          14. Do I need to submit self-declaration form for interest payments
          made on finance lease arrangements?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          To keep pace with industry developments and to simplify the
          withholding tax exemption regime for MSI companies, the scope of
          withholding tax exemption has been expanded for MSI companies to
          include arrangements classified as finance leases (regardless of
          whether it is a finance lease treated as sale or not) for tax purposes
          with effect from 12 December 2018.
          <br />
          No separate self-declaration form is required to enjoy the exemption.
          For more information, you may refer to our shipping circular on
          'Extension of Withholding Tax Exemptions For Maritime Sector Incentive
          – Shipping Enterprise (Singapore Registry of Ships) (MSI-SRS)' via MPA
          website.
          <br />
          <br />
          <br />
        </div>
        <strong>
          15. Is there any restriction on where the loan is taken?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          There is no restriction as to which country the loan is taken from.
          <br />
          <br />
        </div>
        <br />
        <Typography variant="h4" sx={{ textDecoration: "underline" }}>
          General – Completing the Form
        </Typography>
        <br />
        <strong>
          1. Is it necessary for the financing arrangement to be denominated in
          foreign currency?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No, it is not necessary.
          <br />
          <br />
          <br />
        </div>

        <strong>
          2. For a loan denominated in a foreign currency, what is the exchange
          rate that should be used to translate the amounts to SGD-denominated
          currency in the form?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          You may use either the Company’s in-house exchange rate or the
          relevant exchange rate on the day of your application as per MAS
          website:{" "}
          <a
            href="https://www.mas.gov.sg/"
            target="_blank"
            rel="noreferrer noopener"
          >
            www.mas.gov.sg
          </a>{" "}
          <br />
          <br />
          <br />
        </div>
        <strong>
          3.The vessel is under construction and information on the IMO number
          is not available at the moment. How should I complete these fields?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          You may indicate in the form that IMO number is not available at this
          juncture when submitting the form.
          <br />
          Please submit a change request via the portal to update the IMO number
          once this information is available. Please select ‘Change Request’
          from the relevant submitted self-declaration form via the portal.
          <br />
          <br />
          <br />
        </div>
        <strong>
          4. The parent company obtained a foreign loan and on-lend the loan to
          the vessel-owning Special Purpose Vehicle (SPV). Am I required to
          complete the details under the SPV portion?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          If the parent company is a Singapore company, the form will be
          submitted by the parent company and the SPV details are required.
          <br />
          If the parent company is a foreign company, the form will be submitted
          by the SPV itself and the SPV details are not required.
          <br />
          <br />
          <br />
        </div>
        <strong>
          5. If the lender details (e.g. name, registered address) differ from
          that stated in the financing agreement, do I complete the form using
          the new details?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Please complete the form using the new details and upload supporting
          documentation together with the signed financing agreement reflecting
          the change.
          <br />
          <br />
          <br />
        </div>
        <strong>
          6. In the case of syndicated / joint financing arrangements, should I
          include the amounts / details of Singapore lenders?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. Please exclude amounts / details of Singapore lenders under the
          following fields, where applicable:
          <br />
          (i) Section 2: Non-tax resident’s details and
          <br />
          (ii) Section 3: Qualifying details
          <br />
          <br />
          <br />
        </div>
        <strong>
          7. For financing arrangement with more than 1 lenders, do I need to
          complete 1 form for each lender?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. Please only submit 1 form for <u>each</u> financing arrangement
          obtained.
          <br />
          <br />
          <br />
        </div>
        <strong>
          8. Do I need to compute the Present Value (PV) of the loan principal /
          payments in completing the form?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. Please state the actual value of the loan principal / estimated
          payments as per the financing agreement.
          <br />
          <br />
          <br />
        </div>
        <strong>
          9. How do I determine the date of payment for withholding tax purpose?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          The date of payment is defined as the earliest of the following dates:
          <br />
          (i) when payment is due and payable based on agreement / contract. In
          the absence of agreement / contract, the date of invoice would be the
          deemed date of payment (credit terms should not be taken into
          consideration);
          <br />
          (ii) when payment is credited to the account of the non-resident
          (reinvested, accumulated, capitalised or carried to any reserve) or
          any other account however designated; and
          <br />
          (iii) date of actual payment.
          <br />
          <br />
          <u>To illustrate:</u>
          <br />
          Based on the financing agreement, the first relevant payment is due
          and payable on 5 Oct 2022 and paid by the company on 6 Oct 2022. The
          company made a specific accrual to the account designated to the
          foreign lender on 28 Sept 2022.
          <br />
          In this case, the date of payment for withholding tax purpose is 28
          Sept 2022 and the company is required to submit the self-declaration
          form by 15 Oct 2022.
          <br />
          <br />
          <br />
        </div>
        <strong>
          10. How do I determine the ‘Date of earliest relevant qualifying
          payment’?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          <u>To illustrate:</u>
          <br />
          Assuming that the interest payments are due and payable to the lender
          on a quarterly basis, and the 1st interest payment which covers the
          period Apr-June 2022 is payable to the lender on 30 June 2022; the 2nd
          interest payment covering the period July-Sept 2022 payable on 30 Sept
          2022, and so forth.
          <br />
          (i) If the entity submits the form on 1 July 2022 (i.e. before the
          15th of the month following June 2011), the date of earliest relevant
          qualifying payment will be 30 June 2022. Accordingly, the entire sum
          of the qualifying interest payment made on 30 June 2022, as well as
          subsequent interest payments made will qualify for the withholding tax
          exemption.
          <br />
          (ii) If the entity only submits the form on 15 Oct 2022(i.e. on the
          15th of the month following Sept 2022), the date of earliest relevant
          qualifying payment will be 30 Sept 2022. As such, the withholding tax
          exemption will only apply from the 2nd interest payment onwards. The
          entity will still be required to withhold tax on the 1st interest
          payment.
          <br />
          <br />
          <br />
        </div>
        <strong>
          11. I have obtained a financing arrangement for the purchase of
          vessel. Do I need to state the pre-delivery payments?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          For purchase of vessel, pre-delivery payments refers to interest and
          qualifying related payments incurred during the period before the
          vessel is registered or provisionally registered with SRS.
          <br />
          <br />
          <br />
        </div>
        <strong>
          12. The company obtained a loan and repaid a portion of it before
          submitting the self-declaration form. The company periodically repays
          part of the loan principal during the qualifying period. Should the
          “Total Amount Financed” and “Qualifying Amount Financed” be reduced by
          the amount repaid / potentially repaid?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. The amount to indicate should be the loan taken for the qualifying
          purpose, including amount repaid.
          <br />
          <br />
          <br />
        </div>
        <strong>
          13. I have submitted a self-declaration form previously and omitted to
          include certain payments (e.g. commitment fees) in the form.
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Please submit a change request from the relevant submitted
          self-declaration form via the portal to include the payments. However,
          please note that the withholding tax exemption, if applicable, will be
          on a prospective basis.
          <br />
          You may wish to consider if withholding tax is applicable, as under
          Section 12(6A) of Income Tax Act, withholding tax would not be
          applicable if the services are wholly performed outside Singapore.
          <br />
          <br />
          <br />
        </div>
        <Typography variant="h4" sx={{ textDecoration: "underline" }}>
          Purpose of Financing Arrangement
        </Typography>
        <strong>1. What are vessel lengthening works?</strong>
        <br />
        <br />
        <div className="IDPText">
          These are works that allow the vessel to increase its cargo / water
          depth capacity (i.e. to enhance the usage capacity of the vessel).
          <br />
          <br />
          <br />
        </div>
        <strong>
          2. Are vessel upgrading works (other than vessel lengthening /
          conversion works) qualifying financing arrangements for the
          withholding tax exemption?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Please email to <a href="mailto:wht@mpa.gov.sg">wht@mpa.gov.sg</a> and
          detail the nature of the upgrading works.
          <br />
          <br />
          <br />
        </div>
        <strong>
          3. What are examples of vessel navigation and safety equipment onboard
          the vessel that are qualifying?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          The equipment must be integral to the vessel’s operation. Equipment
          such as fire extinguishers, life vests, safety buoys, etc would not
          qualify as they are not integral to the vessel’s operation.
          <br />
          <br />
          <br />
        </div>
        <strong>
          4. The company obtained a foreign loan to:
          <br />
          (i) Finance equity injection into or intercompany loan to wholly owned
          SPV(s) for one or more of the qualifying purposes; and / or
          <br />
          (ii) Purchase 100% of the shares of SPV(s) that has / have 100% legal
          and beneficial ownership of one or more of the vessel(s).
          <br />
          Are there any restrictions on the type of SPV(s) entity type or
          registry of vessel(s)?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          For an SRS ship owner, the SPV would be restricted to Singapore
          companies owning only vessels that are / would be flagged under SRS.
          <br />
          For a ship owner under the MSI-AIS or MSI-ML(Ship) award, the SPV can
          be Singapore or foreign incorporated, and own Singapore and / or
          foreign flagged vessels.
          <br />
          <br />
        </div>
        <strong>
          5. Is the financing of capitalised interest a qualifying purpose under
          the withholding tax regime?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. The financing of capitalised interest (i.e. an arrangement where
          interest due is not paid but capitalised as part of the principal
          amount and only payable later) is not a qualifying purpose.
          <br />
          <br />
          <br />
        </div>
        <strong>
          6. The purpose of the financing arrangement differs from / is not
          clearly stated in the financing agreement.
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Please attach the following in the submission a letter of undertaking
          issued by an authorised signatory confirming that the financing
          arrangement / portion of the financing arrangement was obtained solely
          for the purpose indicated in the submission.
          <br />
          Where the purpose of the financing arrangement is only for general
          corporate use, such financing arrangement does not qualify for the
          withholding tax exemption.
          <br />
          <br />
          <br />
        </div>
        <strong>
          7. For re-financing loan, can I include interest and qualifying
          related payments payable on the original loan?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Yes, if (i) such payments are included in the same refinancing loan
          agreement; (ii) such payments are due but not paid at the point of
          refinancing; and (iii) the original loan was obtained for qualifying
          purpose(s).
          <br />
          <br />
          <u>To illustrate:</u>
          <br />
          A bridging loan of $100m is obtained from Bank X on 1 Jan X1 to
          finance the construction cost of a vessel (i.e. a qualifying purpose).
          Interest and qualifying related payments totalling $1m is payable on a
          quarterly basis and has to be settled by the 15th of the month
          following the end of each quarter (i.e. a 15 day credit period is
          provided by the lender). A long term loan is obtained on 1 Jan X2 from
          Bank Y to refinance the bridging loan. The loan quantum obtained from
          Bank Y includes the interest and qualifying payments of $1m payable
          for the quarter ended 31 Dec X1 on the bridging loan obtained from
          Bank X. The payment that can qualify for withholding tax exemption
          will include the interest and qualifying payments made on this $1m
          portion of the loan obtained from Bank Y.
          <br />
          <br />
          <br />
        </div>
        <strong>
          8. For financing arrangements that include non-qualifying purpose
          (e.g. to finance operating expenses of the vessel, working capital),
          how should I complete the form?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Withholding tax exemption would not be applicable to interest and
          related payments made in respect of loan for non-qualifying purpose.
          <br />
          The company must be able to segregate the quantum, interest and
          related payments between the qualifying and non-qualifying purpose.
          Please exclude amounts / details of lenders under the following
          fields, where applicable:
          <br />
          (i) Section 2: Non-tax resident’s details and
          <br />
          ii) Section 3: Qualifying details
          <br />
          And complete Section 3a: Non- qualifying details Please ensure that
          the company is able to produce documentation to support the
          attribution of funds.
          <br />
          <br />
          <br />
        </div>
        <strong>
          9. Will the following financing arrangements qualify for withholding
          tax exemption, if the shares relate to preference shares:
          <br />
          (a) Purchase of 100% of the shares of SPV(s) that has / have 100%
          legal and beneficial ownership of the qualifying vessel(s).
          <br />
          (b) Equity injection into wholly owned SPV(s) for qualifying purpose.
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Yes, preference shares would qualify. However, treasury shares will
          not qualify.
          <br />
          <br />
          <br />
        </div>
        <strong>
          10. Does a financing arrangement obtained by the company to finance a
          vessel that is not owned by the company, qualify for withholding tax
          exemption?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No, unless the financing arrangement is to finance the equity
          injection or intercompany loan to wholly owned SPV(s) for qualifying
          purpose.
          <br />
          <br />
          <br />
        </div>
        <Typography variant="h4" sx={{ textDecoration: "underline" }}>
          Type of Financing Arrangement
        </Typography>
        <strong>
          1. What type of financing arrangements are non-qualifying?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Non-qualifying arrangements include Islamic financing arrangement,
          seller’s credit, bonds, notes (excluding promissory notes), bills.
          <br />
          <br />
          <br />
        </div>
        <strong>
          2. Is overdraft facility a qualifying financing agreement under the
          withholding tax exemption regime?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. Typically, the difference between revolving facility and overdraft
          facility is as follows:
          <br />
          <br />
          <div class="click-zoom">
            <label>
              <input type="checkbox" />
              <img src={table2} className="WHTTable2Img imgZoom" alt="WHT" />
            </label>
          </div>
          <br />
          <br />
          However, if the nature of the overdraft facility acts like a revolving
          facility (i.e. large committed quantum and fixed tenure), the
          overdraft facility can be regarded as a qualifying financing
          arrangement under the withholding tax exemption regime.
          <br />
          <br />
        </div>
        <Typography variant="h4" sx={{ textDecoration: "underline" }}>
          Competitive Financing
        </Typography>
        <strong>
          1. What documentary evidence can I provide to provide to substantiate
          that competitive financing from Singapore is not available?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Documentary evidence include uncompetitive quotes obtained, rejection
          letters, refusal to quote, names of entities who had refused to quote.
          <br />
          <br />
        </div>
        <Typography variant="h4" sx={{ textDecoration: "underline" }}>
          Qualifying Amount / Payments
        </Typography>
        <strong>
          1. Can insurance payment qualify for the withholding tax exemption?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. The withholding tax exemption covers only interest and qualifying
          related payments on the qualifying financing arrangement obtained to
          finance the insurance premium payable to the export credit agencies
          for guaranteeing the loan.
          <br />
          <br />
          <br />
        </div>
        <strong>
          2. Can I include undrawn facility amount that is not tied to any
          qualifying purpose under the qualifying amount financed?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          No. Please exclude amounts / details of lenders under the following
          fields, where applicable:
          <br />
          i) Section 2: Non-tax resident’s details and
          <br />
          ii) Section 3: Qualifying details
          <br />
          And complete Section 3a: Non-qualifying details
          <br />
          Please submit a change request via the portal when the amount is drawn
          down in future for qualifying purpose.
          <br />
          <br />
          <br />
        </div>
        <strong>
          3. Are interest rate or currency swap payments covered under the
          self-declaration form?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          Yes, if the swap agreements were undertaken in connection with the
          qualifying financing arrangement.
          <br />
          If the swaps arrangements are not specified in the financing
          agreement, please provide supporting documentary evidence to show that
          the swap agreements were taken in connection with the qualifying
          financing arrangement.
          <br />
          <br />
          <br />
        </div>
      </Typography>
    </>
  );
}

export default WHTPage;
