import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material';
import { IconArrowLeft } from '@tabler/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from "react-router-dom";
import AISaward from './AISaward';
import binoculars from '../../Images/Img-MSI.png';
import MLaward from './MLaward';
import SSSaward from './SSSaward';
import SelectMenu from '../FAQs/menuOptions'

const StyledTabs = styled(Tabs)({
  marginLeft: 30,
  marginRight: 30,
  width: 740,
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent"
  }
});
const StyledTab = styled(Tab)({
  fontSize: 18,
  fontFamily: "Univers-65Bold",
  lineHeight: '28px',
  textTransform: 'none',
  textAlign: 'left',
  margin: '10px 0px',
  padding: '20px 0px',
  WebkitAlignItems: 'baseline',
  borderBottom: '2px solid #eaecf0',
  '&.Mui-selected': {
    borderBottom: '2px solid #0065bd'
  }
});
const StyledTabPanel = styled(TabPanel)({
  width: 2100
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function MSIScheme() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickMenu = (event) => {
    setValue(event.target.value)
  };
  const history = useHistory();
  const label1 = <p>MSI - Approved International Shipping Enterprise <nobr>(MSI-AIS)</nobr> Award</p>;
  const label2 = <p>MSI - Maritime Leasing <nobr>(MSI-ML)</nobr> Award</p>;
  const label3 = <p>MSI - Shipping-related Support Services <nobr>(MSI-SSS)</nobr> Award</p>;
  const menuitem = [{ key: 0, text: "MSI - Approved International Shipping Enterprise (MSI-AIS) Award " }, { key: 1, text: "MSI - Maritime Leasing (MSI-ML) Award" }, { key: 2, text: "MSI - Shipping-related Support Services (MSI-SSS) Award" }]

  const checkMin600 = useMediaQuery('(min-width: 600px)')

  return (
    <div className="exploreschemes">
      <div className="scheme-background">
        <div className="backbanner" />
        <Container>
          <br />
          <Button onClick={() => history.push({
            pathname: "/ExploreSchemes",
            tab: 'Incentives'
          })}
            className='nav-back-btn'>
            <IconArrowLeft color='#666' />
          </Button>
          <Breadcrumbs sx={{ display: 'inline-block', verticalAlign: 'middle' }} aria-label="breadcrumb">
            <Link underline='hover'
              onClick={() => history.push('/Home')}
              mx={1}
              className='active-link univers-65-bold-scarpa-flow-12px'>
              Home
            </Link>
            <Link underline='hover'
              onClick={() => history.push({
                pathname: "/ExploreSchemes",
                tab: 'Incentives'
              })}
              mx={1}
              className='active-link univers-65-bold-scarpa-flow-12px'>
              Incentives
            </Link>
            <Link underline='none'
              mx={1} className='disabled-link univers-65-bold-scarpa-flow-12px'>
              Maritime Sector Incentive
            </Link>
          </Breadcrumbs>
          <br />
          <br />
          <Grid container>
          <Grid item xs={useMediaQuery("(min-width: 768px)") ? 6 : 12}>
              <Typography variant='h2'>Maritime Sector Incentive</Typography>
              <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-xl) !important', mt: 1 }}>The Maritime Sector Incentive (MSI) Awards encourage shipowners, operators, and shipping-related support service providers to grow their business operations in Singapore.</Typography>

            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            {/* <img src={binoculars} id='binoculars' alt='MSI' /> */}
            <img style={{ display: useMediaQuery('(min-width: 1090px)') ? 'initial' : 'none' }} src={binoculars} id='idpschemespic' alt='MSI' />
            </Grid>
          </Grid>
        </Container>

        <Container className='schememsi'>
          <div className='mobilefaq' style={{ display: useMediaQuery('(max-width: 900px)') ? "" : "none" }}>
            <br />
            <br />
            <Typography variant='h4'>Categories</Typography>
            <SelectMenu handleClickMenu={handleClickMenu} active={value} menuitem={menuitem} />
          </div>


          <Paper elevation={0} sx={{ position: 'relative', mt: checkMin600 ? 17 : 5, p: 3, border: '1px solid #EAECF0', borderRadius: 3 }}>
            <Box sx={{ flexGrow: 1, display: checkMin600 ? "flex" : "none" }}>
              <StyledTabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
              >
                <StyledTab label={label1} {...a11yProps(0)} />
                <StyledTab label={label2} {...a11yProps(1)} />
                <StyledTab label={label3} {...a11yProps(2)} />
                {/* <StyledTab label={label4} {...a11yProps(3)} /> */}
              </StyledTabs>
              {useMediaQuery('(min-width: 600px)') ?
                <>
                  <StyledTabPanel value={value} index={0}>
                    <AISaward />
                  </StyledTabPanel>
                  <StyledTabPanel value={value} index={1}>
                    <MLaward />
                  </StyledTabPanel>
                  <StyledTabPanel value={value} index={2}>
                    <SSSaward />
                  </StyledTabPanel>
                </>
                : <></>
              }
              {/* <StyledTabPanel value={value} index={3}>
                <MSIFAQ />
              </StyledTabPanel> */}
            </Box>
            {useMediaQuery('(max-width: 599px)') ?
              <Box sx={{ flexGrow: 1, display: 'flex', }}>
                <StyledTabPanel value={value} index={0}>
                  <AISaward />
                </StyledTabPanel>
                <StyledTabPanel value={value} index={1}>
                  <MLaward />
                </StyledTabPanel>
                <StyledTabPanel value={value} index={2}>
                  <SSSaward />
                </StyledTabPanel>
              </Box>
              : <></>
            }
          </Paper>
        </Container>
      </div>
    </div>
  )
}

