import { Button, Typography, useMediaQuery } from '@mui/material';
import { IconArrowNarrowRight } from '@tabler/icons';
import { useHistory } from "react-router-dom";

export default function PurchaseOrConstruction() {
    const history = useHistory();
    const pdfPath = process.env.PUBLIC_URL + '/docs/omp-documents-files/WHT-Internet-User-Guide.pdf';
    return (
        <>
            <Typography variant='h3'>Arrangements Obtained To Finance The Purchase Or Construction Of Ships</Typography>
            <Typography my={5} className='univers-55roman-normal-mine-shaft-22px' align='justify'>
                The WHT exemption will, subject to conditions, be extended to qualifying payments made on or after 1 June 2011 in respect of qualifying financing arrangements entered into on or before 31 December 2026 to finance the purchase or construction of Singapore-flagged and foreign-flagged vessels.
            </Typography>
            <Typography variant='h4'>Benefits</Typography>
            <Typography mt={2} mb={5} className='univers-55roman-normal-mine-shaft-16px' lineHeight={2}>
                Qualifying entities which obtain qualifying financing arrangements are no longer required to apply for such exemption on a case-by-case basis during the period from 1 June 2011 to 31 December 2026.
            </Typography>
            <Typography variant='h4'>Eligibility</Typography>
            <Typography mt={2} mb={5} className='univers-55roman-normal-mine-shaft-16px' lineHeight={2}>
                The exemption is available to:
                <ul>
                    <li>MSI-Shipping Enterprise (Singapore Registry of Ships) (MSI-SRS);</li>
                    <li>MSI-Approved International Shipping (MSI-AIS) entities; and</li>
                    <li>MSI-Maritime Leasing (Ship) [MSI-ML(Ship)] entities.</li>
                </ul>
            </Typography>
            <Typography variant='h4'>How to qualify?</Typography>
            <Typography mt={2} mb={5} className='univers-55roman-normal-mine-shaft-16px' lineHeight={2} align="justify">
                Entities are required to submit a self-declaration form (the "Form") for each financing arrangement obtained to inform the relevant authorities that the qualifying conditions have been met. The Form has to be submitted to MPA via the One Maritime Portal by the 15th of the month following the first relevant payment due date to the non-resident.
            </Typography>

            <Typography variant='h4'>How to apply?</Typography>
            <Typography mt={2} mb={2} className='univers-55roman-normal-mine-shaft-16px' lineHeight={2}>
                <ul>
                    <li>To submit the Form, please login via Corppass though this portal. Please refer to the&nbsp;
                        <a className='clickContactText'
                            href={pdfPath}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            User Guide
                        </a>
                        &nbsp;for submission of the Form.
                    </li>
                    <li>For more information, please refer to the Frequently Asked Questions
                        (<a className='clickContactText' onClick={() => history.push('/FAQs')}>FAQs</a>)
                        page or submit your query via <a className='clickContactText' onClick={() => history.push('/ContactUs')}>Contact Us</a>.
                    </li>
                </ul>
            </Typography>

            <Button className="large-long button-large-with-arrow-outlined univers-65-bold-science-blue-16px"
                sx={{ px: '20px', mr: 4 }}
                onClick={() => history.push({
                    pathname: "/SDshipEligibilityForm",
                    search: `?path=eligibility`,
                })}
                endIcon={<IconArrowNarrowRight />}>
                Check Eligibility
            </Button>
            {useMediaQuery('(max-width: 599px)') ? <><br /><br /></> : null}
            <Button className="large-long button-large-with-arrow-filled univers-65-bold-white-16px"
                onClick={() => history.push({
                    pathname: "/WHTQEntityTypeForm",
                    search: `?path=application`,
                })}
                endIcon={<IconArrowNarrowRight />}>
                Apply Now
            </Button>
        </>
    )
}
