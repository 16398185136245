import React, { useEffect, useRef, useState } from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
	useLocation,
} from "react-router-dom";
import loginHelper from "./Common/loginHelper";
import MasterCSS from "./Components/MasterCSS";
import Header from "./Components/Header/Header";
import Login from "./Components/Login/Login"; //Bernadette
import AboutOMP from "./Components/AboutOMP/AboutOMP";
import GuidedJourney from "./Components/GuidedJourney/GuidedJourney";
import HomePage from "./Components/HomePage/HomePage";
import Dashboard from "./Components/Dashboard/Dashboard";
import FAQs from "./Components/FAQs/FAQs";
import ExploreSchemes from "./Components/ExploreSchemes/ExploreSchemes";
import MSIScheme from "./Components/ExploreSchemes/MSIScheme";
import IDPScheme from "./Components/ExploreSchemes/IDPScheme";
import WHTScheme from "./Components/ExploreSchemes/WHTScheme";
import Footer from "./Components/CommonCSS/Footer";
import ApplicantHeader from "./Components/Header/ApplicantHeader";
import WHTShipForm from "./Components/Incentives/WHT/WHTShipForm";
import WHTShipReview from "./Components/Incentives/WHT/WHTShipReview";
import WHTShipChangeRequestForm from "./Components/Incentives/WHT/WHTShipChangeRequestForm";
import WHTShipChangeRequestReview from "./Components/Incentives/WHT/WHTShipChangeRequestReview";
import WHTShipAppealForm from "./Components/Incentives/WHT/WHTShipAppealForm";
import WHTQEntityTypeForm from "./Components/Incentives/WHT/WHTQEntityTypeForm";
import SDshipEligibilityForm from "./Components/Incentives/WHT/SDshipEligibilityForm";
import SDContainerEligibility from "./Components/Incentives/WHT/SDContainerEligibility";
import IDPEligibilityForm from "./Components/Grants/IDP/IDPEligibilityForm";
import IDPMainForm from "./Components/Grants/IDP/IDPMainForm";
import IDPMainReview from "./Components/Grants/IDP/IDPMainReview";
import IDPClaimForm from "./Components/Grants/IDP/IDPClaimForm";
import IDPClaimReview from "./Components/Grants/IDP/IDPClaimReview";
import LoginRedirect from "./Components/Login/LoginRedirect";
import DevGuide from "./Components/Internal/DevGuide";
import WHTContainerForm from "./Components/Incentives/WHT/WHTContainerForm";
import WHTContainerReview from "./Components/Incentives/WHT/WHTContainerReview";
import WHTContainerChangeRequestForm from "./Components/Incentives/WHT/WHTContainerChangeRequestForm";
import WHTContainerChangeRequestReview from "./Components/Incentives/WHT/WHTContainerChangeRequestReview";
import WHTContainerAppealReview from "./Components/Incentives/WHT/WHTContainerAppealReview";
import WHTContainerAppealForm from "./Components/Incentives/WHT/WHTContainerAppealForm";
import MSIAISMainForm from "./Components/Incentives/MSI-AIS/MSIAISMainForm";
import MSIAISMainReview from "./Components/Incentives/MSI-AIS/MSIAISMainReview";
import MockLogin from "./Components/Login/MockLogin";
import IDPChangeRequest from "./Components/Grants/IDP/IDPChangeRequest";
import IDPChangeRequestReview from "./Components/Grants/IDP/IDPChangeRequestReview";
import { config } from "./config";
import ContactUs from "./Components/ContactUs/ContactUs";
import SSICCodes from "./Components/Details-Information/SSICCodes";
import PrivateRoute from "./PrivateRoute";
import ScrollToTop from "./Hooks/ScrollToTop";
import Acceptance from "./Components/Acceptance/Acceptance";
import PublicUser from "./Components/PublicUser/PublicUser";
import PublicUserListing from "./Components/PublicUser/PublicUserListing";
import WHTShipAppealReview from "./Components/Incentives/WHT/WHTShipAppealReview";
import AddUserView from "./Components/PublicUser/AddUserView";
import NestedApplicationListing from "./Components/NestedApplicationListing/Components/ApplicationListing";
import SearchEngine from "./Components/SearchEngine/SearchEngine";
import PageNotFound from "./Components/404/PageNotFound";
import LogOffAlert from "./Components/LogOffAlert/LogOffAlert";
import SessionExpiredDialog from "./Common/SessionExpiredDialog";
import { dispatchStartAutoLogOutTimerEvent } from "./Common/DispatchEvent";
import { useIdleTimer } from "react-idle-timer";
import { fetch } from "./Common/API";

function retryRenewToken() {
	setTimeout(function () {
		dispatchStartAutoLogOutTimerEvent();
	}, 60000);
}

function Routing() {
	const idleDuration = 5;
	const location = useLocation();
	const eventBus = document;
	const [open, setOpen] = useState(false);
	const [isLogin, setIsLogin] = useState(!!loginHelper.getLoginJwt());
	const ref = useRef("Active");
	const [openSessionExpired, setOpenSessionExpired] = useState(false);
	ref.State = "onActive";
	ref.IsStarted = false;
	const logoutAction = () => {
		loginHelper.deleteLoginInfo();
		window.location.pathname = "/";
	};

	function handleDate(remainingTime) {
		const date = loginHelper.getDate();
		if (date === null) {
			loginHelper.setDate(new Date(new Date().getTime() + remainingTime));
		} else {
			const difference = date.getTime() - new Date().getTime();
			const differenceInMinutes = Math.floor(difference / 1000 / 60);
			if (differenceInMinutes < 0 && differenceInMinutes > idleDuration) {
				loginHelper.setDate(
					new Date(new Date().getTime() + remainingTime * 60000),
				);
			}
		}
	}

	function showCountdown() {
		const date = loginHelper.getDate();
		if (date != null) {
			const difference = date.getTime() - new Date().getTime();
			const differenceInMinutes = Math.floor(difference / 1000 / 60);
			if (differenceInMinutes >= 0) {
				setOpen(true);
			} else {
				loginHelper.setDate(null);
			}
		}
	}

	function startAutoLogOutTimer() {
		setHeader(0);
		startOrPauseIdleTimer(0);
		if (loginHelper.getLoginJwt()) {
			if (ref.Timeout) {
				clearTimeout(ref.Timeout);
			}
			showCountdown();

			ref.Timeout = setTimeout(
				async function () {
					if (ref.State !== "onIdle" && loginHelper.getDate() == null) {
						try {
							const token = {
								refreshToken: loginHelper.getRefreshJWT(),
							};
							const apiResult = await fetch(
								`${config.appURL}/api/Auth/GetNewJwtTokens`,
								"POST",
								token,
							);
							if (apiResult.status === 200) {
								loginHelper.setRefreshJWT(apiResult.data.data.refreshToken);
								loginHelper.setLoginJwt(apiResult.data.data.accessToken);
							} else {
								retryRenewToken();
							}
						} catch (e) {
							if (e !== "Forbidden") {
								retryRenewToken();
							}
						}
					}
				},
				new Date(loginHelper.decodeJwt().exp * 1000) -
					new Date() -
					config.intervalPopupBeforeExpired,
			);
		}
	}

	function sessionExpiredDialog() {
		setOpenSessionExpired(true);
	}

	const onIdle = () => {
		ref.State = "onIdle";
		if (loginHelper.getLoginJwt()) {
			if (ref.Timeout) {
				clearTimeout(ref.Timeout);
			}
			logoutAction();
		}
	};

	const onActive = () => {
		ref.State = "onActive";
	};

	const onPrompt = () => {
		if (loginHelper.getLoginJwt()) {
			if (ref.Timeout) {
				clearTimeout(ref.Timeout);
			}
			handleDate(getRemainingTime());
			setOpen(true);
		}
	};

	const { getRemainingTime, start, pause } = useIdleTimer({
		onIdle,
		onActive,
		onPrompt,
		timeout: 1000 * 60 * 30,
		promptBeforeIdle: 1000 * 60 * 5,
		crossTab: true,
	});

	useEffect(() => {
		eventBus.addEventListener("StartAutoLogOutTimer", startAutoLogOutTimer);

		eventBus.addEventListener("SessionExpiredDialog", sessionExpiredDialog);
	}, []);

	function setHeader(timer) {
		setTimeout(function () {
			setIsLogin(!!loginHelper.getLoginJwt());
		}, timer);
	}

	useEffect(() => {
		dispatchStartAutoLogOutTimerEvent();
		setHeader(400);
	}, [location]);

	function startOrPauseIdleTimer(timer) {
		setTimeout(function () {
			if (loginHelper.getLoginJwt()) {
				if (!ref.IsStarted) {
					ref.IsStarted = true;
					start();
				}
				if (new Date(loginHelper.decodeJwt().exp * 1000) - new Date() <= 0) {
					logoutAction();
				}
			} else {
				ref.IsStarted = false;
				pause();
				if (loginHelper.getLogoutFlag()) {
					logoutAction();
				}
			}
		}, timer);
	}

	useEffect(() => {
		startOrPauseIdleTimer(500);
	}, [loginHelper.getLoginJwt()]);

	return (
		<Router>
			{open && (
				<LogOffAlert
					open={open}
					setOpen={setOpen}
				/>
			)}
			{openSessionExpired && (
				<SessionExpiredDialog
					open={openSessionExpired}
					setOpen={setOpenSessionExpired}
				/>
			)}
			<ScrollToTop>
				<Header isLogin={isLogin} />
				<Switch>
					<Route
						exact
						path="/"
						render={() => {
							return loginHelper.getLoginUser() ? (
								<Redirect to="/Dashboard" />
							) : (
								<Redirect to="/Home" />
							);
						}}
					/>
					<PrivateRoute path="/MasterCSS">
						<Route
							isAdminPage={false}
							futureRelease={false}
							isGlobalPage={true}
							component={MasterCSS}
						/>
					</PrivateRoute>
					<Route
						path="/Header"
						component={Header}
					/>
					<Route
						path="/Login"
						component={Login}
					/>{" "}
					{/* Bernadette */}
					<Route
						path="/AboutOMP"
						component={AboutOMP}
					/>
					<Route
						path="/GuidedJourney"
						component={GuidedJourney}
					/>
					<Route
						path="/Home"
						component={HomePage}
					/>
					<PrivateRoute path="/Dashboard">
						<Route
							isGlobalPage={true}
							futureRelease={false}
							scheme={"none"}
							component={Dashboard}
						/>
					</PrivateRoute>
					<Route
						exact
						path="/Grants"
						render={() => {
							return (
								<Redirect
									to={{
										pathname: "/ExploreSchemes",
										state: {
											tab: "Grants",
										},
									}}
								/>
							);
						}}
					/>
					<Route
						exact
						path="/Incentives"
						render={() => {
							return (
								<Redirect
									to={{
										pathname: "/ExploreSchemes",
										state: {
											tab: "Incentives",
										},
									}}
								/>
							);
						}}
					/>
					<Route
						path="/FAQs"
						component={FAQs}
					/>
					<Route
						path="/ExploreSchemes"
						component={ExploreSchemes}
					/>{" "}
					<Route
						path="/ExploreSchemes"
						component={ExploreSchemes}
					/>
					<Route
						path="/MSIScheme"
						component={MSIScheme}
					/>
					<Route
						path="/WHTScheme"
						component={WHTScheme}
					/>
					{config.isIDP === "true" && (
						<Route
							path="/IDPScheme"
							component={IDPScheme}
						/>
					)}
					<PrivateRoute path="/MSIAISMainForm">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"WHT"}
							component={MSIAISMainForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/MSIAISMainReview">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"WHT"}
							component={MSIAISMainReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/ApplicantHeader">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"none"}
							component={ApplicantHeader}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTShipForm">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTShipForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTShipReview">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTShipReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTShipChangeRequestForm">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTShipChangeRequestForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTShipChangeRequestReview">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTShipChangeRequestReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTShipAppealForm">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTShipAppealForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTShipAppealReview">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTShipAppealReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTContainerForm">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTContainerForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTContainerReview">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTContainerReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTContainerChangeRequestForm">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTContainerChangeRequestForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTContainerChangeRequestReview">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTContainerChangeRequestReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTContainerAppealForm">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTContainerAppealForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTContainerAppealReview">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTContainerAppealReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/WHTQEntityTypeForm">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"WHT"}
							component={WHTQEntityTypeForm}
						/>
					</PrivateRoute>
					<Route
						path="/SDshipEligibilityForm"
						component={SDshipEligibilityForm}
					/>
					<Route
						path="/SDContainerEligibility"
						component={SDContainerEligibility}
					/>
					<PrivateRoute path="/NestedApplicationListing">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"none"}
							component={NestedApplicationListing}
						/>
					</PrivateRoute>
					<PrivateRoute path="/IDPEligibilityForm">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"IDP"}
							component={IDPEligibilityForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/IDPMainForm">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"IDP"}
							component={IDPMainForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/IDPMainReview">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"IDP"}
							component={IDPMainReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/IDPClaimForm">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"IDP"}
							component={IDPClaimForm}
						/>
					</PrivateRoute>
					<PrivateRoute path="/IDPClaimReview">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"IDP"}
							component={IDPClaimReview}
						/>
					</PrivateRoute>
					<PrivateRoute path="/IDPChangeRequestForm">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"IDP"}
							component={IDPChangeRequest}
						/>
					</PrivateRoute>
					<PrivateRoute path="/IDPChangeRequestReview">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"IDP"}
							component={IDPChangeRequestReview}
						/>
					</PrivateRoute>
					<Route
						path="/LoginRedirect"
						component={() => <LoginRedirect setBackToLogin={setIsLogin} />}
					/>
					<PrivateRoute path="/DevGuide">
						<Route
							isAdminPage={false}
							futureRelease={false}
							scheme={"none"}
							component={DevGuide}
						/>
					</PrivateRoute>
					<Route
						path="/MockLogin"
						component={
							["development", "sit"].includes(config.environment)
								? MockLogin
								: HomePage
						}
					/>
					<Route
						path="/ContactUs"
						component={ContactUs}
					/>
					<Route
						path="/Search"
						component={SearchEngine}
					/>
					<PrivateRoute path="/SSICCodes">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"none"}
							component={SSICCodes}
						/>
					</PrivateRoute>
					<PrivateRoute path="/Acceptance/:submissionId">
						<Route
							isAdminPage={false}
							futureRelease={true}
							scheme={"IDP"}
							component={Acceptance}
						/>
					</PrivateRoute>
					<PrivateRoute path="/PublicUser">
						<Route
							isAdminPage={true}
							futureRelease={false}
							scheme={"none"}
							component={PublicUser}
						/>
					</PrivateRoute>
					<PrivateRoute path="/PublicUserListing">
						<Route
							isAdminPage={true}
							futureRelease={false}
							scheme={"none"}
							component={PublicUserListing}
						/>
					</PrivateRoute>
					<PrivateRoute path="/AddUserView">
						<Route
							isAdminPage={true}
							futureRelease={false}
							scheme={"none"}
							component={AddUserView}
						/>
					</PrivateRoute>
					<Route
						path="/404"
						component={PageNotFound}
					/>
					<Route
						exact
						path="*">
						<Redirect to="/404" />
					</Route>
				</Switch>
				<Footer />
			</ScrollToTop>
		</Router>
	);
}

export default Routing;
