import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState, useContext } from "react";
import { GuidedJourneyContext } from "../../Hooks/GuidedJourneyHook";

export default function BusinessType() {
  const [sgregistered, setSgregistered] = useState("");
  const [incentive, setIncentive] = useState("");
  const [journey, setJourney] = useContext(GuidedJourneyContext);

  return (
    <>
      <Typography mb={1} variant="h3">
        Help us get to know you
      </Typography>

      <br />
      <Typography variant="h4">
        <strong>
          Q1. Are you applying for yourself or for your organisation?
        </strong>
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          value={sgregistered}
          onChange={(e) => setSgregistered(e.target.value)}
          onClick={(e) => setJourney(e.target.value)}
          sx={{ m: 2, flexDirection: "row" }}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="individual"
            control={<Radio />}
            label="Individual"
          />
          <FormControlLabel
            value="organization"
            control={<Radio />}
            label="Organisation"
          />
        </RadioGroup>
      </FormControl>
      {sgregistered === "organization" ? (
        <>
          <Typography mt={2} variant="h4">
            <strong>
              Q2. Are you looking for Grant or Incentive to improve your
              organisation?
            </strong>
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              value={incentive}
              onChange={(e) => setIncentive(e.target.value)}
              onClick={(e) => setJourney(e.target.value)}
              sx={{ m: 2, flexDirection: "row" }}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="grant"
                control={<Radio />}
                label="Grant"
              />
              <FormControlLabel
                value="incentive"
                control={<Radio />}
                label="Incentive"
              />
              <FormControlLabel value="both" control={<Radio />} label="Both" />
            </RadioGroup>
          </FormControl>
        </>
      ) : null}
    </>
  );
}
