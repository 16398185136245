import React, { useState } from 'react'
import { Box, Card, Container, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { styled } from "@mui/material/styles";


import LoginTab from './LoginTab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//style tabs
const StyledTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
        backgroundColor: "rgba(0, 101, 189, 1)",
        height: "5px",
        width: "300",
        display: "flex",
        alignItems: "center",
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        height: "70px",
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: "400",
        marginRight: 15,
        fontFamily: "Univers-55Roman",
        fontSize: 20,
        "&.Mui-selected": {
            color: "rgba(51, 51, 51, 1)",
            fontWeight: "700",
            fontSize: "18px",
        },
        "&.Mui-focusVisible": {
            backgroundColor: "#d0343a",
        },
    })
);

function Login() {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Container sx={{ width: "500px", minHeight: "562px", alignItems: "center"}}>
                <Card sx={{ height: "auto" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
                            <StyledTab label="Login" {...a11yProps(0)} />
                            <StyledTab label="Create an Account" {...a11yProps(1)} />
                        </StyledTabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <LoginTab />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Create an Account
                    </TabPanel>
                </Card>
            </Container>
        </div>
    )
}


export default Login
