import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IconArrowLeft, IconArrowNarrowRight } from "@tabler/icons";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import IDPimg from "../../Images/Img-IDP.png";
import greater from "../../Images/greaterthen.png";
import less from "../../Images/lessthen.png";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function IDPScheme() {
  const history = useHistory();

  return (
    <div className="exploreschemes">
      <div className="scheme-background">
        <div className="backbanner" />

        <Container>
          <br />
          <Button
            onClick={() =>
              history.push({
                pathname: "/ExploreSchemes",
                tab: "Grants",
              })
            }
            className="nav-back-btn"
          >
            <IconArrowLeft color="#666" />
          </Button>
          <Breadcrumbs
            sx={{ display: "inline-block", verticalAlign: "middle" }}
            aria-label="breadcrumb"
          >
            <Link
              underline="hover"
              onClick={() => history.push("/Home")}
              mx={1}
              className="active-link univers-65-bold-scarpa-flow-12px"
            >
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ cursor: "pointer" }}
              onClick={() =>
                history.push({
                  pathname: "/ExploreSchemes",
                  tab: "Grants",
                })
              }
              mx={1}
              className="active-link univers-65-bold-scarpa-flow-12px"
            >
              Grants
            </Link>
            <Link
              underline="none"
              mx={1}
              className="disabled-link univers-65-bold-scarpa-flow-12px"
            >
              Sea Transport Industry Digital Plan
            </Link>
          </Breadcrumbs>
          <br />
          <br />
          <Grid container>
            <Grid item xs={useMediaQuery("(max-width: 1090px)") ? 16 : 6.5}>
              <Typography variant="h2">
                Sea Transport Industry Digital Plan
              </Typography>

              <Typography
                className="univers-55roman-normal-mine-shaft-18px"
                sx={{ fontSize: "var(--font-size-xl) !important", mt: 1 }}
              >
                The Sea Transport Industry Digital Plan (IDP) is a part of the
                SMEs Go Digital Programme which provides a step-by-step guide on
                the digital solutions SMEs in Sea Transport sector can adopt at
                each stage of their growth.
              </Typography>
            </Grid>

            <img
              style={{
                display: useMediaQuery("(min-width: 1090px)")
                  ? "initial"
                  : "none",
              }}
              src={IDPimg}
              id="idpschemespic"
              alt="IDP"
            />
          </Grid>
        </Container>

        <Container>
          {useMediaQuery("(min-width: 600px)") ? (
            <Paper
              elevation={0}
              sx={{
                position: "relative",
                mt: 16,
                px: 12,
                py: 8,
                border: "1px solid #EAECF0",
                borderRadius: 3,
              }}
            >
              <Typography variant="h3">
                Sea Transport Industry Digital Plan (IDP)
              </Typography>
              <Typography
                my={5}
                className="univers-55roman-normal-mine-shaft-22px"
                align="justify"
              >
                The Sea Transport Industry Digital Plan (IDP) is a joint
                initiative with Infocomm Media Development Authority (IMDA),
                Enterprise Singapore (ESG) and SkillsFuture Singapore (SSG), as
                part of the SMEs Go Digital Programme that makes going digital
                simple for SMEs. It provides SMEs with an easy-to-use,
                step-by-step guide to support them in the adoption of digital
                solutions.
              </Typography>
              <Typography variant="h4">Benefits</Typography>
              <Typography
                mt={2}
                mb={5}
                className="univers-55roman-normal-mine-shaft-16px"
                lineHeight={2}
                align="justify"
              >
                To ease SMEs' adoption of digital technologies, MPA and IMDA
                have curated a list of maritime-specific digital solutions for
                Sea Transport SMEs to meet their needs.&nbsp;
                <b>
                  The funding quantum to adopt the list of maritime-specific
                  digital solutions is up to 70%, capped at S$30,000 for each
                  SME applicant.
                </b>
                &nbsp;The list of solution providers is as below. This list will
                be updated periodically as we continue to onboard more suitable
                solutions on the programme.
              </Typography>

              <Typography variant="h4">
                <b>
                  The Approved Vendors and Maritime-Specific Digital Solutions
                </b>
              </Typography>

              <Typography
                my={3}
                className="univers-55roman-normal-mine-shaft-18px"
                align="justify"
              >
                <b>
                  <u>Helm CONNECT</u>
                </b>
              </Typography>

              <Typography
                my={3}
                className="univers-55roman-normal-mine-shaft-16px"
                align="justify"
              >
                <strong>Web URL:</strong> &nbsp;
                <a
                  href="https://www.helmoperations.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.helmoperations.com/
                </a>
              </Typography>

              <Typography
                my={3}
                className="univers-55roman-normal-mine-shaft-16px"
                align="justify"
              >
                <strong>Contact Hotline:</strong>&nbsp; 62390280
              </Typography>
              <Typography
                my={3}
                className="univers-55roman-normal-mine-shaft-16px"
                align="justify"
              >
                <strong>Contact Email:</strong>&nbsp; product.apac@spectec.net
              </Typography>

              <Typography
                mt={2}
                mb={5}
                className="univers-55roman-normal-mine-shaft-16px"
                lineHeight={2}
                align="justify"
              >
                Helm CONNECT is a vessel management and optimization solution
                designed to connect your maintenance, compliance, personnel, and
                operations together into one easy-to-use system. Helm CONNECT
                has become the fastest growing platform for managing marine
                operations and is now used by over 250 companies and 5000 assets
                globally. Whether it is managing safety and compliance,
                maintenance, personnel, inventory, billing, or jobs, Helm
                CONNECT gives you the power to do your job more efficiently.
              </Typography>

              <Typography variant="h4">Eligibility</Typography>
              <Typography
                mt={2}
                mb={5}
                className="univers-55roman-normal-mine-shaft-16px"
                lineHeight={2}
              >
                SMEs from the Sea Transport sector are eligible to apply for Sea
                Transport IDP <br />
                The applicant must be registered and is operating in
                Singapore.&nbsp;
                <br />
                <List sx={{ marginLeft: "15px" }}>
                  <ListItem
                    style={{ display: "list-item", textAlign: "justify" }}
                  >
                    The Applicant must have (i)&nbsp;
                    <img src={greater} id="lessgreater" alt="Greater" /> 30%
                    local shareholding ownership AND (ii)&nbsp;
                    <img src={less} id="lessgreater" alt="Lesser" /> S$100 mil
                    annual sales turnover (or if the applicant is part of a
                    Group1, the aggregated annual sales turnover of the Group
                    must be <img src={less} id="lessgreater" alt="lessgreater" /> S$100 mil) OR
                    employment size <img src={less} id="lessgreater" alt="lessgreater" /> 200
                    workers (or if the applicant is part of a Group, the total
                    number of workers employed by the Group must be&nbsp;
                    <img src={less} id="lessgreater" alt="lessgreater" />
                    &nbsp; 200).&nbsp;
                  </ListItem>
                  <ListItem
                    style={{ display: "list-item", textAlign: "justify" }}
                  >
                    The Applicant's primary or secondary business activities
                    must be in the Sea Transport sub-sectors according to the
                    Singapore Standard Industrial Classification (SSIC) code.
                  </ListItem>
                  <ListItem
                    style={{ display: "list-item", textAlign: "justify" }}
                  >
                    The Applicant's purchase / lease / subscription of the IT
                    solution must be solely for the applicant's use in Singapore
                    (e.g. the IT solution must not be for the use of another
                    party or the applicant's overseas entities).
                  </ListItem>
                </List>
              </Typography>

              <Typography variant="h4">How to apply?</Typography>
              <Typography
                mt={2}
                mb={5}
                className="univers-55roman-normal-mine-shaft-16px"
                lineHeight={2}
              >
                <ul>
                <li>To submit the form, please login via Corppass through this portal.</li>
                <li>For more information, please refer to the Frequently Asked Questions 
                    (<a className='clickContactText' onClick={() => history.push('/FAQs')}>FAQs</a>) 
                    page or submit your query via <a className='clickContactText' onClick={() => history.push('/ContactUs')}>Contact Us</a>.
                </li>
                </ul>
              </Typography>
              <Button
                className="large-long button-large-with-arrow-filled univers-65-bold-white-16px"
                onClick={() => history.push("/IDPMainForm")}
                endIcon={<IconArrowNarrowRight />}
              >
                Apply Now
              </Button>
            </Paper>
          ) : (
            <Paper
              elevation={0}
              sx={{
                position: "relative",
                mt: 12.3,
                px: 2,
                py: 8,
                border: "1px solid #EAECF0",
                borderRadius: 3,
              }}
            >
              <Typography variant="h3">
                Sea Transport Industry Digital Plan (IDP)
              </Typography>
              <Typography
                my={5}
                className="univers-55roman-normal-mine-shaft-22px"
                align="justify"
              >
                The Sea Transport Industry Digital Plan (IDP) is a joint
                initiative with Infocomm Media Development Authority (IMDA),
                Enterprise Singapore (ESG) and SkillsFuture Singapore (SSG), as
                part of the SMEs Go Digital Programme that makes going digital
                simple for SMEs. It provides SMEs with an easy-to-use,
                step-by-step guide to support them in the adoption of digital
                solutions.
              </Typography>
              <Typography variant="h4">Benefits</Typography>
              <Typography
                mt={2}
                mb={5}
                className="univers-55roman-normal-mine-shaft-16px"
                lineHeight={2}
                align="justify"
              >
                To ease SMEs' adoption of digital technologies, MPA and IMDA
                have curated a list of maritime-specific digital solutions for
                Sea Transport SMEs to meet their needs.&nbsp;
                <b>
                  The funding quantum to adopt the list of maritime-specific
                  digital solutions is up to 70%, capped at S$30,000 for each
                  SME applicant.
                </b>
                &nbsp; The list of solution providers is as below. This list
                will be updated periodically as we continue to onboard more
                suitable solutions on the programme.
              </Typography>
              <Typography variant="h4">
                <b>
                  The Approved Vendors and Maritime-Specific Digital Solutions
                </b>
              </Typography>

              <Typography
                my={3}
                className="univers-55roman-normal-mine-shaft-18px"
                align="justify"
              >
                <b>
                  <u>Helm CONNECT</u>
                </b>
              </Typography>

              <Typography
                my={3}
                className="univers-55roman-normal-mine-shaft-16px"
                align="justify"
              >
                <strong>Web URL :</strong>&nbsp;
                <a
                  href="https://www.helmoperations.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.helmoperations.com/
                </a>
              </Typography>

              <Typography
                my={3}
                className="univers-55roman-normal-mine-shaft-16px"
                align="justify"
              >
                <strong>Contact Hotline</strong>: 62390280
              </Typography>
              <Typography
                my={3}
                className="univers-55roman-normal-mine-shaft-16px"
                align="justify"
              >
                <strong>Contact Email</strong>: product.apac@spectec.net
              </Typography>

              <Typography
                mt={2}
                mb={5}
                className="univers-55roman-normal-mine-shaft-16px"
                lineHeight={2}
                align="justify"
              >
                Helm CONNECT is a vessel management and optimization solution
                designed to connect your maintenance, compliance, personnel, and
                operations together into one easy-to-use system. Helm CONNECT
                has become the fastest growing platform for managing marine
                operations and is now used by over 250 companies and 5000 assets
                globally. Whether it is managing safety and compliance,
                maintenance, personnel, inventory, billing, or jobs, Helm
                CONNECT gives you the power to do your job more efficiently.
              </Typography>

              <Typography variant="h4">Eligibility</Typography>
              <Typography
                mt={2}
                mb={5}
                className="univers-55roman-normal-mine-shaft-16px"
                lineHeight={2}
              >
                SMEs from the Sea Transport sector are eligible to apply for Sea
                Transport IDP <br />
                The applicant must be registered and is operating in
                Singapore.&nbsp;
                <br />
                <List sx={{ marginLeft: "15px" }}>
                  <ListItem
                    style={{ display: "list-item", textAlign: "justify" }}
                  >
                    The Applicant must have (i)&nbsp;
                    <img src={greater} id="lessgreater" alt="lessgreater" /> 30%
                    local shareholding ownership AND (ii)&nbsp;
                    <img src={less} id="lessgreater" alt="lessgreater" /> S$100
                    mil annual sales turnover (or if the applicant is part of a
                    Group1, the aggregated annual sales turnover of the Group
                    must be&nbsp;
                    <img src={less} id="lessgreater" alt="lessgreater" /> S$100
                    mil) OR employment size&nbsp;
                    <img src={less} id="lessgreater" alt="lessgreater" /> 200
                    workers (or if the applicant is part of a Group, the total
                    number of workers employed by the Group must be&nbsp;
                    <img src={less} id="lessgreater" alt="lessgreater" />
                    &nbsp; 200).&nbsp;
                  </ListItem>
                  <ListItem
                    style={{ display: "list-item", textAlign: "justify" }}
                  >
                    The Applicant's primary or secondary business activities
                    must be in the Sea Transport sub-sectors according to the
                    Singapore Standard Industrial Classification (SSIC) code.
                  </ListItem>
                  <ListItem
                    style={{ display: "list-item", textAlign: "justify" }}
                  >
                    The Applicant's purchase / lease / subscription of the IT
                    solution must be solely for the applicant's use in Singapore
                    (e.g. the IT solution must not be for the use of another
                    party or the applicant's overseas entities).
                  </ListItem>
                </List>
              </Typography>

              <Typography variant="h4">How to apply?</Typography>
              <Typography
                mt={2}
                mb={5}
                className="univers-55roman-normal-mine-shaft-16px"
                lineHeight={2}
              >
                <ul>
                <li>To submit the form, please login via Corppass through this portal.</li>
                <li>For more information, please refer to the Frequently Asked Questions 
                    (<a className='clickContactText' onClick={() => history.push('/FAQs')}>FAQs</a>) 
                    page or submit your query via <a className='clickContactText' onClick={() => history.push('/ContactUs')}>Contact Us</a>.
                </li>
                </ul>
              </Typography>
              <Button
                className="large-long button-large-with-arrow-filled univers-65-bold-white-16px"
                onClick={() => history.push("/IDPMainForm")}
                endIcon={<IconArrowNarrowRight />}
              >
                Apply Now
              </Button>
            </Paper>
          )}
        </Container>
      </div>
    </div>
  );
}
