/* eslint-disable eqeqeq */
import { Components } from 'formiojs';
import './Review.css';

const HTML_ELEMENT = Components.components.field;

export default class ReviewBtn extends HTML_ELEMENT {

  static schema() {
    return HTML_ELEMENT.schema({
      type: 'review',
      numRows: 2,
      numCols: 1
    });
  }

  static builderInfo = {
    title: 'Review',
    group: 'basic',
    icon: 'fa fa-table',
    weight: 70,
    documentation: 'http://help.form.io/userguide/#table',
    schema: ReviewBtn.schema()
  }
  renderString(template: any, data: any): HTMLElement {
    const htmlComponent: HTMLElement = document.createElement("div")
    return htmlComponent
  }
  public render(children:any) {
    return super.render(this.renderString("div", {}).outerHTML);
  }
  public attach(element: any) {
    let label = element.querySelector('label')
    if (label != undefined && label instanceof HTMLElement) {
      label.style.display = "none"
    }
    return super.attach(element)
  }
  
}
