import { CircularProgress, Box } from '@mui/material';

export default function Spinner() {

    return (
        <Box 
            display="flex" 
            alignItems="center"
            justifyContent="center"
            height={300} 
        >
            <CircularProgress />
        </Box>
    )
}