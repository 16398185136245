const {config} = require("../config");
const allowedHosts = [new URL(config.baseURL + "/").host];

function validateAndSanitizeHost(url) {
  return config.environment === "development" || allowedHosts.some(host => host === new URL(url).host);
}

module.exports = {
  validateAndSanitizeHost
};
