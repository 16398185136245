export async function GenerateSRI(URL) {
  try {
    const response = await fetch(URL);
    const scriptContent = await response.text();
    const sriHash = await generateSha512Base64Hash(scriptContent);
    const script = document.createElement("script");
    script.src = URL;
    script.integrity = "sha512-" + sriHash;
    script.crossOrigin = "anonymous";
    document.head.appendChild(script);
  } catch (error) {
    console.error("Error fetching or calculating SRI hash:", error);
  }
}

async function generateSha512Base64Hash(scriptContent) {
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(scriptContent);
    const hashBuffer = await crypto.subtle.digest("SHA-512", data);
    const hashArray = new Uint8Array(hashBuffer);
    return btoa(String.fromCharCode(...hashArray));
  } catch (error) {
    console.error("Error calculating SHA-512 hash:", error);
    return null;
  }
}
