import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, Link, List, ListItem, Typography } from "@mui/material";
import './Acceptance.css';
import {
  acceptLetterOfOfferApplication_APIAsync,
  getLetterOfOfferById,
  getLetterOfOfferPlaceHolders_APIAsync,
  putLetterOfAcceptanceSignedToMongoDB_APIAsync,
  upLoadLetterOfAcceptanceSigned_APIAsync
} from "../../Redux/Acceptance/AcceptanceSlice";
import AcceptanceConfirmRejectDialog from './AcceptanceConfirmRejectDialog';
import AcceptanceAcceptDialog from './AcceptanceAcceptDialog';
import { config } from '../../config';
import { IconFileDownload } from '@tabler/icons';
import Spinner from '../../Common/Spinner';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { v4 as uuidv4 } from 'uuid';
import { getDownload } from '../../Common/API';

export default function Acceptance() {
  const history = useHistory();
  const { submissionId } = useParams();
  const dispatch = useDispatch();
  const [openRejectConfirmationDialog, setOpenRejectConfirmationDialog] = useState(false);
  const [openAcceptConfirmationDialog, setOpenAcceptConfirmationDialog] = useState(false);
  const [letterOfOfferStatus, setLetterOfOfferStatus] = useState('');
  const [btnStatus, setBtnStatus] = useState(true);
  const [alreadyAcceptedOrRejectedLOOStatus, setAlreadyAcceptedOrRejectedLOOStatus] = useState('');
  const [acceptedDateOver30DaysStatus, setAcceptedDateOver30DaysStatus] = useState('');
  const [signedLOA, setSignedLOA] = useState(``);
  const [msg, setMsg] = useState('');
  const acceptanceList = useSelector((state) => state.AcceptanceList);
  const id = acceptanceList.data?.applicationSubmissionId;
  const requestorAction = acceptanceList.data?.requestorAction;
  const created = new Date(acceptanceList.created).toLocaleDateString('en-SG', { day: 'numeric', month: 'short', year: 'numeric' });
  const applicantName = acceptanceList.data?.applicantName;
  const solutionName = acceptanceList.data?.solutionName?.solutionName;
  const estimatedProjectedClaimAmount = acceptanceList.data?.containerHidden?.officerGrantAmount;
  const requestorActionDate = acceptanceList.data?.requestorActionDate;
  const downloadSignedLetterOfAcceptance = acceptanceList.data?.letterOfAcceptanceSigned;
  const todayDate = new Date();
  const expiredDate = new Date(acceptanceList.data?.expiredDate);
  let expiredStatus = "";
  const [file, setFile] = useState();
  const [fileSignedLOA, setFileSignedLOA] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  let myTimeout = 0;
  let upLoadSignedLOOButton;
  if (todayDate > expiredDate && expiredDate.toLocaleDateString('en-SG', { day: 'numeric', month: 'short', year: 'numeric' }) !== '1 Jan 1') {
    expiredStatus = "LOO_EXPIRED";
  }
  let text = "";
  if (alreadyAcceptedOrRejectedLOOStatus === "DO_NOT_SHOW_REJECT_BUTTON")
    text = "Reupload Letter of Acceptance signed with signature";
  else
    text = "Upload Letter of Acceptance signed with signature";
  if (file !== undefined) {
    upLoadSignedLOOButton = <Button component="span"
      size="large"
      className="button-Letter-of-Offer"
      // startIcon={<SvgIcon component={PdfIcon} inheritViewBox />}
      endIcon={<UploadFileIcon />}
    >
      {text}<br />
      File: {file ? file.name.length > 37 ? `${file.name.replace(file.name.substring(((file.name.length - (file.name.length - 37)) / 2) - 3, file.name.length - 8), "***")}` : `${file.name}` : <></>}
    </Button>;
  } else {
    upLoadSignedLOOButton = <Button component="span"
      size="large"
      className="button-Letter-of-Offer"
      // startIcon={<SvgIcon component={PdfIcon} inheritViewBox />}
      endIcon={<UploadFileIcon />}
    >
      {text}
    </Button>;
  }

  useEffect(() => {
    dispatch(getLetterOfOfferById({ submissionId: submissionId }))
      .unwrap()
      .then(() => {
        // cannot set status here because this api returns result OK but data all null
      })
      .catch(() => {
        setLetterOfOfferStatus("CONNECTION ERROR");
      });
  }, [dispatch, submissionId]);

  if (id !== null && id !== undefined && alreadyAcceptedOrRejectedLOOStatus !== "ALREADY_ACCEPTED_OR_REJECTED_LOO") {
    if (letterOfOfferStatus !== "LETTER OF OFFER API OK" && letterOfOfferStatus !== "CONNECTION ERROR") {
      dispatch(getLetterOfOfferPlaceHolders_APIAsync({ submissionId: submissionId }))
        .unwrap()
        .then(() => {
          setLetterOfOfferStatus("LETTER OF OFFER API OK");
          if (requestorAction === "10" && requestorAction !== undefined && requestorAction !== null) {
            setAlreadyAcceptedOrRejectedLOOStatus("DO_NOT_SHOW_REJECT_BUTTON");
          }
          if (requestorActionDate !== "" && requestorActionDate !== undefined && requestorActionDate !== null) {
            let iDate = new Date(requestorActionDate);
            if ((Math.abs(todayDate - iDate) / (1000 * 3600 * 24) > 30)) {
              setAcceptedDateOver30DaysStatus("DO_NOT_SHOW_ACCEPT_BUTTON");
            }
          }
          if (downloadSignedLetterOfAcceptance !== "" && downloadSignedLetterOfAcceptance !== undefined && downloadSignedLetterOfAcceptance !== null) {
            // get file name only without uuid
            let fileName = downloadSignedLetterOfAcceptance.substring(downloadSignedLetterOfAcceptance.indexOf("=") + 1,
              downloadSignedLetterOfAcceptance.lastIndexOf("_")) + '.pdf';

            // Updated correct path to download previously uploaded file
            let fileNameWithUuid = downloadSignedLetterOfAcceptance.substring(downloadSignedLetterOfAcceptance.indexOf("=") + 1,
              downloadSignedLetterOfAcceptance.lastIndexOf(".pdf")) + '.pdf';
            let fileDownloadUrl = `${config.appURL}/api/application/UploadFileAPI?form=${fileNameWithUuid}`;
            setSignedLOA(fileDownloadUrl);
            setFileSignedLOA(fileName);
          }
        })
        .catch(() => {
          setLetterOfOfferStatus("CONNECTION ERROR");
        });
    }
  }

  const acceptLetterOfOfferApplication = () => {
    dispatch(acceptLetterOfOfferApplication_APIAsync({ submissionId: submissionId, formId: 'idpmainform' }))
      .unwrap()
      .then((response) => {
        if (response.Status && response.Status.Message === "OK") {
          setMsg("You have accepted the letter of offer.");
        } else {
          setMsg("Accept application not successful. Please try again later.");
        }
        clearTimeout(myTimeout);
        setBtnStatus(0);
      })
      .catch(() => {
        setMsg("Accept application not successful. Please try again later.");
        setBtnStatus(0);
      });
  }

  const handleAccept = () => {
    setOpenAcceptConfirmationDialog(true);
    myTimeout = setTimeout(() => {
      setMsg("Network Error. Please try again later.");
    }, 120000);
    setMsg("Accept operation in progress.");
    setBtnStatus(0);
    const formData = new FormData();
    formData.append('file', file);
    const myuuid = uuidv4();
    const nameOfFile = file.name.replace(".pdf", "");
    formData.append('name', `${nameOfFile}_${myuuid}.pdf`);
    dispatch(upLoadLetterOfAcceptanceSigned_APIAsync({ formData: formData }))
      .unwrap()
      .then(() => {
        dispatch(putLetterOfAcceptanceSignedToMongoDB_APIAsync({ submissionId: submissionId, fileName: `${nameOfFile}_${myuuid}.pdf` }))
          .unwrap()
          .then(() => {
            if (alreadyAcceptedOrRejectedLOOStatus !== "DO_NOT_SHOW_REJECT_BUTTON")
              acceptLetterOfOfferApplication();
            else {
              setMsg("Letter of acceptance reuploaded.");
              clearTimeout(myTimeout);
              setBtnStatus(0);
            }
          })
          .catch(() => {
            setMsg("Signed Letter of Acceptance upload not successful. Please try again later.");
            setBtnStatus(0);
          });
      })
      .catch(() => {
        setMsg("Upload operation not successful. Please try again later.");
        setBtnStatus(0);
      });
  };

  const handleReject = () => {
    setOpenRejectConfirmationDialog(true);
    myTimeout = setTimeout(() => {
      setMsg("Network Error. Please try again later.");
    }, 120000);
  };

  const handleBack = () => {
    history.push({
      pathname: '/NestedApplicationListing',
      search: '?schema=1'
    })
  };

  function handleChange(event) {
    let result = event.target.files[0]
    setFile(result);
    if (result !== undefined) {
      setIsDisabled(false);
    }
    else {
      setIsDisabled(true);
    }
    if (!result.name.endsWith('.pdf')) {
      setIsDisabled(true);
      alert('Please select a pdf file only.');
    }
  }

  const downloadLetterOfAcceptance = async () => {
    const url = `${config.appURL}/api/letterofoffer/DownloadAcceptancePDF?submissionId=${submissionId}`;
    await getDownload(url, 'LetterOfAcceptance.pdf');
  }

  const downloadPDFLocation = async () => {
    const url = `${config.appURL}/api/letterofoffer/DownloadApplicationPDF?submissionId=${submissionId}`;
    await getDownload(url, 'LetterOfOffer.pdf');
  }

  const downloadYourSignedLetterOfAcceptance = async () => {
    await getDownload(signedLOA, fileSignedLOA);
  }

  return (
    <Container maxWidth="lg">
      <AcceptanceConfirmRejectDialog
        open={openRejectConfirmationDialog}
        setOpen={setOpenRejectConfirmationDialog}
        submissionId={submissionId}
      />
      <AcceptanceAcceptDialog
        open={openAcceptConfirmationDialog}
        setOpen={setOpenAcceptConfirmationDialog}
        submissionId={submissionId}
        msg={msg}
        btnStatus={btnStatus}
      />
      {
        // alreadyAcceptedOrRejectedLOO === "ALREADY_ACCEPTED_OR_REJECTED_LOO" ?
        //       // below already accepted or rejected Letter of Offer render
        //       <>
        //       <br />
        //       <br />
        //       <br />
        //       <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
        //         You have already taken action on the letter of offer.
        //       </h2>
        //       <br />
        //       <br />
        //       <br />
        //       <Button className="large button-large univers-65-bold-white-16px role-margin" onClick={handleBack}>
        //           Back
        //       </Button>
        //       <br />
        //       <br />
        //       <br />
        //     </>
        // : expiredStatus === "LOO_EXPIRED" ?
        expiredStatus === "LOO_EXPIRED" ?
          // below expired Letter of Offer render
          <>
            <br />
            <br />
            <br />
            <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
              Your Letter of Offer has expired.
            </h2>
            <br />
            <br />
            <br />
            <Button className="large button-large univers-65-bold-white-16px role-margin" onClick={handleBack}>
              Back
            </Button>
            <br />
            <br />
            <br />
          </>
          : id === null ?
            // below accept status OK is when user clicks on Accept button and api returns OK
            <>
              <br />
              <br />
              <br />
              <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
                No Letter of Offer found.
              </h2>
              <br />
              <br />
              <br />
              <Button className="large button-large univers-65-bold-white-16px role-margin" onClick={handleBack}>
                Back
              </Button>
              <br />
              <br />
              <br />
            </>
            : letterOfOfferStatus === "CONNECTION ERROR" ?
              // below connection error render
              <>
                <br />
                <br />
                <br />
                <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
                  Internal server error.
                </h2>
                <br />
                <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
                  Please try again later.
                </h2>
                <br />
                <br />
                <br />
                <Button className="large button-large univers-65-bold-white-16px role-margin" onClick={handleBack}>
                  Back
                </Button>
                <br />
                <br />
                <br />
              </>
              : letterOfOfferStatus === "LETTER OF OFFER API OK" ?
                // below status OK can retrieve from database normal render
                <>
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {acceptedDateOver30DaysStatus === "DO_NOT_SHOW_ACCEPT_BUTTON" ?
                      <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
                        View Letter of Offer and Letter of Acceptance
                      </h2>
                      :
                      alreadyAcceptedOrRejectedLOOStatus === "DO_NOT_SHOW_REJECT_BUTTON" ?
                        <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
                          Reupload Letter of Acceptance
                        </h2>
                        :
                        <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
                          Accept Offer
                        </h2>
                    }
                  </div>
                  <br />
                  <div style={{ display: 'inherit', backgroundColor: 'white', marginBottom: 20, border: '1px solid #EAECF0', borderRadius: '12px' }}>
                    <div className="header-MPA-OMP" />
                    <br />
                    <div style={{ margin: 20 }}>
                      <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-xl) !important', mt: 1 }}>We refer to your application first submitted on {created !== 'Invalid Date' && created} and subsequent information provided (hereinafter called "the Application"), for incentives under Enterprise Development Grant (Industry) - Sea Transport IDP.</Typography>
                      <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-xl) !important', mt: 1 }}>We are pleased to inform you that the Maritime and Port Authority of Singapore (hereinafter called "MPA") has agreed to provide a grant not exceeding S$ {estimatedProjectedClaimAmount} in total to {applicantName}, for its project on {solutionName} (the "Project") under Sea Transport IDP.</Typography>
                      <br />
                      <Button size="large"
                        className="button-Letter-of-Offer"
                        endIcon={<IconFileDownload />}
                        onClick={downloadPDFLocation}
                      >
                        Download Letter Of Offer
                      </Button>
                      <br />
                      <br />
                      <Button size="large"
                        className="button-Letter-of-Offer"
                        endIcon={<IconFileDownload />}
                        onClick={downloadLetterOfAcceptance}
                      >
                        Download Letter Of Acceptance
                      </Button>
                      <br />
                      <br />
                      {alreadyAcceptedOrRejectedLOOStatus === "DO_NOT_SHOW_REJECT_BUTTON" ?
                        <>
                          <Button size="large"
                            className="button-Letter-of-Offer"
                            endIcon={<IconFileDownload />}
                            onClick={downloadYourSignedLetterOfAcceptance}
                          >
                            Download Your Signed Letter Of Acceptance<br />
                            Previous Uploaded File: {fileSignedLOA !== undefined ? fileSignedLOA : ''}
                          </Button>
                          <br />
                          <br />
                        </>
                        :
                        <></>
                      }
                      {acceptedDateOver30DaysStatus === "DO_NOT_SHOW_ACCEPT_BUTTON" ?
                        <></>
                        :
                        alreadyAcceptedOrRejectedLOOStatus === "DO_NOT_SHOW_REJECT_BUTTON" ?
                          <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-xl) !important', mt: 1 }}>If necessary, you can reupload Letter of Acceptance signed with your signature.</Typography>
                          :
                          <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-xl) !important', mt: 1 }}>Click below button "Upload Letter of Acceptance signed with signature" and select a signed Letter of Acceptance pdf file to enable the "Accept" button.</Typography>
                      }
                      <br />
                      {acceptedDateOver30DaysStatus === "DO_NOT_SHOW_ACCEPT_BUTTON" ?
                        <></>
                        :
                        <>
                          <input
                            type="file"
                            onChange={handleChange}
                            accept=".pdf"
                            style={{ display: 'none' }}
                            id="uploadfile"
                          />
                          <label htmlFor="uploadfile">
                            {upLoadSignedLOOButton}
                          </label>
                          <br />
                          <br />
                          {alreadyAcceptedOrRejectedLOOStatus === "DO_NOT_SHOW_REJECT_BUTTON" ?
                            <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-xl) !important', mt: 1 }}>By clicking the below "Reupload" button and endorsing the letter of acceptance</Typography>
                            :
                            <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-xl) !important', mt: 1 }}>By clicking "Accept" and endorsing the letter of acceptance</Typography>
                          }
                          <List className="univers-55roman-normal-mine-shaft-18px" sx={{
                            fontSize: 'var(--font-size-xl) !important',
                            listStyleType: 'disc',
                            pl: 4,
                            '& .MuiListItem-root': {
                              display: 'list-item',
                            },
                          }}>
                            <ListItem>I acknowledge on behalf of the applicant that I have read and understood the terms and conditions set out in the Letter of Offer and all it encloses.</ListItem>
                            <ListItem>I confirm that all declarations and information provided in application to this scheme are true and correct.</ListItem>
                            <ListItem>The Applicant accepts the award of the scheme on the terms and conditions set out in the above mentioned Letter of Offer and its enclosures.</ListItem>
                            <ListItem>We undertake to ensure that all terms and conditions in the Letter of Offer and all it encloses are complied with.</ListItem>
                          </List>
                        </>
                      }
                    </div>
                    <div style={{ margin: 20 }}>
                    </div>
                  </div>
                  <Button className="large button-large univers-65-bold-white-16px role-margin" onClick={handleBack}>
                    Back
                  </Button>
                  <div style={{ float: 'right' }}>
                    {acceptedDateOver30DaysStatus === "DO_NOT_SHOW_ACCEPT_BUTTON" ?
                      <></>
                      :
                      alreadyAcceptedOrRejectedLOOStatus === "DO_NOT_SHOW_REJECT_BUTTON" ?
                        <Button className="large button-large univers-65-bold-white-16px role-margin" onClick={() => { handleAccept() }} disabled={isDisabled}>
                          Reupload
                        </Button>
                        :
                        <Button className="large button-large univers-65-bold-white-16px role-margin" onClick={() => { handleAccept() }} disabled={isDisabled}>
                          Accept
                        </Button>
                    }
                    &nbsp;
                    &nbsp;
                    {/* {alreadyAcceptedOrRejectedLOO === "DO_NOT_SHOW_REJECT_BUTTON" ? */}
                    {alreadyAcceptedOrRejectedLOOStatus === "DO_NOT_SHOW_REJECT_BUTTON" ?
                      <></>
                      :
                      <Button className="large button-large univers-65-bold-white-16px role-margin" onClick={() => { handleReject() }} >
                        Reject
                      </Button>
                    }
                  </div>
                  <br />
                  <br />
                </>
                :
                // below retrieving record from server render
                <>
                  <br />
                  <br />
                  <br />
                  <Spinner />
                  {/* <h2 className='h1-global' style={{ fontFamily: "Univers-65Bold" }}>
                Retrieving record from server...
              </h2> */}
                  <br />
                  <br />
                  <br />
                </>
      }
    </Container>
  )
}
