import { useEffect, useRef, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	styled,
	Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import loginHelper from "../../Common/loginHelper";
import { config } from "../../config";
import { fetch } from "../../Common/API";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		width: "767px",
		padding: "24px",
		borderRadius: "10px",
	},
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
		"& li": {
			listStyle: "disc",
		},
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
		justifyContent: "space-between",
	},
}));

const BootstrapDialogTitle = (props) => {
	const { children, ...other } = props;

	return (
		<DialogTitle
			sx={{ m: 0, p: 2, fontSize: "28px !important" }}
			{...other}>
			{children}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
};

export default function LogOffAlert({ open, setOpen }) {
	const Ref = useRef(null);
	const eventBus = document;
	let countDownTimeInSeconds = 300;
	if (loginHelper.getDate() != null) {
		const difference = loginHelper.getDate().getTime() - new Date().getTime();
		countDownTimeInSeconds = Math.floor(difference / 1000);
	}

	const getDeadTime = () => {
		let deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + countDownTimeInSeconds);
		return deadline;
	};
	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		return {
			total,
			minutes,
			seconds,
		};
	};
	const { minutes, seconds } = getTimeRemaining(getDeadTime());
	const countDownTimeText =
		minutes + " min " + (seconds > 9 ? seconds : "0" + seconds) + " sec ";
	const [timer, setTimer] = useState(countDownTimeText);
	const [status, setStatus] = useState("");

	const handleLogOut = (event, reason) => {
		if (reason !== "backdropClick") {
			setOpen(false);
			logoutAction();
		}
	};

	const handleRetry = (event, reason) => {
		if (reason !== "backdropClick") {
			handleToContinue();
			setOpen(true);
		}
	};

	const handleToContinue = async (event, reason) => {
		if (Ref.current) clearInterval(Ref.current);
		if (reason !== "backdropClick") {
			try {
				const token = {
					refreshToken: loginHelper.getRefreshJWT(),
				};
				const apiResult = await fetch(
					`${config.appURL}/api/Auth/GetNewJwtTokens`,
					"POST",
					token,
				);
				if (apiResult.status === 200) {
					setOpen(false);
					if (Ref.current) clearInterval(Ref.current);
					loginHelper.setRefreshJWT(apiResult.data.data.refreshToken);
					loginHelper.setLoginJwt(apiResult.data.data.accessToken);
				} else if (apiResult.status === 401) {
					setStatus("Session expired.");
				}
			} catch (e) {
				setStatus("Refresh token api error.");
			}
		}
	};

	const logoutAction = () => {
		loginHelper.deleteLoginInfo();
		window.location.pathname = "/";
	};

	const startTimer = (e) => {
		let { total, minutes, seconds } = getTimeRemaining(e);
		if (total >= 0) {
			setTimer(
				minutes + " min " + (seconds > 9 ? seconds : "0" + seconds) + " sec ",
			);
		} else {
			handleLogOut();
			if (Ref.current) clearInterval(Ref.current);
		}
	};

	const clearTimer = (e) => {
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000);
		Ref.current = id;
	};

	function CloseLogOffAlert() {
		if (Ref.current) clearInterval(Ref.current);
		setOpen(false);
	}

	useEffect(() => {
		clearTimer(getDeadTime());
		eventBus.addEventListener("CloseLogOffAlert", CloseLogOffAlert);
	}, []);

	return (
		<>
			<BootstrapDialog
				aria-labelledby="customized-dialog-title"
				open={open}
				disableEscapeKeyDown>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					variant="h3">
					{status === "Session expired." ? (
						<>
							<div style={{ display: "flex", justifyContent: "center" }}>
								Your session has expired.
							</div>
						</>
					) : status === "Refresh token api error." ? (
						<>
							<div style={{ display: "flex", justifyContent: "center" }}>
								Refresh token api error.
							</div>
						</>
					) : (
						<>
							<div style={{ display: "flex", justifyContent: "center" }}>
								Your session is about to expire in
							</div>
							<div style={{ display: "flex", justifyContent: "center" }}>
								{timer}
							</div>
							<br />
							<div style={{ display: "flex", justifyContent: "center" }}>
								Continue?
							</div>
						</>
					)}
				</BootstrapDialogTitle>
				<DialogContent /*dividers*/>
					<Typography
						gutterBottom
						className="univers-55roman-normal-mine-shaft-16px"></Typography>
				</DialogContent>
				{status === "Session expired." ? (
					<>
						<div style={{ float: "right" }}>
							<DialogActions style={{ float: "right" }}>
								<Button
									onClick={handleLogOut}
									className="button-large univers-65-bold-white-16px">
									OK
								</Button>
							</DialogActions>
						</div>
					</>
				) : status === "Refresh token api error." ? (
					<>
						<div style={{ float: "right" }}>
							<DialogActions style={{ float: "right" }}>
								<Button
									onClick={handleRetry}
									className="button-large univers-65-bold-white-16px">
									Retry
								</Button>
							</DialogActions>
						</div>
					</>
				) : (
					<>
						<div>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<DialogActions style={{ float: "right" }}>
									<Button
										onClick={handleToContinue}
										className="button-large univers-65-bold-white-16px">
										Yes
									</Button>
								</DialogActions>
							</div>
						</div>
					</>
				)}
			</BootstrapDialog>
		</>
	);
}
