import { Redirect, Route, useHistory } from "react-router-dom";
import loginHelper from './Common/loginHelper';

const {config} = require("./config");
const PrivateRoute = ({children, ...rest}) => {
  const userLoggedIn = loginHelper.getLoginUser();
  const history = useHistory();
  const urlRedirectUser = () => {
    history.push("/Dashboard");
  };
  const renderContent = ({ currentLocation }) => {
    if (!userLoggedIn) {
      return (
              <Redirect
                      to={{
                        pathname: "/Home",
                        state: {
                          from: currentLocation,
                          privateRoute: true,
                        },
                      }}
              />
      );
    }

    const { isAdminPage, isGlobalPage, scheme, futureRelease } = children.props;
    const isUserAdmin = userLoggedIn.CorppassRole === "Admin";
    const userSchemes = userLoggedIn.Schemes || [];

    const isAllowedPage =
            (!isAdminPage || (isAdminPage && isUserAdmin) || isGlobalPage) &&
            (userSchemes.includes(scheme) || scheme === "none");

    if (!isAllowedPage) {
      urlRedirectUser();
    } else if (futureRelease) {
      urlRedirectUser();
    } else {
      return children;
    }
  };
  return <Route {...rest} render={renderContent}/>;
};
export default PrivateRoute;
