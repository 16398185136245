import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  UploadVesselCheckboxType,
} from "../../../Common/CommonEnumTypes";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
      width: "767px",
      padding: "24px",
      borderRadius: "10px",
  },
  "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
      "& li": {
          listStyle: "disc",
      },
  },
  "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
      justifyContent: "space-between",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
      <DialogTitle sx={{ m: 0, p: 2, fontSize: "28px !important" }} {...other}>
          {children}
          {onClose ? (
              <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: "absolute",
                      right: 32,
                      top: 28,
                      color: (theme) => theme.palette.grey[500],
                  }}
              >
                  <CloseIcon />
              </IconButton>
          ) : null}
      </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UploadVesselsDialog({
  open,
  setOpenDialog,
  response,
  type,
}) {
  const handleClose = () => {
    if(setOpenDialog) {
      setOpenDialog(false);
    }
    if(response) {
      response("Cancel", type);
    }
  };
  return (
    <>
      <BootstrapDialog
        BackdropClick="disabled" // Set BackdropClick to 'disabled'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle
            id="customized-dialog-title"
            variant="h3"
            sx = {{marginBottom: '20px'}}
            onClose={handleClose}
        >
        </BootstrapDialogTitle>
        <DialogContent>
          {type === UploadVesselCheckboxType.DATAGRID_TO_EXCEL && (
            <Typography id="alert-dialog-description" gutterBottom
            className="univers-55roman-normal-mine-shaft-16px">
              Please note that your data input in the table will be removed if
              you choose to proceed.
            </Typography>
          )}

          {type === UploadVesselCheckboxType.EXCEL_TO_DATAGRID && (
            <Typography id="alert-dialog-description" gutterBottom
            className="univers-55roman-normal-mine-shaft-16px">
              Please note that your uploaded file will be removed if you choose
              to proceed.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="small univers-65-bold-white-16px btn_cancel"
            onClick={() => {
              setOpenDialog(false);
              response("Cancel", type);
            }}
          >
            Cancel
          </Button>
          <Button
            className="small univers-65-bold-white-16px button-medium"
            onClick={() => {
              setOpenDialog(false);
              response("Confirm", type);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
