export const config = {
    environment: process.env.REACT_APP_ENVIRONMENT,
    appURL : process.env.REACT_APP_APPURL,
    appFormBaseURL: process.env.REACT_APP_APPFORMBASEURL,
    spcpReturnUrl: process.env.REACT_APP_SPCPRETURNURL,
    cseID: process.env.REACT_APP_CSEID,
    deploymentDate: process.env.REACT_APP_DEPLOYMENTDATE,
    intervalPopupBeforeExpired: process.env.REACT_APP_INTERVALPOPUPBEFOREEXPIRED,
    baseURL: process.env.REACT_APP_BASEURL,
    vesselUploadInputSampleURL: process.env.REACT_APP_VESSEL_UPLOAD_INPUT_SAMPLE_DOWNLOAD_URL,
    form_name_target_ship_main: process.env.REACT_APP_FORMNAME_TARGET_SHIP_MAIN,
    form_name_target_ship_cr: process.env.REACT_APP_FORMNAME_TARGET_SHIP_CR,
    form_name_target_ship_mof: process.env.REACT_APP_FORMNAME_TARGET_SHIP_MOF,
    form_name_target_container_main: process.env.REACT_APP_FORMNAME_TARGET_CONTAINER_MAIN,
    form_name_target_container_cr: process.env.REACT_APP_FORMNAME_TARGET_CONTAINER_CR,
    form_name_target_container_mof: process.env.REACT_APP_FORMNAME_TARGET_CONTAINER_MOF,
    form_name_target_idp_main: process.env.REACT_APP_FORMNAME_TARGET_IDP_MAIN,
    form_name_target_idp_cr: process.env.REACT_APP_FORMNAME_TARGET_IDP_CR,
    form_name_target_idp_claim: process.env.REACT_APP_FORMNAME_TARGET_IDP_CLAIM,
    form_name_target_msi_main: process.env.REACT_APP_FORMNAME_TARGET_MSI_MAIN,
    form_name_original_ship_main: process.env.REACT_APP_FORMNAME_ORIGINAL_SHIP_MAIN,
    form_name_original_ship_cr: process.env.REACT_APP_FORMNAME_ORIGINAL_SHIP_CR,
    form_name_original_ship_mof: process.env.REACT_APP_FORMNAME_ORIGINAL_SHIP_MOF,
    form_name_original_container_main: process.env.REACT_APP_FORMNAME_ORIGINAL_CONTAINER_MAIN,
    form_name_original_container_cr: process.env.REACT_APP_FORMNAME_ORIGINAL_CONTAINER_CR,
    form_name_original_container_mof: process.env.REACT_APP_FORMNAME_ORIGINAL_CONTAINER_MOF,
    form_name_original_idp_main: process.env.REACT_APP_FORMNAME_ORIGINAL_IDP_MAIN,
    form_name_original_idp_cr: process.env.REACT_APP_FORMNAME_ORIGINAL_IDP_CR,
    form_name_original_idp_claim: process.env.REACT_APP_FORMNAME_ORIGINAL_IDP_CLAIM,
    form_name_original_msi_main: process.env.REACT_APP_FORMNAME_ORIGINAL_MSI_MAIN,
    wogaaURL: process.env.REACT_APP_WOGAAURL,
    isIDP: process.env.REACT_APP_IDP,
    logoURL: process.env.REACT_APP_LOGOURL,
    contactUsURL: process.env.REACT_APP_CONTACTUSURL
}
