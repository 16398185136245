import $ from 'jquery'

type Data = {
    [key: string]: any
}


const data: Data = {}

let scannedDataOnPageLoad: Data | null;
let scannedDataOnSubmission: Data | null;
export default function scanData(components: {[key: string]: {}}) : Data {
    const scannedData : Data = {}
    for(const key in components) {
        const getNext = key.split("-")
        const elements: NodeListOf<Element> = document.querySelectorAll(`#${key}`)
        const nextelements: NodeListOf<Element> = document.querySelectorAll(`#${getNext[0]}`)

        if(elements.length === 1) {
            const element = elements[0]
            if(element instanceof HTMLInputElement) {
                const value = element.value
                scannedData[key] = value
            }
            if(element instanceof HTMLSelectElement) {
                let selected = $(`select#${key}`).val()
                if(selected === "[object Object]" || typeof selected === "object"){
                    selected = $(`#${key} option:selected`).text();
                }
                const value = selected;
                scannedData[key] = value
            }
            if(element instanceof HTMLTextAreaElement) {
                const value = element.value
                scannedData[key] = value
            }
            continue
        }
        if(elements.length > 1) {
            let values = []
            for(const element of elements) {
                if(element instanceof HTMLInputElement) {
                    const value = element.value
                    values.push(value)
                }
                if(element instanceof HTMLSelectElement) {
                    let selected = $(`select#${key}`).val()
                    if(selected === "[object Object]" || typeof selected === "object"){
                        selected = $(`#${key} option:selected`).text();
                    }
                    const value = selected;
                    // const value = element.value
                    values.push(value)
                }
                if(element instanceof HTMLTextAreaElement) {
                    const value = element.value
                    values.push(value)
                }
            }
            scannedData[key] = values
            continue
        }
        if(nextelements.length === 1) {
            const nextelement = nextelements[0]
            const getKey = getNext[1]
            const radioData = $(`input[name*='${getNext[1]}']:checked`).val();
            const checknested = $(`div[ref*='nested-${getKey}']`)

            const nestedfilename = $(nextelement).find("a").text();

            const nestedfilenameLength = $(nextelement).find("a[ref='fileLink']").length;

            if(nestedfilenameLength > 1) {
                $(nextelement).find("a[ref='fileLink']").each(function(index, element) {
                    if(index !== 0) {
                        scannedData[key] += ",";                        
                    } else {
                    }

                    const fileName = $(element).text();
                    const match = fileName.match(/Press to open (.*?)\n|Press to open (.*)/);
                     let foundFilename;
                    if (match) {
                        foundFilename = (match[1] || match[2]).trim();
                    }
                    if(foundFilename !== null){               
                        scannedData[key] = (index === 0) ? foundFilename : (scannedData[key] + foundFilename);
                    }
                });
            } else {
                const filename = $(nextelement).find("a").first().text();
                const fileref = $(nextelement).find("a").first().attr("ref")
                const checknestedcontainer = $(nextelement).find(`div[ref*='nested-']`)
                const match = filename.match(/Press to open (.*?)\n|Press to open (.*)/);
                let foundFilename;
                if (match) {
                    foundFilename = (match[1] || match[2]).trim();
                }
                if((radioData !== null || radioData !== undefined) && (checknested.length === 0)){
                    scannedData[key] = radioData
                }
                if(foundFilename !== null && fileref?.toString() === "fileLink" && (checknestedcontainer.length === 0)){
                    scannedData[key] = foundFilename || nestedfilename;
                }
            }

        }
    }
    return scannedData
}

export function Changes() {
    let id: string = ""
    let key: string = ""
    const elements: NodeListOf<Element> = document.querySelectorAll(`#${id}-${key}`)
    if (elements.length > 1) {
        // the following for loop is buggy
        const ref = data[key]
        if(!Array.isArray(ref)) {
            return
        }
        for (let i = 0; i < ref.length; i++) {
            const element = elements[i]
            if (element instanceof HTMLInputElement) {
                const value = element.value
                if(ref[i] != value) {
                    triggerChange(element)
                }
            }
        }
        for(let i = ref.length -1; i < elements.length; i++) {
            const element = elements[i]
            if (element instanceof HTMLInputElement) {
                triggerChange(element)
            }
        }
        return
    }
    if(elements.length == 0) {
        const element = elements[0]
        if(!(element instanceof HTMLInputElement)) {
            return
        }
        const preValue = data[key]
        if(element.value != preValue) {
           triggerChange(element)
        }
        return
    }
    throw "no element was found";
}

function triggerChange(element: HTMLInputElement) {
    // This function is intentionally empty. 
}