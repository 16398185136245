import AliceCarousel from "react-alice-carousel";
import Item1 from "./CarouselPages/Item1";
import SchemeBanner from "./FindScheme";

export default function HomepageCarousel() {
  const itemArray = [<Item1 />];
  return (
    <div className="overlap-carousel">
      <SchemeBanner />
      <AliceCarousel
        infinite
        touchTracking={false}
        disableDotsControls
        items={itemArray}
      />
    </div>
  );
}
