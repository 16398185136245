import Utils from 'formiojs/utils';
import $ from 'jquery';

export default function paginationCountersOnly(currentForm) {
    function tick() {
        return '<div class="tickicon" />';
    }
    function partial(x) {
        return `<div class="partialicon">${x}</div>`;
    }
    function current(x) {
        return `<div class="currenticon">${x}</div>`;
    }
    function empty(x) {
        return `<div class="emptyicon">${x}</div>`;
    }
    let percentcomplete = {};

    if (currentForm?.pages !== undefined) {
        for (let x = 0; x < currentForm.pages.length; x++) {
            if (x === currentForm.page) {
                try {
                    $(`li.page-item:nth-child(${x * 2})`).before(current(x - 1));
                } catch (e) {

                }
            }
            if (x > currentForm.page) {
                $(`li.page-item:nth-child(${x * 2})`).before(empty(x - 1));
            }
        }
    }
}
