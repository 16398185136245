import React from "react";
import "./Header.css";
import HomepageHeader from "./HomepageHeader";
import ApplicantHeader from "./ApplicantHeader";

function Header(props) {
  const Component = () => {
    if (props.isLogin) {
      return <ApplicantHeader />;
    } else {
      return <HomepageHeader />;
    }
  };
  return <Component />;
}

export default Header;
