import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch } from "../../Common/API";
import { config } from "../../config";

export const deleteWHTFormAPIAsync = createAsyncThunk(
  "WHTForm/deleteWHTFormAPIAsync",
  async (data, { rejectWithValue }) => {
    try {
      const formId = data.formId;
      const submissionId = data.submissionId;
      const url = `${config.appURL}/api/Application/${formId}/submission/${submissionId}`;
      const response = await fetch(url, "DELETE", "");
      if (response.status == 200) {
        return response.data;
      }
      else {
        return 0;
      }
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteFormAPIAsync = createAsyncThunk(
  "WHTForm/deleteFormAPIAsync",
  async (data, { rejectWithValue }) => {
    try {
      const formId = data.formId;
      const submissionId = data.submissionId;
      const url = `${config.appURL}/api/Application/${formId}/submission/${submissionId}`;
      const response = await fetch(url, "DELETE", "");

      if (response.status === 200) {
        return response.data;
      }
      else {
        return 0;
      }
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const GetLatestApprovedMainOrCR = createAsyncThunk(
  "WHTForm/GetLatestApprovedMainOrCR",
  async (data, { rejectWithValue }) => {
    try {
      const submissionId = data.submissionId;
      const url = `${config.appURL}/api/Application/GetLatestApprovedAcknowledgeApplicationList?submissionId=${submissionId}`;
      const response = await fetch(url, "GET", "");

      if (response.status === 200) {
        return response.data;
      }
      else {
        return 0;
      }
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)


export const withdrawFormAPIAsync = createAsyncThunk(
  "WHTForm/withdrawFormAPIAsync",
  async (data, { rejectWithValue }) => {
    try {
      const formId = data.formId;
      const submissionId = data.submissionId;
      const currentFormStatus = data.currentFormStatus;
      const url = `${config.appURL}/api/Application/WithdrawApplication?status=${currentFormStatus}&formId=${formId}&submissionId=${submissionId}`;
      const response = await fetch(url, "GET", "");
      if (response.status === 200) {
        return response.data;
      }
      else {
        return 0;
      }
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)


export const WHTFormSlice = createSlice({
  name: 'WHTForm',
  initialState: [],
  reducers: {},
  extraReducers: {
    [deleteWHTFormAPIAsync.fulfilled]: (state, action) => {
      return action.payload;
    },
    [GetLatestApprovedMainOrCR.fulfilled]: (state, action) => {
      return action.payload;
    }
  }
});
export default WHTFormSlice.reducer;
