import React from 'react';

const MasterCSS = () => {
    return (
        <div>
            <div class="ui-elements screen">
                <div class="breadcrumb univers-65-bold-science-blue-40px">Breadcrumb</div>
                <div class="default helvetica-bold-hit-gray-20px">Default</div>
                <div class="toggle helvetica-bold-hit-gray-20px">Toggle</div>
                <div class="default-1 helvetica-bold-hit-gray-20px">Default</div>
                <div class="disable helvetica-bold-hit-gray-20px">Disable</div>
                <div class="error helvetica-bold-hit-gray-20px">Error</div>
                <div class="dropdown helvetica-bold-hit-gray-20px">Dropdown</div>
                <div class="final helvetica-bold-hit-gray-20px">Final</div>
                <div class="form-form-group-default">
                    <div class="group-label univers-55roman-normal-scarpa-flow-16px">
                        Password
                    </div>
                    <div class="form-input-text-default">
                        <div class="overlap-group">
                            <div class="input-base">
                                <div class="input-base-1 border-1px-rose"></div>
                            </div>
                            <div class="text-state univers-55roman-normal-mine-shaft-16px">
                                Default input
                            </div>
                        </div>
                    </div>
                    <p class="helper-text-2">We’ll never share your email with anyone else</p>
                </div>
                <div class="form-form-group-default-1">
                    <div class="group-label-1 univers-55roman-normal-scarpa-flow-16px">
                        Password
                    </div>
                    <div class="form-input-text-default-1">
                        <div class="text-state-1 univers-65-bold-mine-shaft-16px">
                            Default input
                        </div>
                    </div>
                </div>
                <div class="form-form-group-default-2">
                    <div class="group-label-1 univers-55roman-normal-scarpa-flow-16px">
                        Password
                    </div>
                    <div class="form-input-text-default-2">
                        <div class="overlap-group">
                            <div class="input-base">
                                <div class="input-base-2 border-1px-gallery"></div>
                            </div>
                            <div class="text-state univers-55roman-normal-mine-shaft-16px">
                                Default input
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form univers-65-bold-science-blue-40px">Form</div>
                <div class="button univers-65-bold-science-blue-40px">Button</div>
                <div class="steps univers-65-bold-science-blue-40px">Steps</div>
                <div class="button-large-with-arrow-primary">
                    <div class="large-button univers-65-bold-white-16px">Large Button</div>
                    <img
                        class="icontabler-arrow-narrow-right"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-arrow-narrow-right@1x.png"
                    />
                </div>
                <div class="button-large-with-arrow-primary-1">
                    <div class="large-button univers-65-bold-science-blue-16px">
                        Large Button
                    </div>
                    <img
                        class="icontabler-arrow-narrow-right"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-arrow-narrow-right-1@1x.png"
                    />
                </div>
                <div class="button-attachment">
                    <div class="overlap-group1-1">
                        <div class="command univers-55roman-normal-scarpa-flow-16px">Add On</div>
                        <img
                            class="icontabler-new-section"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-new-section-1@1x.png"
                        />
                    </div>
                </div>
                <div class="alert univers-65-bold-science-blue-40px">Alert</div>
                <div class="project-listing univers-65-bold-science-blue-40px">
                    Project Listing
                </div>
                <div class="tabs univers-65-bold-science-blue-40px">Tabs</div>
                <div class="header univers-65-bold-science-blue-40px">Header</div>
                <div class="footer univers-65-bold-science-blue-40px">Footer</div>
                <div class="error-1">
                    <div class="successful-add-new-one univers-normal-white-14px">
                        <span class="univers-65-bold-white-14px">Successful!</span
                        ><span class="univers-55roman-normal-white-14px"> Add new one</span>
                    </div>
                    <img
                        class="close"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/close-1@1x.png"
                    />
                </div>
                <div class="error-2">
                    <p class="warning-password-expirses-in-1-day univers-normal-white-14px">
                        <span class="univers-65-bold-white-14px">Warning</span
                        ><span class="univers-55roman-normal-white-14px"
                        >! Password expirses in 1 day.</span
                        >
                    </p>
                    <img
                        class="close"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/close-1@1x.png"
                    />
                </div>
                <div class="error-3">
                    <div class="information-view-my-settings univers-normal-white-14px">
                        <span class="univers-65-bold-white-14px">Information!</span
                        ><span class="univers-55roman-normal-white-14px"> View my settings.</span>
                    </div>
                    <img
                        class="close"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/close-1@1x.png"
                    />
                </div>
                <div class="error-4">
                    <div class="error-please-try-again univers-normal-white-14px">
                        <span class="univers-65-bold-white-14px">Error! </span
                        ><span class="univers-55roman-normal-white-14px">Please try again.</span>
                    </div>
                    <img
                        class="close"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/close-1@1x.png"
                    />
                </div>
                <div class="button-small-primary">
                    <div class="surname univers-65-bold-white-16px">Small</div>
                </div>
                <div class="form-dropdown-default">
                    <div class="group-label-2 univers-55roman-normal-scarpa-flow-16px">
                        Dropdown question?
                    </div>
                    <div class="form-input-text-default-3">
                        <div class="overlap-group1-2">
                            <div class="overlap-group-8">
                                <img
                                    class="tabler-icon-chevron-right"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/tabler-icon-chevron-down@1x.png"
                                />
                            </div>
                            <div class="text-state-2 univers-55roman-normal-hit-gray-16px">
                                Select…
                            </div>
                        </div>
                        <div class="helper-text univers-55roman-normal-hit-gray-12px">
                            Example of help text
                        </div>
                    </div>
                </div>
                <div class="form-dropdown-default-1">
                    <div class="group-label-2 univers-55roman-normal-scarpa-flow-16px">
                        Dropdown question?
                    </div>
                    <div class="form-input-text-default-4">
                        <div class="input-base-3">
                            <div class="overlap-group-9">
                                <img
                                    class="tabler-icon-chevron-right-1"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/tabler-icon-chevron-down@1x.png"
                                />
                                <div class="input-base-4"><div class="input-base-5"></div></div>
                                <div class="label-1 univers-55roman-normal-shark-16px">1</div>
                                <div class="dropdownitem">
                                    <div class="label-2 univers-65-bold-science-blue-16px">2</div>
                                </div>
                                <div class="label-3 univers-55roman-normal-shark-16px">3</div>
                                <div class="label-4 univers-55roman-normal-shark-16px">4</div>
                                <div class="label-5 univers-55roman-normal-shark-16px">5</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-form-group-default-3">
                    <div class="group-label univers-55roman-normal-scarpa-flow-16px">
                        Password
                    </div>
                    <div class="form-input-text-default">
                        <div class="overlap-group">
                            <div class="input-base">
                                <div class="input-base-1 border-1px-gallery"></div>
                            </div>
                            <div class="text-state univers-55roman-normal-hit-gray-16px">
                                Default input
                            </div>
                        </div>
                    </div>
                    <p class="helper-text-1 univers-55roman-normal-hit-gray-12px">
                        Your password must be 8-20 characters long, contain letters and numbers,
                        and must not contain spaces, special characters, or emoji.
                    </p>
                </div>
                <div class="form-form-group-default-4">
                    <div class="group-label univers-55roman-normal-scarpa-flow-16px">
                        Password
                    </div>
                    <div class="form-input-text-default">
                        <div class="overlap-group">
                            <div class="input-base"><div class="input-base-6"></div></div>
                            <div class="text-state univers-55roman-normal-mine-shaft-16px">
                                Default input
                            </div>
                        </div>
                    </div>
                    <p class="helper-text-1 univers-55roman-normal-hit-gray-12px">
                        Your password must be 8-20 characters long, contain letters and numbers,
                        and must not contain spaces, special characters, or emoji.
                    </p>
                </div>
                <div class="group-16227">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-4206@1x.png"
                    />
                    <div class="overlap-group-1">
                        <div class="mid-blue lato-bold-shark-14px">Mid Blue</div>
                        <div class="x0065-bd lato-normal-log-cabin-12px">#0065BD</div>
                    </div>
                </div>
                <div class="group-16220">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-4206@1x.png"
                    />
                    <div class="overlap-group-1">
                        <div class="mid-blue lato-bold-shark-14px">Mid Blue</div>
                        <div class="x0065-bd-1 lato-normal-log-cabin-12px">#0065BD</div>
                    </div>
                </div>
                <div class="group-16218">
                    <div class="rectangle-8"></div>
                    <div class="overlap-group4">
                        <img
                            class="rectangle-7"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/rectangle-19@1x.png"
                        />
                        <div class="gradient lato-bold-shark-14px">Gradient</div>
                        <div class="x0088-ce-009-b74 lato-normal-storm-dust-12px">
                            #0088CE &amp;<br />#009B74
                        </div>
                    </div>
                </div>
                <div class="group-16232">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-4208@1x.png"
                    />
                    <div class="overlap-group-2">
                        <div class="color-1 lato-bold-shark-14px">White</div>
                        <div class="ffffff">#FFFFFF</div>
                    </div>
                </div>
                <div class="group-16222">
                    <div class="rectangle-6352"></div>
                    <div class="overlap-group-2">
                        <div class="light-grey lato-bold-shark-14px">Light Grey</div>
                        <div class="f6-f8-f9 lato-normal-storm-dust-12px">#F6F8F9</div>
                    </div>
                </div>
                <div class="group-16225">
                    <div class="rectangle-24"></div>
                    <div class="overlap-group-2">
                        <div class="color-2 lato-bold-shark-14px">Black</div>
                        <div class="phone lato-normal-storm-dust-12px">#333333</div>
                    </div>
                </div>
                <h1 class="title">H1 - Univers Bold</h1>
                <div class="h2-univers-bold univers-65-bold-mine-shaft-38px">
                    H2 - Univers Bold
                </div>
                <div class="h3-univers-bold">H3 - Univers Bold</div>
                <div class="h4-univers-bold">H4 - Univers Bold</div>
                <div
                    class="paragraph-univers-regular univers-55roman-normal-scarpa-flow-22px"
                >
                    Paragraph Univers - Regular
                </div>
                <div class="price lato-medium-eerie-black-11px">46pt</div>
                <div class="price-1 lato-medium-eerie-black-11px">38 pt</div>
                <div class="price-2 lato-medium-eerie-black-11px">28 pt</div>
                <div class="price-3 lato-medium-eerie-black-11px">22 pt</div>
                <div class="price-4 lato-medium-eerie-black-11px">22 pt</div>
                <div class="optima border-1px-sonic-silver">
                    <div class="aa">Aa</div>
                    <img
                        class="line-1"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-165@1x.png"
                    />
                    <div class="optima-1 universltstd-55roman-normal-eerie-black-12px">
                        Optima
                    </div>
                </div>
                <div class="group-16219">
                    <div class="rectangle-2"></div>
                    <div class="overlap-group-2">
                        <div class="dark-grey lato-bold-shark-14px">Dark Grey</div>
                        <div class="x565-a5-c lato-normal-storm-dust-12px">#565A5C</div>
                    </div>
                </div>
                <div class="group-16221">
                    <div class="group-875">
                        <div class="rectangle-2506"></div>
                        <div class="overlap-group-2">
                            <div class="color-3 lato-bold-shark-14px">Grey</div>
                            <div class="a6-adb4 lato-normal-storm-dust-12px">#A6ADB4</div>
                        </div>
                    </div>
                </div>
                <div class="group-16223">
                    <div class="rectangle-2508"></div>
                    <div class="overlap-group-2">
                        <div class="cool-grey lato-bold-shark-14px">Cool Grey</div>
                        <div class="eaecf0 lato-normal-storm-dust-12px">#EAECF0</div>
                    </div>
                </div>
                <div class="group-16228">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-4282@1x.png"
                    />
                    <div class="overlap-group-2">
                        <div class="light-blue lato-bold-shark-14px">Light Blue</div>
                        <div class="x0088-ce lato-normal-log-cabin-12px">#0088CE</div>
                    </div>
                </div>
                <div class="group-16226">
                    <div class="rectangle-2510"></div>
                    <div class="overlap-group-2">
                        <div class="dark-blue lato-bold-shark-14px">Dark Blue</div>
                        <div class="text-7 lato-normal-storm-dust-12px">#002395</div>
                    </div>
                </div>
                <div class="group-876">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-4286@1x.png"
                    />
                    <div class="overlap-group1">
                        <div class="color lato-bold-shark-14px">Red</div>
                        <div class="ff4-d4-d lato-normal-log-cabin-12px">#FF4D4D</div>
                    </div>
                </div>
                <p class="header-univers-bold universltstd-normal-black-16px">
                    <span class="universltstd-65-bold-black-16px">Header&nbsp;&nbsp;</span
                    ><span class="universltstd-55roman-normal-black-16px">Univers - Bold</span>
                </p>
                <div class="heading-with-subheader universltstd-normal-black-16px">
                    <span class="universltstd-65-bold-black-16px">Heading </span
                    ><span class="universltstd-55roman-normal-black-16px">with Subheader</span>
                </div>
                <div
                    class="paragraph-univers-regular-1 univers-55roman-normal-scarpa-flow-16px"
                >
                    Paragraph Univers - Regular
                </div>
                <div class="h5-univers-bold univers-65-bold-mine-shaft-18px">
                    H5 - Univers Bold
                </div>
                <div class="paragraph-univers-regular-2">Paragraph Univers - Regular</div>
                <div
                    class="paragraph-univers-regular-3 univers-55roman-normal-scarpa-flow-14px"
                >
                    Paragraph Univers - Regular
                </div>
                <div
                    class="paragraph-univers-regular-4 univers-55roman-normal-scarpa-flow-12px"
                >
                    Paragraph Univers - Regular
                </div>
                <div class="price-5 lato-medium-eerie-black-12px">18 pt</div>
                <div class="price-6 lato-medium-eerie-black-12px">18 pt</div>
                <div class="price-7 lato-medium-eerie-black-11px">16 pt</div>
                <div class="price-8 lato-medium-eerie-black-11px">14 pt</div>
                <div class="price-9 lato-medium-eerie-black-11px">12 pt</div>
                <div class="paragraph-univers-regular-5 universltstd-normal-black-16px">
                    <span class="universltstd-65-bold-black-16px">Paragraph </span
                    ><span class="universltstd-55roman-normal-black-16px"
                    >Univers - Regular</span
                    >
                </div>
                <div class="caption-univers-regular universltstd-normal-black-16px">
                    <span class="universltstd-65-bold-black-16px">Caption </span
                    ><span class="universltstd-55roman-normal-black-16px"
                    >Univers - Regular</span
                    >
                </div>
                <div class="univers border-1px-sonic-silver">
                    <div class="aa-1">Aa</div>
                    <img
                        class="line-165"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-165@1x.png"
                    />
                    <div class="univers-1 universltstd-55roman-normal-eerie-black-12px">
                        Univers
                    </div>
                </div>
                <div class="group-16224">
                    <div class="rectangle-2"></div>
                    <div class="overlap-group-2">
                        <div class="dark-grey-1 lato-bold-shark-14px">Dark Grey</div>
                        <div class="x565-a5-c-1 lato-normal-storm-dust-12px">#565A5C</div>
                    </div>
                </div>
                <div class="colours univers-65-bold-science-blue-40px">Colours</div>
                <div class="group-16216">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-4286-1@1x.png"
                    />
                    <div class="overlap-group1">
                        <div class="color lato-bold-mine-shaft-14px">Orange</div>
                        <div class="ff8-a00 lato-normal-log-cabin-12px">#FF8A00</div>
                    </div>
                </div>
                <div class="group-16217">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10724@1x.png"
                    />
                    <div class="overlap-group1">
                        <div class="color lato-bold-mine-shaft-14px">Yellow</div>
                        <div class="ffc96-c lato-normal-log-cabin-12px">#FFC96C</div>
                    </div>
                </div>
                <div class="corporate-font univers-65-bold-science-blue-40px">
                    Corporate Font
                </div>
                <div class="group-16229">
                    <div class="rectangle-2513"></div>
                    <div class="overlap-group-1">
                        <div class="teal-green lato-bold-shark-14px">Teal Green</div>
                        <div class="x009-b74 lato-normal-storm-dust-12px">#009B74</div>
                    </div>
                </div>
                <div class="group-16231">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-4284@1x.png"
                    />
                    <div class="overlap-group-2">
                        <div class="light-green lato-bold-shark-14px">Light Green</div>
                        <div class="x69-be28 lato-normal-storm-dust-12px">#69BE28</div>
                    </div>
                </div>
                <div class="group-16230">
                    <img
                        class="path"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-4285@1x.png"
                    />
                    <div class="overlap-group-2">
                        <div class="mid-green lato-bold-shark-14px">Mid Green</div>
                        <div class="x00-af3-f lato-normal-log-cabin-12px">#00AF3F</div>
                    </div>
                </div>
                <div class="get-the-grants-and-i univers-65-bold-mine-shaft-38px">
                    Heading with Subheader
                </div>
                <div class="get-the-grants-and-i-1 univers-55roman-normal-scarpa-flow-22px">
                    Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
                    laying out print, graphic or web designs. The passage is attributed to an
                    unknown ...
                </div>
                <div class="button-size universltstd-65-bold-black-16px">Button Size</div>
                <div class="back-to-top-button universltstd-65-bold-black-16px">
                    Back to Top Button
                </div>
                <div class="previous-next-button universltstd-65-bold-black-16px">
                    Previous/Next Button
                </div>
                <div class="slider-btn universltstd-65-bold-black-16px">Slider Btn</div>
                <div class="attachment-button universltstd-65-bold-black-16px">
                    Attachment Button
                </div>
                <div class="labeled-icon-button universltstd-65-bold-black-16px">
                    Labeled Icon Button
                </div>
                <div class="cta-button universltstd-65-bold-black-16px">CTA Button</div>
                <div class="button-large-primary">
                    <div class="large univers-65-bold-white-16px">Large</div>
                </div>
                <div class="form-textarea-default">
                    <div class="group-label-3 univers-55roman-normal-scarpa-flow-16px">
                        Example Textarea
                    </div>
                    <div class="overlap-group19">
                        <div class="input-base-7">
                            <div class="input-base-1 border-1px-gallery"></div>
                        </div>
                        <div class="overlap-group-10">
                            <img
                                class="line"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-2@1x.png"
                            />
                        </div>
                    </div>
                </div>
                <div class="place universltstd-65-bold-black-16px">Field</div>
                <div class="text-area universltstd-65-bold-black-16px">Text Area</div>
                <div class="dropdown-1 universltstd-65-bold-black-16px">Dropdown</div>
                <div class="radio-checkbox universltstd-65-bold-black-16px">
                    Radio / Checkbox
                </div>
                <div class="form-switches">
                    <div class="overlap-group20 border-1px-gallery">
                        <div class="control"></div>
                    </div>
                </div>
                <div class="form-input-checkbox-group-default">
                    <div class="form-input-checkbox-group-default-1">
                        <div class="checkbox-1">
                            <img
                                class="check"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/check@1x.png"
                            />
                        </div>
                        <div class="label helveticaneue-normal-shark-16px">2</div>
                    </div>
                </div>
                <div class="form-input-radio-group-default">
                    <div class="radio border-1px-gallery"><div class="ellipse-3"></div></div>
                    <div class="label helveticaneue-normal-shark-16px">1</div>
                </div>
                <div class="form-dropdownand-field-default">
                    <p class="group-label-4 univers-55roman-normal-scarpa-flow-16px">
                        Please states currency &amp; exchange rate used
                    </p>
                    <div class="form-input-text-default-5">
                        <div class="overlap-group1-3">
                            <div class="txt_field">
                                <div class="overlap-group-11">
                                    <div class="input-base-8">
                                        <img
                                            class="input-base-9"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/input-base-1@1x.png"
                                        />
                                    </div>
                                    <div class="text-state-3 univers-55roman-normal-hit-gray-16px">
                                        Input
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-button-left-default">
                                <div class="dropdown-2">
                                    <div class="price-10">SGD</div>
                                    <div class="icondropdown-arrowdefault">
                                        <img
                                            class="icondropdown-arrow"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-dropdown-arrow-1@1x.png"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="helper-text univers-55roman-normal-hit-gray-12px">
                            Example of help text
                        </div>
                    </div>
                </div>
                <div class="breadcrumbs">
                    <img
                        class="icontabler"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-arrow-left-10@1x.png"
                    />
                    <div class="component-257-1">
                        <p class="surname-1">
                            <span class="univers-65-bold-scarpa-flow-12px"
                            >Page
                                1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page
                                2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</span
                            ><span class="span1">&nbsp;&nbsp; </span
                            ><span class="univers-65-bold-scarpa-flow-12px">&nbsp;&nbsp;</span
                            ><span class="span3"> Page 3</span>
                        </p>
                    </div>
                </div>
                <div class="step">
                    <div class="number-container">
                        <div class="number-3 univers-65-bold-hit-gray-18px">1</div>
                        <div class="number-4 univers-65-bold-science-blue-18px">1</div>
                    </div>
                    <div class="flex-col-2">
                        <div
                            class="what-is-grants-and-incentive-portal univers-65-bold-mine-shaft-18px"
                        >
                            Parent Company HQ
                        </div>
                        <div class="group-16162">
                            <div class="x0-completed univers-55roman-normal-scarpa-flow-14px">
                                0% completed
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-1">
                    <div class="overlap-group22">
                        <img
                            class="base-1"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/base-12@1x.png"
                        />
                        <div class="number univers-65-bold-hit-gray-18px">1</div>
                        <div class="number univers-65-bold-science-blue-18px">1</div>
                        <div class="group-16163"></div>
                    </div>
                    <div class="flex-col-3">
                        <div
                            class="what-is-grants-and-incentive-portal univers-65-bold-mine-shaft-18px"
                        >
                            Parent Company HQ
                        </div>
                        <div class="group-16162-1">
                            <div class="x50-completed univers-55roman-normal-scarpa-flow-14px">
                                50% completed
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-2">
                    <div class="overlap-group23">
                        <img
                            class="icontabler"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-check@1x.png"
                        />
                    </div>
                    <div
                        class="what-is-grants-and-incentive-portal-1 univers-65-bold-mine-shaft-18px"
                    >
                        Parent Company HQ
                    </div>
                </div>
                <div class="step-3">
                    <div class="overlap-group24">
                        <div class="number-5 univers-65-bold-hit-gray-18px">1</div>
                    </div>
                    <div
                        class="what-is-grants-and-incentive-portal-1 univers-65-bold-hit-gray-18px"
                    >
                        Parent Company HQ
                    </div>
                </div>
                <div class="default-2 helvetica-bold-hit-gray-20px">Default</div>
                <div class="active helvetica-bold-hit-gray-20px">Active</div>
                <div class="progress-active helvetica-bold-hit-gray-20px">
                    Progress Active
                </div>
                <div class="checked-done helvetica-bold-hit-gray-20px">Checked/ Done</div>
                <div class="error-alert helvetica-bold-hit-gray-20px">Error Alert</div>
                <div class="step-4">
                    <div class="overlap-group25">
                        <img
                            class="icontabler"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-adjustments@1x.png"
                        />
                    </div>
                    <div
                        class="what-is-grants-and-incentive-portal-1 univers-65-bold-mine-shaft-18px"
                    >
                        Parent Company HQ
                    </div>
                </div>
                <div class="progress-button universltstd-65-bold-black-16px">
                    Progress Button
                </div>
                <div class="website-menu universltstd-65-bold-black-16px">Website Menu</div>
                <div class="applicant-menu universltstd-65-bold-black-16px">
                    Applicant Menu
                </div>
                <div class="plp">
                    <div class="what-is-grants-and-i">
                        <div
                            class="what-is-grants-and-incentive-portal-2 univers-65-bold-mine-shaft-18px"
                        >
                            Applications
                        </div>
                        <div
                            class="what-is-grants-and-incentive-portal-3 univers-55roman-normal-mine-shaft-18px"
                        >
                            Annual Report
                        </div>
                        <div
                            class="what-is-grants-and-incentive-portal-4 univers-55roman-normal-mine-shaft-18px"
                        >
                            5 -Year Review
                        </div>
                    </div>
                    <div class="path-container">
                        <img
                            class="path-10435"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10435-6@1x.png"
                        /><img
                            class="path-10526"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10526-3@1x.png"
                        />
                    </div>
                    <div class="flex-row">
                        <div class="status">
                            <div class="group-16196">
                                <div class="overlap-group-3">
                                    <div class="new univers-65-bold-white-16px">New</div>
                                    <div class="group-16193">
                                        <div class="number-1 univers-65-bold-science-blue-16px">1</div>
                                    </div>
                                </div>
                            </div>
                            <div class="group-16238">
                                <div class="overlap-group-3">
                                    <div class="draft univers-65-bold-scarpa-flow-16px">Draft</div>
                                    <div class="group-16194">
                                        <div class="number-1 univers-65-bold-scarpa-flow-16px">2</div>
                                    </div>
                                </div>
                            </div>
                            <div class="group-16237">
                                <div class="processing-1 univers-65-bold-scarpa-flow-16px">
                                    Processing
                                </div>
                                <div class="group-16195">
                                    <div class="number-1 univers-65-bold-scarpa-flow-16px">3</div>
                                </div>
                            </div>
                            <div class="group-16236">
                                <div class="update-request univers-65-bold-scarpa-flow-16px">
                                    Update Request
                                </div>
                                <div class="group-16197">
                                    <div class="number-1 univers-65-bold-white-16px">1</div>
                                </div>
                            </div>
                            <div class="group-16235">
                                <div class="completed univers-65-bold-scarpa-flow-16px">
                                    Completed
                                </div>
                                <div class="group-16198">
                                    <div class="number-1 univers-65-bold-scarpa-flow-16px">1</div>
                                </div>
                            </div>
                        </div>
                        <div class="f-ilter-option">
                            <img
                                class="icontabler"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-search@1x.png"
                            /><img
                                class="icontabler-1"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-adjustments-1@1x.png"
                            /><img
                                class="icontabler-1"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-layout-grid@1x.png"
                            />
                        </div>
                    </div>
                    <div class="table-list">
                        <div class="menu">
                            <img
                                class="path-10707"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10706@1x.png"
                            />
                            <div class="flex-row-1">
                                <div class="checkbox-2 border-2px-gallery"></div>
                                <div class="surname-2 univers-55roman-normal-scarpa-flow-12px">
                                    CASE ID
                                </div>
                                <div class="sort">
                                    <img
                                        class="icon-material-arrow-drop-up"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-material-arrow-drop-up-1@1x.png"
                                    /><img
                                        class="icon-material-arrow-drop-down"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-material-arrow-drop-down-1@1x.png"
                                    />
                                </div>
                                <p class="award-name-type univers-55roman-normal-scarpa-flow-12px">
                                    AWARD NAME &amp;&nbsp;&nbsp;TYPE
                                </p>
                                <div class="sort-1">
                                    <img
                                        class="icon-material-arrow-drop-up"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-material-arrow-drop-up-1@1x.png"
                                    /><img
                                        class="icon-material-arrow-drop-down"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-material-arrow-drop-down-1@1x.png"
                                    />
                                </div>
                                <div class="status-1 univers-55roman-normal-scarpa-flow-12px">
                                    STATUS
                                </div>
                                <div class="sort">
                                    <img
                                        class="icon-material-arrow-drop-up"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-material-arrow-drop-up-1@1x.png"
                                    /><img
                                        class="icon-material-arrow-drop-down"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-material-arrow-drop-down-1@1x.png"
                                    />
                                </div>
                                <div class="overall-progress univers-55roman-normal-scarpa-flow-12px">
                                    OVERALL PROGRESS
                                </div>
                                <div class="document-s univers-55roman-normal-scarpa-flow-12px">
                                    DOCUMENTS
                                </div>
                                <div class="actions univers-55roman-normal-scarpa-flow-12px">
                                    ACTIONS
                                </div>
                            </div>
                            <img
                                class="path-10706"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10706@1x.png"
                            />
                        </div>
                        <div class="overlap-group13">
                            <div class="item">
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                                <div class="overlap-group-4">
                                    <div class="base">
                                        <img
                                            class="path-10530"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10530-1@1x.png"
                                        />
                                    </div>
                                    <div class="checkbox border-2px-gallery"></div>
                                </div>
                            </div>
                            <div class="change-request">
                                <div class="x-container">
                                    <p class="asi-20200210-005 univers-55roman-normal-mine-shaft-16px">
                                        ASI - 20200210 - 005
                                    </p>
                                    <p
                                        class="motified-on-21-oct-2021 univers-55roman-normal-hit-gray-12px"
                                    >
                                        Motified on 21 Oct 2021
                                    </p>
                                </div>
                                <div class="flex-col">
                                    <p
                                        class="local-subsidiary-sister univers-55roman-normal-mine-shaft-16px"
                                    >
                                        Local Subsidiary / Sister ...
                                    </p>
                                    <div class="text_label univers-55roman-normal-hit-gray-12px">
                                        MSI-AIS\
                                    </div>
                                </div>
                                <div class="tag border-1px-lochmara">
                                    <div class="processing univers-65-bold-lochmara-12px">
                                        Processing
                                    </div>
                                </div>
                                <div class="rectangle-6239"></div>
                                <div class="percent univers-55roman-normal-mine-shaft-16px">100%</div>
                                <div class="component-163">
                                    <div class="tabler-icon-folder">
                                        <img
                                            class="path-10715"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-161-1">
                                    <div class="overlap-group-5">
                                        <img
                                            class="path-10450"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                        /><img
                                            class="path-10451"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                        /><img
                                            class="line-18"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                        /><img
                                            class="path-10452"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="more">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                            <div class="change-request-1">
                                <div class="x-container">
                                    <p class="asi-20200210-006 univers-55roman-normal-mine-shaft-16px">
                                        ASI - 20200210 - 006
                                    </p>
                                    <p
                                        class="submitted-on-21-oct-2021 univers-55roman-normal-hit-gray-12px"
                                    >
                                        Submitted on 21 Oct 2021
                                    </p>
                                </div>
                                <div class="flex-col">
                                    <p
                                        class="local-subsidiary-sister univers-55roman-normal-mine-shaft-16px"
                                    >
                                        Local Subsidiary / Sister ...
                                    </p>
                                    <div class="msi-ais univers-55roman-normal-hit-gray-12px">
                                        MSI-AIS
                                    </div>
                                </div>
                                <div class="tag-1 border-1px-lochmara">
                                    <div class="processing univers-65-bold-lochmara-12px">
                                        Processing
                                    </div>
                                </div>
                                <div class="rectangle-6239"></div>
                                <div class="percent univers-55roman-normal-mine-shaft-16px">100%</div>
                                <div class="component-163">
                                    <div class="tabler-icon-folder">
                                        <img
                                            class="path-10715"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-161-1">
                                    <div class="overlap-group-5">
                                        <img
                                            class="path-10450"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                        /><img
                                            class="path-10451"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                        /><img
                                            class="line-18"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                        /><img
                                            class="path-10452"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="more-1">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                            <div class="resubmit">
                                <div class="x-container">
                                    <p class="asi-20200210-004 univers-55roman-normal-mine-shaft-16px">
                                        ASI - 20200210 - 004
                                    </p>
                                    <p
                                        class="submitted-on-21-oct-2021-1 univers-55roman-normal-hit-gray-12px"
                                    >
                                        Submitted on 21 Oct 2021
                                    </p>
                                </div>
                                <div class="flex-col-1">
                                    <div
                                        class="main-application univers-55roman-normal-mine-shaft-16px"
                                    >
                                        (Main Application)
                                    </div>
                                    <div class="msi-ais-1 univers-55roman-normal-hit-gray-12px">
                                        MSI-AIS
                                    </div>
                                </div>
                                <div class="tag-2 border-1px-lochmara">
                                    <div class="update-request-1 univers-65-bold-lochmara-12px">
                                        Update Request
                                    </div>
                                </div>
                                <div class="rectangle-6239-1"></div>
                                <div class="percent univers-55roman-normal-mine-shaft-16px">100%</div>
                                <div class="component-163-7">
                                    <div class="tabler-icon-folder">
                                        <img
                                            class="path-10715"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-161-14">
                                    <div class="overlap-group-5">
                                        <img
                                            class="path-10450"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                        /><img
                                            class="path-10451"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                        /><img
                                            class="line-18"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                        /><img
                                            class="path-10452"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="more-2">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                            <div class="rejected">
                                <div class="x-container-1">
                                    <p class="asi-20200210-001 univers-55roman-normal-mine-shaft-16px">
                                        ASI - 20200210 - 001
                                    </p>
                                    <p
                                        class="submitted-on-21-oct-2021-2 univers-55roman-normal-hit-gray-12px"
                                    >
                                        Submitted on 21 Oct 2021
                                    </p>
                                </div>
                                <div class="flex-col-4">
                                    <div
                                        class="main-application univers-55roman-normal-mine-shaft-16px"
                                    >
                                        (Main Application)
                                    </div>
                                    <div class="msi-ais-2 univers-55roman-normal-hit-gray-12px">
                                        MSI-AIS
                                    </div>
                                </div>
                                <div class="tag-3 border-1px-rose">
                                    <div class="rejected-1 univers-65-bold-rose-12px">Rejected</div>
                                </div>
                                <img
                                    class="path-10725"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10725@1x.png"
                                />
                                <div class="group-15906">
                                    <div class="overlap-group-6">
                                        <img
                                            class="path-10458"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10458-1@1x.png"
                                        />
                                        <div class="ellipse-403 border-2px-rose"></div>
                                    </div>
                                </div>
                                <div class="component-163-9">
                                    <div class="tabler-icon-folder">
                                        <img
                                            class="path-10715"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-13@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-161-1-1">
                                    <div class="overlap-group-5">
                                        <img
                                            class="path-10450"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                        /><img
                                            class="path-10451"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                        /><img
                                            class="line-18"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                        /><img
                                            class="path-10452"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="more-3">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                            <div class="approved">
                                <div class="x-container">
                                    <p class="asi-20200210-002 univers-55roman-normal-mine-shaft-16px">
                                        ASI - 20200210 - 002
                                    </p>
                                    <p
                                        class="accepted-on-21-oct-2021 univers-55roman-normal-hit-gray-12px"
                                    >
                                        Accepted on&nbsp;&nbsp;21 Oct 2021
                                    </p>
                                </div>
                                <div class="flex-col-1">
                                    <div
                                        class="main-application univers-55roman-normal-mine-shaft-16px"
                                    >
                                        (Main Application)
                                    </div>
                                    <div class="msi-ais-3 univers-55roman-normal-hit-gray-12px">
                                        MSI-AIS
                                    </div>
                                </div>
                                <div class="tag-4"><div class="approved-1">Approved</div></div>
                                <img
                                    class="path-10454"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10454@1x.png"
                                />
                                <div class="group-15916">
                                    <div class="overlap-group-12">
                                        <img
                                            class="path-10456"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10456-1@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-163-8">
                                    <div class="tabler-icon-folder">
                                        <img
                                            class="path-10715"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-161-15">
                                    <div class="overlap-group-5">
                                        <img
                                            class="path-10450"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                        /><img
                                            class="path-10451"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                        /><img
                                            class="line-18"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                        /><img
                                            class="path-10452"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="more-4">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                            <div class="rejected-2">
                                <div class="x-container-2">
                                    <p class="asi-20200210-003 univers-55roman-normal-mine-shaft-16px">
                                        ASI - 20200210 - 003
                                    </p>
                                    <p
                                        class="submitted-on-21-oct-2021-3 univers-55roman-normal-hit-gray-12px"
                                    >
                                        Submitted on 21 Oct 2021
                                    </p>
                                </div>
                                <div class="flex-col-5">
                                    <div
                                        class="main-application univers-55roman-normal-mine-shaft-16px"
                                    >
                                        (Main Application)
                                    </div>
                                    <div class="msi-ais-4 univers-55roman-normal-hit-gray-12px">
                                        MSI-AIS
                                    </div>
                                </div>
                                <div class="tag-5 border-1px-rose">
                                    <div class="withdrawal univers-65-bold-rose-12px">Withdrawal</div>
                                </div>
                                <div class="rectangle-6242"></div>
                                <div class="group-15906">
                                    <div class="overlap-group-6">
                                        <img
                                            class="path-10458"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10458-1@1x.png"
                                        />
                                        <div class="ellipse-403 border-2px-rose"></div>
                                    </div>
                                </div>
                                <div class="component-163-10">
                                    <div class="tabler-icon-folder">
                                        <img
                                            class="path-10715"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-13@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-161-1-1">
                                    <div class="overlap-group-5">
                                        <img
                                            class="path-10450"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                        /><img
                                            class="path-10451"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                        /><img
                                            class="line-18"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                        /><img
                                            class="path-10452"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="more-5">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                            <div class="awaiting-approval">
                                <div class="x-container-3">
                                    <p
                                        class="asi-20200210-1930-p univers-55roman-normal-mine-shaft-16px"
                                    >
                                        ASI - 20200210 - 1930P
                                    </p>
                                    <p
                                        class="submitted-on-21-oct-2021-4 univers-55roman-normal-hit-gray-12px"
                                    >
                                        Submitted on 21 Oct 2021
                                    </p>
                                </div>
                                <div class="flex-col-6">
                                    <div
                                        class="main-application-1 univers-55roman-normal-mine-shaft-16px"
                                    >
                                        (Main Application)
                                    </div>
                                    <div class="msi-asi univers-55roman-normal-hit-gray-12px">
                                        MSI- ASI
                                    </div>
                                </div>
                                <div class="tag-6 border-1px-lochmara">
                                    <div class="processing univers-65-bold-lochmara-12px">
                                        Processing
                                    </div>
                                </div>
                                <div class="rectangle-6263"></div>
                                <div class="percent-1 univers-55roman-normal-mine-shaft-16px">
                                    100%
                                </div>
                                <div class="component-163-4">
                                    <div class="tabler-icon-folder">
                                        <img
                                            class="path-10715"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-161">
                                    <div class="overlap-group-5">
                                        <img
                                            class="path-10450"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                        /><img
                                            class="path-10451"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                        /><img
                                            class="line-18"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                        /><img
                                            class="path-10452"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="more-6">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                            <div class="draft-1">
                                <div class="x-container-4">
                                    <p
                                        class="asi-20200210-1930-p-1 univers-55roman-normal-mine-shaft-16px"
                                    >
                                        ASI - 20200210 - 1930P
                                    </p>
                                    <p
                                        class="modified-on-21-oct-2021 univers-55roman-normal-hit-gray-12px"
                                    >
                                        Modified on 21 Oct 2021
                                    </p>
                                </div>
                                <div class="main-application-container">
                                    <div
                                        class="main-application-2 univers-55roman-normal-mine-shaft-16px"
                                    >
                                        (Main Application)
                                    </div>
                                    <div
                                        class="msi-ml-main-application univers-55roman-normal-hit-gray-12px"
                                    >
                                        MSI-ML (Main Application)
                                    </div>
                                </div>
                                <div class="tag-7 border-1px-golden-tainoi">
                                    <div class="draft-2 univers-65-bold-yellow-orange-12px">Draft</div>
                                </div>
                                <div class="tag-8 border-1px-rose">
                                    <div class="due-in-7-days univers-65-bold-wild-watermelon-12px">
                                        Due In 7 Days
                                    </div>
                                </div>
                                <div class="overlap-group11"></div>
                                <div class="percent-2 univers-55roman-normal-mine-shaft-16px">
                                    50%
                                </div>
                                <div class="component-163-3">
                                    <div class="tabler-icon-folder">
                                        <img
                                            class="path-10715"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="component-161-10">
                                    <div class="overlap-group-5">
                                        <img
                                            class="path-10450"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                        /><img
                                            class="path-10451"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                        /><img
                                            class="line-18"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                        /><img
                                            class="path-10452"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="more-7">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                            <div class="draft-3">
                                <div class="group-15958">
                                    <div class="x-container">
                                        <p
                                            class="asi-20200210-007 univers-55roman-normal-mine-shaft-16px"
                                        >
                                            ASI - 20200210 - 007
                                        </p>
                                        <p
                                            class="created-on-21-oct-2021 univers-55roman-normal-hit-gray-12px"
                                        >
                                            Created on 21 Oct 2021
                                        </p>
                                    </div>
                                    <div class="flex-col-7">
                                        <div
                                            class="main-application-3 univers-55roman-normal-mine-shaft-16px"
                                        >
                                            (Main Application)
                                        </div>
                                        <div class="msi-ais-5 univers-55roman-normal-hit-gray-12px">
                                            MSI-AIS
                                        </div>
                                    </div>
                                    <div class="tag-9 border-1px-golden-tainoi">
                                        <div class="draft-4 univers-65-bold-yellow-orange-12px">
                                            Draft
                                        </div>
                                    </div>
                                    <div class="tag-10 border-1px-rose">
                                        <div class="due-in-7-days-1 univers-65-bold-wild-watermelon-12px">
                                            Due In 7 Days
                                        </div>
                                    </div>
                                    <div class="overlap-group1-4"></div>
                                    <div class="percent-3 univers-55roman-normal-mine-shaft-16px">
                                        50%
                                    </div>
                                    <div class="component-163-1">
                                        <div class="tabler-icon-folder">
                                            <img
                                                class="path-10715"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-10@1x.png"
                                            />
                                        </div>
                                    </div>
                                    <div class="component-161">
                                        <div class="overlap-group-5">
                                            <img
                                                class="path-10450"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                            /><img
                                                class="path-10451"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                            /><img
                                                class="line-18"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                            /><img
                                                class="path-10452"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                            />
                                        </div>
                                    </div>
                                    <div class="more-8">
                                        <div class="ellipse-399 border-2px-mine-shaft"></div>
                                        <div class="ellipse-40 border-2px-mine-shaft"></div>
                                        <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="new-1">
                                <div class="overlap-group12">
                                    <div class="case-id">
                                        <div
                                            class="asi-20200210-1930-p-2 univers-55roman-normal-mine-shaft-16px"
                                        >
                                            ASI -20200210 - 1930P
                                        </div>
                                        <p
                                            class="created-on-21-oct-2021-1 univers-55roman-normal-hit-gray-12px"
                                        >
                                            Created on 21 Oct 2021
                                        </p>
                                    </div>
                                    <div class="award-name">
                                        <div
                                            class="main-application-extension univers-55roman-normal-mine-shaft-16px"
                                        >
                                            (Main Application) - Extension
                                        </div>
                                        <div class="msi-ais-6 univers-55roman-normal-hit-gray-12px">
                                            MSI-AIS
                                        </div>
                                    </div>
                                    <div class="progress">
                                        <img
                                            class="rectangle-6258"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/rectangle-6258-1@1x.png"
                                        /><img
                                            class="path-10531"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10531-1@1x.png"
                                        />
                                        <div class="percent-4 univers-55roman-normal-mine-shaft-16px">
                                            0%
                                        </div>
                                        <div class="component-163-2">
                                            <div class="tabler-icon-folder">
                                                <img
                                                    class="path-10715"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10715-10@1x.png"
                                                />
                                            </div>
                                        </div>
                                        <div class="component-161-9">
                                            <div class="overlap-group-5">
                                                <img
                                                    class="path-10450"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10450-10@1x.png"
                                                /><img
                                                    class="path-10451"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10451-10@1x.png"
                                                /><img
                                                    class="line-18"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/line-18-10@1x.png"
                                                /><img
                                                    class="path-10452"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10452-10@1x.png"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tag-11"><div class="new-2">New</div></div>
                                </div>
                                <div class="more-9">
                                    <div class="ellipse-399 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                    <div class="ellipse-40 border-2px-mine-shaft"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row-2">
                        <div class="showing">
                            <div class="showing-1-10-of-35 univers-55roman-normal-scarpa-flow-16px">
                                Showing 1-10 of 35
                            </div>
                            <div class="entries">
                                <img
                                    class="tabler-icon-chevron-down"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/tabler-icon-chevron-down@1x.png"
                                />
                                <div class="group-16240">
                                    <div class="entries-1 univers-55roman-normal-scarpa-flow-16px">
                                        Entries
                                    </div>
                                    <div class="overlap-group-13">
                                        <div class="number-6 univers-55roman-normal-scarpa-flow-16px">
                                            10
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pagination">
                            <div class="group-6">
                                <div class="overlap-group3">
                                    <div class="group-copy-22">
                                        <img
                                            class="down"
                                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/down-3@1x.png"
                                        />
                                    </div>
                                </div>
                                <div class="pagination-button">
                                    <div class="number-2 univers-55roman-normal-black-14px">1</div>
                                </div>
                                <div class="overlap-group8">
                                    <div class="number-2 univers-55roman-normal-white-14px">2</div>
                                </div>
                                <div class="overlap-group-7">
                                    <div class="number-2 univers-55roman-normal-black-14px">3</div>
                                </div>
                                <div class="overlap-group-7">
                                    <div class="number-2 univers-55roman-normal-black-14px">4</div>
                                </div>
                                <div class="overlap-group-7">
                                    <div class="number-2 univers-55roman-normal-black-14px">5</div>
                                </div>
                                <div class="overlap-group-7">
                                    <div class="number-2 univers-55roman-normal-black-14px">6</div>
                                </div>
                                <div class="overlap-group-7">
                                    <div class="number-2 univers-55roman-normal-black-14px">7</div>
                                </div>
                                <div class="overlap-group-7">
                                    <div class="number-2 univers-55roman-normal-black-14px">8</div>
                                </div>
                                <div class="overlap-group-7">
                                    <div class="number-2 univers-55roman-normal-black-14px">9</div>
                                </div>
                                <div class="overlap-group9">
                                    <img
                                        class="down-1"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/down-1@1x.png"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-1">
                    <div class="usp">
                        <div class="one-maritime-portal">One Maritime Portal</div>
                        <div class="description">
                            OMP is one stop station of schemes that provided by Maritime and Port
                            Authority of Singapore .
                        </div>
                    </div>
                    <div class="flex-row-3">
                        <div class="powered-by">
                            <div class="power-by">Powered by</div>
                            <img
                                class="logompa-white"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/logo-mpa-white@1x.png"
                            />
                        </div>
                        <div class="faqs univers-55roman-normal-white-16px">FAQS</div>
                        <div class="contact-us univers-55roman-normal-white-16px">Contact Us</div>
                        <div class="feedback univers-55roman-normal-white-16px">Feedback</div>
                        <div class="social-media">
                            <img
                                class="iconfontawesomefacebook-f"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-awesome-facebook-f-1@1x.png"
                            /><img
                                class="iconfontawesomelinkedin-in"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-awesome-linkedin-in-1@1x.png"
                            /><img
                                class="iconfontawesomeinstagram"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-fontawesome-instagram-10@1x.png"
                            /><img
                                class="iconfontawesometwitter"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-fontawesome-twitter-10@1x.png"
                            />
                        </div>
                    </div>
                    <img
                        class="divider"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/divider-14@1x.png"
                    />
                    <div class="flex-row-4">
                        <div class="report-vulnerability univers-55roman-normal-white-16px">
                            Report Vulnerability
                        </div>
                        <div class="privacy-policy univers-55roman-normal-white-16px">
                            Privacy Policy
                        </div>
                        <div class="terms-of-use univers-55roman-normal-white-16px">
                            Terms of Use
                        </div>
                        <div class="copyright">
                            <p class="x2020-maritime-and univers-55roman-normal-white-16px">
                                © 2020 Maritime and Port Authority of Singapore <br />Last Updated 18
                                Oct 2021
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-colours universltstd-65-bold-black-16px">Text Colours</div>
                <div class="corporate-colours universltstd-65-bold-black-16px">
                    Corporate Colours
                </div>
                <div class="alert-colours universltstd-65-bold-black-16px">Alert Colours</div>
                <div class="line-border universltstd-65-bold-black-16px">Line/ Border</div>
                <div class="background-colours universltstd-65-bold-black-16px">
                    Background Colours
                </div>
                <div class="applicant-menu-flow-2">
                    <div class="overlap-group27">
                        <div class="overlap-group-14">
                            <div class="flex-row-5">
                                <div class="dashboard">
                                    <div class="dashboards univers-65-bold-mine-shaft-16px">
                                        DASHBOARDS
                                    </div>
                                </div>
                                <div class="incentives">
                                    <div class="incentives-1 univers-65-bold-mine-shaft-16px">
                                        INCENTIVES
                                    </div>
                                </div>
                                <div class="grants">
                                    <div class="place-1 univers-65-bold-mine-shaft-16px">GRANTS</div>
                                </div>
                            </div>
                            <div class="hover"></div>
                        </div>
                        <div class="masthead">
                            <img
                                class="logomerlion"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/logo-merlion-10@1x.png"
                            />
                            <p class="a-singapore-governme univers-55roman-normal-mine-shaft-14px">
                                A Singapore Government Agency Website
                            </p>
                        </div>
                        <div class="user-name-login">
                            <div class="user-name univers-55roman-normal-mine-shaft-16px">
                                User Name
                            </div>
                            <div class="company-name univers-55roman-normal-hit-gray-12px">
                                Company Name
                            </div>
                        </div>
                        <div class="overlap-group1-5">
                            <img
                                class="icontabler-inbox"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-inbox@1x.png"
                            />
                            <div class="number-7"><div class="number-8">2</div></div>
                        </div>
                        <div class="logoomp-colour">
                            <div class="path-container-1">
                                <img
                                    class="path-10674"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10674@1x.png"
                                /><img
                                    class="path-10675"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10675-10@1x.png"
                                />
                            </div>
                            <div class="overlap-group-15">
                                <div class="one optima-bold-mine-shaft-16px">One</div>
                                <div class="maritime optima-bold-mine-shaft-16px">Maritime</div>
                                <div class="place-2 optima-bold-mine-shaft-16px">Portal</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="website-navigation">
                    <div class="masthead-1">
                        <img
                            class="logomerlion-1"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/logo-merlion-10@1x.png"
                        />
                        <p class="a-singapore-governme-1 univers-55roman-normal-mine-shaft-14px">
                            A Singapore Government Agency Website
                        </p>
                    </div>
                    <div class="top-navi">
                        <img
                            class="logoomp-colour-1"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/logo-omp-colour@1x.png"
                        />
                        <div class="main-list">
                            <div class="btnexploreschemes">
                                <div class="explore-schemes">
                                    <div class="about-us">
                                        <div class="explore-schemes-1 univers-65-bold-mine-shaft-16px">
                                            EXPLORE SCHEMES
                                        </div>
                                    </div>
                                    <div class="tabler-icon-chevron-right-2">
                                        <div class="tabler-icon-chevron-right-3">
                                            <div class="path-container-2">
                                                <img
                                                    class="path-10419"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10419-10@1x.png"
                                                /><img
                                                    class="path-10420"
                                                    src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10420-10@1x.png"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="about-us-1">
                                <div class="about-omp univers-65-bold-mine-shaft-16px">ABOUT OMP</div>
                            </div>
                            <div class="circulars">
                                <div class="circulars-1 univers-65-bold-mine-shaft-16px">
                                    CIRCULARS
                                </div>
                            </div>
                            <div class="fa-qs">
                                <div class="faqs-1 univers-65-bold-mine-shaft-16px">FAQS</div>
                            </div>
                            <div class="contact-us-1">
                                <div class="contact-us-2 univers-65-bold-mine-shaft-16px">
                                    CONTACT US
                                </div>
                            </div>
                        </div>
                        <div class="search"></div>
                        <div class="overlap-group1-6">
                            <img
                                class="icontabler"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/icon-tabler-lock@1x.png"
                            />
                            <div class="login univers-65-bold-white-16px">LOGIN</div>
                        </div>
                    </div>
                </div>
                <div class="btn-back-to-top">
                    <div class="overlap-group28">
                        <img
                            class="container"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/container-10@1x.png"
                        />
                        <div class="explore univers-55roman-normal-mine-shaft-14px">Top</div>
                        <div class="tabler-icon-chevron-up">
                            <img
                                class="path-10729"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/6220e7b1fb098b642f462967/img/path-10729@1x.png"
                            />
                        </div>
                    </div>
                </div>
                <div class="btn-left-swipe">
                    <img
                        class="icontabler-chevron"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/6220eb3c4b6511d1593a8f04/img/icon-tabler-chevron-left@1x.png"
                    />
                </div>
                <div class="btn-right-swipe">
                    <img
                        class="icontabler-chevron"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/6220eb3c4b6511d1593a8f04/img/icon-tabler-chevron-right-1@1x.png"
                    />
                </div>
                <div class="slider">
                    <div class="ellipse-407"></div>
                    <div class="ellipse-4"></div>
                    <div class="ellipse-4"></div>
                </div>
                <div class="btncta">
                    <div class="read-more">Read More</div>
                    <img
                        class="icontabler"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/6220eb3c4b6511d1593a8f04/img/icon-tabler-arrow-narrow-right-2@1x.png"
                    />
                </div>
                <div class="tab">
                    <div class="flex-row-6">
                        <div class="tab1 univers-65-bold-mine-shaft-18px">All Schemes</div>
                        <div class="tab2 univers-55roman-normal-mine-shaft-18px">Grants</div>
                        <div class="tab3 univers-55roman-normal-mine-shaft-18px">Incentives</div>
                    </div>
                    <div class="overlap-group29">
                        <img
                            class="divider-1"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62206997cf5e8c8280e21f86/releases/62206b645ad79b5f63622f84/img/path-10435-1@1x.png"
                        />
                        <div class="hover-1"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MasterCSS
