import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    Typography,
} from "@mui/material";
import { IconArrowNarrowRight } from "@tabler/icons";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        width: "767px",
        padding: "24px",
        borderRadius: "10px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
        "& li": {
            listStyle: "disc",
        },
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
        justifyContent: "space-between",
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "28px !important" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 32,
                        top: 28,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DateValidationDialog({
    openDialog,
    setOpenDateValidDialog,
    isEligible,
    ineligibleMessage,
    goToForm,
}) {
    let errorMessage = "";

    
    const handleClose = () => {
        setOpenDateValidDialog(false);
    };
    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    variant="h3"
                    onClose={handleClose}
                >
                </BootstrapDialogTitle>
                <DialogContent /*dividers*/>
                    <Typography
                        gutterBottom
                        className="univers-55roman-normal-mine-shaft-16px"
                    >
                    {errorMessage = "Effective date of WHT exemption cannot be before 'Date of earliest relevant qualifying payment made (In Section 3)' and 'Date of submission of application'"} 
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <>
                        
                        <Button
                            onClick={handleClose}
                            className="small button-small univers-65-bold-white-16px"
                            sx={{marginLeft: "400px"}}
                        >
                            OK
                        </Button>
                        
                    </>


                </DialogActions>
            </BootstrapDialog>
        </>
    );
}
