import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    styled,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '767px',
        padding: '24px',
        borderRadius: '10px'
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        '& li': {
            listStyle: 'disc',
        },
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        justifyContent: 'space-between'
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '28px !important' }} {...other}>
            {children}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AcceptanceAcceptDialog({ open, setOpen, submissionId, msg, btnStatus }) {
    const history = useHistory();

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false)
        }
    }

    const handleOK = () => {
        setOpen(false);
        history.push({
            pathname: '/NestedApplicationListing',
            search: '?schema=1'
          })
    };

    return (<>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            disableEscapeKeyDown
        >
            <BootstrapDialogTitle id="customized-dialog-title"
                variant='h3'
                onClose={handleClose}>
                {msg === "Accept operation in progress." ?
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress />
                </div>
                : msg}
            </BootstrapDialogTitle>
            <DialogContent /*dividers*/>
                <Typography gutterBottom
                    className='univers-55roman-normal-mine-shaft-16px'>

                </Typography>
            </DialogContent>
            <div style={{ float: 'right' }}>
                <DialogActions style={{ float: 'right' }}>
                    <Button onClick={handleOK}
                        className='button-large univers-65-bold-white-16px'
                        disabled={btnStatus}
                    >
                        OK
                    </Button>
                </DialogActions>
            </div>
        </BootstrapDialog>
    </>)
}
