import { Button, Card, Typography, useMediaQuery } from "@mui/material";
import { IconArrowRight } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import "../GuidedJourney.css";
import { config } from "../../../config";
import { DirectUserToIdpWebsite } from "../../../Common/CommonUtils";


function IDPCard() {
    const history = useHistory();
    return (
        <>
        {useMediaQuery('(max-width: 465px)') ?
        <Card className="resp465 journey-card" sx={{padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <Typography variant='h4'>
                    Sea Transport Industry Digital Plan (IDP)
                </Typography>
                {config.isIDP === "true" ?
                    <>Enjoy grants of (up to $30,000) when your company adopts a maritime-specific digital solution from a pre-approved list.
                    <br />
                    <a className='clickContactText' onClick={() => history.push('/IDPScheme')}>Learn more <IconArrowRight /></a>
                    </>
                :
                    <>The Sea Transport Industry Digital Plan (IDP) is a joint initiative with Infocomm Media Development Authority (IMDA), Enterprise Singapore (ESG) and SkillsFuture Singapore (SSG), as part of the SME Go Digital Programme that makes going digital simple for SMEs. It provides SMEs with an easy-to-use, step-by-step guide to support them in the adoption of digital solutions.
                    <br />
                    <a className='clickContactText' onClick={() => DirectUserToIdpWebsite()}>Learn more <IconArrowRight /></a>
                    </>
                }

            </div>
            {config.isIDP === "true" &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        onClick={() => history.push('/IDPMainForm')}
                        className='large button-large univers-65-bold-white-16px'
                        sx={{
                            height: '48px',
                            width: '115px',
                            textTransform: 'unset',
                            top: '25px'
                        }}>
                        Apply
                    </Button>
                </div>
            }
        </Card>
        :
        <Card className="journey-card" sx={{ height: 400, width: 400, padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <Typography variant='h4'>
                    Sea Transport Industry Digital Plan (IDP)
                </Typography>
                <br />
                {config.isIDP === "true" ? 
                    <>Enjoy grants of (up to $30,000) when your company adopts a maritime-specific digital solution from a pre-approved list.
                    <br />
                    <a className='clickContactText' onClick={() => history.push('/IDPScheme')}>Learn more <IconArrowRight /></a>
                    </>
                    :
                    <>The Sea Transport Industry Digital Plan (IDP) is a joint initiative with Infocomm Media Development Authority (IMDA), Enterprise Singapore (ESG) and SkillsFuture Singapore (SSG), as part of the SME Go Digital Programme that makes going digital simple for SMEs. It provides SMEs with an easy-to-use, step-by-step guide to support them in the adoption of digital solutions.
                    <br />
                    <a className='clickContactText' onClick={() => DirectUserToIdpWebsite()}>Learn more <IconArrowRight /></a>
                    </>
                }

            </div>
            {config.isIDP === "true" &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        onClick={() => history.push('/IDPMainForm')}
                        className='large button-large univers-65-bold-white-16px'
                        sx={{
                            height: '48px',
                            width: '115px',
                            textTransform: 'unset',
                            top: '25px'
                        }}>
                        Apply
                    </Button>
                </div>
            }
        </Card>
        }
        </>
     );
}

export default IDPCard;
