import { Button, Card, Typography, useMediaQuery } from "@mui/material";
import { IconArrowRight } from '@tabler/icons';
import { useHistory } from 'react-router-dom'
import "../GuidedJourney.css"

function WHTCard() {
    const history = useHistory();
    return (
        <>
        {useMediaQuery('(max-width: 465px)') ?
        <Card className="resp465 journey-card" sx={{padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <Typography variant='h4'>
                    Withholding Tax (WHT) Exemption
                </Typography>Enjoy WHT exemption on interest and related payments made in respect of arrangements obtained to finance the purchase or construction of vessels, or the purchase of containers and intermodal equipment.
                <br />
                <a className='clickContactText' onClick={() => history.push('/WHTScheme')}>Learn more <IconArrowRight /></a>

            </div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>

                <Button
                    onClick={() => history.push('/WHTShipForm')}
                    className='large button-large univers-65-bold-white-16px'
                    sx={{
                        height: '48px',
                        width: '115px',
                        textTransform: 'unset',
                        top: '15px',
                        marginRight: '25px'
                    }}>
                    Apply
                </Button>
            </div>
        </Card>
        :
        <Card className="journey-card" sx={{ height: 400, width: 400, padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <Typography variant='h4'>
                    Withholding Tax (WHT) Exemption
                </Typography>
                <br />
                Enjoy WHT exemption on interest and related payments made in respect of arrangements obtained to finance the purchase or construction of vessels, or the purchase of containers and intermodal equipment.
                <br />
                <a className='clickContactText' onClick={() => history.push('/WHTScheme')}>Learn more <IconArrowRight /></a>
                {/* <a href="/WHTScheme" style={{fontWeight: 'bold', marginLeft:'5px'}}>Learn more <IconArrowRight /></a> */}

                {/* <br />
                <Button sx={{ mt: 1, textTransform: 'unset' }}>
                    <Typography variant='h6' color={'#0065bd'}>Learn more <IconArrowRight /></Typography>
                </Button> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>

                <Button
                    onClick={() => history.push('/WHTShipForm')}
                    className='large button-large univers-65-bold-white-16px'
                    sx={{
                        height: '48px',
                        width: '115px',
                        textTransform: 'unset',
                        top: '15px',
                        marginRight: '25px'
                    }}>
                    Apply
                </Button>
            </div>
        </Card>
        }
        </>
     );
}

export default WHTCard;
