import React from "react";
import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";

export default function SchemeBanner() {
  const history = useHistory();

  return (
    <div className="containerGuidedJourney">
      <div className="scheme-container">
        <Box className="scheme-box">
          <Box className="big-text-box">
            <div className="big-text">
              Find eligible schemes for your business
            </div>
          </Box>
          <Box>
            <Button
              className="guided-journey-button"
              endIcon={<ArrowForwardIcon />}
              onClick={() => history.push("/GuidedJourney")}
            >
              Guided Journey
            </Button>
          </Box>
        </Box>
      </div>
      </div>
  );
}
