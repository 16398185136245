import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetch } from "../../Common/API";
import { config } from "../../config";

export const getPublicUserByIdAPIAsync = createAsyncThunk(
  "publicUser/getPublicUserByIdAPIAsync",
  async (id) => {
    const url = `${config.appURL}/api/InternetPublicUser/GetUserByHashedUserId/${id}`;
    const response = await fetch(url, "GET");
    return response.data;
  }
);

export const updatePublicUserByIdAPIAsync = createAsyncThunk(
  "publicUser/updatePublicUserByIdAPIAsync",
  async ({ body, id }) => {
    const url = `${config.appURL}/api/InternetPublicUser/UpdateUserByHashedUserId/${id}`;
    const response = await fetch(url, "PUT", body);
    return response.data;
  }
);

export const deletePublicUserListAPIAsync = createAsyncThunk(
  "publicUserList/deletePublicUserListAPIAsync",
  async (Id) => {
    const url = `${config.appURL}/api/InternetPublicUser/DeleteUserByHashedUserId/${Id}`;
    const response = await fetch(url, "DELETE");
    return response;
  }
);

export const PublicUserSlice = createSlice({
  name: "publicUser",
  initialState: [],
  reducers: {},
  extraReducers: {
    [updatePublicUserByIdAPIAsync.fulfilled]: (state, action) => {
      return action.payload;
    },
    [getPublicUserByIdAPIAsync.fulfilled]: (state, action) => {
      return action.payload;
    },
    [deletePublicUserListAPIAsync.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export const { clearPublicUsersByGroup } = PublicUserSlice.actions;

export default PublicUserSlice.reducer;
