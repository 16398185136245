/* eslint-disable eqeqeq */
import { Components } from 'formiojs';

const HTML_ELEMENT = Components.components.field;

export default class CommentBtn extends HTML_ELEMENT {

  static schema() {
    return HTML_ELEMENT.schema({
      type: 'comment',
      numRows: 2,
      numCols: 1,
    });
  }

  static builderInfo = {
    title: 'Comment',
    group: 'basic',
    icon: 'fa fa-table',
    weight: 70,
    documentation: '/userguide/#table',
    schema: CommentBtn.schema()
  }

  renderString(template: any, data: any): HTMLElement {
    const htmlComponent: HTMLElement = document.createElement("div")
    return htmlComponent
  }
  public render(children: string) {
    return super.render(this.renderString("div", {}).outerHTML);
  }
  public attach(element: any) {
    let label = element.querySelector('label')
    if (label != undefined && label instanceof HTMLElement) {
      label.style.display = "none"
    }
    return super.attach(element)
  }
  
}
