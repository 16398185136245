import FormReview from "../../../Common/FormReview";
import { useLocation } from "react-router-dom";
import { config } from "../../../config";

export default function WHTContainerReview(props) {
  const formname = "Withholding Tax Exemption Container Form";
  const formId = config.form_name_target_container_main;
  const pathname = "/WHTContainerForm";
  const schemaroute = "3";

  const params = new URLSearchParams(window.location.search);
  let _id = params.get("_id");
  let { state, refresh } = props.location;
  const location  = useLocation()
  const caseID = location.state.caseID
  const canSubmit = location.state.canSubmit
  const prevStatus = location.state.prevStatus


  return (
    <FormReview
      _id={_id}
      refresh={refresh}
      formname={formname}
      caseID={caseID}
      formstatus={prevStatus}
      canSubmit={canSubmit}
      formId={formId}
      pathname={pathname}
      schemaroute={schemaroute}
      onReadyCallback={(form) => {
        //THIS IS FOR SECTION 5 - DECLARATION AND UNDERTAKING//

        //consent and acknowledgement
        const consent = form.getComponent("agreeToAll");
        consent.component.label = `I, ${state.user}, am authorized to submit this on behalf of the borrower(s), as listed in Section 1 - Borrower's Details (Borrower's Particular(s)).`

        window.scrollTo(0, 0);
        form.redraw();
      }}
    />
  );
}
