import * as CryptoJS from 'crypto-js';
import { config } from '../config';
import { fetch } from './API';

const __key = {
    frontEndKey: 'frontEndKey',
    frontEndIv: 'frontEndIv',
}

class AESUtil {
    loaded() {
        return localStorage.getItem(__key.frontEndKey) && localStorage.getItem(__key.frontEndIv);
    }
    decrypt(text) {
        try {
            const isFrontEndKeyInLocalStorage = localStorage.getItem(__key.frontEndKey);
            if (isFrontEndKeyInLocalStorage) {
                const myKey = CryptoJS.enc.Base64.parse(isFrontEndKeyInLocalStorage);
                const myIv = CryptoJS.enc.Base64.parse(localStorage.getItem(__key.frontEndIv));
                let decryptedData = CryptoJS.AES.decrypt(text, myKey,
                    { iv: myIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
                return decryptedData.toString(CryptoJS.enc.Utf8) || text;
            }
        } catch (error) {
            return text;
        }
    }
    decryptResponseData(responseData) {
        const fields = ['UserName', 'ActorName', 'Name', 'contactName', 'contactEmail', 'usersInvolved'];
        this.decryptSecretInfo(responseData, fields);
    }

    decryptSecretInfo(data, fields) {
        if (data && typeof data === 'object' && fields && fields.length > 0) {
            Object.entries(data).forEach(([key, value]) => {
                if (typeof value === 'object') {
                    this.decryptSecretInfo(value, fields);
                } else if (fields.includes(key)) {
                    data[key] = this.decrypt(value);
                }
            });
        }
    }

    async loadFEKey() {
        try {
            const appURL = config.appURL;
            const feKeyURL = `${appURL}/api/Auth/GetFrontendKey`;
            const response = await fetch(feKeyURL, 'GET', null);
            const getFEKeyResponse = response.data;
            if (!getFEKeyResponse)
                return false;
            if (getFEKeyResponse.FrontendKey)
                localStorage.setItem(__key.frontEndKey, getFEKeyResponse.FrontendKey);
            if (getFEKeyResponse.FrontendIv)
                localStorage.setItem(__key.frontEndIv, getFEKeyResponse.FrontendIv);
            return true;
        } catch (error) {
            return false;
        }
    }
}

const __util = new AESUtil();
export default __util;