import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "767px",
    padding: "24px",
    borderRadius: "10px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    "& li": {
      listStyle: "disc",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ mt: 5, p: 2, fontSize: "28px !important" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 32,
            top: 28,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function UpdatePublicUserDialog({
  open,
  setOpen,
  isUpdated,
  nextRouteUrl,
}) {
  const badMessage = <div>Selected Roles are required.</div>;

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);

    // Route to Other url
    if (nextRouteUrl !== "") history.push(nextRouteUrl);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          variant="h4"
          onClose={handleClose}
        >
          Update Roles
        </BootstrapDialogTitle>
        <DialogContent /*dividers*/>
          <Typography
            gutterBottom
            className="univers-55roman-normal-mine-shaft-16px"
          >
            {isUpdated ? "Roles updated." : badMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className="large button-large univers-65-bold-white-16px"
            sx={{ ml: "80%" }}
          >
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export function CreateDialog({ open, setOpen, statusMessage }) {
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
    history.push("/PublicUser");
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          variant="h3"
          onClose={handleClose}
        >
          {statusMessage === "ok"
            ? "User added."
            : statusMessage === "existed"
            ? "User already exists."
            : "Add user is unsuccessful."}
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography
            gutterBottom
            className="univers-55roman-normal-mine-shaft-16px"
          >
            {statusMessage === "ok" || statusMessage === "existed"
              ? ""
              : statusMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className="large button-large univers-65-bold-white-16px"
            sx={{ ml: "80%" }}
          >
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export function UpdateDialog({ open, setOpen, statusMessage }) {
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
    history.push("/PublicUser");
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          variant="h3"
          onClose={handleClose}
        >
          {statusMessage === "ok"
            ? "User has been updated."
            : statusMessage === ""
            ? ""
            : "There is an error."}
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography
            gutterBottom
            className="univers-55roman-normal-mine-shaft-16px"
          >
            {statusMessage === "ok" ? "" : statusMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className="large button-large univers-65-bold-white-16px"
            sx={{ ml: "80%" }}
          >
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export function PublicUserValidationDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          variant="h3"
          onClose={handleClose}
        >
          Error
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography
            gutterBottom
            className="univers-55roman-normal-mine-shaft-16px"
          >
            Please fill up all the compulsory fields.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className="large button-large univers-65-bold-white-16px"
            sx={{ ml: "80%" }}
          >
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export function PublicUserDeleteDialog({
  open,
  setOpen,
  onClickDialogListener,
  userId,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          variant="h3"
          onClose={handleClose}
        >
          Are you sure you want to delete user?
        </BootstrapDialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpen(false);
              onClickDialogListener("Yes", userId);
            }}
            className="large button-large univers-65-bold-white-16px"
          >
            Yes
          </Button>
          <Button
            autoFocus
            onClick={handleClose}
            className="large button-large univers-65-bold-white-16px"
          >
            No
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export function UnauthorisedUserDialog({ open, setOpen }) {
  const history = useHistory();

  const handleUnauthorisedUserClose = () => {
    setOpen(false);
    history.push("/Home");
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleUnauthorisedUserClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          variant="h3"
          onClose={handleUnauthorisedUserClose}
        >
          Error
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography
            gutterBottom
            className="univers-55roman-normal-mine-shaft-16px"
          >
            Unauthorised user detected
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleUnauthorisedUserClose}
            className="large button-large univers-65-bold-white-16px"
            sx={{ ml: "80%" }}
          >
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
