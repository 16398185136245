import Utils from 'formiojs/utils';
import $ from 'jquery';

export default function pagination(currentForm) {
    function info() {
        if (currentForm.page === 1) {
            return '<div class="infoiconactive" />';
        } else {
            return '<div class="infoiconinactive" />';
        }
    }
    function tick() {
        return '<div class="tickicon" />';
    }
    function partial(x) {
        return `<div class="partialicon">${x}</div>`;
    }
    function current(x) {
        return `<div class="currenticon">${x}</div>`;
    }
    function empty(x) {
        return `<div class="emptyicon">${x}</div>`;
    }
    let percentcomplete = {};

    function checkProgress(page) {
        //debugger
        let totalcount = 0, blankcount = 0;
        function checkNested(y) {
            //debugger
            if (typeof y === 'object') {
                if (y !== null) {
                    Object.values(y).forEach(z => checkNested(z))
                } else {
                    if (y === null) { blankcount++ }
                }
                // Object.values(y).forEach(z => checkNested(z))
            } else {
                if (y === '' || y === null) { blankcount++ };
            }
        }
        Object.keys(Utils.flattenComponents(currentForm.pages[page].components)).forEach(x => {
            totalcount++;
            checkNested(currentForm._submission.data[x]);
        });
        percentcomplete[page] = Math.floor((totalcount - blankcount) / totalcount * 20) * 5;
        return percentcomplete[page];
    }

    //hide eligibility page
    if (currentForm?.page > 0) {
        $('li.page-item:first-child').hide();
    }

    //change tab icons
    if (currentForm?.pages !== undefined) {
        $(`li.page-item:nth-child(2)`).before(info);
        for (let x = 2; x < currentForm.pages.length; x++) {
            if (x < currentForm.page) {
                if (checkProgress(x) === 100) {
                    $(`li.page-item:nth-child(${x * 2})`).before(tick);
                } else {
                    $(`li.page-item:nth-child(${x * 2})`).children().addClass("partialicontext");
                    $(`li.page-item:nth-child(${x * 2})`).append(`<div class="partialicontext2" id="partialcount${x}"></div>`);
                    $(`li.page-item:nth-child(${x * 2})`).before(partial(x - 1));
                }
            }
            if (x === currentForm.page) {
                $(`li.page-item:nth-child(${x * 2})`).before(current(x - 1));
            }
            if (x > currentForm.page) {
                $(`li.page-item:nth-child(${x * 2})`).before(empty(x - 1));
            }
        }
    }
}
