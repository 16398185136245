import { Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

export default function SSSaward() {
  const history = useHistory();
  return (
    <>
      <Typography variant="h3">
        MSI - Shipping-related Support Services <nobr>(MSI-SSS)</nobr> Award
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-22px">
        The MSI-SSS award seeks to promote the growth of ancillary shipping
        service providers and to encourage shipping conglomerates to set up
        their corporate services functions in Singapore.
      </Typography>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>
        Benefits
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2">
        An approved MSI-SSS company will enjoy a concessionary tax rate of 10%
        on the incremental income derived from the provision of the following
        qualifying approved shipping-related support services for a 5-year
        renewable period:
        <ul>
          <li>Ship broking;</li>
          <li>Forward freight agreement trading;</li>
          <li>Ship management;</li>
          <li>Ship agency;</li>
          <li>Freight forwarding and logistics services; and</li>
          <li>
            Corporate services rendered to qualifying approved related parties
            who are carrying on business of shipping-related activities.
          </li>
        </ul>
      </Typography>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>
        Eligibility
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px typo-text2">
        <p>
          Applicant with a good track record and demonstrable business plan in
          respect of its ancillary shipping activities in Singapore may apply
          for the MSI-SSS award on or before 31 December 2026.
        </p>
        <p>
          Applicant must be able to demonstrate in its business plan how the
          provision of the ancillary shipping activities will generate economic
          contributions in Singapore through total business spending,
          undertaking additional activities from Singapore and number of
          professionals employed.
        </p>
      </Typography>
      <br />
      <br />
      <Typography variant="h4" fontSize={22}>
        How to apply?
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px">
        To apply, please submit your request via&nbsp;
        <a
          className="clickContactText"
          onClick={() => history.push("/ContactUs")}
        >
          Contact Us
        </a>
        .
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-mine-shaft-16px">
        For more information, please refer to the Frequently Asked Questions (
        <a className="clickContactText" onClick={() => history.push("/FAQs")}>
          FAQs
        </a>
        ) page.
      </Typography>
    </>
  );
}
