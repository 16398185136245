import FormReview from "../../../Common/FormReview";
import Utils from 'formiojs/utils';
import { useLocation } from "react-router-dom";
import { config } from "../../../config";

export default function IDPClaimReview(props) {
  const formname = "Industry Digital Plan Claim Form";
  const formId = config.form_name_target_idp_claim;
  const pathname = "/IDPClaimForm";
  const schemaroute = "1";

  const params = new URLSearchParams(window.location.search);
  let _id = params.get("_id");
  let { state, refresh } = props.location;

  const location  = useLocation()
  const caseID = location.state.caseID
  const canSubmit = location.state.canSubmit
  const prevStatus = location.state.prevStatus

  return (
    <FormReview
      _id={_id}
      refresh={refresh}
      formname={formname}
      caseID={caseID}
      formstatus={prevStatus}
      canSubmit={canSubmit}
      formId={formId}
      pathname={pathname}
      schemaroute={schemaroute}
      onReadyCallback={(form) => {
        let utils = Utils.getComponent(form.components, "agreeToAll", true);
        utils.component.label = `I, ${state.user}, am authorised to submit this on behalf of ${state.company}.`;

        //  Declaration Content
        let declaration = Utils.getComponent(form.components, "declarationContent", true);
        let updatedContent = declaration.component.html;
        declaration.component.html = updatedContent.replace("[IT Solutions Name]", `${state.solutionName}`);

        window.scrollTo(0, 0);
        form.redraw();
      }}
    />
  );
}
