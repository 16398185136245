import { Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router";
import appImage from "../SchemesList/images/Vector-Application.png";
import { SchemesService } from "../SchemesList/SchemesService";
import loginHelper from '../../Common/loginHelper';
import RemoveItemsFromSessionStorage from "../../Common/RemoveItemsFromSessionStorage";
import { config } from "../../config";

export default function Dashboard() {   
   const schemesservice = new SchemesService();
   const grantApplication = schemesservice.getGrantType();
   const incentiveApplication = schemesservice.getIncentiveType();
   const history = useHistory();
   const loginUser = loginHelper.getLoginUser();
   const loginUserScheme = loginUser.Schemes;
   const scheme = [];

   RemoveItemsFromSessionStorage();

   if (config.isIDP === "true" && loginUserScheme?.includes("IDP")) {
      scheme.push(grantApplication[0]);
   }

   if(loginUserScheme?.includes("WHT")) {
      scheme.push(incentiveApplication[3]);
   }

   let types = {};
   for (let x of scheme) {
      if (types[x.type] === undefined) {
         types[x.type] = 1;
      } else {
         types[x.type] += 1;
      }
   }

   function handleApplication(e, grantApp) {
      e.preventDefault();
      let schemeNumber;
      if (config.isIDP && grantApp.list === 'IDP') {
         schemeNumber = 1;
      }  
      else if (grantApp.list === 'WHT_Ship') {
         schemeNumber = 2;
      }
      history.push({
         pathname: '/NestedApplicationListing',
         search: `?schema=${schemeNumber}`
      })
   }

   return (<>
      <Container>
         {loginUserScheme === "" ?
            <Typography variant='h4' m={25}>
               Your administrator did not configure your application.
            </Typography>
         :
            config.isIDP === "true" ?
            <Typography variant='h4' m={4}>
               Application(s)
            </Typography>
            :
            <Typography variant='h4' m={4}>
               Application
            </Typography>
         }
         <Grid container spacing={3} mb={4}>
            {scheme.map((grantApp) => (
               <Grid item key={grantApp.id}>
                  <Card variant="outlined" className="card-main">
                     <Grid container>
                        <Grid item xs={3} />
                        <Grid item xs={6}>
                           <CardMedia
                              component="img"
                              height="100%"
                              src={appImage}
                              alt="grant application"
                              sx={{ width: 115, display: "block", mx: "auto", mt: 3 }}
                           />
                        </Grid>
                     </Grid>

                     <CardContent className="card-content">
                        <Typography className="line-height-28px univers-65-bold-mine-shaft-22px">
                           {grantApp.desc === "MSI - Approved International Shipping Enterprise (MSI-AIS) Award" && <>MSI - Approved International Shipping Enterprise <nobr>(MSI-AIS)</nobr> Award</>}
                           {grantApp.desc !== "MSI - Approved International Shipping Enterprise (MSI-AIS) Award" && grantApp.desc}
                        </Typography>
                        <Button
                           className="button-medium line-height-26px univers-65-bold-white-16px"
                           sx={{ width: 'auto', mb: 0.75, }}
                           onClick={e => handleApplication(e, grantApp)}>
                           View My Application
                        </Button>
                     </CardContent>
                     <CardActions sx={{ padding: 0, justifyContent: 'center' }}>
                        <Grid container justifyContent={'center'}>
                           <Grid item xs={6} borderRight={'1px solid #ecf0f1'}
                              sx={{ display: grantApp.type === 'WHT' ? 'block' : 'none', textAlign: 'center' }}>
                              <Button
                                 className="card-btn line-height-26px univers-55roman-normal-lochmara-16px"
                                 onClick={() => history.push({
                                    pathname: '/WHTQEntityTypeForm',
                                    search: `?path=eligibility`
                                 })} >
                                 Check eligibility
                              </Button>
                           </Grid>
                           <Grid item xs={grantApp.type === 'WHT' ? 6 : 12}
                              sx={{ display: "block", mx: "auto", textAlign: 'center' }}>
                              <Button
                                 className="card-btn line-height-26px univers-55roman-normal-lochmara-16px"
                                 disabled={grantApp.path === '' ? true : false}
                                 onClick={() => history.push({
                                    pathname: grantApp.path,
                                    search: grantApp.type === 'WHT' ? `?path=application` : null,
                                    state: { id: 1 }
                                 })} >
                                 Apply
                              </Button>
                           </Grid>
                        </Grid>
                     </CardActions>
                  </Card>
               </Grid>
            ))}
         </Grid>
      </Container>
   </>)
}
