import axios from 'axios';
import { config } from '../../../config';
import { fetch } from '../../../Common/API';

const APICall = async (fieldName3, submissionId2) => {
  if(!fieldName3 || !submissionId2) 
    return;
  let resp;
  const loadComments = await fetch(`${config.appURL}/api/comment/GetCommentsByFieldName?FieldName=${fieldName3}&SubmissionId=${submissionId2}`,'GET');
  if(loadComments){
    resp = loadComments.data.Comments
  }
  const sortingData = resp.sort((a, b) => {
    let da= new Date(a.Timestamp);
    let db= new Date(b.Timestamp);
    return da - db;
  });
  return sortingData;
}


export default APICall
