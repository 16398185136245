import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { allDocumentsList } from './mockData';

export const getAllDocumentsListAsync = createAsyncThunk(
	'allDocumentsList/getAllDocumentsListAsync',
	async () => {
		return {
			allDocumentsList
		};
	}
);

export const allDocumentsListSlice = createSlice({
	name: 'allDocumentsList',
	initialState: [],
	reducers: {
	},
	extraReducers: {
		[getAllDocumentsListAsync.fulfilled]: (state, action) => {
			return action.payload.allDocumentsList;
		}
	}
});

export default allDocumentsListSlice.reducer;
