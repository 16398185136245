import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Redux/store';
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import "./GlobalCss.css";
import "./MasterCSS.css";
import './index.scss';
import './use';
import { createRoot } from 'react-dom/client';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';

const newHistory = createBrowserHistory();
const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(
         <React.StrictMode>
          <StyledEngineProvider injectFirst>
            <Provider store={store}>
              <Router history={newHistory}>
                <App />
              </Router>
            </Provider>
          </StyledEngineProvider>
         </React.StrictMode>
);

reportWebVitals();
