import { isRejectedWithValue } from '@reduxjs/toolkit';

export function CommonErrorHandling(error) {
  if(error === "Forbidden") {
    return { error };
  }
  else {
      return isRejectedWithValue("Error");
  }
}
