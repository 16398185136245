import $ from "jquery";

const ConvertUtcToLocale_ApplicantRegistrationDate = (currentForm) => {
  const dateUTC_applicantRegistrationDateTime = currentForm.submission.data["applicantRegistrationDateTime"];
  if (dateUTC_applicantRegistrationDateTime !== undefined && dateUTC_applicantRegistrationDateTime !== null && dateUTC_applicantRegistrationDateTime !== "") {
      currentForm.submission.data["applicantRegistrationDateTime"] = new Date(dateUTC_applicantRegistrationDateTime);
  }
}

/**
 * Recursion function, every 1s, check if htmlelement exist, if exist, turn off visibility of progress bar ui
 * 
 * @param {string} targetPropertyName - formio editor property name 
 * @param {ref} refTabCounterTimeOut - reference to this setTimeout, required for cleanup, to prevent timeout leak
 * @param {function} setStartSpinner - function to toggle visiblity of progress bar
 * @param {int} tabBufferCounter - counter to see how many times function will do recursion of itself
 */
async function CommonHandleTabLoadComplete(targetPropertyName, refTabCounterTimeOut, setStartSpinner, tabBufferCounter) {
  // Clean up current instance
  if (refTabCounterTimeOut.current) clearTimeout(refTabCounterTimeOut.current);

  // Generate another timeout for progress bar
  setStartSpinner(true);
  refTabCounterTimeOut.current = setTimeout(async () => {
    if ($(`.formio-component-${targetPropertyName}`).length > 0) {
      setStartSpinner(false);
    } else {
      --tabBufferCounter;
      if (tabBufferCounter > 0) {
        await CommonHandleTabLoadComplete(
          targetPropertyName,
          refTabCounterTimeOut,
          setStartSpinner,
          tabBufferCounter
        );
      } else {
        setStartSpinner(false);
      }
    }
  }, 1000);
}

const GenericTabTimeout = (targetPropertyName, refTabCounterTimeOut, setStartSpinner, tabBufferCounter) => {
  return setTimeout(async () => {
    await CommonHandleTabLoadComplete(
      targetPropertyName,
      refTabCounterTimeOut,
      setStartSpinner,
      tabBufferCounter
    );
  }, 0);
}

export {
  ConvertUtcToLocale_ApplicantRegistrationDate,
  GenericTabTimeout
};
