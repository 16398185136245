export class SchemesService {
    getGrantType() {
        return [
            {
                id: 1,
                desc: "Industry Digital Plan (IDP)",
                path: "/IDPMainForm", //route path
                list: "IDP",
                type: "IDP"
            },
            {
                id: 2,
                desc: "MINT-Product Development",
                path: "",
                list: "",
                type: "MINT"
            },
            {
                id: 3,
                desc: "MINT-Research & Development",
                path: "",
                list: "",
                type: "MINT"
            },
            {
                id: 4,
                desc: "MINT Start-Up (PIER71)",
                path: "",
                list: "",
                type: "MINT"
            },
            {
                id: 5,
                desc: "Global Internship Award",
                path: "",
                list: "",
                type: "OTHERS"
            },
            {
                id: 6,
                desc: "Tripartite Maritime Scholarship",
                path: "",
                list: "",
                type: "OTHERS"
            },
            {
                id: 7,
                desc: "Training@Maritime Singapore - Short course",
                path: "",
                list: "",
                type: "OTHERS"
            },
            {
                id: 8,
                desc: "Training@MaritimeSingapore - Certifiable course",
                path: "",
                list: "",
                type: "OTHERS"
            },
            {
                id: 9,
                desc: "Talent@MartimeSingapore- Industry Attachment",
                path: "",
                list: "",
                type: "OTHERS"
            },
        ]
    }

    getIncentiveType() {
        return [
            {
                id: 1,
                desc: "MSI - Approved International Shipping Enterprise (MSI-AIS) Award",
                path: "/MSIAISMainForm", //route path
                list: "MSI_AIS",
                type: "MSI"
            },
            {
                id: 2,
                desc: "MSI - Maritime Leasing (MSI-ML) Award",
                path: "",
                list: "",
                type: "MSI"
            },
            {
                id: 3,
                desc: "MSI - Shipping-related Support Services (MSI-SSS) Award",
                path: "",
                list: "",
                type: "MSI"
            },
            {
                id: 4,
                desc: "Withholding Tax (WHT) Exemption",
                path: "/WHTQEntityTypeForm",
                list: "WHT_Ship",
                type: "WHT"
            },
            // {
            //     id: 5,
            //     desc: "Withholding Tax (WHT) Exemption Container",
            //     path: "/WHTContainerForm",
            //     list: "WHT_Container",
            //     type: "WHT"
            // },
        ]
    }
}