import { config } from "../../config";

const getTitle = (form: { allPages: any; }, formID: string, formType: string) => {
    let title : any = [];
    let startIndex=0
    const titleComponent = form.allPages
    if(formType?.toLowerCase() === config.form_name_target_idp_main?.toLowerCase() && formID !== null){
        startIndex=1
    }

    for(let i=startIndex; i<titleComponent.length; i++){
        let getTitle : string = titleComponent[i].component.title
        if(getTitle === "Conditions "){
            getTitle="Conditions"
        }
        title.push(getTitle)
    }
    return title
}

export default getTitle
