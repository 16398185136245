import { Chip } from '@mui/material';
import { styled } from '@mui/system';

const ChipStatus = styled(Chip)({
    height: '24px',
    fontFamily: '"Univers-65Bold", Helvetica',
    fontSize: '12px',
    margin: '0 4px 0 0'
})

export default function StatusChip({ type }) {
    let typeLower;
    if (type === null || type === undefined){
        typeLower = '';
    }  else {
        typeLower = type.toLowerCase();
    }
    switch (typeLower) {
        case 'in review':
            return <ChipStatus
                label={'In Review'}
                variant='outlined'
                sx={{
                    color: "#FF8A00",
                    borderColor: "#FF8A00",
                    backgroundColor: "#FFE8CC"
                }} />;
        case 'returned':
            return <ChipStatus
                // label={'Returned'}
                label={'Action Required'}
                variant='outlined'
                sx={{
                    color: "#FF8A00",
                    borderColor: "#FF8A00",
                    backgroundColor: "#FFE8CC"
                }} />;
        case 'submitted':
            //case 'new':
            return <ChipStatus
                label={'Submitted'}
                variant='outlined'
                sx={{
                    color: "#FF8A00",
                    borderColor: "#FF8A00",
                    backgroundColor: "#FFE8CC"
                }} />;
        case '':
        case 'draft':
            return <ChipStatus
                label='Draft'
                variant='outlined'
                sx={{
                    color: "#FFB639",
                    borderColor: "#FFB639",
                    backgroundColor: "#FFF4E1"
                }} />;
        case 'processing':
        case 'update request':
        case 'pending acceptance':
            return <ChipStatus
                label={typeLower === 'processing' ? 'Processing' :
                    typeLower === 'update request' ? 'Update Request' :
                        typeLower === 'pending acceptance' ? 'Pending Acceptance' :
                            ''}
                variant='outlined'
                sx={{
                    color: "#0088CE",
                    borderColor: "#0088CE",
                    backgroundColor: "#CCE7F5"
                }} />;
        case 'expired':
        case 'withdrawn':
        case 'rejected':
            return <ChipStatus
                label={typeLower === 'withdrawn' ? 'Withdrawn' :
                    typeLower === 'expired' ? 'Expired' :
                        typeLower === 'rejected' ? 'Rejected' :
                            ''}
                variant='outlined'
                sx={{
                    color: "#FF4D4D",
                    borderColor: "#FF4D4D",
                    backgroundColor: "#FFDBDB"
                }} />;
        case 'deleted':
            return <ChipStatus
                label='Deleted'
                variant='outlined'
                sx={{
                    color: "#FF4D4D",
                    borderColor: "#FF4D4D",
                    backgroundColor: "#FFDBDB"
                }} />;
        case 'approved':
            return <ChipStatus
                label='Approved'
                variant='outlined'
                sx={{
                    color: "#009B74",
                    borderColor: "#009B74",
                    backgroundColor: "#CCEBE3"
                }} />;
        case 'acknowledged':
            return <ChipStatus
                label='Acknowledged'
                variant='outlined'
                sx={{
                    color: "#009B74",
                    borderColor: "#009B74",
                    backgroundColor: "#CCEBE3"
                }} />;
        case 'lapsed':
            return <ChipStatus
                label='Lapsed'
                variant='outlined'
                sx={{
                    color: "#FF4D4D",
                    borderColor: "#FF4D4D",
                    backgroundColor: "#FFDBDB"
                }} />;
        case 'terminated':
            return <ChipStatus
                label='Terminated'
                variant='outlined'
                sx={{
                    color: "#FF4D4D",
                    borderColor: "#FF4D4D",
                    backgroundColor: "#FFDBDB"
                }} />;
        case 'void':
            return <ChipStatus
                label='Void'
                variant='outlined'
                sx={{
                    color: "#FF4D4D",
                    borderColor: "#FF4D4D",
                    backgroundColor: "#FFDBDB"
                }} />;
        case 'completed':
            return <ChipStatus
                label='Completed'
                variant='outlined'
                sx={{
                    color: "#009B74",
                    borderColor: "#009B74",
                    backgroundColor: "#CCEBE3"
                }} />;
        case 'disbursed':
            return <ChipStatus
                label='Disbursed'
                variant='outlined'
                sx={{
                    color: "#009B74",
                    borderColor: "#009B74",
                    backgroundColor: "#CCEBE3"
                }} />;
        case 'ceased':
            return <ChipStatus
                label='Ceased'
                variant='outlined'
                sx={{
                    color: "#FF4D4D",
                    borderColor: "#FF4D4D",
                    backgroundColor: "#FFDBDB"
                }} />;
        case 'returnedforcomment':
            return <ChipStatus
                label='Returned for comment'
                variant='outlined'
                sx={{
                    color: "#0088CE",
                    borderColor: "#0088CE",
                    backgroundColor: "#CCE7F5"
                }} />;
        case 'approvedbymof':
            return <ChipStatus
                label='Approved By MOF'
                variant='outlined'
                sx={{
                    color: "#009B74",
                    borderColor: "#009B74",
                    backgroundColor: "#CCEBE3"
                }} />;
        case 'rejectedbymof':
            return <ChipStatus
                label='Rejected By MOF'
                variant='outlined'
                sx={{
                    color: "#FF4D4D",
                    borderColor: "#FF4D4D",
                    backgroundColor: "#FFDBDB"
                }} />;
        case 'closed':
            return <ChipStatus
                label='Closed'
                variant='outlined'
                sx={{
                    color: "#009B74",
                    borderColor: "#009B74",
                    backgroundColor: "#CCEBE3"
                }} />;
        case 'pendingmofappeal':
            return <ChipStatus
                label='Pending MOF Appeal'
                variant='outlined'
                sx={{
                    color: "#FFB639",
                    borderColor: "#FFB639",
                    backgroundColor: "#FFF4E1"
                }} />;
        case 'mofappealsuccessful':
            return <ChipStatus
                label='MOF Appeal Successful'
                variant='outlined'
                sx={{
                    color: "#009B74",
                    borderColor: "#009B74",
                    backgroundColor: "#CCEBE3"
                }} />;
        case 'mofappealrejected':
            return <ChipStatus
                label='MOF Appeal Rejected'
                variant='outlined'
                sx={{
                    color: "#FF4D4D",
                    borderColor: "#FF4D4D",
                    backgroundColor: "#FFDBDB"
                }} />;
        case 'false':
            return <ChipStatus
                label='Inactive'
                variant='outlined'
                sx={{
                    color: "#FF0000",
                    borderColor: "#FF0000",
                    backgroundColor: "#ffcccb"
                }} />;
        case 'true':
            return <ChipStatus
                label='Active'
                variant='outlined'
                sx={{
                    color: "#009B74",
                    borderColor: "#009B74",
                    backgroundColor: "#CCEBE3"
                }} />;
        default: {
            if (type === null) return null; else
                if (typeLower.slice(0, 3) === 'due') {
                    return <ChipStatus
                        label={type}
                        variant='outlined'
                        sx={{
                            color: "#FA6980",
                            borderColor: "#FA6980",
                            backgroundColor: "#FEE1E6"
                        }} />;
                } else {
                    return type;
                }
        }
    }
}

