import { Typography, Box, Grid } from "@mui/material";
import IDPCard from '../cards/IDPCard'
import { IconArrowRight } from "@tabler/icons";
import { config } from "../../../config";

export default function OrganizationGrant() {
    return (
        <>
            <Typography mb={1} variant='h3'>Our Recommendation</Typography>
            <br />
            <br />
            <Typography variant='h5'>
                Based on your selection, the following MPA grant(s) and incentive(s) may be applicable for your project:
            </Typography>
            <br />
            {config.isIDP === "true" ?
                <Grid container spacing={4}>
                    <Grid item>
                        <IDPCard />
                    </Grid>
                </Grid>
                :
                <Box
                    sx={{
                        backgroundColor: "white",
                        height: "100px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6" color={"darkgrey"} sx={{ marginLeft: 5 }}>
                        Please visit MPA website for more details.
                        <a
                            href="https://www.mpa.gov.sg/maritime-singapore/what-maritime-singapore-offers/programmes-to-support-your-maritime-business/industry-digital-plan"
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ textDecoration: "none" }}
                        >
                            &nbsp;Learn More <IconArrowRight />
                        </a>
                    </Typography>
                </Box>
            }
        </>
    )
}
