import { useState } from 'react'
import { Box, Button, Container, FormControl, FormHelperText, TextField, Typography, } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import './Login.css';

function LoginTab() {

    const [errorMsg, setErrorMsg] = useState('');
    const { handleSubmit, control, formState: { errors } } = useForm();

    const onSubmit = () => {
        // This method is intentionally empty.
    }


    return (
        <Container>
            <div style={{ textAlign: "center" }}>
                <Typography className='testFont2'>Log in with<br /></Typography>
                <Typography className='testFont'>Temporary Account</Typography>
            </div>

            <br />

            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Box sx={{ mb: 3 }}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: "Please enter your email!" }}
                            render={({ field: { value, onChange } }) => (
                                <div>
                                    <Typography className='testFont2'>Email</Typography>
                                    <FormControl>
                                        <TextField
                                            className='tempTextfield'
                                            fullWidth
                                            placeholder="Enter Email"
                                            value={value}
                                            onChange={onChange}
                                            error={errors.email ? true : false}
                                        />
                                    </FormControl>
                                </div>
                            )}
                        />
                        {errors.email && (
                            <FormHelperText className='error-text' role="alert">
                                {errors.email.message}
                            </FormHelperText>
                        )}
                    </Box>

                    <Box sx={{ mb: 3 }}>
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: "Please enter your password!",
                                // pattern: {
                                //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/,
                                //     message:
                                //         "Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.",
                                // },
                            }}
                            render={({ field: { value, onChange } }) => (
                                <div>
                                    <Typography className='testFont2'>Password</Typography>
                                    <FormControl>
                                        <TextField
                                            className='tempTextfield'
                                            fullWidth
                                            placeholder='Enter Password'
                                            value={value}
                                            onChange={onChange}
                                            type="password"
                                            error={errors.password ? true : false}
                                        />
                                    </FormControl>
                                </div>
                            )}
                        />
                        {errors.password && (
                            <FormHelperText className='error-text' role="alert">
                                {errors.password.message}
                            </FormHelperText>
                        )}
                    </Box>
                </div>

                {/* display error message here */}
                <div style={{ display: errorMsg === '' ? 'none' : 'block', color: 'red' }}>
                    {errorMsg}
                </div>

                <Button variant='contained' className="loginBtn" type="submit" fullWidth>
                    Login
                </Button>

                <div>
                    <Typography sx={{
                        color: "#0088ce",
                        fontFamily: "Univers-55Roman",
                        textAlign: "center",
                        fontWeight: 400,
                    }}>
                        Forgot User Name / Password ?
                    </Typography>
                </div>
            </form>



        </Container>
    )
}

export default LoginTab
