import { Card } from "@mui/material";
import { useHistory } from "react-router-dom";
import { DirectUserToIdpWebsite } from "../../../../Common/CommonUtils";

export default function IDPCard() {
    const history = useHistory();
    return (<>
        <Card className="scheme-card clickText" onClick={()=> DirectUserToIdpWebsite()}>
            <div className="overlay-group">
                <div className="image-1" onClick={()=> DirectUserToIdpWebsite()}>
                    {/* <div className="image-container"></div> */}
                </div>
                <div className="image-text-wht" onClick={()=> DirectUserToIdpWebsite()}>Sea Transport Industry Digital Plan (IDP)</div>
            </div>
            <p className="scheme-description" onClick={()=> DirectUserToIdpWebsite()}>
                The Sea Transport Industry Digital Plan (IDP) is a part of the SMEs Go Digital Programme
                which provides a step-by-step guide on the digital solutions SMEs in Sea Transport
                sector can adopt at each stage of their growth.
            </p>
        </Card>
    </>)
}
