export var allDocumentsList = [
    {id: 1, docName: "1 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},   
    {id: 2, docName: "2 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 3, docName: "3 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 4, docName: "4 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 5, docName: "5 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 6, docName: "6 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 7, docName: "7 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 8, docName: "8 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 9, docName: "9 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 10, docName: "10 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 11, docName: "11 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 12, docName: "12 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 13, docName: "13 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 14, docName: "14 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 15, docName: "15 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
    {id: 16, docName: "16 file.doc", docUpLoadedOn: 'ddmmyy', docFileSizes: '32G', docUpLoadedBy: 'Poh Kiat', docActions: ''},
];