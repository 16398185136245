import { Card } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function MSICard() {
  const history = useHistory();
  return (
    <>
      <Card
        className="scheme-card clickText"
        onClick={() => history.push("/MSIScheme")}
      >
        <div className="overlay-group">
          <div
            className="image-2"
            onClick={() => history.push("/MSIScheme")}
          ></div>
          <div
            className="image-text-wht"
            onClick={() => history.push("/MSIScheme")}
          >
            {" "}
            Maritime Sector Incentive
            <br /> (MSI)
          </div>
        </div>
        <p
          className="scheme-description"
          onClick={() => history.push("/MSIScheme")}
        >
          The Maritime Sector Incentive (MSI) helps shipping enterprises to grow
          their businesses in Singapore. It comprises awards which cater for
          different businesses.
        </p>
      </Card>
    </>
  );
}
