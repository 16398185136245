import React from "react";
import Scheme from "./Components/Schemes";
import HomepageCarousel from "./Components/HomePageCarousel";
import "./HomePage.css";
import loginHelper from "../../Common/loginHelper";
import "@webcomponents/scoped-custom-element-registry"
import "@govtechsg/sgds-web-component";
import "@govtechsg/sgds-web-component/components/Masthead";

function HomePage() {
  loginHelper.deleteLogoutFlag();
  return (
    <div className="overlay-group-container">
      <HomepageCarousel />
      <Scheme />
    </div>
  );
}

export default HomePage;
