import {ApplicationStatusTextOptions} from './ApplicationStatusOptions'

export default function getStatusText(currentStatus){

    let statusText

    switch (currentStatus) {
  
        case 1: statusText = ApplicationStatusTextOptions.Draft;
            break
        case 2: statusText = ApplicationStatusTextOptions.Deleted;
            break
        case 3: statusText = ApplicationStatusTextOptions.Processing;
            break
        case 4: statusText = ApplicationStatusTextOptions.Approved;
            break
        case 5: statusText = ApplicationStatusTextOptions.Rejected;
            break
        case 6: statusText = ApplicationStatusTextOptions.Withdrawn;
            break
        case 7: statusText = ApplicationStatusTextOptions.Lapsed;
            break
        case 8: statusText = ApplicationStatusTextOptions.Acknowledged;
            break
        case 9: statusText = ApplicationStatusTextOptions.Returned;
            break
        case 10: statusText = ApplicationStatusTextOptions.Terminated;
            break
        case 11: statusText = ApplicationStatusTextOptions.Completed;
            break
        case 12: statusText = ApplicationStatusTextOptions.Disbursed;
            break
        case 13: statusText = ApplicationStatusTextOptions.Ceased;
            break
        case 14: statusText = ApplicationStatusTextOptions.Returnedforcomment;
            break
        case 15: statusText = ApplicationStatusTextOptions.ApprovedByMOF;
            break
        case 16: statusText = ApplicationStatusTextOptions.RejectedByMOF;
            break
        case 17: statusText = ApplicationStatusTextOptions.Closed;
            break
        case 18: statusText = ApplicationStatusTextOptions.PendingMOFAppeal;
            break
        case 19: statusText = ApplicationStatusTextOptions.MOFAppealSuccessful;
            break
        case 20: statusText = ApplicationStatusTextOptions.MOFAppealRejected;
            break
        case 21: statusText = ApplicationStatusTextOptions.Expired;
            break
        case 22: statusText = ApplicationStatusTextOptions.Void;
            break
        default: break;
    }

    return statusText

}