import {
  Container,
  Box,
  Button,
  Stack,
  SvgIcon,
  useMediaQuery,
} from "@mui/material";
import { useHistory } from "react-router-dom";

function BlackRightArrow() {
  return (
    <SvgIcon>
      <path
        id="Path_4591"
        data-name="Path 4591"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <line
        id="Line_5"
        data-name="Line 5"
        x2="14"
        transform="translate(4.696 12)"
        fill="none"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_6"
        data-name="Line 6"
        y1="4"
        x2="4"
        transform="translate(14.696 12)"
        fill="none"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_7"
        data-name="Line 7"
        x2="4"
        y2="4"
        transform="translate(14.696 8)"
        fill="none"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}

function WhiteRightArrow() {
  return (
    <SvgIcon>
      <path
        id="Path_4591"
        data-name="Path 4591"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <line
        id="Line_5"
        data-name="Line 5"
        x2="14"
        transform="translate(4.696 12)"
        fill="none"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_6"
        data-name="Line 6"
        y1="4"
        x2="4"
        transform="translate(14.696 12)"
        fill="none"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_7"
        data-name="Line 7"
        x2="4"
        y2="4"
        transform="translate(14.696 8)"
        fill="none"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}

export default function Item1() {
  const history = useHistory();
  return (
    <div
      className="carousel-background"
      id={useMediaQuery("(min-width: 500px)") ? "background-1" : "background-2"}
    >
      <Container>
        <Box sx={{ m: "169px 0px 120px" }}>
          <Box className="main-box">
            <div className="title-indicator">ONE MARITIME PORTAL</div>
            <div className="title-box">
              <div className="h1-title">
                One Maritime Portal is a one-stop digital platform for
                individuals and maritime enterprises to access the schemes
                administered and supported by MPA.
              </div>
            </div>
            {/* <div className="subheader-box">
              <div className="subheader">
                One Maritime Portal provides end-to-end solutions to support the
                diverse business needs of maritime enterprises.
              </div>
            </div> */}
          </Box>
          {/* <Stack sx={{ mt: "20px", flexWrap: 'wrap', gap: 1 }} direction={useMediaQuery('(min-width: 400px)') ? "row": "column"} columnGap={3}> */}
          <Stack
            sx={
              useMediaQuery("(min-width: 500px)")
                ? { mt: "140px", flexWrap: "wrap", gap: 1 }
                : { mt: "100px", flexWrap: "wrap", gap: 1 }
            }
            direction={useMediaQuery("(min-width: 400px)") ? "row" : "column"}
            columnGap={3}
          >
            <Button
              className="explore-button"
              endIcon={<WhiteRightArrow />}
              onClick={() => history.push("/ExploreSchemes")}
            >
              Explore Schemes
            </Button>
            <Button
              className="about-omp-button"
              endIcon={<BlackRightArrow />}
              onClick={() => history.push("/AboutOMP")}
            >
              About OMP
            </Button>
          </Stack>
        </Box>
      </Container>
    </div>
  );
}
