import { Form } from '@formio/react';
import { Container, Paper, Typography } from '@mui/material';
import { config } from '../../config';

export default function SSICCodes() {

  const formId = "ssicCodes";
  const appFormBaseURL = `${config.appFormBaseURL}`;
  const appFormURL = `${appFormBaseURL}/${formId}`;

  return (
        <div className='applicationform'>
            <Container>
                <Typography className='univers-55roman-normal-mine-shaft-22px'>
                    List of SSIC Codes for each subsector
                </Typography>
                <Paper sx={{ py: 2.5, px: 5, my: 3 }}>
                    <Form
                        form={appFormURL}
                        url={appFormURL}
                    />
                </Paper>
            </Container>
        </div>
    )
}
