import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetch } from "../../Common/API";
import { config } from "../../config";

//Listing Tab
export const getPublicUserListingAPIAsync = createAsyncThunk(
  "publicUserList/getPublicUserListingAPIAsync",
  async () => {
    const url = `${config.appURL}/api/InternetPublicUser/GetUsers`;
    const response = await fetch(url, "GET");   
    return response.data;
  }
);

export const getPublicUserListByIdAPIAsync = createAsyncThunk(
  "publicUserList/getPublicUserListByIdAPIAsync",
  // async (id) => { return getPublicUserListById(id) }
  async (id) => {
    const url = `${config.appURL}/api/PublicUsers/PublicUserId/${id}?getRoles=true`;
    const response = await fetch(url, "GET");
    return response.data.Value;
  }
);

export const getPublicUserViewByIdAPIAsync = createAsyncThunk(
  "publicUserList/getPublicUserViewByIdAPIAsync",
  async (id) => {
    const url = `${config.appURL}/api/PublicUsers/PublicUserId/${id}?getRoles=true`;
    const response = await fetch(url, "GET");
    return response.data.Value;
  }
);

export const createPublicUserAPIAsync = createAsyncThunk(
  "publicUserList/createPublicUserAPIAsync",
  async (publicUserList, { rejectWithValue }) => {
    const userId = 2000;
    const payload = {};
    const url = `${config.appURL}/api/PublicUsers?userId=${userId}`;
    try {
      const response = await fetch(url, "POST", payload);
      return response.data.Value;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePublicUserListAPIAsync = createAsyncThunk(
  "publicUserList/updatePublicUserListAPIAsync",
  async (body, { rejectWithValue }) => {
    const payload = {
      Username: "string",
      Status: 0,
      CanDelete: true,
      Updatable: {
        CreateAt: "2022-06-15T04:13:16.996Z",
        CreateBy: 0,
        UpdateAt: "2022-06-15T04:13:16.996Z",
        UpdateBy: 0,
      },
      Remarks: "string",
      Roles: [
        {
          Key: "string",
          Active: true,
          Updatable: {
            CreateAt: "2022-06-15T04:13:16.996Z",
            CreateBy: 0,
            UpdateAt: "2022-06-15T04:13:16.996Z",
            UpdateBy: 0,
          },
          Privileges: [
            {
              Key: "string",
              Id: 0,
              Name: "string",
              Description: "string",
            },
          ],
          Id: 0,
          Name: "string",
          Description: "string",
        },
      ],
      ServiceProvider: {
        Key: "string",
        ParentId: 0,
        Status: 0,
        Approvable: {
          ApproveAt: "2022-06-15T04:13:16.996Z",
          ApproveBy: 0,
        },
        Updatable: {
          CreateAt: "2022-06-15T04:13:16.996Z",
          CreateBy: 0,
          UpdateAt: "2022-06-15T04:13:16.996Z",
          UpdateBy: 0,
        },
        Remark: "string",
        Id: 0,
        Name: "string",
        Description: "string",
      },
      Contact: {
        Id: 0,
        FirstName: "string",
        MobilePhone: "string",
        Email: "string",
        Updatable: {
          CreateAt: "2022-06-15T04:13:16.996Z",
          CreateBy: 0,
          UpdateAt: "2022-06-15T04:13:16.996Z",
          UpdateBy: 0,
        },
      },
      Id: 0,
      Name: "string",
      Description: "string",
      ...body,
    };
    const url = `${config.appURL}/api/PublicUsers`;
    try {
      const response = await fetch(url, "POST", payload);
      return response.data.Value;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const PublicUserListingSlice = createSlice({
  name: "publicUserList",
  initialState: { publicUserListing: [] },
  reducers: {},

  extraReducers: {
    [getPublicUserListingAPIAsync.fulfilled]: (state, action) => {
      return {
        ...state,
        publicUserListing: action.payload,
      };
    },

    [getPublicUserListByIdAPIAsync.fulfilled]: (state, action) => {
      return action.payload;
    },

    [getPublicUserViewByIdAPIAsync.fulfilled]: (state, action) => {
      return action.payload;
    },

    [createPublicUserAPIAsync.fulfilled]: (state, action) => {
      return {
        ...state,
        create: action.payload,
      };
    },
    [createPublicUserAPIAsync.rejected]: (state, action) => {
      return {
        ...state,
        create: action.payload,
      };
    },

    [updatePublicUserListAPIAsync.fulfilled]: (state, action) => {
      return {
        ...state,
        update: action.payload,
      };
    },
    [updatePublicUserListAPIAsync.rejected]: (state, action) => {
      return {
        ...state,
        update: action.payload,
      };
    },
  },
});

export const { createPublicUser } = PublicUserListingSlice.actions;
export default PublicUserListingSlice.reducer;
