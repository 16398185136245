import "./App.css";
import Routing from "./Router";
import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, Typography } from "@mui/material";
import { IconChevronUp } from "@tabler/icons";
import emitter from "./Common/EventEmitterUtil";
import { config } from "./config";
import { GenerateSRI } from "./Common/SRIUtils";
import AssetLoader from "./Common/AssetLoader";
import { ConsoleError } from "./Common/Logger";

function App() {
  const theme = createTheme({
    palette: {
      common: { black: '#333333' },
      primary: { dark: '#002395', main: '#0065BD', light: '#0088CE' },
      info: { dark: '#002395', main: '#0088CE', light: '#0088CE' },
      secondary: { dark: '#009B74', main: '#00AF3F', light: '#69BE28' },
      error: { dark: '#FF4D4D', main: '#FF4D4D', light: '#FFC96C' },
      warning: { dark: '#FF4D4D', main: '#FF8A00', light: '#FFC96C' },
    },
    typography: {
      fontFamily: "'Univers', Helvetica",
      //   fontSize: fontsize
      h1: { fontFamily: "'Univers-65Bold',  Helvetica", fontSize: '46px', lineHeight: '56px', fontWeight: 700, letterSpacing: '0' },
      h2: { fontFamily: "'Univers-65Bold',  Helvetica", fontSize: '38px', lineHeight: '48px', fontWeight: 700, letterSpacing: '0' },
      h3: { fontFamily: "'Univers-65Bold',  Helvetica", fontSize: '28px', lineHeight: '38px', fontWeight: 700, letterSpacing: '0' },
      h4: { fontFamily: "'Univers-65Bold',  Helvetica", fontSize: '20px', lineHeight: '28px', fontWeight: 700, letterSpacing: '0' },
      h5: { fontFamily: "'Univers-65Bold',  Helvetica", fontSize: '18px', lineHeight: '28px', fontWeight: 700, letterSpacing: '0' },
      h6: { fontFamily: "'Univers-65Bold',  Helvetica", fontSize: '16px', lineHeight: '28px', fontWeight: 700, letterSpacing: '0' },
    }
  });

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const images = [
      `${process.env.PUBLIC_URL}${config?.contactUsURL}`,
      `${process.env.PUBLIC_URL}${config?.logoURL}`
    ]; 
    AssetLoader.getInstance().preloadMultipleImages(images)
      .catch((e) => { ConsoleError.error("Failed to load image, ", e);});
  }, []);

  useEffect(() => {
    GenerateSRI(config.wogaaURL);
  }, []);

  useEffect(() => {

    emitter.removeAllListeners();

    emitter.once('duplicateLogin', function (value) {
      alert("Duplicate Login Detected.");
      window.location.replace("/");
    });

    emitter.on('forbidden', function (value) {
      alert("Forbidden");
      window.location.replace("/");
    });

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) setShowButton(true)
      else setShowButton(false);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <sgds-masthead></sgds-masthead>
      <Routing />
      {showButton && <Button className='back-to-top'
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <div>
          <IconChevronUp style={{ marginTop: -4 }} />
          <Typography className="univers-55roman-normal-mine-shaft-14px" textTransform={'none'} mt={-0.7}>
            Top
          </Typography>
        </div>
      </Button>}
    </ThemeProvider>
  );
}

export default App;
