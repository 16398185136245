  //-- Enum 
  const VesselDetailsStatus = {
    EMPTY: 0,                 // Default
    CHECKED_NO_IMO_NUMBER: 1, // Free play, we tick "no imo number checkbox"
    SRS_FOUND: 2,             // Disabled
    SRS_NOT_FOUND: 3,         // Free play
    LOCKED: 4,                // All Disabled
    LOCKED_SRS_NOT_FOUND: 5,  // All Disabled and date of registry not shown
    TOTAL: 6
  }

  const ImoType = {
    NORMAL: 0,                // Refering to imo number
    EDIT: 1,                  // Refering to edit imo number
    REPLACE: 2,               // Refering to replace imo number
    DELETE: 3,                // Refering to delete imo number
    NONE: 4,
    TOTAL: 5
  }

  const ComponentUpdateType = {
    NONE: 0,
    TRIGGER_CHANGE_ONLY: 1,       // Referring to suggesting Formio->Component.TriggerChange only 
    TRIGGER_REDRAW_ONLY: 2,       // Referring to suggesting Formio->Component.TriggerRedraw only
    TRIGGER_CHANGE_AND_REDRAW: 3, // Referring to suggesting both Formio->Component.TriggerChange & Formio->Component.TriggerRedraw
    TOTAL: 4
  }

  const ComponentFieldType = {
    NONE: 0,
    IMO_NUMBER: 1,                        // [Usage for "CommonVesselDatagrid_GetComponent_Index()"]
    FIELD_OF_REGISTRY: 2,                 // [Usage for "CommonVesselDatagrid_GetComponent_Index()"]
    STATUS_VESSEL_DETAIL: 3,              // [Usage for "CommonVesselDatagrid_GetComponent_Index()"]
    CHECKBOX_NO_IMO: 4,                   // [Usage for "CommonVesselDatagrid_GetComponent_Index()"]
    CHECKBOX_NO_IMO_FOR_EDIT: 5,          // [Usage for "CommonVesselDatagrid_GetComponent_Index()"]
    CHECKBOX_NO_IMO_FOR_REPLACEMENT: 6,   // [Usage for "CommonVesselDatagrid_GetComponent_Index()"]
    CONTAINER_FOR_EDIT_CHECKBOX: 7,       // [Usage for "CommonVesselDatagrid_GetComponent_Index()"]
    DELETE_VESSEL_CHECKBOX: 8,            // [Usage for "CommonVesselDatagrid_GetComponent_Index()"]
    NORMAL_COLUMN_TOP: 9,                 // [Usage for "CommonVesselDatagrid_GetComponent_Update()"] 
    NORMAL_COLUMN_BOTTOM: 10,             // [Usage for "CommonVesselDatagrid_GetComponent_Update()"] 
    EDIT_COLUMN_TOP: 11,                  // [Usage for "CommonVesselDatagrid_GetComponent_Update()"] 
    EDIT_COLUMN_BOTTOM: 12,               // [Usage for "CommonVesselDatagrid_GetComponent_Update()"] 
    REPLACEMENT_COLUMN_TOP: 13,           // [Usage for "CommonVesselDatagrid_GetComponent_Update()"] 
    REPLACEMENT_COLUMN_BOTTOM: 14,        // [Usage for "CommonVesselDatagrid_GetComponent_Update()"] 
    TOTAL: 15,
  }

  const UploadVesselCheckboxType = {
    DATAGRID_TO_EXCEL: 0,
    EXCEL_TO_DATAGRID: 1,
    TOTAL: 2
  }

  let SIMULATE_DUMMY_DATA_SRS_API = false; // If failed to fetch SRS->API data, due to MPA SRS down, set this to true, and dummy data will be used

  const WHTS_CONSTANT = {
    SINGAPORE: "singapore",
    AVOID_CHARACTERS_MESSAGE: "Invalid input. Please remove the following characters: # * {{ }} to proceed.",
  };

  export {VesselDetailsStatus, ImoType, ComponentUpdateType, ComponentFieldType, SIMULATE_DUMMY_DATA_SRS_API, UploadVesselCheckboxType, WHTS_CONSTANT}; 