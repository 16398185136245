import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit";
import { fetch } from '../../Common/API'
import { config } from '../../config'
import { ApplicationStatusTextOptions } from '../../Common/ApplicationStatusOptions'
import moment from 'moment';

export const getApplicationListingPaginationAsync = createAsyncThunk(
    'ApplicationListing/getApplicationListingPaginationAsync',
    async (skipLimit) => {
        try {
            let resp;
            if (skipLimit?.search) {
                let query = `${config.appURL}/api/application/GetAllApplicationListing?skip=${skipLimit.skip}&limit=${skipLimit.limit}&sort=-modified&applicationStatus=${skipLimit.applicationStatus}&search=${skipLimit.search}`;
                resp = await fetch(query, 'GET', null);
            }
            else {// filter
                let query= `${config.appURL}/api/Application/GetAllApplicationListing?skip=${skipLimit.skip}&limit=${skipLimit.limit}&sort=-modified`;
                if (skipLimit.caseId) {
                    query += `&caseId=${skipLimit.caseId}`
                }
                if (skipLimit.schemaExtension) {
                    query += `&schemaExtension=${skipLimit.schemaExtension}`
                }
                if (skipLimit.submissionStartDate) {
                    query += `&submissionStartDate=${skipLimit.submissionStartDate}`
                }
                if (skipLimit.submissionEndDate) {
                    query += `&submissionEndDate=${skipLimit.submissionEndDate}`
                }
                if (skipLimit.applicationStatus || skipLimit.applicationStatus === 0) {
                    query += `&applicationStatus=${skipLimit.applicationStatus}`
                }
                if (skipLimit.scheme) {
                    query += `&scheme=${skipLimit.scheme}`
                }
                if (skipLimit.isAssignedToMe) {
                    query += `&isAssignedToMe=${skipLimit.isAssignedToMe}`
                }
                //resp = await axios.get(query);
                // resp = await fetch(query, {
                //     method: 'GET'
                //     headers: {
                //         'Content-Type': 'application/json',
                //         "Authorization": "Bearer " + loginHelper.getLoginJwt(),
                //     }
                // });

                resp = await fetch(query, 'GET', null);

            }

            const app = await resp.data;
            let applicationList = [];
            app.ApplicationListingResponses.forEach(element => {
                let statusText
                switch (element.Status) {

                    case 1: statusText = ApplicationStatusTextOptions.Draft;
                        break
                    case 2: statusText = ApplicationStatusTextOptions.Deleted;
                        break
                    case 3: statusText = ApplicationStatusTextOptions.Processing;
                        break
                    case 4: statusText = ApplicationStatusTextOptions.Approved;
                        break
                    case 5: statusText = ApplicationStatusTextOptions.Rejected;
                        break
                    case 6: statusText = ApplicationStatusTextOptions.Withdrawn;
                        break
                    case 7: statusText = ApplicationStatusTextOptions.Lapsed;
                        break
                    case 8: statusText = ApplicationStatusTextOptions.Acknowledged;
                        break
                    case 9: statusText = ApplicationStatusTextOptions.Returned;
                        break
                    case 10: statusText = ApplicationStatusTextOptions.Terminated;
                        break
                    case 11: statusText = ApplicationStatusTextOptions.Completed;
                        break
                    case 12: statusText = ApplicationStatusTextOptions.Disbursed;
                        break
                    case 13: statusText = ApplicationStatusTextOptions.Ceased;
                        break
                    case 14: statusText = ApplicationStatusTextOptions.Returnedforcomment;
                        break
                    case 15: statusText = ApplicationStatusTextOptions.ApprovedByMOF;
                        break
                    case 16: statusText = ApplicationStatusTextOptions.RejectedByMOF;
                        break
                    case 17: statusText = ApplicationStatusTextOptions.Closed;
                        break
                    case 18: statusText = ApplicationStatusTextOptions.PendingMOFAppeal;
                        break
                    case 19: statusText = ApplicationStatusTextOptions.MOFAppealSuccessful;
                        break
                    case 20: statusText = ApplicationStatusTextOptions.MOFAppealRejected;
                        break
                    case 21: statusText = ApplicationStatusTextOptions.Expired;
                        break
                    case 22: statusText = ApplicationStatusTextOptions.Void;
                        break
                    default: break;
                }

                const datelast = moment(element.LastUpdated).format("DD/MM/YYYY");
                const lasttime = moment(element.LastUpdated).format("HH:mm");
                const thetime = moment(lasttime, "HH:mm").format("LT")

                let a = {
                    id: element.Id,
                    caseId: element.CaseID,
                    award: element.Schema,
                    scheme: element.Schema,
                    schemaExtension: element.schemaExtension,
                    schemaDescription : element.schemaDescription,
                    uen: element.UEN,
                    company: element.Company,
                    // status: element.Status,statusText
                    status: statusText,
                    internalstatus: element.InternalStatus,
                    isIconShowWHTMOFCR: element.IsIconShowWHTMOFCR,
                    endDate: moment(element.EstimatedCompletionDate).format("DD/MM/YYYY HH:mm"),
                    LastUpdated: datelast + ", " + thetime,
                    NRIC: element.NRIC,
                    solution: element.Solution,
                    vendor: element.Vendor,
                    vesselName: element.VesselName,
                    lender: element.Lender,
                    entityType: element.EntityType,
                }
                applicationList.push(a)
            });

            return { applicationList };

        } catch (error) {
            return isRejectedWithValue(error.response.data)
        }
    }
);

const fetchListing = async (skipLimit) => {
    let resp;

            let query= `${config.appURL}/api/Application/GetAllApplicationListing?skip=${skipLimit.skip}&limit=${skipLimit.limit}&sort=-modified`;

            if (skipLimit.scheme) {
                query += `&scheme=${skipLimit.scheme}`
            }

        try {
            resp = await fetch(query, 'GET', null);

            const app = await resp.data;
            let applicationList = [];

            app.ApplicationListingResponses.forEach(element => {

                let statusText
                switch (element.Status) {

                    case 1: statusText = ApplicationStatusTextOptions.Draft;
                        break
                    case 2: statusText = ApplicationStatusTextOptions.Deleted;
                        break
                    case 3: statusText = ApplicationStatusTextOptions.Processing;
                        break
                    case 4: statusText = ApplicationStatusTextOptions.Approved;
                        break
                    case 5: statusText = ApplicationStatusTextOptions.Rejected;
                        break
                    case 6: statusText = ApplicationStatusTextOptions.Withdrawn;
                        break
                    case 7: statusText = ApplicationStatusTextOptions.Lapsed;
                        break
                    case 8: statusText = ApplicationStatusTextOptions.Acknowledged;
                        break
                    case 9: statusText = ApplicationStatusTextOptions.Returned;
                        break
                    case 10: statusText = ApplicationStatusTextOptions.Terminated;
                        break
                    case 11: statusText = ApplicationStatusTextOptions.Completed;
                        break
                    case 12: statusText = ApplicationStatusTextOptions.Disbursed;
                        break
                    case 13: statusText = ApplicationStatusTextOptions.Ceased;
                        break
                    case 14: statusText = ApplicationStatusTextOptions.Returnedforcomment;
                        break
                    case 15: statusText = ApplicationStatusTextOptions.ApprovedByMOF;
                        break
                    case 16: statusText = ApplicationStatusTextOptions.RejectedByMOF;
                        break
                    case 17: statusText = ApplicationStatusTextOptions.Closed;
                        break
                    case 18: statusText = ApplicationStatusTextOptions.PendingMOFAppeal;
                        break
                    case 19: statusText = ApplicationStatusTextOptions.MOFAppealSuccessful;
                        break
                    case 20: statusText = ApplicationStatusTextOptions.MOFAppealRejected;
                        break
                    case 21: statusText = ApplicationStatusTextOptions.Expired;
                        break
                    case 22: statusText = ApplicationStatusTextOptions.Void;
                        break
                    default: break;
                }

                const datelast = moment(element.LastUpdated).format("DD/MM/YYYY");
                const lasttime = moment(element.LastUpdated).format("HH:mm");
                const thetime = moment(lasttime, "HH:mm").format("LT")

                let a = {
                    id: element.Id,
                    caseId: element.CaseID,
                    award: element.Schema,
                    scheme: element.Schema,
                    schemaExtension: element.schemaExtension,
                    schemaDescription : element.schemaDescription,
                    uen: element.UEN,
                    company: element.Company,
                    // status: element.Status,statusText
                    status: statusText,
                    internalstatus: element.InternalStatus,
                    endDate: moment(element.EstimatedCompletionDate).format("DD/MM/YYYY HH:mm"),
                    LastUpdated: datelast + ", " + thetime,
                    NRIC: element.NRIC,
                    solution: element.Solution,
                    vendor: element.Vendor,
                    vesselName: element.VesselName,
                    lender: element.Lender,
                }
                applicationList.push(a)
            });
            return { applicationList };
        }
        catch (error){
            return isRejectedWithValue(error.response.data)
        }
}

export const deleteApplicationListingAPIAsync = createAsyncThunk(
	'ApplicationListing/deleteApplicationListingAPIAsync',
	async ({formId, submissionId, skipLimit}) => {
        try {
        //   var formId = data.formId
        //   var submissionId = data.submissionId
          const url = `${config.appURL}/api/Application/${formId}/submission/${submissionId}`
          const response = await fetch(url, "DELETE", "");

          if (response.data) {
            const getData = await fetchListing(skipLimit)
            return getData
          }
        } catch (err) {
            return isRejectedWithValue(err.response.data)
        }

      }
);

export const ApplicationListingSlice = createSlice({
    name: 'Application Listing',
    initialState: {
        list: []
    },
    reducers: {},
    extraReducers: {
        [getApplicationListingPaginationAsync.fulfilled]: (state, action) => {
            state.list = action.payload.applicationList
        },
        [deleteApplicationListingAPIAsync.fulfilled]: (state, action) => {
            state.list = action.payload.applicationList
        },

    }
});

export default ApplicationListingSlice.reducer;
