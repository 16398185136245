import { Alert, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function SnackbarAlert ({alert, caseID, open, setOpen}){

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            sx={{ textAlign: 'center' }}
            onClose={handleClose}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
            <Alert color="secondary"
            action={<IconButton
                sx={{ top: -2 }}
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}>
                <CloseIcon />
            </IconButton>}
            variant='filled'
            icon={false}
            sx={{ borderRadius: 6, pl: 3, alignItems: 'center' }}>

            <span className="univers-55roman-normal-white-14px">
                {alert === 'Draft' && `Draft ${caseID} is saved successfully.`}
                {alert === 'Withdrawn' && `Application is withdrawn successfully.`}
            </span>
            </Alert>
        </Snackbar>
        </>
    )
}
