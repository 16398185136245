import { useEffect, useRef, useState } from 'react';
import { config } from '../../config';
import { Breadcrumbs, Button, Container, Grid, Link, Paper, Typography } from "@mui/material";
import {
    onChangeGeneric,
    onErrorGeneric,
    onRenderGeneric,
    onSubmitDoneGeneric,
    onSubmitGeneric
} from '../../Common/ApplicationForm';
import { Form } from '@formio/react';
import {
    getPublicUserByIdAPIAsync,
    insertPublicUserAPIAsync,
    updatePublicUserByIdAPIAsync
} from "../../Redux/PublicUserListing/PublicUserSlice"
import loginHelper from "../../Common/loginHelper";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CreateDialog, PublicUserValidationDialog, UpdateDialog } from './PublicUserUpdateDialog';
import { IconArrowLeft } from '@tabler/icons';
import Spinner from '../../Common/Spinner';
import { sanitizeObject } from '../../Common/CommonUtils';
import $ from "jquery";


export default function AddUserView(props) {
    const location = useLocation();
    const _id = location?.state?.userid;
    const [dataIsLoaded, setDataIsLoaded] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false);
    const [updateDialog, setUpdateDialog] = useState(false);
    const [createDialog, setCreateDialog] = useState(false);
    const [validationDialog, setValidationDialog] = useState(false);
    const [createUpdateState, setCreateUpdateState] = useState("");
    const appFormBaseURL = `${config.appURL}/api/application`;
    const response_ref = useRef("");
    const dispatch = useDispatch();
    const history = useHistory();
    let createdDateTime;
    const schemeStatus = config.isIDP === "true"
        ? [
            { schemename: "IDP", status: true },
            { schemename: "WHT", status: true }
        ]
        : [
            { schemename: "WHT", status: true }
        ];

    const todayDate = new Date();
    const dateTime = todayDate.getMonth() + 1 + "/"
        + (todayDate.getDate()) + "/"
        + todayDate.getFullYear() + " "
        + todayDate.getHours() + ":"
        + todayDate.getMinutes() + ":"
        + todayDate.getSeconds();
    createdDateTime = dateTime;
    let loginUser = loginHelper.getLoginUser();
    let role = loginUser.CorppassRole;

    useEffect(() => {
        if (role.toUpperCase() !== "ADMIN") {
            alert("Unauthorised user detected.");
            history.push("/Dashboard");
        }

        if (_id === undefined) {
            history.push("/Dashboard");
        }

        async function populate() {
            if (_id) {
                await dispatch(getPublicUserByIdAPIAsync(_id))
                    .unwrap()
                    .then((result) => {
                        response_ref.current = result;
                        setDataIsLoaded(true);
                    });
                if (response_ref.current.MaskedNRIC === undefined) {
                    setDataIsLoaded(false);
                    setUpdateDialog(true);
                    setCreateUpdateState("Suspicious operation.");
                }
            } 
        }
        populate();
    }, [dispatch]);

    let currentForm = {};
    const formReady = async (form) => {
        currentForm = form
        currentForm.nosubmit = true;
        setFormLoaded(true);
        let applicantTel = currentForm.getComponent("applicantTelNo");

        if (_id) {
            currentForm.submission.data.name = response_ref.current.Name;
            currentForm.submission.data.userid = response_ref.current.Id;
            createdDateTime = response_ref.current.CreatedDateTime;
            currentForm.submission.data.nric = response_ref.current.MaskedNRIC;
            currentForm.submission.data.email = response_ref.current.Email;

            if (response_ref.current.MobilePhone) {
                currentForm.submission.data.applicantTelNo = response_ref.current.MobilePhone;
            }

            if (response_ref.current.Schemes) {
                let idp = { IDP: false };
                let wht = { WHT: false };
                if (config.isIDP === "true" && response_ref.current.Schemes.includes("IDP"))
                    idp = { IDP: true };
                if (response_ref.current.Schemes.includes("WHT"))
                    wht = { WHT: true };
                currentForm.submission.data.schemes = Object.assign(idp, wht);
            }
            currentForm.submission.data.designation = response_ref.current.Designation;
            currentForm.submission.data.accountActive = (response_ref.current.isActive === true) ? "active" : "inactive";
        }

        currentForm.on("cancel", () => {
            history.push('/PublicUser')
        });

        currentForm.on("saveDetails", () => {
            applicantTel.component.inputMask = "";
            let valid = currentForm.checkValidity(null, true, null, false);
            if (valid) {
                let loginUser = loginHelper.getLoginUser();
                let schemeStatuses = "";
                let runOnce = 0;
                for (const element of schemeStatus) {
                    if (currentForm.submission.data.schemes[element.schemename] === true) {
                        if (!runOnce) {
                            runOnce = 1;
                            schemeStatuses = element.schemename;
                        } else
                            schemeStatuses += ", " + element.schemename;
                    }
                }

                currentForm.submission.data = sanitizeObject(currentForm.submission.data);
                const body = {
                    "CreatedDateTime": createdDateTime,
                    "UEN": loginUser.UEN,
                    "NRIC": currentForm.submission.data.nric,
                    "Email": currentForm.submission.data.email,
                    "loginType": "corppass",
                    "corppassRole": "User",
                    "name": currentForm.submission.data.name,
                    "companyName": loginUser.CompanyName,
                    "CreatedBy": loginUser.Id,
                    "MobilePhone": currentForm.submission.data.applicantTelNo,
                    "isActive": (currentForm.submission.data.accountActive === "active"),
                    // "Schemes": schemeStatuses,
                    "Schemes": "WHT",
                    "Designation": currentForm.submission.data.designation
                };

                //  Call API to Update the user
                dispatch(updatePublicUserByIdAPIAsync(
                    {
                        body: JSON.stringify(body),
                        id: _id
                    }
                ))
                    .unwrap()
                    .then((result) => {
                        setUpdateDialog(true);
                        setCreateUpdateState("ok");
                    }).catch((error) => {
                        setUpdateDialog(true);
                        setCreateUpdateState("");
                    });
            } else {
                setValidationDialog(true);
            }
        });
        currentForm.redraw();

        const isAdmin = response_ref?.current?.CorppassRole?.toUpperCase() === "ADMIN";
        $('input[type="radio"][name*="accountActive"]').prop("disabled", isAdmin);

        if (config.isIDP === "false") {
            $('input[type="checkbox"]:first').parent().closest('div.checkbox.form-check').remove();
        }

    }

    return (
        <div>
            {role.toUpperCase() !== "ADMIN" ? <></> :
                <div className='applicationform'>
                    <Grid container>
                        <Container sx={{ pt: 3, pb: 5 }}>
                            <Button onClick={() => history.push('/Dashboard')}
                                className='nav-back-btn'>
                                <IconArrowLeft color='#666' />
                            </Button>
                            <Breadcrumbs sx={{ display: 'inline-block', verticalAlign: 'middle' }} aria-label="breadcrumb">
                                <Link underline='hover'
                                    onClick={() => history.push('/Dashboard')}
                                    mx={1}
                                    className='active-link univers-65-bold-scarpa-flow-12px'>
                                    Dashboard
                                </Link>
                                <Link underline='none'
                                    mx={1} className='disabled-link univers-65-bold-scarpa-flow-12px'>
                                    Users Management
                                </Link>
                            </Breadcrumbs>
                            <br />
                            <br />
                            <h2>Update User</h2>
                            <Typography className="line-height-32px univers-55roman-normal-mine-shaft-22px">You can manage your users and assign schemes here.</Typography>
                        </Container>
                    </Grid>
                    <Container sx={{ pb: 2.5 }}>
                        <Paper elevation={0}>
                            <Container sx={{ padding: 2.5 }} >
                                <h3>{(formLoaded) ? "User Information" : <Spinner />}</h3>
                                {(dataIsLoaded) ?
                                    <Form
                                        {...props}
                                        form={`${appFormBaseURL}/assignPublicUserManagement`}
                                        url={`${appFormBaseURL}/assignPublicUserManagement`}
                                        onChange={onChangeGeneric}
                                        onError={onErrorGeneric}
                                        formReady={formReady}
                                        onSubmit={onSubmitGeneric}
                                        onSubmitDone={onSubmitDoneGeneric}
                                        onRender={onRenderGeneric}
                                    />
                                    : null
                                }
                            </Container>
                        </Paper>
                    </Container>
                    <UpdateDialog
                        open={updateDialog}
                        setOpen={setUpdateDialog}
                        statusMessage={createUpdateState}
                    />
                    <CreateDialog
                        open={createDialog}
                        setOpen={setCreateDialog}
                        statusMessage={createUpdateState}
                    />
                    <PublicUserValidationDialog
                        open={validationDialog}
                        setOpen={setValidationDialog}
                    />
                </div>
            }
        </div>
    )
}
