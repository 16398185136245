import { Container } from '@mui/material';
import Utils from 'formiojs/utils';
import { useEffect, useRef, useState } from 'react';
import {
  createOrSave,
  FormHtmlErrors,
  FormHtmlForm,
  FormHtmlHead,
  FormHtmlTitle,
  onChangeGeneric,
  onErrorGeneric,
  onRenderGeneric,
  onSubmitDoneGeneric,
  onSubmitGeneric,
  PageCarousel
} from '../../../Common/ApplicationForm';
import { getIDPEDHInfo } from '../../../Common/EDH';
import { config } from '../../../config';
import '../../ApplicationForm/ApplicationForm.css';
import 'datatables.net'
import 'datatables.net-dt';
import 'datatables.net-plugins/sorting/absolute';
import pagination from '../../ApplicationForm/pagination';
import { useHistory } from "react-router-dom";
import $ from 'jquery';
import ValidationDialog from '../../../Common/ValidationDialog';
import { fetch } from '../../../Common/API';
import vesselFile from '../../../Downloads/MSI-Vessel_Template.xlsx'
import moment from 'moment';
import * as XLSX from 'xlsx';
import loginHelper from '../../../Common/loginHelper';
import countries from './Countries'

MSIMainForm.defaultProps = {
    formname: 'Maritime Sector Incentive - Approved International Shipping Enterprise (MSI-AIS) Main Form',
    formstatus: 'draft',
    formId: `${config.form_name_target_msi_main}`,
    appFormBaseURL: `${config.appFormBaseURL}`,
    schema: 'MSI'
}

let allowScrolling = true
export default function MSIMainForm({ formname, formid, formstatus, formId, appFormBaseURL, schema, props }) {
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const appFormJson = useRef();
    const [openValidationDialog, setOpenValidationDialog] = useState(false);
  let validityTriggered = false;

  const [disableScrollingFunc, setScrollState] = useState(() => { 
    // This function is intentionally empty. 
  });
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
  let _id = params.get('_id');
  let createdData = {_id: _id};
  const status = params.get('status');

    //  REFS
    const submitID_ref = useRef("");
    const form_ref = useRef({});

    if (!_id) { //new applcation
        _id = ''
    }
    else { // update existing application
        // if (createdData._id !== _id.slice(0)) {
        //     setCreatedData({ ...createdData, _id: _id.slice(0) });
        // }
        _id = 'submission/' + _id;
    };

  const appFormUrl2 = `${appFormBaseURL}/${formId}`;
  const appFormURL = `${appFormBaseURL}/${formId}/${_id}`;
  const createURL = `${appFormBaseURL}/${formId}/submission`;
  let currentForm = {};
  const [thisForm, setThisForm] = useState();
    const [formReadOnly, setFormReadOnly] = useState(false);
    const [fetchingform, setFetchingform] = useState(true);
    const [pagesErrors, setPagesErrors] = useState([]);
    const [currentPageErrors, setCurrentPageErrors] = useState(0);

  const alreadySubmitted = false;

  function handleClick() {
        $(".fa-plus-square-o").addClass("fa-angle-down");
        $(".fa-plus-square-o").removeClass("fa-plus-square-o");
        $(".fa-minus-square-o").addClass("fa-angle-up");
        $(".fa-minus-square-o").removeClass("fa-minus-square-o");
    }

    useEffect(() => {
        async function getform() {
            setBusy(true);
          const json = await fetch(appFormURL, 'GET', null);
          if (json) {
                setBusy(false);
                appFormJson.current = json.data;
            }
        };
        getform();
    }, [appFormURL])

    useEffect(() => {
        //  Update datagrid and styling configuration on click.
        let initialize = (e) => {
            handleClick();
        }

        document.addEventListener("click", initialize);
        return () => document.removeEventListener("click", initialize);
    }, []);

    function saveCreatedData(x) {
        createdData = x;
    }

    const populateEntities = () => {
        const entities = [];

        //  Populate parent
        let parent = {};
        parent.entity = currentForm.submission.data['parentContainer']['nameOfCompany'];
        parent.status = (typeof currentForm.submission.data['parentContainer']['msiAisStatus'] !== "object") ? currentForm.submission.data['parentContainer']['msiAisStatus'] : "-";
        let formattedDate = moment(currentForm.submission.data['fyStart']).format("DD MMM yyyy");
        parent.awardDate = (formattedDate === "Invalid date") ? "-" : formattedDate
        entities.push(parent);

        //  Check if applicant applied for sister company
        //  Populate subsidaries
        if (Array.isArray(currentForm.submission.data['sisterCompanyGrid']) && !currentForm.submission.data['chkNoLocal']) {
          const subsidaries = currentForm.submission.data['sisterCompanyGrid'];
          subsidaries.forEach((subsidary) => {
                let template = {};
                template.entity = subsidary['companyName'];
                template.status = (typeof subsidary['msiAisStatus'] !== "object") ? subsidary['msiAisStatus'] : "-";
                let formattedDate = moment(subsidary['sisterfyStart']).format("DD MMM yyyy");
                template.awardDate = (formattedDate === "Invalid date") ? "-" : formattedDate;
                entities.push(template);
            });
        }

        //  Check if applicant applied for ANC
        //  Populate ANCs
        if (Array.isArray(currentForm.submission.data['ancGrid']) && !currentForm.submission.data['chkNoANC']) {
          const ANCS = currentForm.submission.data['ancGrid'];
          ANCS.forEach((ANC) => {
                let template = {};
                template.entity = ANC['companyName'];
                template.status = ANC['msiAisStatus'];
                let formattedDate =  moment(ANC['companyContainer']['ancfyStart2']).format("DD MMM yyyy");
                template.awardDate = (formattedDate === "Invalid date") ? "-" : formattedDate;
                entities.push(template);
            });
        }

        //  Setting number of rows
      const entityGrid = Utils.getComponent(currentForm.components, "entityGrid");
      entityGrid.setValue(entities);
    }

    const formReady = async (form) => {
        setFetchingform(false);
        currentForm = form;
        currentForm.nosubmit = true;
        setThisForm(currentForm);
        if (appFormJson.current.data) currentForm.data = appFormJson.current.data;

        //  Retrieve the information of the login user to be added to the configuration fields
      const applicant = loginHelper.getLoginUser();
      currentForm.submission.data.configContainer['usersInvolved'] = applicant?.Name;
        currentForm.submission.data.configContainer['companyInvolved'] = applicant?.CompanyName;
        currentForm.submission.data['contactName'] = applicant?.Name;
        currentForm.submission.data['contactEmail'] = applicant?.Email;

        if (createdData._id) {
           submitID_ref.current = createdData._id;
        }

        //  Change Data Grid Add Button
        Utils.searchComponents(form.components, {type: "datagrid"}).forEach((instance,) => {
            if (instance?.component) {
                instance.component.addAnother = "Add On";
            }
        });

        try {
          const EDH = await getIDPEDHInfo();
          populateEDH(currentForm, EDH);
        } catch (e) {
        }

        currentForm.on('prevPage', async () => {
            await prevPage();
        });
        currentForm.on('nextPage', async () => {
            await nextPage();
        });
        //  Review page
        currentForm.on('review', async () => {
            validityTriggered = true;
          const valCheck = checkValidity();
          //  Validation fails
            if (valCheck === false) {
                //  Open the validation dialog
                setOpenValidationDialog(true)
                form_ref.current = currentForm;
                await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
                submitID_ref.current = createdData._id;
            } else {
                await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
                submitID_ref.current = createdData._id;
                form_ref.current = currentForm;
                goToReview();
            }
        });

        //  Called when a component is deleted and re-created again.
        currentForm.on('redraw', () => {
            // This function is intentionally empty. 
        });
        currentForm.on('render', (comp) => {
            populateEntities();
        });

        setTimeout(() => {
            pagination(currentForm);
        }, 90);

        setInterval(() => createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId), 120000);

        //  Add event listeners
        $('.page-link').on('click', async (event)=> {
            await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
        });

        setScrollState(() => {
            allowScrolling = false;
        });
        currentForm.redraw();
    };

    const onRender = async () => {
        if (allowScrolling)
            window.scrollTo(0, 0);
        else
            setScrollState(() => allowScrolling = true)
        await onRenderGeneric(setPage, currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId, status);

        setTimeout(() => {
            checkValidity();
        }, 0)
    }

    const onChange = async (event) => {
        if (event.changed) {
            //  File conversion for vessels
            if (event.changed.component.type === "file" && "scanVessel" in event.changed.component.properties) {
                const { instance, component } = event.changed;
                const grid = currentForm.getComponent(`dg_uploadGrid${component.properties.scanVessel}`);
                let JSON = [], errors = false;

                try {
                    if (event.changed.value.length !== 0) {
                        //  Read from XLSX.
                        let base64_data = event.changed.value[0].url.split(',')[1]
                        const parsedDocument = XLSX.read(base64_data, {type: 'base64'});
                        const worksheet = parsedDocument.Sheets['Vessel Information'];

                        //  Throw if excel file was invalid
                        if (worksheet === undefined) {
                            throw("Wrong file uploaded");
                        }
                        JSON = XLSX.utils.sheet_to_json(worksheet, {raw: false});
                    }
                } catch(e) {
                    errors = true;
                    instance.component.uploadInvalid = true;
                    instance.checkValidity(currentForm.submission.data[component.key], true, null, false);
                }

                //  Check if no errors and if there is a file being uploaded
                if (!errors && event.changed.value.length !== 0) {
                    const record = [];

                    for (const element of JSON) {
                      const testTypes = ['Type 1', 'Type 2', 'Type 3', 'Type 4'];

                      record.push({
                        vesselOwner: element['Vessel Owner'],
                        vesselName: element['Vessel Name'],
                        vesselType: (testTypes.includes(element['Vessel Type'])) ? element['Vessel Type'] : "",
                        country: (countries.includes(element['Country Of Incorporation'])) ? element['Country Of Incorporation'] : "",
                        ownedOrChartered: (element['Owned / Chartered'] === "C") ? "Chartered-In" : "Owned/Operated",
                        duration: element['Average Charter Duration']
                        })
                    };

                    if (grid.rows.length === 0 && record.length > 0) {
                        grid.addRow();
                    }

                    //  Update the datagrid
                    grid.setValue(record);
                }
            }

            //  Implementation of unchecking logic
            if (event.changed.component.key === "holdingCompanyIncorporationDate") {
                //  Get the year
                let date_instance = event.changed.value
                let year = moment(date_instance).year();

                //  Components
                let chk_Y1 = currentForm.getComponent("chkNA");
                let chk_Y2 = currentForm.getComponent("chkNA2");
                let chk_Y3 = currentForm.getComponent("chkNA3");

                //  Uncheck
                if (isNaN(year) || year <= moment().diff('0000-01-01','years') - 3 && !chk_Y1.component.disabled)
                    chk_Y1.setValue(false);
                if (isNaN(year) || year <= moment().diff('0000-01-01','years') - 2 && !chk_Y2.component.disabled)
                    chk_Y2.setValue(false);
                if (isNaN(year) || year <= moment().diff('0000-01-01','years') - 1 && !chk_Y3.component.disabled)
                    chk_Y3.setValue(false);
            }
        }
        onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
        checkValidity();
    }

    const onSubmit = async (submission) => {
        onSubmitGeneric(submission, currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
    }

    const onError = async (errors) => {
        onErrorGeneric(errors)
    }

    const onSubmitDone = (submission) => {
        onSubmitDoneGeneric(submission)
    };

    const populateEDH = async (currentForm, EDH) => {
      let [user, , companyCapital] = EDH;
      currentForm.submission.data['parentContainer']['uen'] = user.uen;
        currentForm.submission.data['parentContainer']['nameOfCompany'] = user.companyDetails.entityName;
        currentForm.submission.data['parentContainer']['incorporationDate'] = user.companyDetails.registrationDate;
        currentForm.submission.data['parentContainer']['telNo'] = user.companyDetails.telephone || "99999999";
        currentForm.submission.data['parentContainer']['countryOfIncorporation'] = user.countryOfIncorporation || "Singapore";

        currentForm.submission.data['parentAddressField']['ptCode'] = user.formattedAddress.postalCode;
        currentForm.submission.data['parentAddressField']['blkNo'] = user.formattedAddress.blockHouseNumber;
        currentForm.submission.data['parentAddressField']['buildingName'] = user.formattedAddress.buildingName;
        currentForm.submission.data['parentAddressField']['country'] = user.formattedAddress?.residentCountry || "Singapore";
        currentForm.submission.data['parentAddressField']['street'] = user.formattedAddress.streetName;
        currentForm.submission.data['parentAddressField']['lvl'] = user.formattedAddress.levelNumber;
        currentForm.submission.data['parentAddressField']['unitNo'] = user.formattedAddress.unitNumber.padStart(2, "0");
    }

    const onCustomEvent = async ({ type, component, data, event }) => {
        if (type === "downloadVesselTemplate") {
          const el = document.createElement("a");
          $(el).attr('href', vesselFile);
            $(el).attr('download', "MSI-Vessel_Template");

            $("body").append(el);
            el.click();
            $("body").remove(el);
        }
    }

    async function prevPage() {
        await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
        currentForm.setPage(currentForm.page - 1);
    }

    async function nextPage() {
        $('button:contains("Next")').hide();
      const responseStatus = await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
      if (responseStatus.status === 200 || responseStatus.status === 201) {
            $('button:contains("Next")').show();
            currentForm.setPage(currentForm.page + 1);
            submitID_ref.current = createdData._id;
        } else {
            $('button:contains("Next")').show();
        }
    }

    function changePage(x) {
        thisForm.setPage(x);
    }

    function goToReview() {
        //  You can go to the review page even if incomplete if you want to test something
      const contactName = form_ref.current._submission.data.contactName;
      history.push({
            pathname: '/MSIAISMainReview',
            search: `?_id=${submitID_ref.current}`,
            state: {
                user: (contactName === "") ? form_ref.current._submission.data.configContainer.usersInvolved : contactName,
                company: form_ref.current._submission.data.configContainer.companyInvolved
            },
            refresh: true
        });
    }

    function checkValidity() {
        const valCheck = currentForm.checkValidity(null, true, null, false);
      if (validityTriggered) {
        const oldPagesErrors = pagesErrors;
          currentForm.pages.forEach((x, index) => oldPagesErrors[index] = x.errors.length);
            setPagesErrors(oldPagesErrors);
            setCurrentPageErrors(pagesErrors[currentForm.page]);
        }
        return valCheck;
    }

    return (
        <div className='applicationform'>
            <ValidationDialog
                open={openValidationDialog}
                setOpen={setOpenValidationDialog}/>
            <Container>
                <FormHtmlHead formname={formname} schema={schema}/>
                {/*<div style={{display: 'block'}}>
                    <Typography>
                        Maritime Sector Incentive - Approved International Shipping Enterprise (MSI-AIS) Award
                    </Typography>
                    </div>*/}
                <div style={{ flexDirection: 'column' }}>
                    <FormHtmlTitle formname={formname} formid={formid} formstatus={formstatus}/>
                    <br />
                    <PageCarousel thisform={thisForm} setpage={changePage} pagesErrors={pagesErrors} />
                </div>
                <FormHtmlErrors thisForm={thisForm} pagesErrors={pagesErrors} currentPageErrors={currentPageErrors} />
                <FormHtmlForm fetchingform={fetchingform} formReadOnly={formReadOnly} props={props} appFormURL={appFormUrl2} onChange={onChange} onError={onError} formReady={formReady} onSubmit={onSubmit} onSubmitDone={onSubmitDone} onRender={onRender} onCustomEvent={onCustomEvent} isBusy={isBusy} appFormJson={appFormJson}/>
            </Container>
            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>test</div>
            </Modal> */}
        </div>
    )
}






