import { Container, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  createOrSave,
  FormHtmlErrors,
  FormHtmlForm,
  FormHtmlHead,
  FormHtmlTitle,
  onChangeGeneric,
  onErrorGeneric,
  onRenderGeneric,
  onSubmitDoneGeneric,
  onSubmitGeneric,
  PageCarousel,
} from "../../../Common/ApplicationForm";
import { config } from "../../../config";
import "../../ApplicationForm/ApplicationForm.css";
import pagination from "../../ApplicationForm/pagination";
import { useHistory, useLocation } from "react-router-dom";
import ValidationDialog from "../../../Common/ValidationDialog";
import moment from "moment";
import $ from "jquery";
import { fetch } from "../../../Common/API";
import loginHelper from "../../../Common/loginHelper";
import Utils from "formiojs/utils";
import { deleteFormAPIAsync } from "../../../Redux/CommonForm/CommonFormSlice.js.js";
import flatComponent from "../../../Common/FormIOComponent/flatComponent";
import scanData from "../../../Common/FormIOComponent/scanData";
import { getTrackChanges, NamePropertyTrackChanges, TrackChanges, } from "../../ApplicationListing/APICall/apicall";
import SnackbarAlert from "../../../Common/snackbarAlert";
import ProgressBar from "../../../Common/progressBar";
import AutoSaveErrorDialog from "../../../Common/autoSaveError";
import getTitle from "../../../Common/FormIOComponent/titleComponent";
import getStatusText from "../../../Common/GetStatusText";
import { ConvertUtcToLocale_ApplicantRegistrationDate, GenericTabTimeout } from "../../../Common/GenericIDP";
import { GenericFileUploadOnRender, GenericFileUploadOnChange } from '../../../Common/GenericForm';

IDPClaimForm.defaultProps = {
  formname: "Industry Digital Plan Claim Form",
  formstatus: "draft",
  formId: `${config.form_name_target_idp_claim}`,
  appFormBaseURL: `${config.appFormBaseURL}`,
  schema: "IDP",
};

let allowScrolling = true;
export default function IDPClaimForm({
  formname,
  formid,
  formstatus,
  formId,
  appFormBaseURL,
  schema,
  props,
}) {
  const [page, setPage] = useState(0);
  const [isBusy, setBusy] = useState(true);
  const appFormJson = useRef();
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState();
  const [open, setOpen] = useState(false);
  let validityTriggered = false;
  const [disableScrollingFunc, setScrollState] = useState(() => {
    // This function is intentionally empty. 
  });
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const getCaseID = location?.state?.caseID;
  const [caseID, setCaseID] = useState(getCaseID);
  const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false);

  const params = new URLSearchParams(window.location.search);
  let _id = params.get("_id");
  const theformId = _id;
  let mainSubmissionId = params.get("mainSubmissionId");
  let createdData = { _id: _id };
  let getStatus = params.get("status");
  const [status, setStatus] = useState();
  let prevStatus;
  let reviewCaseID;

  let formType = "create";
  if (_id) {
    formType = "update";
  }

  let createAppFormURL = `${appFormBaseURL}/${formId}`;
  let updateAppFormURL = `${appFormBaseURL}/${formId}/submission/${_id}`;
  let createURL = `${appFormBaseURL}/${formId}/submission`;
  let currentForm = {};
  const [thisForm, setThisForm] = useState({});
  const [formReadOnly, setFormReadOnly] = useState(false);

  const [fetchingform, setFetchingform] = useState(true);
  const [pagesErrors, setPagesErrors] = useState([]);
  const [currentPageErrors, setCurrentPageErrors] = useState(0);
  const [startSpinner, setStartSpinner] = useState(false);

  let alreadySubmitted = false;
  const submitID_ref = useRef("");
  const form_ref = useRef({});
  let canSubmit = true;
  const [bar, setBar] = useState(false);
  const ref = useRef();

  let reloadStrictMode = false;
  let tabBufferCounter = 5;
  let refTabCounterTimeOut = useRef(null);

  const checkErrorStatusAndOpenErrorStatusDialog = () => {
    if(sessionStorage.getItem("error") !== "409") {
      setOpenErrorStatusDialog(true);
    }
  };

  useEffect(() => {
    async function getform() {
      setBusy(true);

      let url = createAppFormURL;
      if (formType === "update") {
        url = updateAppFormURL;
      }
      let json = await fetch(url, "GET", null);
      if (json) {
        if(reloadStrictMode) {
          reloadStrictMode = false;
        } else {
          setBusy(false);

          appFormJson.current = json.data;

          if (getStatus === "All") {
            setStatus("draft");
            prevStatus = "draft";
          } else {
            const currentStatus =
              json.data.data.configContainer.publicApplicationStatus;
            const getText = getStatusText(currentStatus);
            setStatus(getText);
            prevStatus = getText;
          }
        }
      }
    }
    getform();

    return () => reloadStrictMode = true;
  }, []);

  useEffect(() => {

    return () => {
        if (ref.Interval !== null)
          clearInterval(ref.Interval);
        if(refTabCounterTimeOut.current != null)
          clearTimeout(refTabCounterTimeOut.current);
    };
}, []);
  function saveCreatedData(x) {
    createdData = x;
  }

  async function getChanges(component, currentData, preData) {
    for (const item in currentData) {
      for (const check in preData) {
        if (item === check) {
          let now = currentData[item];
          let before = preData[check];
          if (typeof now == Date || typeof before == Date) {
            before = moment(before).format("DD/MM/YYYY");
            now = moment(now).format("DD/MM/YYYY");
          }
          if (before !== now) {
            changes.push(item);
          }
        }
      }
    }
  }

  async function saveChanges(component, currentData, preData, checkingDB) {
    let pageChanges = [];
    for (const item in currentData) {
      for (const check in preData) {
        if (item === check) {
          const checkSplit = check.split("-");
          const findChecking = checkingDB?.find(
            (item) => item?.key.split("-")[1] === checkSplit[1]
          );
          if (findChecking) {
          } else if (
            checkSplit[1].includes("date") ||
            checkSplit[1].includes("Date") ||
            checkSplit[1].includes("period") ||
            checkSplit[1].includes("Period")
          ) {
          } else {
            let now = currentData[item];
            let before = preData[check];
            if (now instanceof Date || before instanceof Date) {
              before = moment(before).format("DD/MM/YYYY");
              now = moment(now).format("DD/MM/YYYY");
            }
            if (now instanceof Array || before instanceof Array) {
            } else if (before !== now) {
              changes.push(item);
              pageChanges.push(item);
            }
          }
        }
      }
    }
    return await TrackChanges(pageChanges, theformId);
  }

  let resultComponent;
  let prevData;
  const changes = [];
  let data = [];

  function searchElements() {
    const element = document.querySelectorAll("[name]");
    for (const el of element) {
      const attr = el.getAttribute("name");
      if (attr) {
        if (attr.startsWith("data")) {
          let elementValue = el.value;
          if (
            el.name.toString().includes("Date") ||
            el.name.toString().includes("date") ||
            el.name.toString().includes("period") ||
            el.name.toString().includes("Period")
          ) {
            elementValue = moment(el.value).format("DD/MM/YYYY");
          }
          const theValue = {
            name: el.name,
            nodeName: el.nodeName,
            value: elementValue,
            element: el,
          };
          const check = data.find((item) => item.name === el.name);
          if (check) {
          } else {
            data.push(theValue);
          }
        }
      }
    }
    return "success";
  }

  async function searchElementsSave(checkingDB) {
    let newData = [];
    const element = document.querySelectorAll("[name]");
    for (const el of element) {
      const attr = el.getAttribute("name");
      if (attr) {
        if (attr.startsWith("data")) {
          const theValue = {
            name: el.name,
            nodeName: el.nodeName,
            value: el.value,
            element: attr,
          };

          const check = data.find((item) => item.name === el.name);
          const findChecking = checkingDB?.find((i) => i.key === el.name);
          let elementValue = el.value;
          if (
            el.name.toString().includes("Date") ||
            el.name.toString().includes("date") ||
            el.name.toString().includes("period") ||
            el.name.toString().includes("Period")
          ) {
            elementValue = moment(el.value).format("DD/MM/YYYY");
          }

          if (
            el.name.toString().includes("selectBoxesQFA_") ||
            el.name.toString().includes("fyAuditedYear")
          ) {
          } else if (findChecking) {
          } else if (check && check.nodeName === "BUTTON") {
          } else {
            if (check && check.value !== elementValue) {
              newData.push(theValue);
            } else if (check === undefined || !check) {
              newData.push(theValue);
            }
          }
        }
      }
    }
    return await NamePropertyTrackChanges(newData, theformId);
  }

  let allTitle;

  const formReady = async (form) => {
    $(async function () {      
      setFetchingform(false);
      currentForm = form;
      currentForm.nosubmit = true;
      setThisForm(currentForm);
      if (currentForm.data.configContainer.publicApplicationStatus ===
        1 ||
        currentForm.data.configContainer.publicApplicationStatus ===
        9 ||
        currentForm.data.configContainer.applicationStatus === "1" ||
        currentForm.data.configContainer.applicationStatus === "9") {
        ref.Interval = setInterval(async function () {
            if (currentForm)
                await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
        }, 60000);
      }
      allTitle = getTitle(currentForm, theformId, "IDPClaimForm");

      if (appFormJson.current.data) currentForm.data = appFormJson.current.data;

      if (getStatus === "All") {
        setStatus("draft");
        prevStatus = "draft";
      } else {
        const statusTextCurrent = getStatusText(
          currentForm.submission.data.configContainer.publicApplicationStatus
        );
        setStatus(statusTextCurrent);
        prevStatus = statusTextCurrent;
      }

      // below 1 is draft status, 9 is returned status
      // only draft and returned back-end statuses can edit contents of this form
      if (
        currentForm.submission.data.configContainer.publicApplicationStatus !==
          1 &&
        currentForm.submission.data.configContainer.publicApplicationStatus !==
          9 &&
        currentForm.submission.data.configContainer.applicationStatus !== "1" &&
        currentForm.submission.data.configContainer.applicationStatus !== "9"
      ) {
        canSubmit = false;
        submitID_ref.current = createdData._id;
        form_ref.current = currentForm;
        goToReview();
      }

      if (currentForm.submission.data["contactName"] === "") {
        let applicant = loginHelper.getLoginUser();
        currentForm.submission.data.configContainer["usersInvolved"] =
          applicant?.Name;
        currentForm.submission.data.configContainer["companyInvolved"] =
          applicant?.CompanyName;
        currentForm.submission.data["contactName"] =
          applicant?.Name;
        currentForm.submission.data["contactEmail"] =
          applicant?.Email;
      }

      //  Main form id
      if (formType === "create" && mainSubmissionId) {
        currentForm.submission.data["mainFormId"] = mainSubmissionId;
      } else {
        //update
        currentForm.setPage(1);
        refTabCounterTimeOut.current = GenericTabTimeout(
          "applicantName",
          refTabCounterTimeOut,
          setStartSpinner,
          tabBufferCounter
        );
        currentForm.redraw();
        mainSubmissionId = currentForm.submission.data["mainFormId"];
      }

      if (prevStatus === "Returned" && theformId !== null) {
        resultComponent = flatComponent(currentForm);
        mainSubmissionId = params.get("mainSubmissionId");
      }

      //  Change Data Grid Add Button
      Utils.searchComponents(form.components, { type: "datagrid" }).forEach(
        (instance) => {
          if (instance?.component) {
            instance.component.addAnother = "Add On";
          }
        }
      );
      if (createdData._id) {
        submitID_ref.current = createdData._id;
      }

      try {
        if (mainSubmissionId) {
          let url = `${config.appURL}/api/application/GetLatestApproveMainORCRSubmissionBySubmissionId/${mainSubmissionId}`;
          if (formType !== "update") {
            //"calling latest approved" - mainSubmissionId
            // var info = await fetch(`${config.appURL}/api/application/idpmainform/submission/${mainSubmissionId}`, 'GET', null)

            // below api to get latest approved change request submission data for new claim form
            let info = await fetch(url, "GET", null).then((json) => {
              return json.data.data;
            });

            //  Populate data
            currentForm.submission.data["applicantName"] = info?.applicantName;
            currentForm.submission.data["applicantRegistrationNo"] =
              info?.applicantRegistrationNo;
            currentForm.submission.data["applicantRegistrationDateTime"] =
              info?.applicantRegistrationDateTime;
            currentForm.submission.data["applicantIndustryName"] =
              info?.applicantIndustryName;
            currentForm.submission.data.configContainer["companyInvolved"] =
              info?.applicantName;
            currentForm.submission.data["contactEmail"] = info?.contactEmail;

            //  Company address field
            currentForm.submission.data["companyAddressInfo"][
              "companyCountry"
            ] = info?.companyAddressInfo.companyCountry;
            currentForm.submission.data["companyAddressInfo"]["companyPtCode"] =
              info?.companyAddressInfo.companyPtCode;
            currentForm.submission.data["companyAddressInfo"]["companyBlkNo"] =
              info?.companyAddressInfo.companyBlkNo;
            currentForm.submission.data["companyAddressInfo"]["companyStreet"] =
              info?.companyAddressInfo.companyStreet;
            currentForm.submission.data["companyAddressInfo"]["companyLvl"] =
              info?.companyAddressInfo.companyLvl;
            currentForm.submission.data["companyAddressInfo"]["companyUnitNo"] =
              info?.companyAddressInfo.companyUnitNo.padStart(2, "0");
            currentForm.submission.data["companyAddressInfo"][
              "companyBuildingName"
            ] = info?.companyAddressInfo.companyBuildingName;

            //  Applicant field
            currentForm.submission.data["applicantContactName"] =
              info?.applicantContactName;
            currentForm.submission.data["salutation"] = info?.salutation;
            currentForm.submission.data["applicantDesignation"] =
              info?.applicantDesignation;
            currentForm.submission.data["applicantTelNo"] =
              info?.applicantTelNo;
            currentForm.submission.data["applicantEmail"] =
              info?.applicantEmail;

            //  Postal field
            currentForm.submission.data["postalAddressInfo"]["postCountry"] =
              info?.postalAddressInfo.postCountry;
            currentForm.submission.data["postalAddressInfo"]["postPtCode"] =
              info?.postalAddressInfo.postPtCode;
            currentForm.submission.data["postalAddressInfo"]["postBlkNo"] =
              info?.postalAddressInfo.postBlkNo;
            currentForm.submission.data["postalAddressInfo"]["postStreet"] =
              info?.postalAddressInfo.postStreet;
            currentForm.submission.data["postalAddressInfo"]["postLvl"] =
              info?.postalAddressInfo.postLvl;
            currentForm.submission.data["postalAddressInfo"]["postUnitNo"] =
              info?.postalAddressInfo.postUnitNo.padStart(2, "0");
            currentForm.submission.data["postalAddressInfo"][
              "postBuildingName"
            ] = info?.postalAddressInfo.postBuildingName;

            //  Company Solution Details
            currentForm.submission.data["letterOfOfferReferenceNo"] =
              info?.configContainer.applicationCaseId;

            //-- [Date/Time Component] Handle convert UTC date to LOCAL date
            let showDebugDate = false;
            let testDate = "2022-02-02T00:00:00+08:00";
            {
              {
                let dateUTC = info?.estimatedCommencementDate;
                if (dateUTC !== undefined && dateUTC !== "") {
                  let dateLocal = new Date(dateUTC);
                  currentForm.submission.data["commencementDate"] =
                    showDebugDate ? testDate : dateLocal;
                }
              }
              {
                let dateUTC = info?.estimatedCompletionDate;
                if (dateUTC !== undefined && dateUTC !== "") {
                  let dateLocal = new Date(dateUTC);
                  currentForm.submission.data["completionDate"] = showDebugDate
                    ? testDate
                    : dateLocal;
                }
              }
              {
                if (info?.implementationDate !== undefined) {
                  let dateUTC = info?.implementationDate;
                  if (dateUTC !== undefined && dateUTC !== "") {
                    let dateLocal = new Date(dateUTC);
                    currentForm.submission.data["implementationDate"] =
                      showDebugDate ? testDate : dateLocal;
                  }
                }
              }
            }

            currentForm.submission.data["companyNameAsRegisteredWithAcra"] =
              info?.applicantName;

            //  Project Details
            currentForm.submission.data["solutionName"] =
              info?.solutionName.solutionName;
            currentForm.submission.data["vendorName"] = info?.vendorName;
            currentForm.submission.data["grantApprovedIncludeAuditFees"] =
              info?.containerHidden?.officerGrantAmount;
            currentForm.submission.data["chkSameAddress"] =
              info?.chkSameAddress;

            //  Applicant Details / Productivity
            currentForm.submission.data[
              "describeTheBusinessProcessWorkTaskInvolvedAndHowIsItImproved"
            ] =
              info?.describeTheBusinessProcessWorkTaskInvolvedAndHowIsItImproved;
            currentForm.submission.data["avgHrNo"] = info?.avgHrNo;
            currentForm.submission.data["avgStaffNo"] = info?.avgStaffNo;

            // Contact Details
            currentForm.submission.data["companyManagingDirector"] =
              info?.companyManagingDirector;
            currentForm.submission.data["chiefFinancialOfficer"] =
              info?.chiefFinancialOfficer;
          }
        }
      } catch (e) {}

      ConvertUtcToLocale_ApplicantRegistrationDate(currentForm);

      //  Declaration Content
      let declaration = currentForm.getComponent("declarationContent");
      let updatedContent = declaration.component.html;
      declaration.component.html = updatedContent.replace(
        "[IT Solutions Name]",
        currentForm.submission.data["solutionName"]
      );

      setTimeout(() => {
        getPreData();
      }, 10);

      currentForm.on("change", (changed) => {
        GenericFileUploadOnChange(changed, false, currentForm, setStartSpinner);
      });

      currentForm.on("prevPage", async () => {
        setOpenAlert();
        setStartSpinner(true);
        const sendData = await getPrevData();

        if (sendData !== "noSave") {
          await prevPage(resultComponent);
        } else {
          setStartSpinner(false);
          // pop up and show error message
          checkErrorStatusAndOpenErrorStatusDialog(); 
        }
      });

      currentForm.on("nextPage", async () => {
        setOpenAlert();
        setStartSpinner(true);
        const sendData = await getPrevData();

        if (sendData !== "noSave") {
          await nextPage(resultComponent);
        } else {
          setStartSpinner(false);
          // pop up and show error message
          checkErrorStatusAndOpenErrorStatusDialog(); 
        }
      });

      currentForm.on("draftSave", async () => {
        setStartSpinner(true);

        const savingDraft = await createOrSave(
          "draft",
          currentForm,
          alreadySubmitted,
          createdData,
          saveCreatedData,
          createURL,
          appFormBaseURL,
          formId
        );

        if (savingDraft.status === 200 || savingDraft.status === 201) {
          setStartSpinner(false);
          //"done saving as draft"
          setOpenAlert("Draft");
          setOpen(true);
        } else {
          setStartSpinner(false);
          checkErrorStatusAndOpenErrorStatusDialog(); 
        }
      });

      currentForm.on("review", async () => {
        $('button:contains("Next")').hide();
        if (allowScrolling) window.scrollTo(0, 0);
        else setScrollState(() => (allowScrolling = true));
        setStartSpinner(true);
        const savingChangesData = await getPrevData();
        const responseStatus = await createOrSave(
          "draft",
          currentForm,
          alreadySubmitted,
          createdData,
          saveCreatedData,
          createURL,
          appFormBaseURL,
          formId
        );
        if (
          (responseStatus.status === 200 || responseStatus.status === 201) &&
          savingChangesData !== "noSave"
        ) {
          validityTriggered = true;
          let valCheck = checkValidity();

          if (valCheck === false) {
            $('button:contains("Next")').show();
            setOpenValidationDialog(true);
            form_ref.current = currentForm;
            submitID_ref.current = createdData._id;

            getPreData();
            setStartSpinner(false);
          } else {
            submitID_ref.current = createdData._id;
            form_ref.current = currentForm;
            goToReview();
          }
        } else {
          $('button:contains("Next")').show();
          setStartSpinner(false);
          // pop up and show error message
          checkErrorStatusAndOpenErrorStatusDialog(); 
        }
      });

      setTimeout(() => {
        pagination(currentForm);
      }, 90);

      if (currentForm.submission.data.configContainer) {
        setCaseID(currentForm.submission.data.configContainer.applicationCaseId);
      }

      if (currentForm.submission.data.configContainer.applicationCaseId) {
        reviewCaseID = currentForm.submission.data.configContainer.applicationCaseId;
      }

      $(function () {
        $(".page-link").on("click", async (event) => {
          const findTitleIndex = allTitle.indexOf(
            event.currentTarget.outerText
          );

          if (parseInt(currentForm.page) === 0) {
          } else {
            setOpenAlert();
            setStartSpinner(true);
            const sendData = await getPrevData();

            if (sendData !== "noSave") {
              const response = await createOrSave(
                "draft",
                currentForm,
                alreadySubmitted,
                createdData,
                saveCreatedData,
                createURL,
                appFormBaseURL,
                formId
              );

              if (response.status === 200 || response.status === 201) {
                currentForm.setPage(findTitleIndex);
                setStartSpinner(false);
              } else {
                setStartSpinner(false);
                // pop up and show error message
                checkErrorStatusAndOpenErrorStatusDialog(); 
              }
            } else {
              setStartSpinner(false);
              // pop up and show error message
              checkErrorStatusAndOpenErrorStatusDialog(); 
            }

            if (prevStatus === "Returned") {
              getPreData();
            }
          }
        });
      });

      currentForm.on("fileUploadingStart", async () => {
        setStartSpinner(true);
      });
      currentForm.on("fileUploadingEnd", async () => {
        setStartSpinner(false);
      });

      handleUTCDateToLocalDate();
    });
  };

  const handleCustomRenderEvent = () => {
    //-- JQuery to overwrite file component functionality to allow file download and delete with JWT Token
    GenericFileUploadOnRender(false, currentForm, setStartSpinner);
  };

  const handleUTCDateToLocalDate = () => {
    //-- [Date/Time Component] Handle convert UTC date to LOCAL date
    let showDebugDate = false;
    let testDate = "2022-02-02T00:00:00+08:00";

    {
      if (currentForm.submission.data?.implementationDate !== undefined) {
        let dateUTC = currentForm.submission.data?.implementationDate ?? "";
        if (dateUTC !== undefined && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          currentForm.submission.data["implementationDate"] = showDebugDate
            ? testDate
            : dateLocal;
        }
      }
    }
  };

  let solutionNamePage = 1;

  const onRender = async () => {
    if (allowScrolling) window.scrollTo(0, 0);
    else setScrollState(() => (allowScrolling = true));

    const rendering = await onRenderGeneric(
      setPage,
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId,
      prevStatus
    );
    setTimeout(() => {
      checkValidity();
    }, 0);
    $(function() {      
      handleCustomRenderEvent();
    })
    if (rendering === "ok") {
      if (prevStatus === "Returned" || prevStatus === "returned") {
        setStartSpinner(true);
        $('button:contains("Next")').hide();
        $('button:contains("Back")').hide();
        $('button:contains("Save as Draft")').hide();
        $('button:contains("Review")').hide();
        solutionNamePage = solutionNamePage + 1;

        //"solutionNamePage in render", solutionNamePage
        //"currentForm.page in render", currentForm.page

        if (solutionNamePage === 2 || solutionNamePage > 2) {
          setTimeout(() => {
            //"running page 2 change render"
            removeSpinner();
          }, 2000);
        }
      }
    }
  };

  const onChange = async (event) => {
    if (event.changed != undefined) {
      switch (event.changed.component.key) {
        case "chkSameAddress": {
          if (currentForm.submission.data.chkSameAddress == false) {
            //  Clear all the post data
            currentForm.submission.data.postalAddressInfo.postBlkNo = "";
            currentForm.submission.data.postalAddressInfo.postBuildingName = "";
            currentForm.submission.data.postalAddressInfo.postCountry = "";
            currentForm.submission.data.postalAddressInfo.postLvl = "";
            currentForm.submission.data.postalAddressInfo.postPtCode = "";
            currentForm.submission.data.postalAddressInfo.postStreet = "";
            currentForm.submission.data.postalAddressInfo.postUnitNo = "";
          } else {
            const postCountryComponent = currentForm.getComponent("postCountry");
            postCountryComponent.setValue(
              currentForm.submission.data.companyAddressInfo.companyCountry
            );
          }
          setScrollState(() => {
            allowScrolling = false;
          });
          currentForm.redraw();
          break;
        }
        default: {
          break;
        }
      }
    }
    onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
    checkValidity();
    setStartSpinner(false);
  };

  const onSubmit = async (submission) => {
    onSubmitGeneric(
      submission,
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );
  };

  const onError = async (errors) => {
    onErrorGeneric(errors);
  };

  const onSubmitDone = (submission) => {
    onSubmitDoneGeneric(submission);
  };

  const onCustomEvent = async ({ type, component, data, event }) => {
    // This function is intentionally empty. 
  };

  function removeSpinner() {
    setTimeout(() => {
      //"running this show button!"
      $('button:contains("Next")').show();
      $('button:contains("Back")').show();
      $('button:contains("Review")').show();
      // $('button:contains("Save as Draft")').show()
      setStartSpinner(false);
    }, 2000);
  }

  async function prevPage(resultComponent) {
    let responseStatus = await createOrSave(
      "draft",
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );
    if (responseStatus.status === 200 || responseStatus.status === 201) {
      currentForm.setPage(currentForm.page - 1);
      setStartSpinner(false);
    } else {
      setStartSpinner(false);
      //pop up and show error message
      checkErrorStatusAndOpenErrorStatusDialog(); 
    }

    getPreData();
    // searchElements()
  }

  async function nextPage(resultComponent) {
    $('button:contains("Next")').hide();
    let responseStatus = await createOrSave(
      "draft",
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );

    if (responseStatus.status === 200 || responseStatus.status === 201) {
      $('button:contains("Next")').show();
      currentForm.setPage(currentForm.page + 1);
      submitID_ref.current = createdData._id;     
      if (responseStatus.message.data.configContainer.applicationCaseId) {
        setCaseID(responseStatus.message.data.configContainer.applicationCaseId);
        reviewCaseID =
          responseStatus.message.data.configContainer.applicationCaseId;
      }
      setStartSpinner(false);
    } else {
      $('button:contains("Next")').show();
      setStartSpinner(false);
      //pop up and show error message
      checkErrorStatusAndOpenErrorStatusDialog(); 
    }

    getPreData();
    // searchElements()
  }

  function getPreData() {
    if (prevStatus === "Returned" && theformId !== null) {
      const preData = scanData(resultComponent);
      const getSearch = searchElements();

      if (preData && getSearch) {
        prevData = preData;
        setTimeout(() => {
          ///"running this show button!"

          $('button:contains("Next")').show();
          $('button:contains("Back")').show();
          // $('button:contains("Save as Draft")').show()
          $('button:contains("Review")').show();
          setStartSpinner(false);
        }, 2000);
      }
      // prevData = preData
    }
  }

  async function getPrevData() {
    const latestData = scanData(resultComponent);

    if (prevStatus === "Returned" && theformId !== null && latestData) {
      // await getChanges(resultComponent, latestData, prevData)
      const checkingDB = await getTrackChanges(theformId);
      const searchSave = await searchElementsSave(checkingDB.data.data);
      const TrackChangesSave = await saveChanges(
        resultComponent,
        latestData,
        prevData,
        checkingDB.data.data
      );

      if ([200, 201].includes(searchSave.status) && [200, 201].includes(TrackChangesSave.status)) {
        return "ok Success";
      } else {
        return "noSave";
      }
    } else {
      return "no track changes";
    }
  }

  function changePage(x) {
    thisForm.setPage(x);
  }

  function goToReview() {
    let contactName = form_ref.current._submission.data.contactName;
    history.push({
      pathname: "/IDPClaimReview",
      search: `?_id=${submitID_ref.current}&status=${prevStatus}`,
      // search: `?_id=${submitID_ref.current}`,
      state: {
        user:
          contactName === ""
            ? form_ref.current._submission.data.configContainer.usersInvolved
            : contactName,
        company:
          form_ref.current._submission.data.configContainer.companyInvolved,
        solutionName: form_ref.current._submission.data.solutionName,
        caseID: caseID === null || caseID === undefined ? reviewCaseID : caseID,
        prevStatus: prevStatus,
        canSubmit: canSubmit,
      },
      refresh: true,
    });
  }

  function scrollToError() {
    window.scrollTo(0, 0);
  }

  function goToTop() {
    window.scrollTo(0, 0);
    setTimeout(scrollToError, 2000);
  }

  function checkValidity() {
    let valCheck = false;
    if (validityTriggered) {
      valCheck = currentForm.checkValidity(null, true, null, false);
      let oldPagesErrors = pagesErrors;
      currentForm.pages.forEach(
        (x, index) => (oldPagesErrors[index] = x.errors.length)
      );
      setPagesErrors(oldPagesErrors);
      setCurrentPageErrors(pagesErrors[currentForm.page]);
    }
    return valCheck;
  }

  const handleDelete = async () => {
    try {
      if (submitID_ref.current) {
        const originalPromiseResult = await dispatch(
          deleteFormAPIAsync({
            formId: formId,
            submissionId: submitID_ref.current,
          })
        ).unwrap();
        history.push({
          pathname: "/NestedApplicationListing",
          search: `?schema=1`,
          refresh: true,
        });
      } else {
        alert("Delete is not allowed");
      }
    } catch (rejectedValueOrSerializedError) {
      alert("Delete is not allowed");
    }
  };

  return (
    <div className="applicationform">
      <ValidationDialog
        open={openValidationDialog}
        setOpen={setOpenValidationDialog}
        goToTop={goToTop}
      />
      <Container>
        <FormHtmlHead formname={formname} schema={schema} />
        <div style={{ display: page === 0 ? "block" : "none" }}>
          <Typography variant="h2">
            Claim for Industry Digital Plan (IDP)
          </Typography>
          <PageCarousel
            thisform={thisForm}
            setpage={changePage}
            pagesErrors={pagesErrors}
            status={status}
          />
        </div>
        <div
          style={{
            display: page === 0 ? "none" : "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormHtmlTitle
              formname={formname}
              formid={formid}
              formstatus={status}
              caseID={caseID}
            />
            {/* <Button className="large button-large univers-65-bold-white-16px role-margin"

                            onClick={() => { handleDelete() }}
                        >
                            Delete
                        </Button> */}
          </div>
          <br />
          <PageCarousel
            thisform={thisForm}
            setpage={changePage}
            pagesErrors={pagesErrors}
            status={status}
          />
        </div>
        <FormHtmlErrors
          thisForm={thisForm}
          pagesErrors={pagesErrors}
          currentPageErrors={currentPageErrors}
        />
        <FormHtmlForm
          fetchingform={fetchingform}
          formReadOnly={formReadOnly}
          props={props}
          appFormURL={createAppFormURL}
          onChange={onChange}
          onError={onError}
          formReady={formReady}
          onSubmit={onSubmit}
          onSubmitDone={onSubmitDone}
          onRender={onRender}
          onCustomEvent={onCustomEvent}
          isBusy={isBusy}
          appFormJson={appFormJson}
        />
      </Container>
      <AutoSaveErrorDialog
        open={openErrorStatusDialog}
        setOpen={setOpenErrorStatusDialog}
      />
      <SnackbarAlert
        alert={openAlert}
        caseID={caseID}
        open={open}
        setOpen={setOpen}
      />
      <ProgressBar
        ref={ref}
        startSpinner={startSpinner}
        setStartSpinner={setStartSpinner}
        bar={bar}
        setBar={setBar}
      />
    </div>
  );
}
