import { Typography, useMediaQuery } from "@mui/material";

function IDPPage() {
  return (
    <>
      <Typography variant="h3">Sea Transport IDP</Typography>
      <br />
      <Typography variant="h4" sx={{ textDecoration: "underline" }}>
        Sea Transport IDP
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        <strong>1. What is the Sea Transport IDP?</strong>
        <br />
        <br />
        <div className="IDPText">
          The Sea Transport Industry Digital Plan (IDP) is a joint initiative
          with Infocomm Media Development Authority (IMDA), Enterprise Singapore
          (ESG) and SkillsFuture Singapore (SSG), as part of the&nbsp;
          <a
            href="https://www.imda.gov.sg/SMEsGoDigital"
            target="_blank"
            rel="noreferrer noopener"
          >
            SMEs Go Digital Programme
          </a>
          &nbsp; that makes going digital simple for SMEs.
        </div>
        <br />
        <br />
        <br />
        <strong>2. Where can I access the Sea Transport IDP?</strong>
        <br />
        <br />
        <div className="IDPText">
          SMEs can refer to the Digital Roadmap of the IDP at&nbsp;
          {useMediaQuery("(min-width: 600px)") ? (
            <a
              href="https://www.imda.gov.sg/sea-transport-idp"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.imda.gov.sg/sea-transport-idp
            </a>
          ) : (
            <a
              href="https://www.imda.gov.sg/sea-transport-idp"
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
          )}
          , as a guide to assess their digital readiness as well as identify
          opportunities for going digital and training programmes to raise
          employees’ digital skills. The IDP will be updated over time as
          digitalisation of the industry progresses and new technologies are
          introduced to the industry.
        </div>
        <br />
        <br />
        <br />
        <strong>
          3. What form of support is available under SMEs Go Digital Programme
          for the Sea Transport sector?
        </strong>
        <br />
        <br />
        <div className="IDPTextNormal">
          - Maritime-Specific Digital Solutions under MPA’s Sea Transport IDP
          Grant via&nbsp;
          <a
            href="https://www.mpa.gov.sg/maritime-singapore/what-maritime-singapore-offers/programmes-to-support-your-maritime-business/industry-digital-plan"
            target="_blank"
            rel="noreferrer noopener"
          >
            MPA website
          </a>
          &nbsp;
          <br />- Go Digital Today via&nbsp;
          <a
            href="https://www.imda.gov.sg/how-we-can-help/smes-go-digital/ctoaas"
            target="_blank"
            rel="noreferrer noopener"
          >
            CTO-as-a-Service
          </a>
          <br />- Start Digital Pack for new SMEs via&nbsp;
          <a
            href="https://www.imda.gov.sg/StartDigital"
            target="_blank"
            rel="noreferrer noopener"
          >
            IMDA website
          </a>
          &nbsp;
          <br />- Basic Advisory via&nbsp;
          <a
            href="https://www.enterprisesg.gov.sg/about-us/contact-us/sme-centres"
            target="_blank"
            rel="noreferrer noopener"
          >
            SME Centre&nbsp;
          </a>
          <br />- IT Solutions and Equipment via&nbsp;
          <a
            href="https://www.enterprisesg.gov.sg/financial-support/productivity-solutions-grant"
            target="_blank"
            rel="noreferrer noopener"
          >
            Productivity Solutions Grant&nbsp;
          </a>
          <br />
        </div>
        <br />
        <br />
        <br />
        <strong>
          4. What is the extent of digitalisation required in the Sea Transport
          industry?
        </strong>
        <br />
        <br />
        <div className="IDPText">
          For a start, internal core business processes need to be streamlined
          and integrated through the use of digital technologies. This will
          allow companies to enhance operational efficiencies and provide
          greater value to its customers.
        </div>
        <br />
        <br />
        <br />
        For more information, you may email&nbsp;
        <a
          className="clickContactText"
          href="mailto:MPA_IDP@mpa.gov.sg?body=MPA Information"
          target="_blank"
          rel="noreferrer noopener"
        >
          MPA_IDP@mpa.gov.sg.
        </a>
      </Typography>
    </>
  );
}

export default IDPPage;
