import { Button, Container, useMediaQuery } from '@mui/material';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons';
import { useState } from 'react';
import BusinessType from './BusinessType';
import IndividualNext from './nextPage/invidualNext'
import OrganizationTax from './nextPage/organizationTax'
import OrganizationGrant from './nextPage/organizationGrant'
import OrganizationBoth from './nextPage/organizationBoth'
import { GuidedJourneyContext } from '../../Hooks/GuidedJourneyHook'
import './GuidedJourney.css'
import { useHistory } from "react-router-dom";


export default function GuidedJourney() {
    const [page, setPage] = useState(0);
    const [journey, setJourney] = useState(null);
    const history = useHistory();

    const handleBack = () => {
        setPage(page - 1)
        setJourney(null)
    }


    return (
        <div style={{ backgroundColor: 'var(--black-haze2)', padding: 24 }}>
            <GuidedJourneyContext.Provider value={[journey, setJourney]}>
            <Container maxWidth='md'>
                {/* <Grid container spacing={2} mb={5}>
                    <Grid item>
                        <Progress status={page === 0 ? 'active' : 'completed'} number={1} text='Business Type' />
                    </Grid>
                    <Grid item>
                        <Progress status={page === 0 ? 'inactive' : page === 1 ? 'active' : 'completed'} number={2} text='Project Objective' />
                    </Grid>
                    <Grid item>
                        <Progress status={page === 2 ? 'active' : 'inactive'} number={3} text='Recommendation' />
                    </Grid>
                </Grid> */}

                    {/* <IncentiveContext.Provider value={incentive}> */}
                    {function () {
                        switch (page) {
                            // case 1: return <> {journey === "individual" ? <IndividualNext /> : <ProjectObjective /> } </>;
                            // case 2: return <Recommendation />;
                            case 1: return <> {journey === "individual" ? <IndividualNext /> :
                            <>
                            {/* <>{ journey === "incentive" ? <OrganizationTax /> : <Recommendation /> }  */}
                            {function () {
                                switch (journey) {
                                    case "both": return <OrganizationBoth />;
                                    case "grant": return <OrganizationGrant />;
                                    case "incentive": return <OrganizationTax />;
                                    default: break;
                                }
                            }()}
                            </> } </>;
                            default: return <BusinessType />;
                        }
                    }()}
                    {/* </IncentiveContext.Provider> */}

                <br />
                <br />
                {useMediaQuery('(max-width: 315px)') ?
                <div style={{marginBottom: 24, textAlign:"center" }}>
                    <Button variant='outlined'
                        onClick={() => page > 0 ? handleBack() : history.push('/Home')}
                        startIcon={<IconArrowNarrowLeft size='20' />}
                        className='univers-65-bold-science-blue-16px'
                        sx={{
                            width: '115px',
                            border: '1px solid var(--science-blue) !important',
                            borderRadius: 6,
                            textTransform: 'unset'
                        }}>
                        Back
                    </Button>
                    <br />
                    <br />
                    <Button
                        disabled={journey === null || journey === "organization"}
                        onClick={() => page < 1 ? setPage(page + 1) : history.push('/ContactUs')}
                        endIcon={<IconArrowNarrowRight size='20' />}
                        className='large button-large univers-65-bold-white-16px disablebutton'

                        sx={{
                            height: '48px',
                            width: page===1 ? '161px' : '115px',
                            textTransform: 'unset',
                        }}>
                        {page === 1 ? 'Contact Us' : 'Next'}
                    </Button>
                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                    <Button variant='outlined'
                        onClick={() => page > 0 ? handleBack() : history.push('/Home')}
                        startIcon={<IconArrowNarrowLeft size='20' />}
                        className='univers-65-bold-science-blue-16px'
                        sx={{
                            width: '115px',
                            border: '1px solid var(--science-blue) !important',
                            borderRadius: 6,
                            textTransform: 'unset'
                        }}>
                        Back
                    </Button>
                    {/* <Button variant='contained' */}
                    <Button
                        disabled={journey === null || journey === "organization"}
                        onClick={() => page < 1 ? setPage(page + 1) : history.push('/ContactUs')}
                        endIcon={<IconArrowNarrowRight size='20' />}
                        className='large button-large univers-65-bold-white-16px disablebutton'

                        sx={{
                            height: '48px',
                            width: page===1 ? '161px' : '115px',
                            // background: 'linear-gradient(270deg, #0088ce 0%, #0065bd 100%)',
                            // borderRadius: 6,
                            textTransform: 'unset',
                        }}>
                        {page === 1 ? 'Contact Us' : 'Next'}
                    </Button>
                </div>
                }
            </Container>
            </GuidedJourneyContext.Provider>
        </div>
    )
}
