import axios from 'axios';
import { config } from '../../../config';
import { fetch } from '../../../Common/API'

const APICall = async (submissionId, formId) => {

  let resp

  const deleteApplicationListing = await axios.delete(`${config.appURL}/api/Application/${formId}/Submission/${submissionId}`).then(function (response) {
    resp = response.data
  });

  return resp
}


export const getTrackChanges = async (id) => {
  const query = `${config.appURL}/api/ChangeTracking/GetChangeTrackingdetails?submissionId=${id}`;

    const resp = await fetch(query, 'get', null);

    return resp
}


export const TrackChanges = async (changes, id) => {
  const query = `${config.appURL}/api/ChangeTracking/PostChangeTrackingdetails`;

    const dataarray = []

    if(changes.length > 0){
        for(const element of changes) {
          const thechanges = {
            "label": "none",
            "tableView": true,
            "key": element,
                "type": "none",
                "input": true
            }

          dataarray.push(thechanges)
        }
    }

    const data = {
        "changeTracking": {
          "applicationSubmissionId": id,
          "components": dataarray
        }
      }

    const resp = await fetch(query, 'POST', data);

    return resp
}

export const updateTrackChanges = async (changes, id) => {
  const query = `${config.appURL}/api/ChangeTracking/PostChangeTrackingdetails`;

    const dataarray = []

    if(changes.length > 0){
        for(const element of changes) {
          const thechanges = {
            "label": "none",
            "tableView": true,
            "key": element,
                "type": "none",
                "input": true
            }

          dataarray.push(thechanges)
        }
    }

    const data = {
        "changeTracking": {
          "applicationSubmissionId": id,
          "components": dataarray
        }
      }

    const resp = await fetch(query, 'PUT', data);

    return resp
}

export const NamePropertyTrackChanges = async (changes, id) => {
  const query = `${config.appURL}/api/ChangeTracking/PostChangeTrackingdetails`;

    const dataarray = []

    if(changes.length > 0){
        for(const element of changes) {
          const thechanges = {
            "label": element.element,
            "tableView": true,
            "key": element.name,
                "type": "none",
                "input": true
            }

          dataarray.push(thechanges)
        }
    }

    const data = {
        "changeTracking": {
          "applicationSubmissionId": id,
          "components": dataarray
        }
      }

    const resp = await fetch(query, 'POST', data);

    return resp
}


export default APICall
