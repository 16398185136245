import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectMenu({ handleClickMenu, active, menuitem }) {

  const formControlLabelStyle = {
    "& .MuiFormControlLabel-label": {
      fontSize: "1.5rem",
      width: 100,
      backgroundColor: 'rgba(0,0,0,0.1)'
    },
    "& .MuiFormControl-root": {
      width: 100,
    },
    "& .MuiInputBase-root": {
      height: 'auto',
    }
  }

  return (
    <div className='selectmobile'>
      <FormControl size='medium' fullWidth={true} margin='none' autoWidth={true}
        sx={{ ...formControlLabelStyle }}
      >
        <Select
          value={active}
          onChange={handleClickMenu}
          autoWidth
        >
          {menuitem.map((n) => (
            <MenuItem key={n.key} value={n.key}>
              <div style={{ whiteSpace: 'normal', Height: 'auto', fontSize: 14 }}>{n.text}</div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}