import {
    AccordionSummary,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    Link,
    Paper,
    Typography,
    useMediaQuery
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import { IconArrowLeft } from '@tabler/icons';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import './FAQs.css';
import MSIPage from './pages/MSIPage'
import IDPPage from './pages/IDPPage'
import WHTPage from './pages/WHTPage'
import GeneralPage from './pages/GeneralPage'
import SelectMenu from './menuOptions'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: '1px gray solid',
    },
    '&.Mui-expanded &:not(:last-child)': {
        borderBottom: '1px #0065bd solid',
    },
    '.MuiAccordionSummary-root': {
        backgroundColor: '#fff',
        color: 'unset',
        fontFamily: 'var(--font-family-univers-65bold)',
        fontSize: 'var(--font-size-l)',
    },
    '.Mui-expanded': {
        color: '#0065bd',
        fontFamily: 'var(--font-family-univers-65bold)',
        fontSize: 'var(--font-size-l)',
    },
    '.MuiListItem-root': {
        padding: '6px 24px',
        fontFamily: 'var(--font-family-univers-55roman)',
        fontSize: 'var(--font-size-l)',
    },
    '.MuiAccordionDetails-root': {
        paddingTop: '0'
    }
}));

export default function FAQs() {
    const history = useHistory();
    const [, setSearchstring] = useState('');
    const [searchwithin, setSearchwithin] = useState('');
    const [active, setActive] = useState('general');
    const handleClickMenu = (event) => {
        setActive(event.target.value)
        // alert(event.target.value);
      };
    const menuitem = [{key: "general", text: "General"}, {key: "idp", text: "Industry Digital Plan (IDP)"}, {key: "wht", text: "Withholding Tax (WHT) Exemption"}, {key:"msi", text: "Maritime Sector Incentive (MSI)"}]
    return (<>
    {/* <Helmet>
    <title>FAQ - MPA</title>
    <meta name="description" content="MPA Website" />
    </Helmet> */}
        <div className='FAQs'>
            <div className="exploreschemes">
                <div className="scheme-background">
                    <div className="backbanner" style={{ height: '310px' }} />
                    <Container>
                        <br />
                        <Button onClick={() => history.push('/Home')}
                            className='nav-back-btn'>
                            <IconArrowLeft color='#666' />
                        </Button>
                        <Breadcrumbs sx={{ display: 'inline-block', verticalAlign: 'middle' }} aria-label="breadcrumb">
                            <Link underline='hover' sx={{ cursor: 'pointer' }}
                                onClick={() => history.push('/Home')}
                                mx={1}
                                className='active-link univers-65-bold-scarpa-flow-12px'>
                                Home
                            </Link>
                            <Link underline='none'
                                mx={1} className='disabled-link univers-65-bold-scarpa-flow-12px'>
                                FAQS
                            </Link>
                        </Breadcrumbs>
                        <br />
                        <br />
                        <Typography variant='h2'>FAQS</Typography>
                        <Typography fontSize={'22px !important'} className='univers-55roman-normal-mine-shaft-18px' my={2}>
                            How can we help you?
                        </Typography>
                        {/* <TextField
                            fullWidth
                            onChange={e => setSearchstring(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Enter your search here..."
                        />
                        <Grid container justifyContent={'space-between'}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className='univers-55roman-normal-mine-shaft-16px'>
                                    Search within:
                                </Typography>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons-group-label"
                                        value={searchwithin}
                                        onChange={e => setSearchwithin(e.target.value)}
                                        sx={{ m: 2, flexDirection: 'row' }}
                                        name="radio-buttons-group" >
                                        <FormControlLabel value="oneMaritime" control={<Radio />} label="oneMaritime" />
                                        <FormControlLabel value="Whole of Government" control={<Radio />} label="Whole of Government" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <Link mt={2} underline='none'>Advanced Search</Link>
                        </Grid> */}


                        <div className='mobilefaq' style={{display: useMediaQuery('(max-width: 900px)') ? "" : "none"}}>
                        <br />
                            <Typography variant='h4'>Categories</Typography>
                            <SelectMenu handleClickMenu={handleClickMenu} active={active} menuitem={menuitem} />
                        </div>

                        <Paper elevation={0} sx={{ position: 'relative', mt: 6, mb: 6, px: 8, py: 2, border: '1px solid #EAECF0', borderRadius: 2 }}>

                            <Grid container mt={0} mb={5} spacing={6}>
                                <Grid item xs={useMediaQuery('(min-width: 900px)') ? 3 : 9} style={{display: useMediaQuery('(min-width: 900px)') ? "" : "none"}}>
                                    <Typography variant="h3">Categories</Typography>
                                    <br />

                                    {/* There is a change of queue for General (last) & MSI (first) */}
                                    <Accordion defaultExpanded={true}>
                                        {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="left-menu"
                                            id="left-menu">
                                            General
                                        </AccordionSummary> */}
                                         <AccordionSummary >
                                        {active === "general" ?
                                        <Typography variant="h6"
                                            aria-controls="left-menu"
                                            onClick={() => setActive("general")}
                                            sx={{color:"dodgerblue"}}
                                            id="left-menu">
                                            General
                                        </Typography>
                                        :
                                        <Typography variant="h6"
                                            aria-controls="left-menu"
                                            onClick={() => setActive("general")}
                                            sx={{color:"black"}}
                                            id="left-menu">
                                            General
                                        </Typography>
                                        }
                                        </AccordionSummary>

                                        {/* <AccordionDetails>
                                            <List>
                                                <ListItem>
                                                    About
                                                </ListItem>
                                                <ListItem>
                                                    Login
                                                </ListItem>
                                                <ListItem>
                                                    Maintain Profile
                                                </ListItem>
                                            </List>
                                        </AccordionDetails> */}
                                    </Accordion>


                                    <Accordion>
                                    <AccordionSummary >
                                    {active === "idp" ?

                                        <Typography variant="h6"
                                            aria-controls="left-menu"
                                            onClick={() => setActive("idp")}
                                            sx={{color:"dodgerblue"}}
                                            id="left-menu">
                                            Industry Digital Plan (IDP)
                                        </Typography>
                                        :
                                        <Typography variant="h6"
                                            aria-controls="left-menu"
                                            onClick={() => setActive("idp")}
                                            sx={{color:"black"}}
                                            id="left-menu">
                                            Industry Digital Plan (IDP)
                                        </Typography>
                                        }
                                        </AccordionSummary>
                                        {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="left-menu"
                                            id="left-menu">
                                            Industry Digital Plan (IDP)
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List>
                                                <ListItem>
                                                    About
                                                </ListItem>
                                                <ListItem>
                                                    Login
                                                </ListItem>
                                                <ListItem>
                                                    Maintain Profile
                                                </ListItem>
                                            </List>
                                        </AccordionDetails> */}
                                    </Accordion>
                                    <Accordion >
                                        {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="left-menu"
                                            id="left-menu">
                                            Withholding Tax (WHT) Exemption
                                        </AccordionSummary> */}

                                    <AccordionSummary >
                                        {active === "wht" ?
                                        <Typography variant="h6"
                                            aria-controls="left-menu"
                                            onClick={() => setActive("wht")}
                                            sx={{color:"dodgerblue"}}
                                            id="left-menu">
                                            Withholding Tax (WHT) Exemption
                                        </Typography>
                                        :
                                        <Typography variant="h6"
                                            aria-controls="left-menu"
                                            onClick={() => setActive("wht")}
                                            sx={{color:"black"}}
                                            id="left-menu">
                                            Withholding Tax (WHT) Exemption
                                        </Typography>
                                        }
                                    </AccordionSummary>

                                        {/* <AccordionDetails>
                                            <List>
                                                <ListItem>
                                                    About
                                                </ListItem>
                                                <ListItem>
                                                    Login
                                                </ListItem>
                                                <ListItem>
                                                    Maintain Profile
                                                </ListItem>
                                            </List>
                                        </AccordionDetails> */}
                                    </Accordion>


                                    <Accordion defaultExpanded={true}>
                                    <AccordionSummary >
                                    {active === "msi" ?
                                    <Typography variant="h6"
                                        aria-controls="left-menu"
                                        onClick={() => setActive("msi")}
                                        sx={{color:"dodgerblue"}}
                                        id="left-menu">

                                    Maritime Sector Incentive (MSI)
                                    </Typography>
                                    :
                                    <Typography variant="h6"
                                        aria-controls="left-menu"
                                        onClick={() => setActive("msi")}
                                        sx={{color:"black"}}
                                        id="left-menu">

                                    Maritime Sector Incentive (MSI)
                                    </Typography>

                                    }
                                     </AccordionSummary>

                                        {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="left-menu"
                                            id="left-menu">
                                            Maritime Sector Incentive (MSI)
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List>
                                                <ListItem>
                                                    About
                                                </ListItem>
                                                <ListItem>
                                                    Login
                                                </ListItem>
                                                <ListItem>
                                                    Maintain Profile
                                                </ListItem>
                                            </List>
                                        </AccordionDetails> */}
                                    </Accordion>

                                    <Accordion >
                                    <AccordionSummary >
                                    </AccordionSummary>
                                    </Accordion>
                                </Grid>


                                <Grid item xs={useMediaQuery('(min-width: 900px)') ? 9 : 12}>
                                    {/* <Typography variant="h3">Schemes implemented by MPA which are available for the port and maritime community</Typography>
                                    <br />
                                    <Typography variant='h4'>1. What are the schemes implemented by MPA which are available for the port and maritime community ?</Typography>
                                    <br />
                                    <Typography className='univers-55roman-normal-scarpa-flow-16px'>
                                        MPA has implemented the following schemes:
                                        <br /><br />
                                        (i) Maritime Cluster Fund to facilitate the enhancement of local expertise in maritime services that are high value-added and knowledge-based such as ship management, ship financing, ship brokering/chartering, maritime law/arbitration, port terminal management and marine engineering. Please visit our website for more details.
                                        <br /><br />
                                        (ii) Maritime Innovation and Technology (MINT) Fund to enhance maritime research and development capabilities in the tertiary and research institutes and to develop the maritime technology industry in Singapore. Please visit our website for more details.
                                        <br /><br />
                                        (iii) Maritime Enterprise IT Development (MERIT) programme to enhance the IT capabilities of local maritime SMEs and increase the competitive advantage of the smaller players in the maritime value chain. Please visit our website for more details.
                                    </Typography>
                                    <br /><hr /><br />
                                    <Typography variant="h3">Concession to exempt vessel disposal gains from tax</Typography>
                                    <br /><br />
                                    <Typography variant='h4'>1. What is the concession about ?</Typography>
                                    <br /><hr /><br />
                                    <Typography variant='h4'>2. What is the scope of the concession ?</Typography>
                                    <br /><hr /><br />
                                    <Typography variant='h4'>3. Who is eligible for the concession ?</Typography>
                                    <br /><hr /><br />
                                    <Typography variant='h4'>4. Is there any requirement on the minimum length of ownership of vessels or shares in vessel-owning SPCs ?</Typography>
                                    <br /><hr /><br /> */}

                            {function () {
                                switch (active) {
                                    case "msi": return <MSIPage />;
                                    case "idp": return <IDPPage />;
                                    case "wht": return <WHTPage/> ;
                                    case "general": return <GeneralPage/>;
                                    default: return <MSIPage />;
                                }
                            }()}

                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                </div>
            </div>
        </div>
    </>)
}
