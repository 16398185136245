import React, { useEffect, useState } from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from "@mui/material";
import { IconArrowNarrowRight, IconChevronDown, IconLock, IconMenu2, IconX } from "@tabler/icons";
import "./Header.css";
import "../PreLogin/PreLogin.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import StatusDialog from '../../Common/StatusDialog';
import Merlion from "../../Images/logo-merlion-10@1x.png";
import Ship from "../../Images/ship.jpg";
import singpass from "../../Images/singpass.png";
import { fetch } from '../../Common/API';
import { config } from '../../config';
import { styled } from "@mui/material/styles";
import FakeHeader from './fakeHeader'
import PropTypes from "prop-types";
import $ from "jquery";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DirectUserToIdpWebsite } from "../../Common/CommonUtils";
import "@webcomponents/scoped-custom-element-registry"
import "@govtechsg/sgds-web-component";
import "@govtechsg/sgds-web-component/components/Masthead";
import debounce from 'lodash/debounce';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    height: "5px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 55,
    width: "100%",
    backgroundColor: "#0065BD",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontWeight: "bold",
    marginRight: 15,
    fontSize: 16,
    lineHeight: 4.5,
    color: "black",
    "&:hover": {},
    "&.Mui-selected": {
      color: "gray",
    },
    "&.Mui-focusVisible": {},
  })
);

const boxSX = {
  color: "white",
  "&:hover": {
    color: 'lightgray',
  },
};

function HomepageHeader() {
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorElHamburger, setAnchorElHamburger] = useState(null);
  const [openHamburger, setOpenHamburger] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const status = "Please login using your desktop browser to continue.";
  const mobile = useMediaQuery('(max-width:1055px)');
  const Samsung = useMediaQuery('(max-width:360px)');
  const surfaceDuo = useMediaQuery('(max-width:540px)');
  const tablet = useMediaQuery('(min-width:450px)');
  const [colorChange, setColorchange] = useState(false);

  useEffect(() => {
    if (location.state?.privateRoute === true && anchorEl2 === null && mobile) {
      setOpenStatusDialog(true);
    } else if (location.state?.privateRoute === true && anchorEl2 === null && !mobile) {
      handleOpen2();
    }
  }, [])

  let whiteHeader = (anchorEl !== null) || (anchorEl2 !== null) || (location.pathname !== '/Home') || (location.pathname === '/Home' && colorChange === true);

  const open1 = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const appURL = config.appURL;
  const [dropMenu, setDropMenu] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    handleClose1();
    setTabValue(newValue);
  };

  function checkPath() {
    const x = location.pathname;
    switch (x) {
      case '/WHTScheme':
        return '/ExploreSchemes';
      case '/MSIScheme':
        return '/ExploreSchemes';
      case '/IDPScheme':
        return '/ExploreSchemes';
      case '/ExploreSchemes':
        return '/ExploreSchemes';
      case '/AboutOMP':
        return '/AboutOMP';
      case '/FAQs':
        return '/FAQs';
      case '/ContactUs':
        return '/ContactUs';
      default:
        return false;
    }
  }

  function handleOpen1() {
    setAnchorEl(document.getElementById('header-anchor'));
  }

  function handleClose1() {
    setAnchorEl(null);
    history.replace(location.state?.privateRoute, [false])
  }

  function handleOpen2() {
    if (openHamburger === false) {
      setAnchorEl2(document.getElementById('header-anchor'));
    } else {
      setOpenStatusDialog(true);
    }
  }

  function handleClose2() {
    setAnchorEl2(null);
    history.replace(location.state?.privateRoute, [false])
  }

  function handleOpenHamburger(e) {
    if (openHamburger === false) {
      setAnchorElHamburger(e.currentTarget.parentElement.parentElement.parentElement);
      setOpenHamburger(true);
      setColorchange(true);
    } else {
      setOpenHamburger(false);
      setColorchange(false);
    }
  }

  function handleOpenRedirect(URL) {
    handleClose1();
    history.push(URL);
  }

  async function handleCorppassLogin() {
    const spcpReturnUrl = config.spcpReturnUrl;
    const nric = "G5280542U";
    const uen = "R11HS5907E";
    const isCorppass = "1";
    const spcpURL = `${appURL}/api/auth/GetSPCPLoginURL?target=${spcpReturnUrl}&clientState=s=${nric},u=${uen}&isCorppass=${isCorppass}`;
    const getSPCPLoginInfoResponse = await fetch(spcpURL, 'GET', null).then((response) => {
      window.location.href = response.data.spcPloginURL;
    });

  }

  const changeNavbarColor = debounce(() => {
    if (window.scrollY >= 50) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  }, 160);

  window.addEventListener('scroll', changeNavbarColor);

  $(function () {
    $("#ExploreSchemes").on("mouseenter", function () {
     handleOpen1();
    });
    $("#ExploreSchemes").on("mouseleave", function () {
     handleClose1();
    });
    $("#ExploreSchemeContent").on("mouseenter", function () {
      handleOpen1();
    });
    $("#ExploreSchemeContent").on("mouseleave", function () {
      handleClose1();
    });
  });

  return (
    <div className='noPrint' style={{ zIndex: 2 }}>      
      <StatusDialog
        open={openStatusDialog}
        setOpen={setOpenStatusDialog}
        status={status}
      />
      <AppBar position="fixed" className={colorChange ? 'appbarcolor2' : 'appbarcolor'} elevation={0}
        sx={{ zIndex: 4 }}>
        <div className='agencyheader1'>
          <div className='agencyheader2'>
            <Container maxWidth={'1200px'} sx={{ marginLeft: '0px', zIndex: 100}}>
              
              {/* <MuiLink href='https://www.gov.sg' underline='none' sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={Merlion}
                  height={16}
                  style={{ marginRight: 22 }}
                  alt="merlion"
                />
                <div className="univers-55roman-normal-mine-shaft-14px" style={{ whiteSpace: "nowrap" }}>
                  A Singapore Government Agency Website
                </div>
              </MuiLink> */}
            </Container>
          </div>
        </div>
        <div className={whiteHeader ? "top-navi" : "top-navi-transparent"} id='header-anchor'>
          <Container
            className="header-inner-container">
            <div style={{ display: 'flex', cursor: 'pointer', paddingTop: 20, height: 96 }}
              onClick={() => {
                if (location.pathname !== '/Home') handleOpenRedirect('/Home')
              }}>
              <img
                src={`${process.env.PUBLIC_URL}${config?.logoURL}`}
                height={55}
                style={{ marginRight: 12.7 }}
                alt="logo"
              />
              <Typography className="optima-bold-mine-shaft-16px OMP-right-margin"
                sx={{
                  lineHeight: 1.15,
                  color: whiteHeader ? '#333333' : '#ffffff !important',
                  cursor: 'pointer'
                }}>
                One<br />Maritime<br />Portal
              </Typography>
            </div>

            <div className="responsive-header" style={{ display: 'flex' }}>
              {whiteHeader ?
                <StyledTabs
                  value={checkPath()}
                  onChange={handleChangeTab}
                  aria-label="header tabs"
                >
                  <StyledTab id="ExploreSchemes"
                    aria-controls={open1 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    sx={{ mx: 1 }}
                    icon={<IconChevronDown color="black" />}
                    iconPosition="end"
                    label="EXPLORE SCHEMES" value={'/ExploreSchemes'} component={Link}
                    to={'/ExploreSchemes'} />
                  <StyledTab label="ABOUT OMP" value={'/AboutOMP'} component={Link} to={'/AboutOMP'} />
                  <StyledTab label="FAQS" value={'/FAQs'} component={Link} to={'/FAQs'} />
                  <StyledTab label="CONTACT US" value={'/ContactUs'} component={Link} to={'/ContactUs'} />
                  <IconButton component={Link} to="/search" color="info"
                    style={{ backgroundColor: 'transparent' }}>
                    <SearchIcon />
                  </IconButton>
                </StyledTabs>
                :
                <StyledTabs
                  value={checkPath()}
                  onChange={handleChangeTab}
                  aria-label="header tabs"
                >
                  <StyledTab id="ExploreSchemes"
                    aria-controls={open1 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    icon={<IconChevronDown color="white" />}
                    iconPosition="end"
                    sx={boxSX}
                    label="EXPLORE SCHEMES" value={'/ExploreSchemes'} component={Link}
                    to={'/ExploreSchemes'} />

                  <StyledTab sx={boxSX} label="ABOUT OMP" value={'/AboutOMP'} component={Link}
                    to={'/AboutOMP'} />
                  <StyledTab sx={boxSX} label="FAQS" value={'/FAQs'} component={Link} to={'/FAQs'} />
                  <StyledTab sx={boxSX} label="CONTACT US" value={'/ContactUs'} component={Link}
                    to={'/ContactUs'} />
                  <IconButton component={Link} to="/search" color="inherit"
                    style={{ backgroundColor: 'transparent' }}>
                    <SearchIcon />
                  </IconButton>
                </StyledTabs>
              }
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                sx={{
                  ml: 2,
                  width: "123px",
                  marginRight: 0,
                  display: useMediaQuery('(min-width: 1055px)') ? "inherit" : "none"
                }}
                className="loginTextAlign button-large univers-65-bold-white-16px"
                startIcon={<IconLock />}
                onClick={handleOpen2}
              >
                <p style={{ alignSelf: "baseline" }}>LOGIN</p>
              </Button>
              {whiteHeader ?
                <IconButton sx={{ ml: 2 }} className='responsive-hamburger2 hamburgerColor2'
                  onClick={handleOpenHamburger}>
                  {openHamburger ? <IconX /> : <IconMenu2 />}
                </IconButton>
                :
                <IconButton sx={{ ml: 2 }} className='responsive-hamburger2 hamburgerColor'
                  onClick={handleOpenHamburger}>
                  {openHamburger ? <IconX /> : <IconMenu2 />}
                </IconButton>
              }
            </div>
          </Container>
        </div>
      </AppBar>

      <Backdrop open={open1} sx={{ zIndex: 2, }}>
        <Menu
          anchorEl={anchorEl}
          open={open1}
          elevation={0}
          sx={{ zIndex: 3 }}
          PaperProps={{
            style: {
              width: "100%",
              maxWidth: "100%",
              borderRadius: "0",
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Grid container id="ExploreSchemeContent">
            <Container>
              <Grid
                container
                justifyContent={"space-around"}
                borderBottom={"2px solid #f1f1f1"}
                p={6}
              >
                <Grid item>
                  <MenuItem
                    sx={{ p: "6px 16px !important" }}
                    onClick={() => handleOpenRedirect("/Grants")}
                  >
                    <Typography variant="h5">Grants</Typography>
                  </MenuItem>
                  <MenuItem onClick={()=> DirectUserToIdpWebsite()}>
                    Sea Transport Industry Digital Plan
                  </MenuItem>
                </Grid>
                <Grid item>
                  <MenuItem
                    sx={{ p: "6px 16px !important" }}
                    onClick={() => handleOpenRedirect("/Incentives")}
                  >
                    <Typography variant="h5">Incentives</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => handleOpenRedirect("/WHTScheme")}>
                    Withholding Tax Exemption
                  </MenuItem>
                  <MenuItem onClick={() => handleOpenRedirect("/MSIScheme")}>
                    Maritime Sector Incentive
                  </MenuItem>
                </Grid>
                <Grid item>
                  <div id="banner-gradient">
                    <img id="shipbanner" src={Ship} alt="ship" />
                    <Box id="commoncss2-big-text-box">
                      <div id="commoncss2-big-text">
                        Find eligible schemes for your business
                      </div>
                    </Box>
                    <Button
                      id="commoncss2-guided-journey-button"
                      endIcon={<IconArrowNarrowRight />}
                      onClick={() => handleOpenRedirect("/GuidedJourney")}
                    >
                      Guided Journey
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Grid container width={"95%"} justifyContent={"flex-end"} p={2.5}>
                <Button
                  className="univers-65-bold-science-blue-16px"
                  sx={{ textTransform: "none" }}
                  endIcon={<IconArrowNarrowRight />}
                  onClick={() => handleOpenRedirect("/ExploreSchemes")}
                >
                  See All Schemes
                </Button>
              </Grid>
            </Container>
          </Grid>
        </Menu>
      </Backdrop>
      <Backdrop open={open2} onClick={handleClose2} sx={{ zIndex: 2 }}>
        <Menu
          anchorEl={anchorEl2}
          open={open2}
          elevation={0}
          PaperProps={{
            style: {
              width: "100%",
              maxWidth: "100%",
              borderRadius: "0",
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={handleClose2}
        >
          <div className="MuiGrid-container">
            <Typography className="univers-55roman-normal-scarpa-flow-18px advisoryColor"
              sx={{ textAlign: 'left' }}>
              <p className="alignContent">
                <br /><b>[Scam Advisory]</b> Beware of emails / SMSes / calls falsely claiming to be from MPA,
                requesting for your personal or financial information. MPA will never send you any automated
                emails / SMSes / calls with links requesting for your personal or financial information. If you
                receive any email / call purporting to be from MPA requesting for your personal or financial
                information, you should verify the authenticity of such instructions by calling MPA’s Quality
                Service Manager at <b>1800 272 5880</b> or emailing qsm@mpa.gov.sg before you respond. Thank you.
                <br /> <br /></p></Typography>
          </div>

          <br /><br />
          <Grid container>
            <Container>

              <div className="login-card">

                <img src={singpass} alt="singpass logo" width={179} />
                <Typography mt={2} className="univers-55roman-normal-scarpa-flow-18px">
                  Now you can login to apply and manage all your maritime schemes here.
                </Typography>
              </div>
              <Grid container mt={3} sx={{ alignItems: 'center', maxWidth: 900, mx: 'auto' }}>
                <Grid item xs={4.5} className="corp-pass">
                  <Button className="orglogin-button" onClick={handleCorppassLogin}>
                    Login As Organisation
                  </Button>
                </Grid>
                <Grid item xs={3} height={101}>
                  <Divider orientation="vertical">
                    <Typography className="univers-55roman-normal-scarpa-flow-18px">
                      Or
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={4.5} className="sing-pass">
                  <Button
                    className="indlogin-button-disabled"
                    disabled>
                    {" "}
                    Login As Individual{" "}
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <Divider />
            <div className="overlay"></div>
          </Grid>
        </Menu>
      </Backdrop>

      <Popper anchorEl={anchorElHamburger} open={openHamburger} sx={{ zIndex: 1101, width: '100%', height: 'auto' }}
        className='responsive-hamburger' placement='bottom-start' disablePortal>

        <Paper sx={{ height: '-webkit-fill-available', boxShadow: 'none', overflow: 'auto' }}>
          <div style={{ padding: "15px" }}>
            <MenuList dense>
              <MenuItem onClick={() => handleOpenRedirect("/search")} style={{ fontWeight: "bold" }}>
                <SearchIcon />
              </MenuItem>
              <Divider light={true} />
              <MenuItem style={{ fontWeight: "bold" }}>
                {tablet ?
                  <Stack direction="row" alignItems="center" gap={surfaceDuo ? 40 : 65}>
                    <span onClick={() => { setOpenHamburger(false); handleOpenRedirect('/ExploreSchemes') }}>EXPLORE SCHEMES</span>
                    <span className="iconMenu" onClick={() => {
                      setDropMenu(!dropMenu)
                    }}>
                      {dropMenu ?
                        <KeyboardArrowDownIcon />
                        :
                        <KeyboardArrowRightIcon />
                      }
                    </span>
                  </Stack> :
                  <Stack direction="row" alignItems="center" gap={Samsung ? 15 : 20}>
                    <span onClick={() => { setOpenHamburger(false); handleOpenRedirect('/ExploreSchemes') }}>EXPLORE SCHEMES</span>
                    <span className="iconMenu" onClick={() => {
                      setDropMenu(!dropMenu)
                    }}>
                      {dropMenu ?
                        <KeyboardArrowDownIcon />
                        :
                        <KeyboardArrowRightIcon />
                      }
                    </span>
                  </Stack>
                }
              </MenuItem>
              <Divider light={true} />
              <div style={{ display: dropMenu ? "block" : "none" }}>
                <MenuItem
                  onClick={() => { setOpenHamburger(false); handleOpenRedirect('/Grants') }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GRANTS
                </MenuItem>
                <Divider light={true} />
                <MenuItem
                  onClick={() => { setOpenHamburger(false); DirectUserToIdpWebsite() }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SEA
                  TRANSPORT INDUSTRY DIGITAL PLAN
                </MenuItem>
                <Divider light={true} />
                <MenuItem
                  onClick={() => { setOpenHamburger(false); handleOpenRedirect('/Incentives') }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INCENTIVES
                </MenuItem>
                <Divider light={true} />
                <MenuItem
                  onClick={() => { setOpenHamburger(false); handleOpenRedirect('/WHTScheme') }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WITHHOLDING
                  TAX EXEMPTION
                </MenuItem>
                <Divider light={true} />
                <MenuItem
                  onClick={() => { setOpenHamburger(false); handleOpenRedirect('/MSIScheme') }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MARITIME
                  SECTOR INCENTIVE
                </MenuItem>
                <Divider style={{ color: "blue" }} />
              </div>
              <MenuItem onClick={() => { setOpenHamburger(false); handleOpenRedirect('/AboutOMP') }} style={{ fontWeight: "bold" }}>ABOUT
                OMP
              </MenuItem>
              <Divider light={true} />
              <MenuItem onClick={() => { setOpenHamburger(false); handleOpenRedirect("/FAQs") }} style={{ fontWeight: "bold" }}>FAQS
              </MenuItem>
              <Divider light={true} />
              <MenuItem onClick={() => { setOpenHamburger(false); handleOpenRedirect('/ContactUs') }} style={{ fontWeight: "bold" }}>CONTACT
                US
              </MenuItem>
            </MenuList>

            <div style={{ textAlign: "center" }}>
              <Button
                sx={{ ml: 2, width: "123px", mt: 4, mb: 4 }}
                className="loginTextAlign button-large univers-65-bold-white-16px"
                startIcon={<IconLock />}
                onClick={handleOpen2}
              >
                <p style={{ alignSelf: "baseline" }}>LOGIN</p>
              </Button>
            </div>
          </div>
        </Paper>

      </Popper>
    </div>
  );
}

export default HomepageHeader;

