import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { inboxList } from './mockData';
export const getInboxListAsync = createAsyncThunk(
	'inbox/getInboxListAsync',
	async () => {
		return {
			inboxList
		};
	}
);

export const InboxSlice = createSlice({
    name: 'inboxList',
    initialState: [],
    reducers: {},
    extraReducers: {
        [getInboxListAsync.fulfilled]: (state, action) => {
            return action.payload.inboxList;
        }
    }
});

export default InboxSlice.reducer;
