import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

export default function ActionDialog({open, setOpenDialog, actionName, response, type}){


    return(
        <>
        <Dialog
          open={open}
          onClose={() => {
            setOpenDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            {type === "delete" ?
            <DialogContentText id="alert-dialog-description">
              Are you sure you wish to {type} this draft?
              {/* Are you sure to {type} {actionName} draft? */}
            </DialogContentText>
            :
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to {type}?
              {/* Are you sure to {type} {actionName}? */}
            </DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenDialog(false);
                response('Yes', type)
            }}>
              Yes
            </Button>
            <Button onClick={() => {
              setOpenDialog(false);
                response('No', type)
            }}
              autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        </>
    )
}
