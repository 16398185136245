import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  List,
  ListItem,
} from "@mui/material";
import { IconArrowLeft } from "@tabler/icons";
import { useHistory } from "react-router-dom";
import aboutOMPPic from "../../Images/aboutOMP.png";
import aboutomp2 from "../../Images/img_whatOMPoffers@2x.png";
import "./AboutOMP.css";
import { config } from "../../config";

export default function AboutOMP() {
  const history = useHistory();
  return (
    <div className="exploreschemes">
      <div className="scheme-background">
        <div className="aboutomp-backbanner" />

        <Container>
          <br />
          <Button
            onClick={() => history.push("/Home")}
            className="nav-back-btn"
          >
            <IconArrowLeft color="#666" />
          </Button>
          <Breadcrumbs
            sx={{ display: "inline-block", verticalAlign: "middle" }}
            aria-label="breadcrumb"
          >
            <Link
              underline="hover"
              sx={{ cursor: "pointer" }}
              onClick={() => history.push("/Home")}
              mx={1}
              className="active-link univers-65-bold-scarpa-flow-12px"
            >
              Home
            </Link>
            <Link
              underline="none"
              mx={1}
              className="disabled-link univers-65-bold-scarpa-flow-12px"
            >
              About One Maritime Portal (OMP)
            </Link>
          </Breadcrumbs>

          <Grid container>
             <Grid item xs={useMediaQuery("(min-width: 768px)") ? 6.5 : 12}>
               <br />
               <Typography variant="h2">
                 About One Maritime Portal (OMP)
               </Typography> 
              <br/>
               <Typography className="univers-55roman-normal-mine-shaft-22px textomp">
                 One Maritime Portal is a one-stop digital platform 
                 for individuals and maritime enterprises to 
                 access the schemes administered and supported by MPA.
               </Typography>
             </Grid>
             <Grid item xs={12} sm={6} md={3}>
               <img 
                style={{
                  display: useMediaQuery("(min-width: 1090px)")
                    ? "initial"
                    : "none",
                }}
                src={aboutOMPPic} id="aboutomppic" alt="aboutOMP"  className="contactUsImg"/>
             </Grid>
          </Grid>
        </Container>
      </div>
      <div className="about-omp-sec2">
        <div className="screen-container2">
          <Grid container>
            <Grid
              item
              xs={useMediaQuery("(min-width: 1100px)") ? 6 : 12}
              sx={{ display: "flex", alignItems: "flex-end" }}
              p={3}
            >
              <img
                src={aboutomp2}
                className="about-omp-Sec2overlap"
                alt="aboutOMP"
              />
            </Grid>
            <Grid item xs={useMediaQuery("(min-width: 1100px)") ? 6 : 12} p={3}>
              <Box
                sx={{
                  alignItems: "center",
                  mt: useMediaQuery("(min-width: 1100px)") ? 2 : 0,
                }}
              >
                <Typography variant="h2" fontSize={"28px !important"} mb={2}>
                  What OMP offers
                </Typography>
                {config.isIDP === "true" ?
                <Typography className="univers-55roman-normal-scarpa-flow-18px">
                  You can submit your forms to qualify for Withholding Tax (WHT)
                  exemption and Sea Transport Industry Digital Plan (IDP) on
                  OMP.
                </Typography>
                :
                <>
                  <Typography className="univers-55roman-normal-scarpa-flow-18px">
                    As a one-stop digital platform, OMP offers you the following:
                  </Typography>
                  <List sx={{ pl: 2 }}>
                    <ListItem start={1} sx={{
                      display: 'list-item',
                      listStyleType: "decimal",
                      fontWeight: 400, fontStyle: 'normal', fontSize: 'var(--font-size-l)', color: 'var(--x565a5c)',
                      fontFamily: 'var(--font-family-univers-55roman)'
                    }}
                    >
                      <u>Convenience</u> - Apply and submit forms for all the schemes administered and supported by MPA.
                    </ListItem>
                    <ListItem sx={{
                      display: 'list-item',
                      listStyleType: "decimal",
                      fontWeight: 400, fontStyle: 'normal', fontSize: 'var(--font-size-l)', color: 'var(--x565a5c)',
                      fontFamily: 'var(--font-family-univers-55roman)'
                    }}
                    >
                      <u>Visibility</u> - Manage all your applications and view their status and updates via a single portal.
                    </ListItem>
                    <ListItem sx={{
                      display: 'list-item',
                      listStyleType: "decimal",
                      fontWeight: 400, fontStyle: 'normal', fontSize: 'var(--font-size-l)', color: 'var(--x565a5c)',
                      fontFamily: 'var(--font-family-univers-55roman)'
                    }}
                    >
                      <u>Control</u> - Effect seamless changes to your applications.
                    </ListItem>
                  </List>
                </>
                }
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
