import { Typography, Box } from "@mui/material";
import { IconArrowRight } from "@tabler/icons";

export default function IndividualNext() {
  return (
    <>
      <Typography mb={1} variant="h3">
        Our Recommendation
      </Typography>

      <br />
      <br />
      <Typography variant="h5">
        <strong>
          Based on your selection, the following MPA grant(s) and incentive(s)
          may be applicable for your project:
        </strong>
      </Typography>
      <br />

      <Box
        sx={{
          backgroundColor: "white",
          height: "100px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" color={"darkgrey"} sx={{ marginLeft: 5 }}>
          Please visit MPA website for more details.
          <a
            href="https://www.mpa.gov.sg/events-careers/maritime-scholarships"
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: "none" }}
          >
            &nbsp;Learn More <IconArrowRight />
          </a>
        </Typography>
      </Box>
    </>
  );
}
