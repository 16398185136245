import FormReview from "../../../Common/FormReview";
import { useLocation } from "react-router-dom";
import { config } from "../../../config";


export default function IDPChangeRequestReview(props) {
  const formname = "Industry Digital Plan Change Request Form";
  const formId = config.form_name_target_idp_cr;
  const pathname = "/IDPChangeRequestForm";
  const schemaroute = "1";

  const params = new URLSearchParams(window.location.search);
  let _id = params.get("_id");
  let { refresh } = props.location;

  const location  = useLocation()
  const caseID = location.state.caseID
  const canSubmit = location.state.canSubmit
  const prevStatus = location.state.prevStatus

  return (
    <FormReview
      _id={_id}
      refresh={refresh}
      formname={formname}
      caseID={caseID}
      formstatus={prevStatus}
      canSubmit={canSubmit}
      formId={formId}
      pathname={pathname}
      schemaroute={schemaroute}
      options={{hooks: {
        addComponent: (component) => {
          if (component.key == "solutionDescriptionColumn") {
            //  Unfocus the estimated commencement date
            component.columns[0].components[0].autofocus = false;
          }
          return component;
        }
      }}}
    />
  );
}
