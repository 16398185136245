import { Container } from "@mui/material";
import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
	createOrSave,
	FormHtmlErrors,
	FormHtmlForm,
	FormHtmlHead,
	FormHtmlTitle,
	onChangeGeneric,
	onErrorGeneric,
	onRenderGeneric,
	onSubmitDoneGeneric,
	onSubmitGeneric,
	PageCarousel,
} from "../../../Common/ApplicationForm";
import { config } from "../../../config";
import "../../ApplicationForm/ApplicationForm.css";
import { useHistory, useLocation } from "react-router-dom";
import ValidationDialog from "../../../Common/ValidationDialog";
import { fetch } from "../../../Common/API";
import loginHelper from "../../../Common/loginHelper";
import moment from "moment";
import DateValidationDialog from "./DateValidationDialog";
import Utils from "formiojs/utils";
import { deleteFormAPIAsync } from "../../../Redux/CommonForm/CommonFormSlice.js.js";
import flatComponent from "../../../Common/FormIOComponent/flatComponent";
import scanData from "../../../Common/FormIOComponent/scanData";
import {
	getTrackChanges,
	NamePropertyTrackChanges,
	TrackChanges,
} from "../../ApplicationListing/APICall/apicall";
import SnackbarAlert from "../../../Common/snackbarAlert";
import ProgressBar from "../../../Common/progressBar";
import AutoSaveErrorDialog from "../../../Common/autoSaveError";
import getTitle from "../../../Common/FormIOComponent/titleComponent";
import getStatusText from "../../../Common/GetStatusText";
import {
	CommonPopulateQuestionEDH,
	CommonUpdateValidation_NonQualifyingAmountFinancedCost,
	CommonUpdateValidation_QualifyingPayment,
	CommonUpdateValidation_TotalNonQualifyingAmountFinanced,
	CommonUpdateValidation_TypeOfFinancingArrangementObtained,
	DateTransferCertContainer,
	CommonHandleAllDropdownListMetadataFix_WHTContainer,
	CommonHandleWHTContainerDate,
} from "../../../Common/GenericWHTContainer";
import { SwapsCheckbox } from "../../../Common/SwapsCheckboxValidation";
import {
	GenericFileUploadOnRender,
	GenericFileUploadOnChange,
} from "../../../Common/GenericForm";
import { ApplicationStatusTextOptions as ApplicationStatuses } from "../../../Common/ApplicationStatusOptions";

//declaration
let declarationHTMLTEST = "";
let borrowerName = "";

let main_info;
let cr_info;

WHTContainerAppealForm.defaultProps = {
	formname: "Withholding Tax Exemption (Container) Application to MOF",
	//formid: "ASI-20200210-007",
	formstatus: "draft",
	formId: `${config.form_name_target_container_mof}`, //copy exact name reflected in Form.IO
	appFormBaseURL: `${config.appFormBaseURL}`,
	schema: "WHT_Container",
};

export default function WHTContainerAppealForm({
	formname,
	formid,
	formstatus,
	formId,
	appFormBaseURL,
	schema,
	props,
}) {
	//-- useState variable(s)
	const [page, setPage] = useState(0); // Usage: Inform which tab are you in WHT_CONTAINER
	const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false); // Usage: Trigger open error dialog box
	const [isBusy, setBusy] = useState(true); // Usage: Busy = true means form is loading, dont render form yet
	const appFormJson = useRef(); // Usage: Load form using json files, for now we using load form using url
	const [openValidationDialog, setOpenValidationDialog] = useState(false); // Usage: When we click review button, we need validate dialog to validate everything, this flag will trigger whether to open validation dialog or not
	const [disableScrollingFunc, setScrollState] = useState(() => {
		// This function is intentionally empty.
	}); // Usage: Scroll to top when you press next button
	const [openDateValidDialog, setOpenDateValidDialog] = useState(false);

	//-- helper variable(s)
	let allowScrolling = true;
	let validityTriggered = false;

	//
	const dispatch = useDispatch();

	//-- Navigation variable(s)
	const history = useHistory();
	const params = new URLSearchParams(window.location.search);
	let _id = params.get("_id"); // Usage: id of this form
	const theformId = _id;
	let submissionId = _id;
	const selected = params.get("selected"); // Usage: In WHT_EntityChecker Form, user selected their entity type. We use 'selected' to get which entity type they selected.
	let createdData = { _id: _id };

	let parentSubmissionId = "";
	let parentSubmissionType = "";

	let mainSubmissionId = params.get("mainSubmissionId");
	let crSubmissionId = params.get("crSubmissionId");

	let getStatus = params.get("status");
	const [status, setStatus] = useState();
	let prevStatus;
	let reviewCaseID;

	if (mainSubmissionId) {
		parentSubmissionId = mainSubmissionId;
		parentSubmissionType = "main";
	} else {
		parentSubmissionId = crSubmissionId;
		parentSubmissionType = "cr";
	}

	//-- Custom event 1: When vessel name have been modified and user press auto-fill in vessel details using vessel name,
	//                   it will call backend API and fetch the vessel details.
	let whtExemption_startDate = "";
	let whtExemption_endDate = "";

	let existingEntityType = "";

	let formType = "create";
	if (_id) {
		formType = "update";
	}

	//-- Form instance and form url macros
	let createAppFormURL = `${appFormBaseURL}/${formId}`;
	let updateAppFormURL = `${appFormBaseURL}/${formId}/submission/${_id}`;
	let createURL = `${appFormBaseURL}/${formId}/submission`;
	let currentForm = {};

	//-- More useState(s) for Form
	const [thisForm, setThisForm] = useState();
	const [formReadOnly, setFormReadOnly] = useState(false);
	const [fetchingform, setFetchingform] = useState(true);
	const [pagesErrors, setPagesErrors] = useState([]);
	const [currentPageErrors, setCurrentPageErrors] = useState(0);

	const location = useLocation();
	const getCaseID = location?.state?.caseID;
	const [caseID, setCaseID] = useState(getCaseID);
	const [openAlert, setOpenAlert] = useState();
	const [open, setOpen] = useState(false);
	const [startSpinner, setStartSpinner] = useState(false);

	//-- Submission of form
	let alreadySubmitted = false;
	const submitID_Ref = useRef("");
	const form_ref = useRef({});
	const currentFormref = useRef({});
	let canSubmit = true;
	const [bar, setBar] = useState(false);
	const ref = useRef();

	let reloadStrictMode = false;

	const checkErrorStatusAndOpenErrorStatusDialog = () => {
		if (sessionStorage.getItem("error") !== "409") {
			setOpenErrorStatusDialog(true);
		}
	};

	useEffect(() => {
		async function getform() {
			setBusy(true);

			let url = `${config.appURL}/api/application/GetLatestApproveMainORCRSubmissionBySubmissionId/${mainSubmissionId}`;
			if (formType === "update") {
				url = updateAppFormURL;
			}

			let json = await fetch(url, "GET", null);
			if (json) {
				if (reloadStrictMode) {
					reloadStrictMode = false;
				} else {
					setBusy(false);

					let tempStatus = "";

					if (getStatus === "All") {
						setStatus("draft");
						prevStatus = "draft";
						tempStatus = ApplicationStatuses.Draft;
					} else {
						const currentStatus =
							json.data.data.configContainer.publicApplicationStatus;
						const getText = (tempStatus = getStatusText(currentStatus));
						setStatus(getText);
						prevStatus = getText;
					}
					appFormJson.current = json.data;

					if (tempStatus === ApplicationStatuses.Draft) {
						cleanupEmptyFilesFromMigratedContainerMain(appFormJson);
					}
				}
			}
		}
		getform();

		return () => (reloadStrictMode = true);
	}, []);

	useEffect(() => {
		// Create a new interval, and also run it every 'n' seconds
		let intervalInstance = setInterval(async function () {
			// form_ref.current
			if (currentFormref.current) {
				CommonHandleAllDropdownListMetadataFix_WHTContainer(
					currentFormref.current,
				);

				await createOrSave(
					"draft",
					currentFormref.current,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);
			}
		}, 60000);

		return () => {
			clearInterval(intervalInstance);
		};
	}, []);

	function cleanupEmptyFilesFromMigratedContainerMain(appFormJson) {
		const countFinancingAgreementSignedFiles =
			appFormJson.current.data.containerNonTax.containerLender
				.containerFinancingAgreementSigned?.uploadFile?.length;
		for (let i = 0; i < countFinancingAgreementSignedFiles; i++) {
			if (
				appFormJson.current.data.containerNonTax.containerLender
					.containerFinancingAgreementSigned?.uploadFile[i]?.hash?.length === 0
			) {
				appFormJson.current.data.containerNonTax.containerLender.containerFinancingAgreementSigned?.uploadFile?.splice(
					i,
					1,
				);
			}
		}

		const countFinancingAddendumFiles =
			appFormJson.current.data.containerNonTax.containerLender
				.containerFinancingAddendum?.uploadFile?.length;
		for (let i = 0; i < countFinancingAddendumFiles; i++) {
			if (
				appFormJson.current.data.containerNonTax.containerLender
					.containerFinancingAddendum?.uploadFile[i]?.hash?.length === 0
			) {
				appFormJson.current.data.containerNonTax.containerLender.containerFinancingAddendum?.uploadFile?.splice(
					i,
					1,
				);
			}
		}

		const countLetterOfUndertakingFiles =
			appFormJson.current.data.containerNonTax.containerLender
				.containerLetterOfUndertaking?.uploadFile?.length;
		for (let i = 0; i < countLetterOfUndertakingFiles; i++) {
			if (
				appFormJson.current.data.containerNonTax.containerLender
					.containerLetterOfUndertaking?.uploadFile[i]?.hash?.length === 0
			) {
				appFormJson.current.data.containerNonTax.containerLender.containerLetterOfUndertaking?.uploadFile?.splice(
					i,
					1,
				);
			}
		}

		const countNonTaxResidentAgreement2Files =
			appFormJson.current.data.containerNonTax.containerNonTaxResident
				.dataGridNonTaxResident?.length;
		for (let i = 0; i < countNonTaxResidentAgreement2Files; i++) {
			if (
				appFormJson.current.data.containerNonTax.containerNonTaxResident
					.dataGridNonTaxResident[i]?.containerNonTax.containerAgreement2
					.uploadFile[0]?.hash?.length === 0
			) {
				appFormJson.current.data.containerNonTax.containerNonTaxResident.dataGridNonTaxResident[
					i
				]?.containerNonTax.containerAgreement2.uploadFile.splice(0, 1);
			}
		}

		const countAttachmentOfSgBankOrFIFiles =
			appFormJson.current.data.containerCU.containerReasonFA.containerWhyFA3
				?.dataGridAttachmentOfSgBankOrFI?.length;
		for (let i = 0; i < countAttachmentOfSgBankOrFIFiles; i++) {
			if (
				appFormJson.current.data.containerCU.containerReasonFA.containerWhyFA3
					?.dataGridAttachmentOfSgBankOrFI[i]?.uploadFile[0]?.hash?.length === 0
			) {
				appFormJson.current.data.containerCU.containerReasonFA.containerWhyFA3?.dataGridAttachmentOfSgBankOrFI?.splice(
					i,
					1,
				);
			}
		}
	}

	function saveCreatedData(x) {
		createdData = x;
	}

	async function getChanges(component, currentData, preData) {
		for (const item in currentData) {
			for (const check in preData) {
				if (item === check) {
					let now = currentData[item];
					let before = preData[check];
					if (typeof now == Date || typeof before == Date) {
						before = moment(before).format("DD/MM/YYYY");
						now = moment(now).format("DD/MM/YYYY");
					}
					if (now instanceof Array || before instanceof Array) {
						let theLength;
						if (now.length > before.length) {
							theLength = now.length;
						} else {
							theLength = before.length;
						}
						for (let i = 0; i < theLength; i++) {
							if (before[i] !== now[i]) {
								changes.push(`${item}-key:${i}`);
							}
						}
					} else if (before !== now) {
						changes.push(item);
					}
				}
			}
		}
	}

	async function saveChanges(component, currentData, preData, checkingDB) {
		let pageChanges = [];
		for (const item in currentData) {
			for (const check in preData) {
				if (item === check) {
					const checkSplit = check.split("-");

					const findChecking = checkingDB?.find(
						(item) => item?.key.split("-")[1] === checkSplit[1],
					);

					if (findChecking) {
					} else if (
						checkSplit[1].includes("date") ||
						checkSplit[1].includes("Date") ||
						checkSplit[1].includes("period") ||
						checkSplit[1].includes("Period") ||
						checkSplit[1].includes("totalNonQualifyingInterestPaymentCost") ||
						checkSplit[1].includes("totalNonQualifyingRelatedPaymentsCost") ||
						checkSplit[1].includes("nonQualifyingAmountFinancedCost") ||
						checkSplit[1].includes("purposeOfFinancingArrangement") ||
						checkSplit[1].includes("dataGridVessel1")
					) {
					} else {
						let now = currentData[item];
						let before = preData[check];

						if (
							before !== undefined &&
							(before === "01/01/1970" ||
								before === "01 Jan 1970" ||
								before === null ||
								before === "" ||
								before.toString() === "Invalid date")
						) {
							before = now;
						}
						if (now instanceof Date || before instanceof Date) {
							before = moment(before).format("DD/MM/YYYY");
							now = moment(now).format("DD/MM/YYYY");
							if (
								before === "01/01/1970" ||
								before === "01 Jan 1970" ||
								before === null ||
								before === "" ||
								before.toString() === "Invalid date"
							) {
								before = now;
							}
						}
						if (now instanceof Array || before instanceof Array) {
						} else if (before !== now) {
							if (item.split("-")[1].includes("uploadFile")) {
								const newName =
									item.split("-")[0] + "-" + component[item].label;

								changes.push(newName);
								pageChanges.push(newName);
							} else {
								changes.push(item);
								pageChanges.push(item);
							}
						}
					}
				}
			}

			if (item.split("-")[1].includes("uploadFile")) {
				const checkingBefore = Object.keys(preData).find((k) => k === item);
				if (!checkingBefore) {
					const newName = item.split("-")[0] + "-" + component[item].label;
					changes.push(newName);
					pageChanges.push(newName);
				}
			}
		}
		return await TrackChanges(pageChanges, theformId);
	}

	let resultComponent;
	let prevData;
	const changes = [];
	let data = [];
	const NewChanges = [];

	function searchElements() {
		const element = document.querySelectorAll("[name]");
		for (const el of element) {
			const attr = el.getAttribute("name");
			if (attr) {
				if (attr.startsWith("data")) {
					let elementValue = el.value;
					if (
						el.name.toString().includes("Date") ||
						el.name.toString().includes("date") ||
						el.name.toString().includes("period") ||
						el.name.toString().includes("Period")
					) {
						elementValue = moment(el.value).format("DD/MM/YYYY");
						if (
							elementValue === "01/01/1970" ||
							elementValue === null ||
							elementValue === "" ||
							elementValue.toString() === "Invalid date"
						) {
							elementValue = null;
						}
					}
					if (el.type.toString() === "checkbox") {
						elementValue = el.checked;
					}
					const theValue = {
						name: el.name,
						nodeName: el.nodeName,
						value: elementValue,
						element: el,
					};
					const check = data.find((item) => item.name === el.name);

					if (check) {
					} else {
						data.push(theValue);
						if (
							el.name
								.toString()
								.includes("[containerNonTax][nonTaxResidentName]")
						) {
							const fileParent =
								el.parentElement.parentElement.parentElement.parentElement
									.parentElement;
							let searchFile = $(fileParent.parentElement).find("a").text();
							const match = searchFile.match(
								/Press to open (.*?)\n|Press to open (.*)/,
							);
							if (match) {
								searchFile = (match[1] || match[2]).trim();
							}
							const fileData = el.name + "-AgreementDocument";

							const fileValue = {
								name: fileData,
								nodeName: "File",
								value: searchFile,
								element: fileParent.parentElement,
							};
							data.push(fileValue);
						}
						if (
							el.name
								.toString()
								.includes(
									"[containerWhyFA3][sgBasedBankOrFinancialInstitutionName]",
								)
						) {
							const fileParent =
								el.parentElement.parentElement.parentElement.parentElement;

							const searchTablefile = fileParent.querySelector(
								`[ref*="datagrid-dataGridAttachmentOfSgBankOrFI-tbody"]`,
							);

							const childLength = searchTablefile.children.length;

							for (let i = 0; i < childLength; i++) {
								const childComp = searchTablefile.children[i];

								const tableFile = $(childComp).find("a").text();

								const changefilename =
									el.name +
									"-" +
									`${i}` +
									"-sgBasedBankOrFinancialInstitutionName";

								const fileValue = {
									name: changefilename,
									nodeName: "File",
									value: tableFile,
									element: childComp,
								};
								data.push(fileValue);
							}
						}
					}
				}
			}
		}

		const checkBoxReason = document.querySelector(`[id*="-r1"]`);

		if (
			checkBoxReason &&
			checkBoxReason.getAttribute("name").includes("containerReasonFA")
		) {
			for (let i = 1; i < 6; i++) {
				const theId = `-r${i}`;

				const checkBoxReason = document.querySelector(`[id*=${theId}]`);

				if (checkBoxReason) {
					const theValueBox = {
						name: checkBoxReason.id,
						nodeName: checkBoxReason.nodeName,
						value: checkBoxReason.checked,
						element: checkBoxReason,
					};
					data.push(theValueBox);
				}
			}
		}

		const selecttheBox = document.querySelector(`[id*="-a1"]`);

		if (
			selecttheBox &&
			selecttheBox.getAttribute("name").includes("selectBoxesQFA")
		) {
			for (let i = 1; i < 10; i++) {
				const theId = `-a${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);
				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
		} else if (
			selecttheBox &&
			selecttheBox
				.getAttribute("name")
				.includes("selectBoxesStrategicManagement")
		) {
			for (let i = 1; i < 4; i++) {
				const theId = `-a${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);
				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
			for (let i = 1; i < 7; i++) {
				const theId = `-b${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);
				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
			for (let i = 1; i < 6; i++) {
				const theId = `-t${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);
				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
			for (let i = 1; i < 5; i++) {
				const theId = `-r${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);
				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
		}

		return "success";
	}

	async function searchElementsSave(checkingDB) {
		let newData = [];
		const element = document.querySelectorAll("[name]");
		for (const el of element) {
			const attr = el.getAttribute("name");
			if (attr) {
				if (attr.startsWith("data")) {
					const check = data.find((item) => item.name === el.name);
					const findChecking = checkingDB?.find((i) => i.key === el.name);
					let elementValue = el.value;
					if (
						el.name.toString().includes("Date") ||
						el.name.toString().includes("date") ||
						el.name.toString().includes("period") ||
						el.name.toString().includes("Period")
					) {
						elementValue = moment(el.value).format("DD/MM/YYYY");
						if (
							elementValue === "01/01/1970" ||
							elementValue === null ||
							elementValue === "" ||
							elementValue.toString() === "Invalid date"
						) {
							elementValue = null;
						}
					}
					if (el.type.toString() === "checkbox") {
						elementValue = el.checked;
					}

					const theValue = {
						name: el.name,
						nodeName: el.nodeName,
						value: elementValue,
						element: attr,
					};

					if (
						el.name.toString().includes("selectBoxesQFA_") ||
						el.name.toString().includes("[selectBoxesOtherCondition]") ||
						el.name.toString().includes("[selectBoxUndertakings]") ||
						el.name.toString().includes("[selectBoxesStrategicManagement]") ||
						el.name.toString().includes("[selectBoxesCommercialManagement]") ||
						el.name.toString().includes("[selectBoxesTechnicalManagement]") ||
						el.name.toString().includes("[selectBoxPreDelivery]")
					) {
					} else if (findChecking) {
					} else {
						if (check && check.value !== elementValue) {
							// NewChanges.push(theValue)
							newData.push(theValue);
						} else if (check === undefined || !check) {
							if (el.type.toString() === "checkbox") {
								if (el.checked === true) {
									newData.push(theValue);
								} else {
								}
							} else {
								// NewChanges.push(theValue)
								newData.push(theValue);
								if (
									el.name
										.toString()
										.includes("[containerNonTax][nonTaxResidentName]")
								) {
									const fileParent =
										el.parentElement.parentElement.parentElement.parentElement
											.parentElement;
									let searchFile = $(fileParent.parentElement).find("a").text();
									const match = searchFile.match(
										/Press to open (.*?)\n|Press to open (.*)/,
									);
									if (match) {
										searchFile = (match[1] || match[2]).trim();
									}
									const fileData = el.name + "-AgreementDocument";
									const fileValue = {
										name: fileData,
										nodeName: "File",
										value: searchFile,
										element: attr,
									};
									newData.push(fileValue);
								}
							}
						}
					}

					if (
						el.name.toString().includes("[containerNonTax][nonTaxResidentName]")
					) {
						const parentComp =
							el.parentElement.parentElement.parentElement.parentElement
								.parentElement;
						let FileName = $(parentComp.parentElement).find("a").text();
						const match = FileName.match(
							/Press to open (.*?)\n|Press to open (.*)/,
						);
						if (match) {
							FileName = (match[1] || match[2]).trim();
						}
						const dataName = el.name + "-AgreementDocument";
						const checkFile = data.find((item) => item.name === dataName);
						const fileDBChecking = checkingDB?.find((i) => i.key === dataName);
						if (fileDBChecking) {
						}
						if (checkFile && checkFile.value !== FileName) {
							const fileValue = {
								name: dataName,
								nodeName: "File",
								value: FileName,
								element: attr,
							};
							newData.push(fileValue);
						}
					}

					if (
						el.name
							.toString()
							.includes("[containerFinancingAgreementSigned][uploadFile]")
					) {
						const parentComp =
							el.parentElement.parentElement.parentElement.parentElement
								.parentElement;
						let FileName = $(parentComp.parentElement).find("a").text();
						const match = FileName.match(
							/Press to open (.*?)\n|Press to open (.*)/,
						);
						if (match) {
							FileName = (match[1] || match[2]).trim();
						}
						const dataName = el.name + "-AgreementDocument";
						const checkFile = data.find((item) => item.name === dataName);
						const fileDBChecking = checkingDB?.find((i) => i.key === dataName);
						if (fileDBChecking) {
						}
						if (checkFile && checkFile.value !== FileName) {
							const fileValue = {
								name: dataName,
								nodeName: "File",
								value: FileName,
								element: attr,
							};
							newData.push(fileValue);
						}
					}

					if (
						el.name
							.toString()
							.includes(
								"[containerWhyFA3][sgBasedBankOrFinancialInstitutionName]",
							)
					) {
						const fileParent =
							el.parentElement.parentElement.parentElement.parentElement;
						const searchTablefile = fileParent.querySelector(
							`[ref*="datagrid-dataGridAttachmentOfSgBankOrFI-tbody"]`,
						);

						const childLength = searchTablefile.children.length;

						for (let i = 0; i < childLength; i++) {
							const childComp = searchTablefile.children[i];
							const tableFile = $(childComp).find("a").text();
							const changefilename =
								el.name +
								"-" +
								`${i}` +
								"-sgBasedBankOrFinancialInstitutionName";
							const checkFile = data.find(
								(item) => item.name === changefilename,
							);
							const fileDBChecking = checkingDB?.find(
								(i) => i.key === changefilename,
							);
							if (fileDBChecking) {
							}
							if (checkFile && checkFile.value !== tableFile) {
								const fileValue = {
									name: changefilename,
									nodeName: "File",
									value: tableFile,
									element: changefilename,
								};
								newData.push(fileValue);
							} else if (checkFile === undefined || !checkFile) {
								const fileValue = {
									name: changefilename,
									nodeName: "File",
									value: tableFile,
									element: changefilename,
								};
								newData.push(fileValue);
							}
						}
					}
				}
			}
		}

		const checkBoxReason = document.querySelector(`[id*="-r1"]`);
		if (
			checkBoxReason &&
			checkBoxReason.getAttribute("name").includes("containerReasonFA")
		) {
			for (let i = 1; i < 6; i++) {
				const searchselectBox = document.querySelector(`[id*="-r${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						// NewChanges.push(theValue)
						newData.push(theValueBox);
					}
				}
			}
		}

		const selectBox = document.querySelector(`[id*="-a1"]`);
		if (
			selectBox &&
			selectBox.getAttribute("name").includes("selectBoxesQFA")
		) {
			for (let i = 1; i < 10; i++) {
				const searchselectBox = document.querySelector(`[id*="-a${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id,
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						// NewChanges.push(theValue)
						newData.push(theValueBox);
					}
				}
			}
		} else if (
			selectBox &&
			selectBox.getAttribute("name").includes("selectBoxesStrategicManagement")
		) {
			for (let i = 1; i < 4; i++) {
				const searchselectBox = document.querySelector(`[id*="-a${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						// NewChanges.push(theValue)
						newData.push(theValueBox);
					}
				}
			}
			for (let i = 1; i < 7; i++) {
				const searchselectBox = document.querySelector(`[id*="-b${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						// NewChanges.push(theValue)
						newData.push(theValueBox);
					}
				}
			}
			for (let i = 1; i < 6; i++) {
				const searchselectBox = document.querySelector(`[id*="-t${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						// NewChanges.push(theValue)
						newData.push(theValueBox);
					}
				}
			}
			for (let i = 1; i < 5; i++) {
				const searchselectBox = document.querySelector(`[id*="-r${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						// NewChanges.push(theValue)
						newData.push(theValueBox);
					}
				}
			}
		}

		// const posting = await NamePropertyTrackChanges(NewChanges, theformId)
		const posting = await NamePropertyTrackChanges(newData, theformId);
		return posting;
	}

	let allTitle;

	//assign a variable to the form
	const formReady = async (form) => {
		$(async function () {
			//form.io built-in (standard)
			setFetchingform(false);
			currentForm = form;
			currentForm.nosubmit = true;
			setThisForm(currentForm);
			const getAllTitle = getTitle(currentForm, theformId, "WHTCAppealForm");
			allTitle = getAllTitle;

			// Fetch form, so that data are populated correctly.
			if (_id) {
				currentForm.data = appFormJson.current.data;
				//Borrowers Details
				//-- Element after index 0: Modifiable (update)
				let borrowersDetail = Utils.searchComponents(currentForm.components, {
					type: "textfield",
				});
				borrowersDetail.forEach((borrower) => {
					if (borrower.key === "borrowerName") borrower.disabled = false;
					else if (borrower.key === "uniqueEntityNumber")
						borrower.disabled = false;
					else if (borrower.key === "postalCode") borrower.disabled = false;
					else if (borrower.key === "block") borrower.disabled = false;
					else if (borrower.key === "street") borrower.disabled = false;
					else if (borrower.key === "floor") borrower.disabled = false;
					else if (borrower.key === "unit") borrower.disabled = false;
					else if (borrower.key === "buildingName") borrower.disabled = false;
				});

				try {
					//Swaps checkbox validation
					SwapsCheckbox(currentForm);
				} catch (e) {}
			} else {
				if (parentSubmissionType == "main") {
					//--"TAKE THE EXISTING DATA FROM THE CONTAINER MAIN";
					// var main_info = await fetch(`${config.appURL}/api/application/sdcontainermain2/submission/${mainSubmissionId}`, 'GET', null)
					//   .then((json) => {
					//     return json.data.data;
					//   });
					main_info = appFormJson.current.data;
					//Borrowers Details
					//-- Element after index 0: Modifiable (update)
					let borrowers_Detail = Utils.searchComponents(
						currentForm.components,
						{ type: "textfield" },
					);
					borrowers_Detail.forEach((borrower) => {
						if (borrower.key === "borrowerName") borrower.disabled = false;
						else if (borrower.key === "uniqueEntityNumber")
							borrower.disabled = false;
						else if (borrower.key === "postalCode") borrower.disabled = false;
						else if (borrower.key === "block") borrower.disabled = false;
						else if (borrower.key === "street") borrower.disabled = false;
						else if (borrower.key === "floor") borrower.disabled = false;
						else if (borrower.key === "unit") borrower.disabled = false;
						else if (borrower.key === "buildingName") borrower.disabled = false;
					});
				} else {
					cr_info = await fetch(
						`${config.appURL}/api/application/GetLatestApproveMainORCRSubmissionBySubmissionId/${parentSubmissionId}`,
						"GET",
						null,
					).then((json) => {
						return json.data.data;
					});
				}

				try {
					if (main_info) {
						//"Data from MAIN"

						currentForm.data = main_info;

						currentForm.submission.data["mainFormId"] = mainSubmissionId;
						currentForm.submission.data["schemaExtension"] = 8;
						currentForm.submission.data.configContainer["applicationCaseId"] =
							""; //Clear the main form case Id
						// currentForm.submission.data.configContainer['companyInvolved'] = main_info?.applicantName;

						existingEntityType =
							main_info.containerParticulars.containerBorrower["entityType"];
						whtExemption_startDate = moment(
							main_info.containerQualifyingDetails.containerQualifyingPeriod[
								"effectiveDateOfWHTExemption"
							],
						).format("DD-MMM-YYYY");
						whtExemption_endDate = moment(
							main_info.containerQualifyingDetails.containerQualifyingPeriod[
								"endDate"
							],
						).format("DD-MMM-YYYY");

						//reset declaration
						currentForm.submission.data.containerDeclarationAndUndertaking[
							"DeclarationAndAgreement"
						] = false;
						currentForm.submission.data.containerDeclarationAndUndertaking.selectBoxUndertakings = false;
						currentForm.submission.data["cpkName"] = "";
						currentForm.submission.data["cpkEmail"] = "";
						currentForm.submission.data["designation"] = "";
						currentForm.submission.data["contactNo"] = "";
						currentForm.submission.data["agreeToAll"] = false;

						//Swaps checkbox validation
						SwapsCheckbox(currentForm);
					} else {
						//"Data from CR"
						currentForm.data = cr_info;

						//Swaps checkbox validation
						SwapsCheckbox(currentForm);
					}
				} catch (e) {}
			}

			if (getStatus === "All") {
				setStatus("draft");
				prevStatus = "draft";
			} else {
				const statusTextCurrent = getStatusText(
					currentForm.submission.data.configContainer.publicApplicationStatus,
				);
				setStatus(statusTextCurrent);
				prevStatus = statusTextCurrent;
			}

			// below 1 is draft status, 9 is returned status
			// only draft and returned back-end statuses can edit contents of this form
			if (
				currentForm.submission.data.configContainer.publicApplicationStatus !==
					1 &&
				currentForm.submission.data.configContainer.publicApplicationStatus !==
					9 &&
				currentForm.submission.data.configContainer.applicationStatus !== "1" &&
				currentForm.submission.data.configContainer.applicationStatus !== "9" &&
				_id !== null
			) {
				canSubmit = false;
				submitID_Ref.current = createdData._id;
				form_ref.current = currentForm;
				goToReview();
			}

			//declaration
			declarationHTMLTEST =
				currentForm.submission.data.containerDeclarationAndUndertaking
					.textAreaDeclaration;
			populateDeclarationAndUndertaking();

			// Applicant information
			let applicant = loginHelper.getLoginUser();
			if (
				applicant?.Name !== currentForm.submission.data["contactName"] ||
				currentForm.submission.data["contactName"] === ""
			) {
				currentForm.submission.data.configContainer["usersInvolved"] =
					applicant?.Name;
				currentForm.submission.data.configContainer["companyInvolved"] =
					applicant?.CompanyName;
				currentForm.submission.data.configContainer["applicationCaseId"] = "";
				currentForm.submission.data["contactName"] = applicant?.Name;
				currentForm.submission.data["contactEmail"] = applicant?.Email;
			}

			CommonPopulateQuestionEDH(currentForm, selected, applicant.UEN);

			if (prevStatus === "Returned" && theformId !== null) {
				resultComponent = flatComponent(currentForm);
			}

			if (createdData._id) {
				submitID_Ref.current = createdData._id;
			}

			// Event Listener: Execute this event inside when you change something in a component
			currentForm.on("change", (changed) => {
				if (changed.changed?.component.key === "effectiveDateOfWHTExemption") {
					whtExemption_startDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				} else if (changed.changed?.component.key === "periodOfFinancingTo") {
					whtExemption_endDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				} else if (changed.changed?.component.key === "endDate") {
					whtExemption_endDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				}

				//--Event: Input changed for Borrower DataGrid (in SHIP form)
				else if (changed.changed?.component.key === "dataGridBorrower") {
					// Hide the css of datagridBorrower first delete btn
					removeBorrowerFirstDeleteButton();
				}

				//-- Event: Input changed for Date of Transfer cert (in SHIP form)
				else if (changed.changed?.component.key === "dateOfTransferCert") {
					if (
						currentForm.submission.data.containerNonTax.containerLender
							.dateOfTransferCert === "" ||
						currentForm.submission.data.containerNonTax.containerLender
							.dateOfTransferCert === null
					) {
						currentForm.submission.data.containerNonTax.containerLender.totalAmountTransferred =
							"";
					}

					const totalAmountTransferredComp = currentForm.getComponent(
						"totalAmountTransferred",
					);
					totalAmountTransferredComp.triggerChange();
					totalAmountTransferredComp.triggerRedraw();
				}

				//-- Event: Input changed for Date of Signed Agreement
				else if (
					changed.changed?.component.key ===
					"dateOfSignedAgreementTransferCertificate"
				) {
					//[1] Period Of Financing From
					const periodOfFinancingFromComp = currentForm.getComponent(
						"periodOfFinancingFrom",
					);
					let datePeriod = moment(
						currentForm.submission.data.containerNonTax.containerLender
							.periodOfFinancingFrom ?? 0,
					);
					let datePeriodMinDate = moment(
						periodOfFinancingFromComp.component.datePicker.minDate ?? 0,
					);

					// Handle reset period of financing from
					if (datePeriodMinDate !== 0 && datePeriod !== 0) {
						if (datePeriod < datePeriodMinDate) {
							currentForm.submission.data.containerNonTax.containerLender.periodOfFinancingFrom =
								null;
							periodOfFinancingFromComp.triggerChange();
							periodOfFinancingFromComp.triggerRedraw();
						}
					}

					DateTransferCertContainer(currentForm);

					//[2] Date of Earilest Qualifying Payment
					const dateEarliestQualifyingPaymentComp = currentForm.getComponent(
						"dateEarliestQualifyingPayment",
					);
					let dateEarliestQualifying = moment(
						currentForm.data.containerQualifyingDetails
							.containerQualifyingPayment.dateEarliestQualifyingPayment ?? 0,
					);
					let datedateEarliestQualifyingMinDate = moment(
						dateEarliestQualifyingPaymentComp.component.datePicker.minDate ?? 0,
					);

					// Handle reset period of financing from
					if (
						datedateEarliestQualifyingMinDate !== 0 &&
						dateEarliestQualifying !== 0
					) {
						if (dateEarliestQualifying < datedateEarliestQualifyingMinDate) {
							currentForm.submission.data.containerQualifyingDetails.containerQualifyingPayment.dateEarliestQualifyingPayment =
								null;
							dateEarliestQualifyingPaymentComp.triggerChange();
							dateEarliestQualifyingPaymentComp.triggerRedraw();
						}
					}
				} else if (
					changed.changed?.component.key === "totalAmountFinanced" ||
					changed.changed?.component.key === "totalAmountTransferred"
				) {
					CommonUpdateValidation_TotalNonQualifyingAmountFinanced(currentForm);
				} else if (
					changed.changed?.component.key ===
						"totalNonQualifyingInterestPaymentCost" ||
					changed.changed?.component.key ===
						"totalNonQualifyingRelatedPaymentsCost"
				) {
					CommonUpdateValidation_NonQualifyingAmountFinancedCost(currentForm);
				} else if (
					changed.changed?.component.key === "selectBoxesQFA_1" ||
					changed.changed?.component.key === "selectBoxesQFA_2" ||
					changed.changed?.component.key === "selectBoxesQFA_3"
				) {
					CommonUpdateValidation_TypeOfFinancingArrangementObtained(
						currentForm,
					);
				}
				CommonHandleWHTContainerDate(currentForm, changed);
				GenericFileUploadOnChange(changed, false, currentForm, setStartSpinner);
			});

			getPreData();
			// searchElements()

			//--------------------------------------------------------//
			// Handle Events
			//--------------------------------------------------------//

			//THIS IS USING CUSTOM WIZARD CONTROLS (FOR WIZARD FORMS ONLY):
			//[1] In button component (Form.IO):
			//    (a) set Action = Event, Button Event = prevPage , match the name else it won't work

			//-- Event: Press prev page button, create/overwrite current form and navigate to prev page
			currentForm.on("prevPage", async () => {
				setOpenAlert();
				setStartSpinner(true);
				const sendData = await getPrevData();

				if (sendData !== "noSave") {
					// Create/Overwrite current form data
					const responseStatus = await createOrSave(
						"draft",
						currentForm,
						alreadySubmitted,
						createdData,
						saveCreatedData,
						createURL,
						appFormBaseURL,
						formId,
					);
					if (responseStatus.status === 200 || responseStatus.status === 201) {
						setStartSpinner(false);
						// Navigate to prev page of current form
						currentForm.setPage(currentForm.page - 1);
					} else {
						setStartSpinner(false);
						//pop up and show error message
						checkErrorStatusAndOpenErrorStatusDialog();
					}
				} else {
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}

				if (prevStatus === "Returned") {
					getPreData();
				}
			});

			//-- Event: Press next page button, create/overwrite current form and navigate to next page
			currentForm.on("nextPage", async () => {
				$('button:contains("Next")').hide();
				setOpenAlert();
				setStartSpinner(true);
				const sendData = await getPrevData();

				if (sendData !== "noSave") {
					// Create/Overwrite current form data
					const responseStatus = await createOrSave(
						"draft",
						currentForm,
						alreadySubmitted,
						createdData,
						saveCreatedData,
						createURL,
						appFormBaseURL,
						formId,
					);

					// Only if ^ action is successful
					if (responseStatus.status === 200 || responseStatus.status === 201) {
						$('button:contains("Next")').show();
						setStartSpinner(false);
						// Navigate to next page of current form
						currentForm.setPage(currentForm.page + 1);
						submitID_Ref.current = createdData._id;
						if (responseStatus.message.data.configContainer.applicationCaseId) {
							setCaseID(
								responseStatus.message.data.configContainer.applicationCaseId,
							);
							reviewCaseID =
								responseStatus.message.data.configContainer.applicationCaseId;
						}
					} else {
						$('button:contains("Next")').show();
						setStartSpinner(false);
						// pop up and show error message
						checkErrorStatusAndOpenErrorStatusDialog();
					}
				} else {
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}

				if (prevStatus === "Returned") {
					getPreData();
				}
			});

			currentForm.on("draftSave", async () => {
				CommonHandleAllDropdownListMetadataFix_WHTContainer(
					currentFormref.current,
				);
				$('button:contains("Next")').hide();
				setStartSpinner(true);

				const savingDraft = await createOrSave(
					"draft",
					currentForm,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);

				if (savingDraft.status === 200 || savingDraft.status === 201) {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					setOpenAlert("Draft");
					setOpen(true);
				} else {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}
			});

			//-- Event: Press review button, overwrite current form data then validate the form fields, then navigate to review page
			currentForm.on("review", async () => {
				CommonHandleAllDropdownListMetadataFix_WHTContainer(
					currentFormref.current,
				);

				$('button:contains("Next")').hide();
				if (allowScrolling) {
					window.scrollTo(0, 0);
				} else {
					setScrollState(() => (allowScrolling = true));
				}

				setStartSpinner(true);

				const savingChangesData = await getPrevData();

				const responseStatus = await createOrSave(
					"draft",
					currentForm,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);

				// Only if ^ action is successful
				if (
					(responseStatus.status === 200 || responseStatus.status === 201) &&
					savingChangesData !== "noSave"
				) {
					$('button:contains("Next")').show();
					// Check if valid, go to review page, if not, show dialog to feedback, there are still so errors
					submitID_Ref.current = createdData._id;
					validityTriggered = true;
					let valCheck = checkValidity(); //REFER TO DOCS -> checkValidity
					if (valCheck === false) {
						form_ref.current = currentForm;
						setOpenValidationDialog(true);
						setStartSpinner(false);
						getPreData();
					} else {
						form_ref.current = currentForm;
						goToReview();
					}
				} else {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}
			});

			//-- Event: Press cancel button, then navigate to Application Listing page
			currentForm.on("cancelPage", () => {
				history.push({
					pathname: "/NestedApplicationListing",
					search: `?schema=3`,
					refresh: true,
				});
			});

			$(function () {
				$(".page-link").on("click", async (event) => {
					const findTitleIndex = allTitle.indexOf(
						event.currentTarget.outerText,
					);

					if (parseInt(currentForm.page) === 0) {
					} else {
						setOpenAlert();
						setStartSpinner(true);
						// getPrevData();
						const sendData = await getPrevData();

						if (sendData !== "noSave") {
							//-- Save/overwrite
							const response = await createOrSave(
								"draft",
								currentForm,
								alreadySubmitted,
								createdData,
								saveCreatedData,
								createURL,
								appFormBaseURL,
								formId,
							);

							if (response.status === 200 || response.status === 201) {
								setStartSpinner(false);
								currentForm.setPage(findTitleIndex);
							} else {
								setStartSpinner(false);
								// pop up and show error message
								checkErrorStatusAndOpenErrorStatusDialog();
							}
						} else {
							setStartSpinner(false);
							// pop up and show error message
							checkErrorStatusAndOpenErrorStatusDialog();
						}

						if (prevStatus === "Returned") {
							getPreData();
						}
					}
				});
			});

			//  Change Data Grid Add Button
			Utils.searchComponents(form.components, { type: "datagrid" }).forEach(
				(instance) => {
					if (instance?.component) {
						instance.component.addAnother = "Add On";
					}
				},
			);

			currentForm.on("fileUploadingStart", async () => {
				setStartSpinner(true);
			});
			currentForm.on("fileUploadingEnd", async () => {
				setStartSpinner(false);
			});

			handleConvertAllUTCDateToLocalDate();
			currentFormref.current = currentForm;
		});
	};

	const handleConvertAllUTCDateToLocalDate = () => {
		let showDebugDate = false; // Dont delete this variable, let it stay, it's for debugging. Set it to true if u want debug, set it to false to use realtime data
		let testDate = "2023-02-14T00:00:00+08:00";

		//--------------------------------------------------------------------------------
		// Handle All [Datagrids]->[Date/Time Component] here
		//--------------------------------------------------------------------------------
		// Handle Section 1
		{
			// Dategrid->Details of Non-Tax Resident(s) and the Agreement
			{
				if (
					currentForm.submission.data.containerNonTax?.containerNonTaxResident
						?.dataGridNonTaxResident !== undefined &&
					currentForm.submission.data.containerNonTax?.containerNonTaxResident
						?.dataGridNonTaxResident !== null
				) {
					let size =
						currentForm.submission.data.containerNonTax?.containerNonTaxResident
							.dataGridNonTaxResident?.length ?? 0;
					for (let i = 0; i < size; ++i) {
						let index = i.toString();

						// Details of Non-Tax Resident(s) and the Agreement -> Date Of Signed Agreement
						let dateUTC =
							currentForm.submission.data["containerNonTax"][
								"containerNonTaxResident"
							]["dataGridNonTaxResident"][index]["containerNonTax"][
								"dateOfSignedAgreement"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerNonTax"][
								"containerNonTaxResident"
							]["dataGridNonTaxResident"][index]["containerNonTax"][
								"dateOfSignedAgreement"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					//-- Update formIO side to trigger change event and redraw ui event
					const dgComp = currentForm.getComponent("dataGridNonTaxResident");
					dgComp.triggerChange();
					dgComp.triggerRedraw();
				}
			}
		}

		//--------------------------------------------------------------------------------
		// Handle All "NON"->[Datagrids]->[Date/Time Component] here
		//--------------------------------------------------------------------------------
		// Handle Section 2
		{
			//-- [Date/Time Component] Handle Date Of Signed Agreement
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfSignedAgreementTransferCertificate !== undefined &&
				currentForm.submission.data.containerNonTax.containerLender
					?.dateOfSignedAgreementTransferCertificate !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfSignedAgreementTransferCertificate"
					];
				let datePropertyName = "dateOfSignedAgreementTransferCertificate";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfSignedAgreementTransferCertificate"
					] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
			//-- [Date/Time Component] Handle Date of Transfer Certificate
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfTransferCert !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfTransferCert !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfTransferCert"
					];
				let datePropertyName = "dateOfTransferCert";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfTransferCert"
					] = showDebugDate ? testDate : dateLocal;
				}

				let selectedComponent = currentForm.getComponent(datePropertyName);
				if (selectedComponent) {
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
			//-- [Date/Time Component] Handle Period of Financing (From)
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingFrom !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingFrom !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingFrom"
					];
				let datePropertyName = "periodOfFinancingFrom";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingFrom"
					] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
			//-- [Date/Time Component] Handle Period of Financing (To)
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingTo !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingTo !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingTo"
					];
				let datePropertyName = "periodOfFinancingTo";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingTo"
					] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			if (
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredFrom !== undefined &&
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredFrom !== null
			) {
				//-- [Date/Time Component] Handle Period Covered (From)
				{
					let dateUTC =
						currentForm.submission.data["containerNonTax"][
							"containerNonTaxResident"
						]["datePeriodCoveredFrom"];
					let datePropertyName = "datePeriodCoveredFrom";
					if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
						let dateLocal = new Date(dateUTC);
						let selectedComponent = currentForm.getComponent(datePropertyName);
						currentForm.submission.data["containerNonTax"][
							"containerNonTaxResident"
						]["datePeriodCoveredFrom"] = showDebugDate ? testDate : dateLocal;
						selectedComponent.triggerChange();
						selectedComponent.triggerRedraw();
					}
				}

				//-- [Date/Time Component] Handle Period Covered (To)
				if (
					currentForm.submission.data.containerNonTax?.containerNonTaxResident
						?.datePeriodCoveredTo !== undefined &&
					currentForm.submission.data.containerNonTax?.containerNonTaxResident
						?.datePeriodCoveredTo !== null
				) {
					let dateUTC =
						currentForm.submission.data["containerNonTax"][
							"containerNonTaxResident"
						]["datePeriodCoveredTo"];
					let datePropertyName = "datePeriodCoveredTo";
					if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
						let dateLocal = new Date(dateUTC);
						let selectedComponent = currentForm.getComponent(datePropertyName);
						currentForm.submission.data["containerNonTax"][
							"containerNonTaxResident"
						]["datePeriodCoveredTo"] = showDebugDate ? testDate : dateLocal;
						selectedComponent.triggerChange();
						selectedComponent.triggerRedraw();
					}
				}
			}
		}

		// Handle Section 3
		{
			//-- [Date/Time Component] Handle Date of earliest relevant qualifying payment made
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPayment?.dateEarliestQualifyingPayment !==
					undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPayment?.dateEarliestQualifyingPayment !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPayment"
					]["dateEarliestQualifyingPayment"];
				let datePropertyName = "dateEarliestQualifyingPayment";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPayment"
					]["dateEarliestQualifyingPayment"] = showDebugDate
						? testDate
						: dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Date of Effective date of WHT exemption
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.effectiveDateOfWHTExemption !==
					undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.effectiveDateOfWHTExemption !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["effectiveDateOfWHTExemption"];
				let datePropertyName = "effectiveDateOfWHTExemption";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["effectiveDateOfWHTExemption"] = showDebugDate
						? testDate
						: dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Date of End date
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.endDate !== undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.endDate !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["endDate"];
				let datePropertyName = "endDate";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["endDate"] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
		}
	};

	const populateQ1 = (currentForm, selected) => {
		let r;
		switch (selected) {
			case "SRS":
				r = "r1";
				break;
			case "MSI-AIS-SRS":
				r = "r2";
				break;
			case "MSI-ML-Ship":
				r = "r3";
				break;
			case "MSI-ML-Container":
				r = "r4";
				break;
			default:
				return null;
		}
		currentForm.submission.data.containerParticulars.containerBorrower.entityType =
			r;

		// currentForm.redraw(); // Only need to re-render stuff if you are re-rendering it at the same page
	};

	const populateDeclarationAndUndertaking = () => {
		whtExemption_startDate =
			moment(
				currentForm.submission.data.containerQualifyingDetails
					.containerQualifyingPeriod["effectiveDateOfWHTExemption"],
			)?.format("DD-MMM-YYYY") ?? "START_DATE_IS_EMPTY";
		whtExemption_endDate =
			moment(
				currentForm.submission.data.containerQualifyingDetails
					.containerQualifyingPeriod["endDate"],
			)?.format("DD-MMM-YYYY") ?? "END_DATE_IS_EMPTY";

		//-- Display the Main Borrower in Declaration page
		let getMainBorrower = "";

		for (const element of currentForm.submission.data.containerParticulars
			.containerBorrower.dataGridBorrower) {
			getMainBorrower =
				currentForm.submission.data.containerParticulars.containerBorrower
					.dataGridBorrower[0].containerCompanyRegisteredAddress.borrowerName;
		}

		let text = `(a) The WHT exemption is granted with effective from ${whtExemption_startDate} to ${whtExemption_endDate}\n\n(b) The information given or attached in the form is true and correct and that the WHT exemption is granted based on the representations made in this form. Should there be:\n\n     (i) any false or incorrect declaration of information; or\n\n     (ii) any change in material information [e.g. terms and conditions of financing agreement(s), sell down of loan, etc],\n\n    the borrower, ${getMainBorrower} is required to re-submit a new declaration form immediately.  \n\nWhere the qualifying conditions are not met, the WHT exemption granted will be withdrawn with immediate effect and the Government reserves the right to recover the taxes that should be withheld to account for any false or incorrect declarations made or material change in the information provided.  \n\n<b>Under the Singapore Income Tax Act 1947, there are penalties for making a false or incorrect declaration.</b>`;

		currentForm.submission.data.containerDeclarationAndUndertaking.textAreaDeclaration =
			text;

		const textDeclaration = currentForm.getComponent("textAreaDeclaration");
		textDeclaration.triggerChange();
		textDeclaration.triggerRedraw();
	};

	//[End of populate]----------------------------------------------------------------------//

	const handleCustomRenderEvent = () => {
		//-- Ensure borrower's datagrid->first element->delete button is always hidden
		removeBorrowerFirstDeleteButton();

		//-- To update the list of borrowers to be displayed in the declaration page
		updateBorrowersInDeclaration();

		//-- JQuery to overwrite file component functionality to allow file download and delete with JWT Token
		GenericFileUploadOnRender(false, currentForm, setStartSpinner);
	};

	const removeBorrowerFirstDeleteButton = () => {
		//-- Temporary solution: Ensure borrower datagrid first element delete button is always hidden
		// Only run at page 1 where the borrower datagrid exist in.
		if (currentForm.page == 1) {
			let listOfDataGridBorrower_td = $(
				".formio-component-dataGridBorrower",
			).find("td");
			if (listOfDataGridBorrower_td) {
				// >= 5 means it will have more than 2 entries in the datagrid (hardcoded for now),
				if (listOfDataGridBorrower_td.length >= 5) {
					// the delete html element is located in 2nd <td/>, hence we access listOfDataGridBorrower_td[1]
					listOfDataGridBorrower_td[1].hidden = true;
				}
			}
		}
	};

	const updateBorrowersInDeclaration = () => {
		if (currentForm.page == 5) {
			populateDeclarationAndUndertaking();
		}
	};

	const onRender = async () => {
		if (allowScrolling) window.scrollTo(0, 0);
		else setScrollState(() => (allowScrolling = true));

		const rendering = await onRenderGeneric(
			setPage,
			currentForm,
			alreadySubmitted,
			createdData,
			saveCreatedData,
			createURL,
			appFormBaseURL,
			formId,
			prevStatus,
		);
		$("ol > li:not(.MuiBreadcrumbs-li)").css("list-style", "lower-alpha");
		$("ol > li > ol > li:not(.MuiBreadcrumbs-li)").css(
			"list-style",
			"lower-roman",
		);
		setTimeout(() => {
			checkValidity();
		}, 0);
		$(function () {
			handleCustomRenderEvent();
		});
		if (rendering === "ok") {
			if (prevStatus === "Returned" || prevStatus === "returned") {
				setStartSpinner(true);
				$('button:contains("Next")').hide();
				$('button:contains("Back")').hide();
				$('button:contains("Save as Draft")').hide();
				$('button:contains("Review")').hide();
			}
		}
	};

	function getPreData() {
		if (prevStatus === "Returned" && theformId !== null) {
			const preData = scanData(resultComponent);
			const getSearch = searchElements();
			if (preData && getSearch) {
				prevData = preData;
				setTimeout(() => {
					$('button:contains("Next")').show();
					$('button:contains("Back")').show();
					$('button:contains("Review")').show();
					setStartSpinner(false);
				}, 1000);
			}
		}
	}

	async function getPrevData() {
		resultComponent = flatComponent(currentForm);
		const latestData = scanData(resultComponent);

		if (prevStatus === "Returned" && theformId !== null) {
			// await getChanges(resultComponent, latestData, prevData)
			const checkingDB = await getTrackChanges(theformId);
			const searchSave = await searchElementsSave(checkingDB.data.data);
			const TrackChangesSave = await saveChanges(
				resultComponent,
				latestData,
				prevData,
				checkingDB.data.data,
			);
			if (
				[200, 201].includes(searchSave.status) &&
				[200, 201].includes(TrackChangesSave.status)
			) {
				return "ok Success";
			} else {
				return "noSave";
			}
		} else {
			return "no track changes";
		}
	}

	function goToReview() {
		let contactName = form_ref.current._submission.data.contactName;
		history.push({
			pathname: "/WHTContainerAppealReview",
			search: `?_id=${submitID_Ref.current}&status=${prevStatus}`,

			state: {
				user:
					contactName === ""
						? form_ref.current._submission.data.configContainer.usersInvolved
						: contactName,
				company:
					form_ref.current._submission.data.configContainer.companyInvolved,
				caseID: caseID === null || caseID === undefined ? reviewCaseID : caseID,
				prevStatus: prevStatus,
				canSubmit: canSubmit,
			},
			refresh: true,
		});
	}

	const onChange = async (event) => {
		if (
			prevStatus === "Draft" ||
			prevStatus === "draft" ||
			prevStatus === "All" ||
			prevStatus === "all" ||
			prevStatus === null ||
			prevStatus === undefined
		) {
			$(".formio-component-applicantcomment").hide();
		}
		onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
		checkValidity();

		if (
			event.changed?.component.key == "periodOfFinancingTo" &&
			currentForm.submission.data.containerNonTax.containerLender
				.periodOfFinancingTo !== ""
		) {
			currentForm.submission.data.containerQualifyingDetails.containerQualifyingPeriod.endDate =
				currentForm.submission.data.containerNonTax.containerLender.periodOfFinancingTo;
		}

		if (
			event.changed?.component.key === "radioAnyPayment" ||
			event.changed?.component.key ===
				"isThereAnySwapArrangementInTheSameFinancingAgreement"
		) {
			CommonUpdateValidation_QualifyingPayment(
				currentForm,
				event.changed?.component.key,
			);
		}

		setStartSpinner(false);
	};

	const onSubmit = async (submission) => {
		onSubmitGeneric(
			submission,
			currentForm,
			alreadySubmitted,
			createdData,
			saveCreatedData,
			createURL,
			appFormBaseURL,
			formId,
		);
	};

	const onError = async (errors) => {
		onErrorGeneric(errors);
	};

	const onSubmitDone = (submission) => {
		onSubmitDoneGeneric(submission);
	};

	const onCustomEvent = async ({ type, component, data, event }) => {
		// This function is intentionally empty.
	};

	function changePage(x) {
		thisForm.setPage(x);
	}

	function scrollToError() {
		window.scrollTo(0, 0);
	}

	function goToTop() {
		window.scrollTo(0, 0);
		setTimeout(scrollToError, 2000);
	}

	function checkValidity() {
		let valCheck = false;
		if (validityTriggered) {
			valCheck = currentForm.checkValidity(null, true, null, false);
			let oldPagesErrors = pagesErrors;
			currentForm.pages.forEach(
				(x, index) => (oldPagesErrors[index] = x.errors.length),
			);
			setPagesErrors(oldPagesErrors);
			setCurrentPageErrors(pagesErrors[currentForm.page]);
		}
		return valCheck;
	}

	const handleDelete = async () => {
		try {
			if (submitID_Ref.current) {
				const originalPromiseResult = await dispatch(
					deleteFormAPIAsync({
						formId: formId,
						submissionId: submitID_Ref.current,
					}),
				).unwrap();
				history.push({
					pathname: "/NestedApplicationListing",
					search: `?schema=3`,
					refresh: true,
				});
			} else {
				alert("Delete is not allowed");
			}
		} catch (rejectedValueOrSerializedError) {
			alert("Delete is not allowed");
		}
	};

	return (
		<div className="applicationform">
			<ValidationDialog
				open={openValidationDialog}
				setOpen={setOpenValidationDialog}
				goToTop={goToTop}
			/>
			<Container>
				<FormHtmlHead
					formname={formname}
					schema={schema}
				/>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<FormHtmlTitle
						formname={formname}
						formid={formid}
						formstatus={status}
						caseID={caseID}
					/>
				</div>
				<br />
				<PageCarousel
					thisform={thisForm}
					setpage={changePage}
					pagesErrors={pagesErrors}
					status={status}
					removeAssessmentClick={true}
				/>
				<FormHtmlErrors
					thisForm={thisForm}
					pagesErrors={pagesErrors}
					currentPageErrors={currentPageErrors}
				/>

				<FormHtmlForm
					fetchingform={fetchingform}
					formReadOnly={formReadOnly}
					props={props}
					appFormURL={createAppFormURL}
					onChange={onChange}
					onError={onError}
					formReady={formReady}
					onSubmit={onSubmit}
					onSubmitDone={onSubmitDone}
					onRender={onRender}
					onCustomEvent={onCustomEvent}
					isBusy={isBusy}
					appFormJson={appFormJson}
				/>
			</Container>
			<DateValidationDialog
				openDialog={openDateValidDialog}
				setOpenDateValidDialog={setOpenDateValidDialog}
			/>
			<AutoSaveErrorDialog
				open={openErrorStatusDialog}
				setOpen={setOpenErrorStatusDialog}
			/>
			<SnackbarAlert
				alert={openAlert}
				caseID={caseID}
				open={open}
				setOpen={setOpen}
			/>
			<ProgressBar
				ref={ref}
				startSpinner={startSpinner}
				setStartSpinner={setStartSpinner}
				bar={bar}
				setBar={setBar}
			/>
		</div>
	);
}
