import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch, postFormToServer } from '../../Common/API';
import { config } from '../../config';

export const getLetterOfOfferById = createAsyncThunk(
	'Acceptance/getLetterOfOfferById',
	async (loo, {rejectWithValue}) => {
		let url = `${config.appURL}/api/LetterOfOffer/GetBySubmissionId?SubmissionId=${loo.submissionId}`;
		try {
			let response = await fetch(url, 'GET');
			if(response.status === 200)
				return response.data;
			else
				return rejectWithValue("Get Letter Of Offer Error");
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
    }
);

export const getLetterOfOfferPlaceHolders_APIAsync = createAsyncThunk(
	'Acceptance/getLetterOfOfferPlaceHolders_APIAsync',
	async (loo, {rejectWithValue}) => {
		let url = `${config.appURL}/api/Application/idpmainform/submission/${loo.submissionId}`;
		try {
			let response = await fetch(url, 'GET');
			if(response.status === 200)
				return response.data;
			else
				return rejectWithValue("Get Letter Of Offer PlaceHolders Error");
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
    }
);

export const upLoadLetterOfAcceptanceSigned_APIAsync = createAsyncThunk(
	'Acceptance/upLoadLetterOfAcceptanceSigned_APIAsync',
	async (loo, {rejectWithValue}) => {
		let url = `${config.appURL}/api/application/UploadFileAPI`;

		try {
			let response = await postFormToServer(url, loo.formData);
			if(response.status === 200)
				return response.data;
			else
				return rejectWithValue("Upload Error");
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
    }
);

export const putLetterOfAcceptanceSignedToMongoDB_APIAsync = createAsyncThunk(
	'Acceptance/putLetterOfAcceptanceSignedToMongoDB_APIAsync',
	async (loo, {rejectWithValue}) => {
		let url = `${config.appURL}/api/letterofoffer/PutLetterOfOfferLetterOfAcceptanceSigned?applicationSubmissionId=${loo.submissionId}&letterOfAcceptanceSigned=${loo.fileName}`;
		try {
			let response = await fetch(url, 'PUT');
			if(response.status === 200)
				return response.data;
			else
				return rejectWithValue("Upload Error");
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
    }
);

export const acceptLetterOfOfferApplication_APIAsync = createAsyncThunk(
	'Acceptance/acceptLetterOfOfferApplication_APIAsync',
	async (loo, {rejectWithValue}) => {
		let url = `${config.appURL}/api/Application/AcceptLetterOfOfferApplication?submissionId=${loo.submissionId}&formId=${loo.formId}`;
		try {
			let response = await fetch(url, 'GET');
			if(response.status === 200)
				return response.data;
			else
				return rejectWithValue("Accept Error");
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
    }
);

export const rejectLetterOfOfferApplication_APIAsync = createAsyncThunk(
	'Acceptance/rejectLetterOfOfferApplication_APIAsync',
	async (loo, {rejectWithValue}) => {
		let url = `${config.appURL}/api/Application/RejectLetterOfOfferApplication?SubmissionId=${loo.submissionId}&formId=${loo.formId}`;
		try {
			let response = await fetch(url, 'GET');
			if(response.status === 200)
				return response.data;
			else
				return rejectWithValue("Reject Error");
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
    }
);

export const AcceptanceListSlice = createSlice({
  name: 'AcceptanceList',
  initialState: [],
  reducers: {
  },
  extraReducers: {
	[getLetterOfOfferById.fulfilled]: (state, action) => {
		return action.payload;
	},
	[getLetterOfOfferPlaceHolders_APIAsync.fulfilled]: (state, action) => {
		return action.payload;
	},
	[acceptLetterOfOfferApplication_APIAsync.fulfilled]: (state, action) => {
		return action.payload;
	},
	[rejectLetterOfOfferApplication_APIAsync.fulfilled]: (state, action) => {
		return action.payload;
	},
  }
});

export default AcceptanceListSlice.reducer;
