import { config } from '../config'
import {fetch} from './API'

const edhURL = `${config.appURL}/api/edh`

export async function getIDPEDHInfo(uen) {
    try {
        const edh = await fetch(`${edhURL}/GetCompanyDetail?UEN=${uen}&appName=OMP`, 'GET')
            .then((response) => response.data);
        window.sessionStorage.setItem(
            "Started",
            JSON.stringify("false")
        );
        return [edh];
    } catch (e) {
        window.sessionStorage.setItem(
            "Started",
            JSON.stringify("false")
        );
        alert("Unable to retrieve company details");
    }
}
