import { Form, Formio } from '@formio/react';
import { Button, CircularProgress, Container, Paper, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { IconArrowLeft, IconCheckupList } from '@tabler/icons';
import { useState } from 'react';
import '../../ApplicationForm/ApplicationForm.css';
import { useHistory } from "react-router";

IDPEligibilityForm.defaultProps = {
    formname: 'Apply for Industry Digital Plan (IDP)',
    formid: '',
    formstatus: 'Answer a few simple questions to check if you are eligible for this grant.',
    formBaseURL: "https://wpqonetfmqmnudy.form.io",
    formId: "idpeligibility",
    appFormBaseURL: "http://localhost:13285/api/IDP"
}

export default function IDPEligibilityForm({ formname, formid, formstatus, formBaseURL, formId, appFormBaseURL, props }) {
    let appFormURL = `${appFormBaseURL}/${formId}?url=${formBaseURL}`
    let createURL = `${appFormBaseURL}/${formId}/submission?url=${formBaseURL}`
    let currentForm = {};
    let createdData = {};
    let fetch = async (url, method, data) => {
        let response = await Formio.fetch(url, {
            body: JSON.stringify(data),
            headers: {
                'content-type': 'application/json'
            },
            method: method,
            mode: 'cors',
        });
        return response
    }
    const formReady = (form) => {
        setFetchingform(false);
        currentForm = form
        currentForm.nosubmit = true;
        currentForm.on('nextPage', async function (page) {
            await createOrSave();
        });
    };

    const onChange = async (event) => {
        if (event.changed && event.changed.component.key.includes('saveAsDraft')) {
            await createOrSave();
        }
        if (event.changed === undefined) setFetchingform(false);
        if (event.next === true) {handleNext()};
        if (event.back === true) {handleBack()};
    }

    async function createOrSave() {
        if (!createdData._id) { //create
            let response = await fetch(createURL, 'POST', currentForm._submission.data);
            //'creating' - currentForm._submission.data
            if (response.status == 201) {
                createdData = response.json();
            }
        }
        else {
            let updateURL = `${appFormBaseURL}/${formId}/submission/${createdData._id}/?url=${formBaseURL}`;
            await fetch(updateURL, 'PUT', currentForm._submission.data);
        }
    }

    const onSubmit = async (submission) => {
        await createOrSave();
        currentForm.emit('submitDone', currentForm._submission)
    };

    const onSubmitDone = (submission) => {
        // This function is intentionally empty. 
    };

    const history = useHistory();
    const handleNext = () => {
        history.push("/IDPMainForm");
    };
    const handleBack = () => {
        history.push("/Grant");
    };

    const [fetchingform, setFetchingform] = useState(true);
    return (
        <div className='applicationform' style={{ padding: '16px 4px', backgroundColor: '#f6f8f9' }}>
            <Container>
                <Button><IconArrowLeft color='#666' /></Button>
                <Breadcrumbs sx={{ display: 'inline-block', verticalAlign: 'middle' }} aria-label="breadcrumb">
                    <Link underline='hover' sx={{ cursor: 'pointer' }} className='univers-65-bold-scarpa-flow-12px' onClick={() => history.push('/Dashboard')}>
                        Dashboard &nbsp;&nbsp;
                    </Link>
                    <Link underline='hover' sx={{ cursor: 'pointer' }} className='univers-65-bold-scarpa-flow-12px' onClick={() => history.push('/Grants')}>
                        &nbsp;&nbsp; Grants &nbsp;&nbsp;
                    </Link>
                    <Link underline='hover' sx={{ cursor: 'pointer' }} className='univers-65-bold-scarpa-flow-12px'>
                        <span className='surname-1'>
                            &nbsp;&nbsp; Industry Digital Plan
                        </span>
                    </Link>
                </Breadcrumbs>
                <br /><br />
                <Typography variant='h2'>
                    {formname}
                </Typography>
                <br />
                <Typography className='univers-55roman-normal-mine-shaft-22px'>
                    {formid} {formstatus}
                </Typography>
                <br />
                <div style={{ display: 'flex' }}>
                    <IconCheckupList />&nbsp;&nbsp;
                    <Typography variant='h4'>
                        Check your eligibility
                    </Typography>
                </div>
                <Paper sx={{ py: 2.5, px: 5, my: 3 }}>
                    {fetchingform ? <><CircularProgress /> Form is loading</> : null}
                    <Form
                        {...props}
                        //src={appFormURL}
                        form={appFormURL}
                        url={appFormURL}
                        onChange={onChange}
                        formReady={formReady}
                        onSubmit={onSubmit}
                        onSubmitDone={onSubmitDone}
                    />
                </Paper>
            </Container>
        </div>
    )
}

