import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit";
import { fetch } from '../../Common/API'
import { config } from '../../config'
import { ApplicationStatusTextOptions } from '../../Common/ApplicationStatusOptions'
import moment from 'moment';
import { CommonErrorHandling } from '../../Common/CommonErrorHandling'

//for Application Listing
export const getApplicationListingPaginationAsync = createAsyncThunk(
    'ApplicationListing/getApplicationListingPaginationAsync',
    async (skipLimit) => {
        try {
            let resp;
            if (skipLimit?.search) {
                let query = `${config.appURL}/api/application/GetNestedListingPages?skip=${skipLimit.skip}&limit=${skipLimit.limit}&sort=-modified&applicationStatus=${skipLimit.applicationStatus}&search=${skipLimit.search}`;
                resp = await fetch(query, 'GET', null);
            }
            else {// filter
                let query= `${config.appURL}/api/Application/GetNestedListingPages?skip=${skipLimit.skip}&limit=${skipLimit.limit}&sort=-modified`;
                if (skipLimit.caseId) {
                    query += `&caseId=${skipLimit.caseId}`
                }
                if (skipLimit.schemaExtension) {
                    query += `&schemaExtension=${skipLimit.schemaExtension}`
                }
                if (skipLimit.submissionStartDate) {
                    query += `&submissionStartDate=${skipLimit.submissionStartDate}`
                }
                if (skipLimit.submissionEndDate) {
                    query += `&submissionEndDate=${skipLimit.submissionEndDate}`
                }
                if (skipLimit.applicationStatus || skipLimit.applicationStatus === 0) {
                    query += `&applicationStatus=${skipLimit.applicationStatus}`
                }
                if (skipLimit.scheme) {
                    query += `&scheme=${skipLimit.scheme}`
                }
                if (skipLimit.isAssignedToMe) {
                    query += `&isAssignedToMe=${skipLimit.isAssignedToMe}`
                }

                resp = await fetch(query, 'GET', null);
            }
            if(resp.data !== null) {
                const app = await resp.data;
                let applicationList = [];

                app.ApplicationListingResponses.forEach(element => {
                    let statusText
                    switch (element.Status) {

                        case 1: statusText = ApplicationStatusTextOptions.Draft;
                            break
                        case 2: statusText = ApplicationStatusTextOptions.Deleted;
                            break
                        case 3: statusText = ApplicationStatusTextOptions.Processing;
                            break
                        case 4: statusText = ApplicationStatusTextOptions.Approved;
                            break
                        case 5: statusText = ApplicationStatusTextOptions.Rejected;
                            break
                        case 6: statusText = ApplicationStatusTextOptions.Withdrawn;
                            break
                        case 7: statusText = ApplicationStatusTextOptions.Lapsed;
                            break
                        case 8: statusText = ApplicationStatusTextOptions.Acknowledged;
                            break
                        case 9: statusText = ApplicationStatusTextOptions.Returned;
                            break
                        case 10: statusText = ApplicationStatusTextOptions.Terminated;
                            break
                        case 11: statusText = ApplicationStatusTextOptions.Completed;
                            break
                        case 12: statusText = ApplicationStatusTextOptions.Disbursed;
                            break
                        case 13: statusText = ApplicationStatusTextOptions.Ceased;
                            break
                        case 14: statusText = ApplicationStatusTextOptions.Returnedforcomment;
                            break
                        case 15: statusText = ApplicationStatusTextOptions.ApprovedByMOF;
                            break
                        case 16: statusText = ApplicationStatusTextOptions.RejectedByMOF;
                            break
                        case 17: statusText = ApplicationStatusTextOptions.Closed;
                            break
                        case 18: statusText = ApplicationStatusTextOptions.PendingMOFAppeal;
                            break
                        case 19: statusText = ApplicationStatusTextOptions.MOFAppealSuccessful;
                            break
                        case 20: statusText = ApplicationStatusTextOptions.MOFAppealRejected;
                            break
                        case 21: statusText = ApplicationStatusTextOptions.Expired;
                            break
                        case 22: statusText = ApplicationStatusTextOptions.Void;
                            break
                        default: break;
                    }

                    const datelast = new Date(element.LastUpdated).toLocaleDateString('en-SG', {day: 'numeric', month: 'short', year: 'numeric'});
                    const lasttime = moment(element.LastUpdated).format("HH:mm");
                    const thetime = moment(lasttime, "HH:mm").format("LT")

                    let statusNestedText;
                    let datelastNested;
                    let lasttimeNested;
                    let thetimeNested;

                    let tmpNestedChangeRequest = []
                    if(element.NestedChangeRequest.length) {
                        element.NestedChangeRequest.forEach(element => {
                            switch (element.Status) {
                                case 1: statusNestedText = ApplicationStatusTextOptions.Draft;
                                    break
                                case 2: statusNestedText = ApplicationStatusTextOptions.Deleted;
                                    break
                                case 3: statusNestedText = ApplicationStatusTextOptions.Processing;
                                    break
                                case 4: statusNestedText = ApplicationStatusTextOptions.Approved;
                                    break
                                case 5: statusNestedText = ApplicationStatusTextOptions.Rejected;
                                    break
                                case 6: statusNestedText = ApplicationStatusTextOptions.Withdrawn;
                                    break
                                case 7: statusNestedText = ApplicationStatusTextOptions.Lapsed;
                                    break
                                case 8: statusNestedText = ApplicationStatusTextOptions.Acknowledged;
                                    break
                                case 9: statusNestedText = ApplicationStatusTextOptions.Returned;
                                    break
                                case 10: statusNestedText = ApplicationStatusTextOptions.Terminated;
                                    break
                                case 11: statusNestedText = ApplicationStatusTextOptions.Completed;
                                    break
                                case 12: statusNestedText = ApplicationStatusTextOptions.Disbursed;
                                    break
                                case 13: statusNestedText = ApplicationStatusTextOptions.Ceased;
                                    break
                                case 14: statusNestedText = ApplicationStatusTextOptions.Returnedforcomment;
                                    break
                                case 15: statusNestedText = ApplicationStatusTextOptions.ApprovedByMOF;
                                    break
                                case 16: statusNestedText = ApplicationStatusTextOptions.RejectedByMOF;
                                    break
                                case 17: statusNestedText = ApplicationStatusTextOptions.Closed;
                                    break
                                case 18: statusNestedText = ApplicationStatusTextOptions.PendingMOFAppeal;
                                    break
                                case 19: statusNestedText = ApplicationStatusTextOptions.MOFAppealSuccessful;
                                    break
                                case 20: statusNestedText = ApplicationStatusTextOptions.MOFAppealRejected;
                                    break
                                case 21: statusNestedText = ApplicationStatusTextOptions.Expired;
                                    break
                                case 22: statusNestedText = ApplicationStatusTextOptions.Void;
                                    break
                                default: break;
                            }
                            datelastNested = new Date(element.LastUpdated).toLocaleDateString('en-SG', {day: 'numeric', month: 'short', year: 'numeric'});
                            lasttimeNested = moment(element.LastUpdated).format("HH:mm");
                            thetimeNested = moment(lasttimeNested, "HH:mm").format("LT");

                            let tmp = {
                                id:  element.Id,
                                caseId:  element.CaseID,
                                award:  element.Schema,
                                scheme:  element.Schema,
                                schemaExtension:  element.schemaExtension,
                                schemaDescription :  element.schemaDescription,
                                uen:  element.UEN,
                                company:  element.Company,
                                status: statusNestedText,
                                internalstatus:  element.InternalStatus,
                                isIconShowWHTMOFCR: element.IsIconShowWHTMOFCR,
                                endDate: moment(element.EstimatedCompletionDate).format("DD/MM/YYYY HH:mm"),
                                LastUpdated: datelastNested + "," + thetimeNested,
                                NRIC:  element.NRIC,
                                solution:  element.Solution,
                                vendor:  element.Vendor,
                                vesselName:  element.VesselName,
                                lender:  element.Lender,
                                entityType:  element.EntityType,
                                submissionStartDate:  element.submissionStartDate,
                                username:  element.UserName,
                            }
                            tmpNestedChangeRequest.push(tmp)
                        })
                    }

                    let tmpNestedClaim = []
                    if(element.NestedClaim.length) {
                        element.NestedClaim.forEach(element => {
                            switch (element.Status) {
                                case 1: statusNestedText = ApplicationStatusTextOptions.Draft;
                                    break
                                case 2: statusNestedText = ApplicationStatusTextOptions.Deleted;
                                    break
                                case 3: statusNestedText = ApplicationStatusTextOptions.Processing;
                                    break
                                case 4: statusNestedText = ApplicationStatusTextOptions.Approved;
                                    break
                                case 5: statusNestedText = ApplicationStatusTextOptions.Rejected;
                                    break
                                case 6: statusNestedText = ApplicationStatusTextOptions.Withdrawn;
                                    break
                                case 7: statusNestedText = ApplicationStatusTextOptions.Lapsed;
                                    break
                                case 8: statusNestedText = ApplicationStatusTextOptions.Acknowledged;
                                    break
                                case 9: statusNestedText = ApplicationStatusTextOptions.Returned;
                                    break
                                case 10: statusNestedText = ApplicationStatusTextOptions.Terminated;
                                    break
                                case 11: statusNestedText = ApplicationStatusTextOptions.Completed;
                                    break
                                case 12: statusNestedText = ApplicationStatusTextOptions.Disbursed;
                                    break
                                case 13: statusNestedText = ApplicationStatusTextOptions.Ceased;
                                    break
                                case 14: statusNestedText = ApplicationStatusTextOptions.Returnedforcomment;
                                    break
                                case 15: statusNestedText = ApplicationStatusTextOptions.ApprovedByMOF;
                                    break
                                case 16: statusNestedText = ApplicationStatusTextOptions.RejectedByMOF;
                                    break
                                case 17: statusNestedText = ApplicationStatusTextOptions.Closed;
                                    break
                                case 18: statusNestedText = ApplicationStatusTextOptions.PendingMOFAppeal;
                                    break
                                case 19: statusNestedText = ApplicationStatusTextOptions.MOFAppealSuccessful;
                                    break
                                case 20: statusNestedText = ApplicationStatusTextOptions.MOFAppealRejected;
                                    break
                                case 21: statusNestedText = ApplicationStatusTextOptions.Expired;
                                    break
                                case 22: statusNestedText = ApplicationStatusTextOptions.Void;
                                    break
                                default: break;
                            }
                            datelastNested = new Date(element.LastUpdated).toLocaleDateString('en-SG', {day: 'numeric', month: 'short', year: 'numeric'});
                            lasttimeNested = moment(element.LastUpdated).format("HH:mm");
                            thetimeNested = moment(lasttimeNested, "HH:mm").format("LT");

                            let tmp = {
                                id:  element.Id,
                                caseId:  element.CaseID,
                                award:  element.Schema,
                                scheme:  element.Schema,
                                schemaExtension:  element.schemaExtension,
                                schemaDescription :  element.schemaDescription,
                                uen:  element.UEN,
                                company:  element.Company,
                                status: statusNestedText,
                                internalstatus:  element.InternalStatus,
                                isIconShowWHTMOFCR: element.IsIconShowWHTMOFCR,
                                endDate: moment(element.EstimatedCompletionDate).format("DD/MM/YYYY HH:mm"),
                                LastUpdated: datelastNested + "," + thetimeNested,
                                NRIC:  element.NRIC,
                                solution:  element.Solution,
                                vendor:  element.Vendor,
                                vesselName:  element.VesselName,
                                lender:  element.Lender,
                                entityType:  element.EntityType,
                                submissionStartDate:  element.submissionStartDate,
                                username:  element.UserName,
                            }
                            tmpNestedClaim.push(tmp)
                        })
                    }

                    let tmpNestedAppeal = []
                    if(element.NestedAppeal.length) {
                        element.NestedAppeal.forEach(element => {
                            switch (element.Status) {
                                case 1: statusNestedText = ApplicationStatusTextOptions.Draft;
                                    break
                                case 2: statusNestedText = ApplicationStatusTextOptions.Deleted;
                                    break
                                case 3: statusNestedText = ApplicationStatusTextOptions.Processing;
                                    break
                                case 4: statusNestedText = ApplicationStatusTextOptions.Approved;
                                    break
                                case 5: statusNestedText = ApplicationStatusTextOptions.Rejected;
                                    break
                                case 6: statusNestedText = ApplicationStatusTextOptions.Withdrawn;
                                    break
                                case 7: statusNestedText = ApplicationStatusTextOptions.Lapsed;
                                    break
                                case 8: statusNestedText = ApplicationStatusTextOptions.Acknowledged;
                                    break
                                case 9: statusNestedText = ApplicationStatusTextOptions.Returned;
                                    break
                                case 10: statusNestedText = ApplicationStatusTextOptions.Terminated;
                                    break
                                case 11: statusNestedText = ApplicationStatusTextOptions.Completed;
                                    break
                                case 12: statusNestedText = ApplicationStatusTextOptions.Disbursed;
                                    break
                                case 13: statusNestedText = ApplicationStatusTextOptions.Ceased;
                                    break
                                case 14: statusNestedText = ApplicationStatusTextOptions.Returnedforcomment;
                                    break
                                case 15: statusNestedText = ApplicationStatusTextOptions.ApprovedByMOF;
                                    break
                                case 16: statusNestedText = ApplicationStatusTextOptions.RejectedByMOF;
                                    break
                                case 17: statusNestedText = ApplicationStatusTextOptions.Closed;
                                    break
                                case 18: statusNestedText = ApplicationStatusTextOptions.PendingMOFAppeal;
                                    break
                                case 19: statusNestedText = ApplicationStatusTextOptions.MOFAppealSuccessful;
                                    break
                                case 20: statusNestedText = ApplicationStatusTextOptions.MOFAppealRejected;
                                    break
                                case 21: statusNestedText = ApplicationStatusTextOptions.Expired;
                                    break
                                case 22: statusNestedText = ApplicationStatusTextOptions.Void;
                                    break
                                default: break;
                            }
                            datelastNested = new Date(element.LastUpdated).toLocaleDateString('en-SG', {day: 'numeric', month: 'short', year: 'numeric'});
                            lasttimeNested = moment(element.LastUpdated).format("HH:mm");
                            thetimeNested = moment(lasttimeNested, "HH:mm").format("LT");

                            let tmp = {
                                id:  element.Id,
                                caseId:  element.CaseID,
                                award:  element.Schema,
                                scheme:  element.Schema,
                                schemaExtension:  element.schemaExtension,
                                schemaDescription :  element.schemaDescription,
                                uen:  element.UEN,
                                company:  element.Company,
                                status: statusNestedText,
                                internalstatus:  element.InternalStatus,
                                isIconShowWHTMOFCR: element.IsIconShowWHTMOFCR,
                                endDate: moment(element.EstimatedCompletionDate).format("DD/MM/YYYY HH:mm"),
                                LastUpdated: datelastNested + "," + thetimeNested,
                                NRIC:  element.NRIC,
                                solution:  element.Solution,
                                vendor:  element.Vendor,
                                vesselName:  element.VesselName,
                                lender:  element.Lender,
                                entityType:  element.EntityType,
                                submissionStartDate:  element.submissionStartDate,
                                username:  element.UserName,
                            }
                            tmpNestedAppeal.push(tmp)
                        })
                    }

                    let a = {
                        id: element.Id,
                        caseId: element.CaseID,
                        award: element.Schema,
                        scheme: element.Schema,
                        schemaExtension: element.schemaExtension,
                        schemaDescription : element.schemaDescription,
                        uen: element.UEN,
                        company: element.Company,
                        status: statusText,
                        internalstatus: element.InternalStatus,
                        isIconShowWHTMOFCR: element.IsIconShowWHTMOFCR,
                        endDate: moment(element.EstimatedCompletionDate).format("DD/MM/YYYY HH:mm"),
                        LastUpdated: datelast + "," + thetime,
                        NRIC: element.NRIC,
                        solution: element.Solution,
                        vendor: element.Vendor,
                        vesselName: element.VesselName,
                        lender: element.Lender,
                        NestedChangeRequest: tmpNestedChangeRequest,
                        NestedClaim: tmpNestedClaim,
                        NestedAppeal: tmpNestedAppeal,
                        entityType: element.EntityType,
                        submissionStartDate:  element.submissionStartDate,
                        username:  element.UserName,
                    }
                    applicationList.push(a)
                });
                applicationList.push(app.TotalRecord)
                return { applicationList };
            }
            else {
                return false;
            }
        } catch (error) {
            return CommonErrorHandling(error);
        }
    }
);

const fetchListing = async (skipLimit) => {
        let resp;
        let query= `${config.appURL}/api/Application/GetNestedListingPages?skip=${skipLimit.skip}&limit=${skipLimit.limit}&sort=-modified`;
        if (skipLimit.scheme) {
            query += `&scheme=${skipLimit.scheme}`
        }

        try {
            resp = await fetch(query, 'GET', null);

            const app = await resp.data;
            let applicationList = [];

            app.ApplicationListingResponses.forEach(element => {

                let statusText
                switch (element.Status) {

                    case 1: statusText = ApplicationStatusTextOptions.Draft;
                        break
                    case 2: statusText = ApplicationStatusTextOptions.Deleted;
                        break
                    case 3: statusText = ApplicationStatusTextOptions.Processing;
                        break
                    case 4: statusText = ApplicationStatusTextOptions.Approved;
                        break
                    case 5: statusText = ApplicationStatusTextOptions.Rejected;
                        break
                    case 6: statusText = ApplicationStatusTextOptions.Withdrawn;
                        break
                    case 7: statusText = ApplicationStatusTextOptions.Lapsed;
                        break
                    case 8: statusText = ApplicationStatusTextOptions.Acknowledged;
                        break
                    case 9: statusText = ApplicationStatusTextOptions.Returned;
                        break
                    case 10: statusText = ApplicationStatusTextOptions.Terminated;
                        break
                    case 11: statusText = ApplicationStatusTextOptions.Completed;
                        break
                    case 12: statusText = ApplicationStatusTextOptions.Disbursed;
                        break
                    case 13: statusText = ApplicationStatusTextOptions.Ceased;
                        break
                    case 14: statusText = ApplicationStatusTextOptions.Returnedforcomment;
                        break
                    case 15: statusText = ApplicationStatusTextOptions.ApprovedByMOF;
                        break
                    case 16: statusText = ApplicationStatusTextOptions.RejectedByMOF;
                        break
                    case 17: statusText = ApplicationStatusTextOptions.Closed;
                        break
                    case 18: statusText = ApplicationStatusTextOptions.PendingMOFAppeal;
                        break
                    case 19: statusText = ApplicationStatusTextOptions.MOFAppealSuccessful;
                        break
                    case 20: statusText = ApplicationStatusTextOptions.MOFAppealRejected;
                        break
                    case 21: statusText = ApplicationStatusTextOptions.Expired;
                        break
                    case 22: statusText = ApplicationStatusTextOptions.Void;
                        break
                    default: break;
                }

                const datelast = new Date(element.LastUpdated).toLocaleDateString('en-SG', {day: 'numeric', month: 'short', year: 'numeric'});
                const lasttime = moment(element.LastUpdated).format("HH:mm");
                const thetime = moment(lasttime, "HH:mm").format("LT")

                let a = {
                    id: element.Id,
                    caseId: element.CaseID,
                    award: element.Schema,
                    scheme: element.Schema,
                    schemaExtension: element.schemaExtension,
                    schemaDescription : element.schemaDescription,
                    uen: element.UEN,
                    company: element.Company,
                    // status: element.Status,statusText
                    status: statusText,
                    internalstatus: element.InternalStatus,
                    isIconShowWHTMOFCR: element.IsIconShowWHTMOFCR,
                    endDate: moment(element.EstimatedCompletionDate).format("DD/MM/YYYY HH:mm"),
                    LastUpdated: datelast + ", " + thetime,
                    NRIC: element.NRIC,
                    solution: element.Solution,
                    vendor: element.Vendor,
                    vesselName: element.VesselName,
                    lender: element.Lender,
                    entityType: element.EntityType,
                    submissionStartDate:  element.submissionStartDate,
                    username:  element.UserName,
                }
                applicationList.push(a)
            });
            return { applicationList };
        }
        catch (error){
            return CommonErrorHandling(error.response.data);
        }
}

export const deleteApplicationListingAPIAsync = createAsyncThunk(
	'ApplicationListing/deleteApplicationListingAPIAsync',
	async ({formId, submissionId, skipLimit}) => {
        try {
          const url = `${config.appURL}/api/Application/${formId}/submission/${submissionId}`
          const response = await fetch(url, "DELETE", "");

          if (response.data) {
            const getData = await fetchListing(skipLimit)
            return getData
          }
        } catch (err) {
            return isRejectedWithValue(err.response.data)
        }

      }
);

export const ApplicationListingSlice = createSlice({
    name: 'Application Listing',
    initialState: {
        list: []
    },
    reducers: {},
    extraReducers: {
        //for Application Listing
        [getApplicationListingPaginationAsync.fulfilled]: (state, action) => {
            state.list = action.payload.applicationList
        },
        [deleteApplicationListingAPIAsync.fulfilled]: (state, action) => {
            state.list = action.payload.applicationList
        },

    }
});

export default ApplicationListingSlice.reducer;
