import { Box, Container, Paper, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    createOrSave,
    FormHtmlErrors,
    FormHtmlForm,
    FormHtmlHead,
    FormHtmlTitle,
    onChangeGeneric,
    onErrorGeneric,
    onRenderGeneric,
    onSubmitDoneGeneric,
    onSubmitGeneric,
    PageCarousel
} from '../../../Common/ApplicationForm';
import { config } from '../../../config';
import '../../ApplicationForm/ApplicationForm.css';
import pagination from '../../ApplicationForm/pagination';
import InfoIcon from '@mui/icons-material/Info';
import { useHistory, useLocation } from "react-router-dom";
import ValidationDialog from '../../../Common/ValidationDialog';
import moment from 'moment';
import $ from 'jquery';
import { fetch } from '../../../Common/API';
import Utils from 'formiojs/utils';
import { deleteFormAPIAsync } from "../../../Redux/CommonForm/CommonFormSlice.js.js";
import flatComponent from '../../../Common/FormIOComponent/flatComponent'
import scanData from '../../../Common/FormIOComponent/scanData'
import { getTrackChanges, NamePropertyTrackChanges, TrackChanges } from '../../ApplicationListing/APICall/apicall'
import SnackbarAlert from '../../../Common/snackbarAlert';
import ProgressBar from '../../../Common/progressBar'
import AutoSaveErrorDialog from '../../../Common/autoSaveError'
import getTitle from '../../../Common/FormIOComponent/titleComponent'
import getStatusText from '../../../Common/GetStatusText'
import { ConvertUtcToLocale_ApplicantRegistrationDate, GenericTabTimeout } from "../../../Common/GenericIDP";
import loginHelper from "../../../Common/loginHelper";
import { GenericFileUploadOnRender, GenericFileUploadOnChange } from '../../../Common/GenericForm';

IDPChangeRequest.defaultProps = {
    formname: 'Industry Digital Plan Change Request',
    formstatus: 'draft',
    formId: `${config.form_name_target_idp_cr}`,
    appFormBaseURL: `${config.appFormBaseURL}`,
    schema: 'IDP'
}

let allowScrolling = true;

export default function IDPChangeRequest({ formname, formid, formstatus, formId, appFormBaseURL, schema, props }) {
    const [page, setPage] = useState(0);
    const [isBusy, setBusy] = useState(true)
    const appFormJson = useRef();
    const [openValidationDialog, setOpenValidationDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState();
    const [open, setOpen] = useState(false);
    const [openChangeRequestErrorDialog, setOpenChangeRequestErrorDialog] = useState(false);
    let validityTriggered = false;
    const [disableScrollingFunc, setScrollState] = useState(() => { 
        // This function is intentionally empty. 
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const [bar, setBar] = useState(false)
    const ref = useRef()
    const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false);

    const location = useLocation();
    const params = new URLSearchParams(window.location.search);
    let _id = params.get('_id');
    const theformId = _id
    let submissionId = _id;
    let mainSubmissionId = params.get('mainSubmissionId')
    let createdData = { _id: _id };
    let getStatus = params.get('status');

    const [status, setStatus] = useState();
    let prevStatus;
    let reviewCaseID;

    let requestorActionDate;
    let myEstimatedCommencementDate;
    let newDate;
    const getCaseID = location?.state?.caseID
    const [caseID, setCaseID] = useState(getCaseID);
    let info;
    let formType = "create";
    if (_id) {
        formType = "update";
    }

    let mockDate = "2022-09-11T00:00:00+08:00";
    let options = {
        hooks: {
            addComponents: (components, instance) => {
                //  Apply to nested components
                components.forEach((comp) => {
                    if (comp.type == "columns") {
                        comp.columns.forEach((c) => {
                            c.components.forEach((sc) => {
                                if (sc.key == "estimatedCommencementDate") {
                                    sc.datePicker.minDate = moment(mockDate).startOf("day");
                                    sc.widget.minDate = moment(mockDate).startOf("day");
                                }
                            });
                        });
                    }
                });

                return components;
            }
        }
    }

    let createAppFormURL = `${appFormBaseURL}/${formId}`
    let updateAppFormURL = `${appFormBaseURL}/${formId}/submission/${_id}`
    //var appFormURL = `${appFormBaseURL}/${formId}/${_id}`
    let createURL = `${appFormBaseURL}/${formId}/submission`
    let currentForm = {};
    const [thisForm, setThisForm] = useState({});
    const [formReadOnly, setFormReadOnly] = useState(false);

    const [fetchingform, setFetchingform] = useState(true);
    const [pagesErrors, setPagesErrors] = useState([]);
    const [currentPageErrors, setCurrentPageErrors] = useState(0);
    const [startSpinner, setStartSpinner] = useState(false);

    let alreadySubmitted = false;

    const submitID_ref = useRef("");
    const form_ref = useRef({});
    let canSubmit = true;

    let reloadStrictMode = false;
    let tabBufferCounter = 5;
    let refTabCounterTimeOut = useRef(null);

    const checkErrorStatusAndOpenErrorStatusDialog = () => {
        if (sessionStorage.getItem("error") !== "409") {
            setOpenErrorStatusDialog(true);
        }
    };
    
    useEffect(() => {
        async function getform() {
            setBusy(true);

            let url = createAppFormURL;
            if (formType === "update") {
                url = updateAppFormURL
                //url = ${config.appURL}/api/application/GetLatestApproveMainORCRSubmissionBySubmissionId/${mainSubmissionId};
            }

            let json = await fetch(url, 'GET', null);
            if (json) {
                if (reloadStrictMode) {
                    reloadStrictMode = false;
                } else {
                    setBusy(false);
                    appFormJson.current = json.data;


                    if (getStatus === "All") {
                        setStatus("draft")
                        prevStatus = "draft";
                    }
                    else {
                        const currentStatus = json.data.data.configContainer.publicApplicationStatus
                        const getText = getStatusText(currentStatus)
                        setStatus(getText)
                        prevStatus = getText;
                    }
                }
            }
        };
        getform();

        return () => reloadStrictMode = true;
    }, [])

    useEffect(() => {

        return () => {
            if (ref.Interval !== null)
                clearInterval(ref.Interval);
            if (refTabCounterTimeOut.current != null)
                clearTimeout(refTabCounterTimeOut.current);
        };
    }, []);

    function saveCreatedData(x) {
        createdData = x;
    }


    async function getChanges(component, currentData, preData) {

        for (const item in currentData) {

            for (const check in preData) {
                if (item === check) {
                    let now = currentData[item]
                    let before = preData[check]
                    if (typeof now == Date || typeof before == Date) {
                        before = moment(before).format("DD/MM/YYYY")
                        now = moment(now).format("DD/MM/YYYY")
                    }
                    if (before !== now) {
                        changes.push(item)
                    }
                }
            }

        }

    }


    async function saveChanges(component, currentData, preData, checkingDB) {
        let pageChanges = []
        for (const item in currentData) {

            for (const check in preData) {
                if (item === check) {
                    const checkSplit = check.split("-")
                    const findChecking = checkingDB?.find(item => item?.key.split("-")[1] === checkSplit[1])
                    //   data exist in db
                    if (findChecking) {

                    }
                    else if (checkSplit[1].includes("estimatedCommencementDate") || checkSplit[1].includes("estimatedCompletionDate") || checkSplit[1].includes("date") || checkSplit[1].includes("Date") || checkSplit[1].includes("period") || checkSplit[1].includes("Period")) {

                    }
                    else {
                        let now = currentData[item]
                        let before = preData[check]
                        if (now instanceof Date || before instanceof Date) {
                            before = moment(before).format("DD/MM/YYYY")
                            now = moment(now).format("DD/MM/YYYY")
                        }
                        if (now instanceof Array || before instanceof Array) {

                        }
                        else if ((before !== now) && (item.includes("solutionName") === false)) {
                            changes.push(item)
                            pageChanges.push(item)
                        }
                    }
                }
            }
        }

        for (let i = 0; i < changes.length; i++) {
            if (changes[i].includes("solutionName")) {
                changes.splice(i, 1)
            }
        }
        return await TrackChanges(pageChanges, theformId);
    }

    let resultComponent
    let prevData
    const changes = []
    let data = []
    const NewChanges = []
    let firstTimeSubmission = true;
    function searchElements() {
        const element = document.querySelectorAll("[name]")
        for (const el of element) {
            const attr = el.getAttribute("name")
            if (attr) {
                if (attr.startsWith("data")) {
                    let elementValue = el.value
                    if (el.name.toString().includes("Date") || el.name.toString().includes("date")) {
                        elementValue = moment(el.value).format("DD/MM/YYYY")
                    }
                    if (el.name.toString().includes("data[declarationAgreement][agreementCheck")) {
                        elementValue = el.checked
                    }
                    const theValue = {
                        "name": el.name,
                        "nodeName": el.nodeName,
                        "value": elementValue,
                        "element": el
                    }
                    const check = data.find(item => item.name === el.name)
                    if (check) {
                    }
                    else {
                        data.push(theValue)
                    }
                }
            }
        }
        return "success"
    }

    async function callNextPageFunction() {
        setOpenAlert()
        setStartSpinner(true)
        const sendData = await getPrevData()
        if (sendData !== "noSave") {
            await nextPage(resultComponent);
        }
        else {
            setStartSpinner(false)
            // pop up and show error message
            checkErrorStatusAndOpenErrorStatusDialog(); 
        }
    }
    async function searchElementsSave(checkingDB) {
        let newData = []
        const element = document.querySelectorAll("[name]")
        for (const el of element) {
            const attr = el.getAttribute("name")
            if (attr) {
                if (attr.startsWith("data")) {
                    let elementValue = el.value
                    if (el.name.toString().includes("data[declarationAgreement][agreementCheck")) {
                        elementValue = el.checked
                    }

                    const check = data.find(item => item.name === el.name)
                    const findChecking = checkingDB?.find(i => i.key === el.name)

                    if (el.name.toString().includes("Date") || el.name.toString().includes("date")) {
                        elementValue = moment(el.value).format("DD/MM/YYYY")
                    }

                    const theValue = {
                        "name": el.name,
                        "nodeName": el.nodeName,
                        "value": elementValue,
                        "element": attr
                    }

                    if (el.name.toString().includes("selectBoxesQFA_") || el.name.toString().includes("fyAuditedYear") || el.name.toString().includes("solutionName") || el.name.toString().includes("agreeToAll")) {
                    }
                    else if (findChecking) {
                    }
                    else if (check && check.nodeName === "BUTTON") {
                    }
                    else {
                        if (check && check.value !== elementValue) {
                            // NewChanges.push(theValue)
                            newData.push(theValue)
                        }
                    }

                }
            }
        }

        // const posting = await NamePropertyTrackChanges(NewChanges, theformId)
        const posting = await NamePropertyTrackChanges(newData, theformId)

        return posting
    }

    let allTitle

    const formReady = async (form) => {
        $(async function () {
            let letterOfOfferAcceptanceDate;
            if (mainSubmissionId) {
                letterOfOfferAcceptanceDate = await fetch(`${config.appURL}/api/LetterOfOffer/GetBySubmissionId?SubmissionId=${mainSubmissionId}`, 'GET', null);
            }
            else {
                letterOfOfferAcceptanceDate = await fetch(`${config.appURL}/api/LetterOfOffer/GetBySubmissionId?SubmissionId=${appFormJson.current.data.mainFormId}`, 'GET', null);
            }

            requestorActionDate = letterOfOfferAcceptanceDate.data.data?.requestorActionDate;
            setFetchingform(false);
            currentForm = form;
            currentForm.nosubmit = true;
            if (currentForm.data.configContainer.publicApplicationStatus ===
                1 ||
                currentForm.data.configContainer.publicApplicationStatus ===
                9 ||
                currentForm.data.configContainer.applicationStatus === "1" ||
                currentForm.data.configContainer.applicationStatus === "9") {
                ref.Interval = setInterval(async function () {
                    if (currentForm)
                        await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
                }, 60000);
            }
            const getAllTitle = getTitle(currentForm, theformId, "IDPCRForm")
            allTitle = getAllTitle
            setThisForm(currentForm);

            if (_id) {
                currentForm.data = appFormJson.current.data;
            } else {
                info = await fetch(`${config.appURL}/api/application/GetLatestApproveMainORCRSubmissionBySubmissionId/${mainSubmissionId}`, 'GET', null)
                    .then((json) => {
                        return json.data.data;
                    });
                let info_array = Object.entries(info)
                currentForm.data = Object.fromEntries(info_array);
                currentForm.submission.data["mainFormId"] = mainSubmissionId;
                currentForm.submission.data.configContainer['applicationCaseId'] = "";
                currentForm.submission.data.configContainer['companyInvolved'] = info?.applicantName;
                currentForm.submission.data["schemaExtension"] = 3;
                if (currentForm.submission.data["contactName"] === "") {
                    let applicant = loginHelper.getLoginUser();
                    currentForm.submission.data.configContainer["usersInvolved"] = applicant?.Name;
                    currentForm.submission.data["contactEmail"] = applicant?.Email;
                }
                firstTimeSubmission = true;
                callNextPageFunction();
                const dgShareHolders = currentForm.getComponent("shareholders");
                if (dgShareHolders) {
                    dgShareHolders.setValue(
                        currentForm.submission.data.shareholders
                    );
                }
            }

            if (getStatus === "All") {
                setStatus("draft")
                prevStatus = "draft";
            }
            else {
                const statusTextCurrent = getStatusText(currentForm.submission.data.configContainer.publicApplicationStatus)
                setStatus(statusTextCurrent)
                prevStatus = statusTextCurrent
            }

            // below 1 is draft status, 9 is returned status
            // only draft and returned back-end statuses can edit contents of this form
            if (currentForm.submission.data.configContainer.publicApplicationStatus !== 1 && currentForm.submission.data.configContainer.publicApplicationStatus !== 9 && currentForm.submission.data.configContainer.applicationStatus !== '1' && currentForm.submission.data.configContainer.applicationStatus !== '9' && _id !== null) {
                canSubmit = false;
                submitID_ref.current = createdData._id;
                form_ref.current = currentForm;
                goToReview();
            }

            if (prevStatus === "Returned" && theformId !== null) {
                resultComponent = flatComponent(currentForm)
            }

            if (createdData._id) {
                submitID_ref.current = createdData._id;
            }

            //  Change Data Grid Add Button
            Utils.searchComponents(form.components, { type: "datagrid" }).forEach((instance,) => {
                if (instance?.component) {
                    instance.component.addAnother = "Add On";
                }
            });

            const commencementDate = new Date(currentForm.submission.data["estimatedCommencementDate"]);
            commencementDate.setDate(commencementDate.getDate());
            currentForm.submission.data["estimatedCommencementDate"] = commencementDate;
            myEstimatedCommencementDate = currentForm.getComponent("estimatedCommencementDate");
            disablePartOfEstimatedCommencementDate();

            ConvertUtcToLocale_ApplicantRegistrationDate(currentForm);

            // Handle minDate for EstCommencement
            myEstimatedCommencementDate.on('redraw', async () => {
                myEstimatedCommencementDate.component.datePicker.minDate = moment(newDate).startOf("day");
                myEstimatedCommencementDate.component.widget.minDate = moment(newDate).startOf("day");
            });

            // Convert UTC to Locale for EstCompletion
            const completionDate = new Date(currentForm.submission.data["estimatedCompletionDate"]);
            completionDate.setDate(completionDate.getDate());
            currentForm.submission.data["estimatedCompletionDate"] = completionDate;

            // Set minDate for EstCompletion according to EstCommencement
            let estimatedCompletionDateComp = currentForm.getComponent("estimatedCompletionDate");
            estimatedCompletionDateComp.component.datePicker.minDate = moment(currentForm.submission.data["estimatedCommencementDate"]).add(1, 'year').subtract(1, 'days').startOf("day");
            estimatedCompletionDateComp.component.widget.minDate = moment(currentForm.submission.data["estimatedCommencementDate"]).add(1, 'year').subtract(1, 'days').startOf("day");
            estimatedCompletionDateComp.redraw();

            currentForm.setPage(2);
            refTabCounterTimeOut.current = GenericTabTimeout(
                "solutionName",
                refTabCounterTimeOut,
                setStartSpinner,
                tabBufferCounter
            );
            currentForm.redraw();

            // getPreData()
            // searchElements()
            setTimeout(() => {
                getPreData();
            }, 1000);

            currentForm.on('prevPage', async () => {

                setOpenAlert()
                setStartSpinner(true)
                const sendData = await getPrevData()

                if (sendData !== "noSave") {
                    await prevPage(resultComponent);
                }
                else {
                    setStartSpinner(false)
                    // pop up and show error message
                    checkErrorStatusAndOpenErrorStatusDialog(); 
                }
            });

            currentForm.on("change", (changed) => {
                GenericFileUploadOnChange(changed, false, currentForm, setStartSpinner);
            });

            currentForm.on('nextPage', async () => {
                firstTimeSubmission = false;
                callNextPageFunction();
            });

            currentForm.on("draftSave", async () => {
                setStartSpinner(true)

                const savingDraft = await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);

                if (savingDraft.status === 200 || savingDraft.status === 201) {
                    setStartSpinner(false)
                    setOpenAlert("Draft")
                    setOpen(true)
                }
                else {
                    setStartSpinner(false);
                    checkErrorStatusAndOpenErrorStatusDialog(); 
                }

            });

            currentForm.on('review', async () => {
                if (allowScrolling) window.scrollTo(0, 0);
                else setScrollState(() => (allowScrolling = true));
                setStartSpinner(true)

                const savingChangesData = await getPrevData()

                const responseStatus = await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);

                if ((responseStatus.status === 200 || responseStatus.status === 201) && savingChangesData !== "noSave") {
                    //  Check Validity
                    validityTriggered = true;
                    let valCheck = checkValidity();

                    if (valCheck === false) {
                        //  Validation Error
                        setOpenValidationDialog(true);
                        form_ref.current = currentForm;
                        submitID_ref.current = createdData._id;
                        // searchElements()
                        getPreData()
                        setStartSpinner(false)
                    } else {
                        submitID_ref.current = createdData._id;
                        form_ref.current = currentForm;
                        goToReview();
                    }
                }
                else {
                    setStartSpinner(false)
                    // pop up and show error message
                    checkErrorStatusAndOpenErrorStatusDialog(); 
                }
            });

            currentForm.on("fileUploadingStart", async () => {
                setStartSpinner(true);
            });      
            currentForm.on("fileUploadingEnd", async () => {
                setStartSpinner(false);
              });

            if (currentForm.submission.data.configContainer) {
                setCaseID(currentForm.submission.data.configContainer.applicationCaseId);
            }

            if (currentForm.submission.data.configContainer.applicationCaseId) {
                reviewCaseID = currentForm.submission.data.configContainer.applicationCaseId;
            }

            $(function () {
                $('.page-link').on('click', async (event) => {
                    const findTitleIndex = allTitle.indexOf(event.currentTarget.outerText)
                    setOpenAlert()
                    setStartSpinner(true)
                    const sendData = await getPrevData();

                    if (sendData !== "noSave") {
                        const response = await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
                        if (response.status === 200 || response.status === 201) {
                            currentForm.setPage(findTitleIndex);
                            setStartSpinner(false)
                        } else {
                            setStartSpinner(false)
                            // pop up and show error message
                            checkErrorStatusAndOpenErrorStatusDialog(); 
                        }
                    }
                    else {
                        setStartSpinner(false)
                        // pop up and show error message
                        checkErrorStatusAndOpenErrorStatusDialog(); 
                    }

                    if (prevStatus === "Returned") {
                        getPreData();
                    }
                });
            });
            setTimeout(() => {
                pagination(currentForm)
            }, 90);
        });

    };

    const handleCustomRenderEvent = () => {
        //-- JQuery to overwrite file component functionality to allow file download and delete with JWT Token
        GenericFileUploadOnRender(false, currentForm, setStartSpinner);
    };

    const onRender = async () => {
        if (allowScrolling)
            window.scrollTo(0, 0);
        else
            setScrollState(() => allowScrolling = true)
        const rendering = await onRenderGeneric(setPage, currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId, prevStatus);
        setTimeout(() => {
            checkValidity();
        }, 0);
        $(function() {      
            handleCustomRenderEvent();
        })
        if (rendering === "ok") {
            if (prevStatus === "Returned" || prevStatus === "returned") {
                setStartSpinner(true)
                $('button:contains("Next")').hide()
                $('button:contains("Back")').hide()
                $('button:contains("Save as Draft")').hide()
                $('button:contains("Review")').hide()
            }
        }
    }

    const onChange = async (event) => {
        if (event.changed?.component?.key === "estimatedCommencementDate") {
            let estimatedCompletionDateComp = currentForm.getComponent("estimatedCompletionDate");

            estimatedCompletionDateComp.component.datePicker.minDate = moment(event.changed.value).add(1, 'year').subtract(1, 'days').startOf("day");
            estimatedCompletionDateComp.component.widget.minDate = moment(event.changed.value).add(1, 'year').subtract(1, 'days').startOf("day");

            let estimatedCompletionDateModified = moment(event.changed.value).add(1, 'year').subtract(1, 'days').startOf("day");
            let estimatedCompletionDate = new Date(estimatedCompletionDateModified);
            currentForm.submission.data['estimatedCompletionDate'] = estimatedCompletionDate;

            estimatedCompletionDateComp.triggerRedraw();
            disablePartOfEstimatedCommencementDate();
        }

        //moment(currentForm.submission.data.estimatedCommencementDate).valueOf(), moment(currentForm.submission.data.initialDateTime).valueOf()
        onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
        checkValidity();
    }

    const onSubmit = async (submission) => {
        onSubmitGeneric(submission, currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
    }

    const onError = async (errors) => {
        onErrorGeneric(errors)
    }

    const onSubmitDone = (submission) => {
        onSubmitDoneGeneric(submission)
    };

    async function prevPage(resultComponent) {
        const responseStatus = await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
        if (responseStatus.status === 200 || responseStatus.status === 201) {
            currentForm.setPage(currentForm.page - 1);
            setStartSpinner(false)
        }

        else {
            setStartSpinner(false)
            //pop up and show error message
            checkErrorStatusAndOpenErrorStatusDialog(); 
        }

        getPreData()
        // searchElements()
    }

    async function nextPage(resultComponent) {
        $('button:contains("Next")').hide();
        const responseStatus = await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);

        if (responseStatus.status === 200 || responseStatus.status === 201) {
            $('button:contains("Next")').show();
            if (firstTimeSubmission === false) {
                currentForm.setPage(currentForm.page + 1);
            }
            submitID_ref.current = createdData._id;           
            if (responseStatus.message.data.configContainer.applicationCaseId) {
                setCaseID(responseStatus.message.data.configContainer.applicationCaseId);
                reviewCaseID = responseStatus.message.data.configContainer.applicationCaseId;
            }
            setStartSpinner(false)
        }
        else {
            $('button:contains("Next")').show();
            setStartSpinner(false)
            //pop up and show error message
            checkErrorStatusAndOpenErrorStatusDialog(); 
        }

        getPreData()
        // searchElements()

    }

    function getPreData() {
        if (prevStatus === "Returned" && theformId !== null) {
            const preData = scanData(resultComponent)
            const getSearch = searchElements()
            if (preData && getSearch) {
                if (currentForm.page === 2) {
                    const checkSolutionName = Object.keys(preData).find(key => key.split('-')[1] === "solutionName");

                    if (checkSolutionName && (preData[checkSolutionName] === "" || preData[checkSolutionName] === undefined)) {
                        setTimeout(() => {
                            getPreData();
                        }, 1000);
                    }
                    else {
                        prevData = preData;
                        setTimeout(() => {
                            $('button:contains("Next")').show()
                            $('button:contains("Back")').show()
                            $('button:contains("Review")').show()
                            // $('button:contains("Save as Draft")').show()
                            setStartSpinner(false)
                        }, 2000);
                    }
                }
                else {
                    prevData = preData;
                    setTimeout(() => {

                        $('button:contains("Next")').show()
                        $('button:contains("Back")').show()
                        // $('button:contains("Save as Draft")').show()
                        $('button:contains("Review")').show()
                        setStartSpinner(false)
                    }, 2000);
                }
            }
        }
    }

    async function getPrevData() {
        const latestData = scanData(resultComponent)

        if (prevStatus === "Returned" && theformId !== null && latestData) {
            // await getChanges(resultComponent, latestData, prevData)
            const checkingDB = await getTrackChanges(theformId)
            const searchSave = await searchElementsSave(checkingDB.data.data)
            const TrackChangesSave = await saveChanges(resultComponent, latestData, prevData, checkingDB.data.data)

            if ([200, 201].includes(searchSave.status) && [200, 201].includes(TrackChangesSave.status)) {
                return "ok Success"
            }
            else {
                return "noSave"
            }

        }
        else {
            return "no track changes"
        }
    }

    function changePage(x) {
        thisForm.setPage(x);
    }
    function onCustomEvent(event) {
        // This function is intentionally empty. 
    }
    function goToReview() {
        history.push({
            pathname: '/IDPChangeRequestReview',
            search: `?_id=${submitID_ref.current}&status=${prevStatus}`,
            // search: `?_id=${submitID_ref.current}`,
            refresh: true,
            state: {
                caseID: (caseID === null || caseID === undefined)
                    ? reviewCaseID
                    : caseID,
                prevStatus: prevStatus,
                canSubmit: canSubmit
            }
        });
    }

    function scrollToError() {
        window.scrollTo(0, 0);
    }

    function goToTop() {
        window.scrollTo(0, 0);
        setTimeout(scrollToError, 2000)
    }

    function disablePartOfEstimatedCommencementDate() {
        newDate = new Date(requestorActionDate);
        newDate.setDate(newDate.getDate());
        if (myEstimatedCommencementDate) {
            myEstimatedCommencementDate.component.datePicker.minDate = moment(newDate).startOf("day");
            myEstimatedCommencementDate.component.widget.minDate = moment(newDate).startOf("day");
            myEstimatedCommencementDate.redraw();
        }
    }

    function checkValidity() {
        let valCheck = false;
        if (validityTriggered) {
            valCheck = currentForm.checkValidity(null, true, null, false);
            let oldPagesErrors = pagesErrors;
            currentForm.pages.forEach(
                (x, index) => (oldPagesErrors[index] = x.errors.length)
            );
            setPagesErrors(oldPagesErrors);
            setCurrentPageErrors(pagesErrors[currentForm.page]);
        }
        return valCheck;
    }

    const handleDelete = async () => {
        try {
            if (submitID_ref.current) {
                const originalPromiseResult = await dispatch(deleteFormAPIAsync({ formId: formId, submissionId: submitID_ref.current })).unwrap()
                history.push({
                    pathname: '/NestedApplicationListing',
                    search: `?schema=1`,
                    refresh: true
                });
            }
            else {
                alert("Delete is not allowed");
            }
        } catch (rejectedValueOrSerializedError) {
            alert("Delete is not allowed");
        }
    };

    return (
        <div className='applicationform'>

            <ValidationDialog
                open={openValidationDialog}
                setOpen={setOpenValidationDialog}
                goToTop={goToTop} />
            <Container>
                <FormHtmlHead formname={formname} schema={schema} />
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FormHtmlTitle formname={formname} formid={formid} formstatus={status} caseID={caseID} />
                        {/* <Button className="large button-large univers-65-bold-white-16px role-margin"

                            onClick={() => { handleDelete() }}
                        >
                            Delete
                        </Button> */}
                    </div>
                    <br />
                    <Paper variant='outlined' className='bg-info' sx={{ mt: 1 }}>
                        <Box sx={{ px: 1, py: 2 }} display="flex" justifyContent="center">
                            <InfoIcon sx={{ color: "white", fontSize: 32 }} />
                            <Typography variant="p" sx={{ mx: 1, color: "white", fontSize: 20 }}>You can only edit the following fields, "Estimated Commencement Date" and "Estimated Completion Date".</Typography>
                        </Box>
                    </Paper>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <PageCarousel thisform={thisForm} setpage={changePage} pagesErrors={pagesErrors} status={status} />
                    </div>
                    <br />
                </div>
                <FormHtmlErrors thisForm={thisForm} pagesErrors={pagesErrors} currentPageErrors={currentPageErrors} />

                <FormHtmlForm fetchingform={fetchingform} formReadOnly={formReadOnly} props={props} appFormURL={createAppFormURL} onChange={onChange} onError={onError} formReady={formReady} onSubmit={onSubmit} onSubmitDone={onSubmitDone} onRender={onRender} onCustomEvent={onCustomEvent} isBusy={isBusy} appFormJson={appFormJson} options={options} />
            </Container>
            <AutoSaveErrorDialog
                open={openErrorStatusDialog}
                setOpen={setOpenErrorStatusDialog}
            />
            <SnackbarAlert alert={openAlert} caseID={caseID} open={open} setOpen={setOpen} />
            <ProgressBar ref={ref} startSpinner={startSpinner} setStartSpinner={setStartSpinner} bar={bar} setBar={setBar} />
        </div>
    )
}
