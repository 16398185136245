export const SwapsCheckbox = (json) => {

  //statement_one in Section 2 and statement_two in Section 3
  let statement_one = json.data.containerNonTax.containerLender.radioAnyPayment;
  let statement_two = json.data.containerQualifyingDetails.containerQualifyingAmount.isThereAnySwapArrangementInTheSameFinancingAgreement;
  let interestRateCheckbox = json.getComponent("interestRateSwapsCheckBox");
  let currencySwapsCheckbox = json.getComponent("currencySwapsCheckBox");

  if (statement_one === "no" && statement_two === "no") {
    json.data.containerQualifyingDetails.containerQualifyingPayment.interestRateSwapsCheckBox = false;
    json.data.containerQualifyingDetails.containerQualifyingPayment.currencySwapsCheckBox = false;
    interestRateCheckbox._disabled = true;
    currencySwapsCheckbox._disabled = true;
    checkboxTrigger();
  } else if (statement_one === "yes" || statement_two === "yes") {
    interestRateCheckbox._disabled = false;
    currencySwapsCheckbox._disabled = false;
    checkboxTrigger();
  }

  function checkboxTrigger() {
    interestRateCheckbox.triggerRedraw();
    currencySwapsCheckbox.triggerRedraw();
  }

  return json;
};
