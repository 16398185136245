import { Typography } from "@mui/material";

function MSIPage() {
  return (
    <>
      <Typography variant="h3">Maritime Sector Incentive (MSI)</Typography>
      <br />
      <Typography variant="h4">Pool Operations</Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For guidelines on shipping pool operations, please{" "}
        <a
          href="https://www.mpa.gov.sg/docs/mpalibraries/mpa-documents-files/business-capability-development-division/general-guidelines-for-pool-operations.pdf?sfvrsn=5727a330_0"
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
      <br />
      <Typography variant="h4">Tax Exemption</Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For tax exemption on vessel disposal gains, please{" "}
        <a
          href="https://www.mpa.gov.sg/docs/mpalibraries/mpa-documents-files/business-capability-development-division/faq-msi-tax-exemption-of-vessel-disposal-gains-jan2022.pdf?sfvrsn=cfbf8726_0"
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For tax exemption on in-house ship management fees, please{" "}
        <a
          href="https://www.mpa.gov.sg/docs/mpalibraries/mpa-documents-files/business-capability-development-division/faq-msi-tax-exemption-on-in-house-ship-management-fees-jan2022.pdf?sfvrsn=2e742c49_0"
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
      <br />
      <Typography variant="h4">Minimum Commitments</Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For minimum commitments under the MSI, please{" "}
        <a
          href="https://www.mpa.gov.sg/docs/mpalibraries/mpa-documents-files/business-capability-development-division/faq-msi-on-minimum-commitments-under-msi.pdf?sfvrsn=912e2d37_0"
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
    </>
  );
}

export default MSIPage;
