import { Breadcrumbs, Button, Container, Grid, Link, Typography, useMediaQuery, } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormHtmlForm,
  onChangeGeneric,
  onErrorGeneric,
  onRenderGeneric,
  onSubmitDoneGeneric,
  onSubmitGeneric,
} from "../../Common/ApplicationForm";
import { config } from "../../config";
import "../ApplicationForm/ApplicationForm.css";
import "./ContactUs.css";
import { fetch } from "../../Common/API";
import ContactUsSuccessDialog from "../../Common/ContactUsSuccessDialog";
import ContactUsErrorDialog from "../../Common/ContactUsErrorDialog";

ContactUs.defaultProps = {
  formname: "Contact Us Form",
  formstatus: "draft",
  formId: "contactus",
  appFormBaseURL: `${config.appURL}`,
  schema: "",
};

export default function ContactUs({
                                    formId,
                                    appFormBaseURL,
                                    props,
}) {
  let myTimeout = 0;
  const [openSuccessStatusDialog, setOpenSuccessStatusDialog] = useState(false);
  const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false);

  const [isBusy, setBusy] = useState(true);
  const [appFormJson, setAppFormJson] = useState({});
  const history = useHistory();
  const _id = "";
  let createdData = { _id: _id };
  const appFormURL = `${appFormBaseURL}/api/Generic/GetContactUsForm`;
  const createURL = `${appFormBaseURL}/api/Generic/${formId}/submissionContactUs`;
  let currentForm = {};
  const [formReadOnly, setFormReadOnly] = useState(false);
  const [fetchingform, setFetchingform] = useState(true);
  const alreadySubmitted = false;
  useEffect(() => {
    async function getform() {
      setBusy(true);
      const json = await fetch(appFormURL, "GET", null);
      if (json) {
        setBusy(false);
        setAppFormJson(json.data);
      }
    }
    getform();
    window.scrollTo(0, 0);
  }, []);

  function saveCreatedData(x) {
    createdData = x;
  }

  const formReady = async (form) => {
    setFetchingform(false);
    currentForm = form;
    currentForm.nosubmit = true;
  };

  const onRender = async () => {
    await onRenderGeneric(
      0,
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );
  };

  const onChange = async (event) => {
    onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
  };

  const onSubmit = async (submission) => {
    onSubmitGeneric(
      submission,
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );
    myTimeout = setTimeout(() => {
      setOpenErrorStatusDialog(true);
    }, 9000);
  };

  const onError = async (errors) => {
    onErrorGeneric(errors);
    setOpenErrorStatusDialog(true);
  };

  const onSubmitDone = (submission) => {
    clearTimeout(myTimeout);
    onSubmitDoneGeneric(submission);
    setOpenSuccessStatusDialog(true);
  };

  const onCustomEvent = async ({ type, component, data, event }) => {
    // This function is intentionally empty.
  };
  return (
      <div className="exploreschemes">
        <div className="scheme-background">
          <div className="backbanner-contactus" />
          <ContactUsSuccessDialog
            open={openSuccessStatusDialog}
            setOpen={setOpenSuccessStatusDialog}
          />
          <ContactUsErrorDialog
            open={openErrorStatusDialog}
            setOpen={setOpenErrorStatusDialog}
          />
          <Container>
            <br />
            <Button
              onClick={() => history.push("/Home")}
              className="nav-back-btn"
            >
              <IconArrowLeft color="#666" />
            </Button>
            <Breadcrumbs
              sx={{ display: "inline-block", verticalAlign: "middle" }}
              aria-label="breadcrumb"
            >
              <Link
                underline="hover"
                onClick={() => history.push("/Home")}
                mx={1}
                className="active-link univers-65-bold-scarpa-flow-12px"
              >
                Home
              </Link>
              <Link
                underline="none"
                mx={1}
                className="disabled-link univers-65-bold-scarpa-flow-12px"
              >
                Contact Us
              </Link>
            </Breadcrumbs>

            <Grid container>
              <Grid item xs={useMediaQuery("(min-width: 768px)") ? 7 : 12}>
                <br />
                <Typography variant="h2">Contact Us</Typography>
              </Grid>
              <Grid item xs={useMediaQuery("(min-width: 768px)") ? 6 : 12}>
                <Typography className="univers-55roman-normal-mine-shaft-18px">
                  Submit your query / request / feedback via the form below. We
                  will get back to you the soonest if further assistance is
                  required.
                </Typography>
                <br />
                <Typography className="univers-55roman-normal-mine-shaft-18px">
                  You may wish to explore the Frequently Asked Questions&nbsp;
                  <a href="/FAQs">(FAQs)</a> page to find the information you
                  need.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <img
                  style={{
                    display: useMediaQuery("(min-width: 1090px)")
                      ? "initial"
                      : "none",
                  }}
                  alt="Contact Us"
                  src={`${process.env.PUBLIC_URL}${config?.contactUsURL}`}
                  id="contactuspic"
                  className="contactUsImg"
                />
              </Grid>
            </Grid>
          </Container>

          <Container sx={{ pt: useMediaQuery("(min-width: 1920px)") ? 5 : 0 }}>
            <div
              className="contactus contactform applicationform"
            >
              <FormHtmlForm
                fetchingform={fetchingform}
                formReadOnly={formReadOnly}
                props={props}
                appFormURL={appFormURL}
                onChange={onChange}
                onError={onError}
                formReady={formReady}
                onSubmit={onSubmit}
                onSubmitDone={onSubmitDone}
                onRender={onRender}
                onCustomEvent={onCustomEvent}
                isBusy={isBusy}
                appFormJson={appFormJson}
              />
            </div>
          </Container>
          <iframe
            title="Address of MPA"
            src="https://www.onemap.gov.sg/minimap/mm.html?mapStyle=Default&zoomLevel=17&latLng=1.2737515493144,103.801179385061&ewt=JTNDcCUzRSUzQ3N0cm9uZyUzRU1hcml0aW1lJTIwYW5kJTIwUG9ydCUyMEF1dGhvcml0eSUyMG9mJTIwU2luZ2Fwb3JlJTNDJTJGc3Ryb25nJTNFJTNDJTJGcCUzRSUwQSUzQ3AlM0VtVG93ZXIlM0MlMkZwJTNFJTBBJTNDcCUzRTQ2MCUyMEFsZXhhbmRyYSUyMFJkJTJDJTNDJTJGcCUzRSUwQSUzQ3AlM0UlMjMxOS0wMCUyQyUzQyUyRnAlM0UlMEElM0NwJTNFU2luZ2Fwb3JlJTIwMTE5OTYzJTNDJTJGcCUzRQ==&popupWidth=250&showPopup=true"
            height="450px"
            width="100%"
            allowFullScreen="allowfullscreen"
            sandbox
          ></iframe>
        </div>
      </div>
  );
}
