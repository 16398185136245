import { Breadcrumbs, Button, Container, Grid, Link, Paper, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { IconArrowLeft } from '@tabler/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from "react-router-dom";
import bannerpic from '../../Images/Img-WHT.png';
import PurchaseOrConstruction from './tabs/purchaseOrConstruction'
import PurchaseContainer from './tabs/purchaseContainer'
import SelectMenu from '../FAQs/menuOptions'

const StyledTabs = styled(Tabs)({
  marginLeft: 30,
  marginRight: 30,
  width: 740,
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent"
  }
});
const StyledTab = styled(Tab)({
  fontSize: 18,
  fontFamily: "Univers-65Bold",
  lineHeight: '28px',
  textTransform: 'none',
  textAlign: 'left',
  margin: '10px 0px',
  padding: '20px 0px',
  WebkitAlignItems: 'baseline',
  borderBottom: '2px solid #eaecf0',
  '&.Mui-selected': {
    borderBottom: '2px solid #0065bd'
  }
});
const StyledTabPanel = styled(TabPanel)({
  width: 2100
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function WHTScheme() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickMenu = (event) => {
    setValue(event.target.value)
  };
  const history = useHistory();
  const label1 = <p>Arrangements Obtained To Finance The Purchase Or Construction Of Ships</p>;
  const label2 = <p>Arrangements Obtained To Finance The Purchase Of Containers And Intermodal Equipment</p>;

  const menuitem = [{ key: 0, text: "Arrangements Obtained To Finance The Purchase Or Construction Of Ships" }, { key: 1, text: "Arrangements Obtained To Finance The Purchase Of Containers And Intermodal Equipment" }]

  const checkMin600 = useMediaQuery('(min-width: 600px)')

  return (
    <div className="exploreschemes">
      <div className="scheme-background">
        <div className="backbanner" />
        <Container>
          <br />
          <Button onClick={() => history.push({
            pathname: "/ExploreSchemes",
            tab: 'Incentives'
          })}
            className='nav-back-btn'>
            <IconArrowLeft color='#666' />
          </Button>
          <Breadcrumbs sx={{ display: 'inline-block', verticalAlign: 'middle' }} aria-label="breadcrumb">
            <Link underline='hover'
              onClick={() => history.push('/Home')}
              mx={1}
              className='active-link univers-65-bold-scarpa-flow-12px'>
              Home
            </Link>
            <Link underline='hover'
              onClick={() => history.push({
                pathname: "/ExploreSchemes",
                tab: 'Incentives'
              })}
              mx={1}
              className='active-link univers-65-bold-scarpa-flow-12px'>
              Incentives
            </Link>
            <Link underline='none'
              mx={1} className='disabled-link univers-65-bold-scarpa-flow-12px'>
              Withholding Tax Exemption
            </Link>
          </Breadcrumbs>
          <br />
          <br />
          <Grid container>
            <Grid item xs={useMediaQuery("(min-width: 768px)") ? 6 : 12}>
              <Typography variant='h2'>Withholding Tax (WHT) Exemption</Typography>

              <Typography className="univers-55roman-normal-mine-shaft-18px" sx={{ fontSize: 'var(--font-size-xl) !important', mt: 1 }}>Qualifying shipping enterprises will enjoy WHT exemption on interest and related payments made in respect of arrangements obtained to finance the purchase or construction of vessels, or the purchase of containers and intermodal equipment.</Typography>

            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <img style={{ display: useMediaQuery('(min-width: 1090px)') ? 'initial' : 'none' }}
             src={bannerpic} id='idpschemespic' alt='WHT' />
             </Grid>
            {/* <img src={bannerpic} id='grpdiscusspic' alt='WHT'/> */}
           

          </Grid>
        </Container>
        <Container className='schemewht'>
          <div className='mobilefaq ' style={{ display: useMediaQuery('(max-width: 900px)') ? "" : "none" }}>
            <br />
            <br />
            <Typography variant='h4'>Categories</Typography>
            <SelectMenu handleClickMenu={handleClickMenu} active={value} menuitem={menuitem} />
          </div>

          <Paper elevation={0} sx={{ position: 'relative', mt: checkMin600 ? 12.3 : 5, p: 3, border: '1px solid #EAECF0', borderRadius: 3 }}>
            <Box sx={{ flexGrow: 1, display: checkMin600 ? "flex" : "none" }}>
              <StyledTabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
              >
                <StyledTab label={label1} {...a11yProps(0)} />
                <StyledTab label={label2} {...a11yProps(1)} />
              </StyledTabs>
              {checkMin600 ?
                <>
                  <StyledTabPanel value={value} index={0}>
                    <PurchaseOrConstruction />
                  </StyledTabPanel>
                  <StyledTabPanel value={value} index={1}>
                    <PurchaseContainer />
                  </StyledTabPanel>
                </>
                : <></>
              }
            </Box>
            {useMediaQuery('(max-width: 599px)') ?
              <Box sx={{ flexGrow: 1, display: 'flex', }}>
                <StyledTabPanel value={value} index={0}>
                  <PurchaseOrConstruction />
                </StyledTabPanel>
                <StyledTabPanel value={value} index={1}>
                  <PurchaseContainer />
                </StyledTabPanel>
              </Box>
              : <></>
            }
          </Paper>
        </Container>
      </div>
    </div>
  )
}

