import {Components} from 'formiojs';
import moment from 'moment';
import {config} from '../../config';
import editForm from './Comment.form';
import logo from '../../Images/officer.png';
import user from '../../Images/user-profile.png';
import loginHelper from '../../Common/loginHelper';
import './Comment.css';
import APICall from './APICall/apicall';
import $ from 'jquery'
import {fetch} from '../../Common/API';


const HTML_ELEMENT = Components.components.field;
type ApplicantComment = {
  Comment: string,
  OfficerId: string,
  ApplicantId: string,
  FieldName: string,
  SubmissionId: string,
  Timestamp: Date
}

export default class ApplicantCommentBtn extends HTML_ELEMENT {
  private is_mounted!: boolean;
  private comments: ApplicantComment[];
  constructor(component: any, options: any, data: any) {
    super(component, options, data);
    this.comments = [];
  }

  static schema() {
    return HTML_ELEMENT.schema({
      type: "applicantcomment",
      numRows: 2,
      numCols: 1,
    });
  }

  public static editForm = editForm;

  static builderInfo = {
    title: "Applicant Comment",
    group: "basic",
    icon: "fa fa-table",
    weight: 70,
    documentation: "/userguide/#table",
    schema: ApplicantCommentBtn.schema(),
  };
  verify(token: string): boolean {
    return true;
  }
  decode(token: string): any {
    // This method is intentionally empty.
  }
  renderString(template: any, data: any): HTMLElement {
    const htmlComponent: HTMLElement = document.createElement("div");
    htmlComponent.setAttribute("icon-preview", this.id);
    htmlComponent.setAttribute("id", "iconPlace");
    htmlComponent.appendChild(ApplicantCommentBtn.modal_template(this.id));

    return htmlComponent;
  }
  public render(children: any) {
    return super.render(this.renderString("div", {}).outerHTML);
  }

  public detach(): void {
    this.is_mounted = false;
    super.detach();
  }

  public updateDiv(fieldName3: any, submissionId2: any) {
    const ul = document.querySelector(`[data-preview=${this.id}]`);
    if (ul instanceof HTMLUListElement) {
      ul.innerHTML = "";
      (async () => {
        const sortingData = await APICall(fieldName3, submissionId2);
        this.comments = sortingData;
        for (const item of this.comments) {
          const li = document.createElement("li");
          const img = document.createElement("img");
          // img.setAttribute("src", face)
          img.style.verticalAlign = "top";
          img.style.height = "40px";
          img.style.width = "40px";
          const userDetails = document.createElement("div");
          userDetails.style.display = "inline-block";
          userDetails.style.marginLeft = "21px";
          const name = document.createElement("div");
          const commentDate = moment(item.Timestamp);
          const small = document.createElement("small");
          name.innerText = item.OfficerId;

          // small.style.fontSize = "small"
          // small.style.color = "gray"
          // small.innerText = `Reviewing Officer ‧ ${commentDate.format('DD-MMM-YYYY HH:mm A')}`

          if (
            item.OfficerId !== "" &&
            (item.ApplicantId === "" || item.ApplicantId === undefined)
          ) {
            name.innerText = item.OfficerId;

            small.style.fontSize = "small";
            small.style.color = "gray";
            small.innerText = `Reviewing Officer ‧ ${commentDate.format(
              "DD-MMM-YYYY HH:mm A"
            )}`;
            img.setAttribute("src", logo);
          }
          if (
            item.ApplicantId !== "" &&
            (item.OfficerId === "" || item.OfficerId === undefined)
          ) {
            name.innerText = item.ApplicantId;

            small.style.fontSize = "small";
            small.style.color = "gray";
            small.innerText = `Applicant ‧ ${commentDate.format(
              "DD-MMM-YYYY HH:mm A"
            )}`;
            // img.innerHTML = '<i class="fa-solid fa-circle-user"></i>'
            img.setAttribute("src", user);
          }
          if (
            (item.OfficerId === "" || item.OfficerId === undefined) &&
            (item.ApplicantId === "" || item.ApplicantId === undefined)
          ) {
            name.innerText = "input username is empty !";

            small.style.fontSize = "small";
            small.style.color = "gray";
            small.innerText = `${commentDate.format("DD-MMM-YYYY HH:mm A")}`;
            img.setAttribute("src", user);
          }

          userDetails.appendChild(name);
          userDetails.appendChild(small);
          const span = document.createElement("p");
          span.innerText = item.Comment;
          span.style.marginTop = "12px";
          span.classList.add("comment-span");
          const hr = document.createElement("hr");
          li.appendChild(img);
          li.appendChild(userDetails);
          li.appendChild(span);
          li.appendChild(hr);
          ul.appendChild(li);
        }
      })();
    }
  }

  public attach(element: any) {
    if (!this.is_mounted) {
      if (element instanceof HTMLElement) {
        const fieldNameLabel = element.querySelector("label")!.innerText;

        let button = document.createElement("button");
        button.classList.add("btn");
        button.setAttribute("data-target", this.id);
        button.setAttribute("data-clickable", "true");
        button.setAttribute("id", fieldNameLabel);

        button.innerHTML = '<i class="message-icon" aria-hidden="true"></i>';
        element.appendChild(button);

        const iconcheck = document.getElementById(fieldNameLabel);

        const fieldName = element.querySelector("label")!.innerText;
        const urlParams = new URLSearchParams(window.location.search);
        const submissionId2 = urlParams.get("_id") || urlParams.get("mainSubmissionId");
        const tmpString: any = sessionStorage.getItem("info");
        const isRouteBack = tmpString as string;
        if (isRouteBack?.includes("Returned")) {
          (async () => {
            const sortingData = await APICall(fieldName, submissionId2).catch(
              console.error
            );

            if (sortingData && sortingData.length !== 0) {
              const thelength = sortingData.length;
              const lastdata = sortingData[thelength - 1];
              const check = "!";
              const officer = lastdata.OfficerId;
              const applicant = lastdata.ApplicantId;

              if (
                (applicant === "" || applicant === undefined || applicant === null) &&
                (officer !== "" || officer !== undefined || officer !== null) &&
                iconcheck
              ) {
                iconcheck.innerHTML = `<div class= "notification"><i class="message-icon" aria-hidden="true"></i><span class="badge">${check}</span></div>`;
              }
            }
          })();
        }        

        element
          .querySelector('[data-clickable="true"]')
          ?.addEventListener("click", (e) => {
            const fieldName = element.querySelector("label")!.innerText;
            const fieldName2 = fieldName.split("\n")[1];
            const fieldName3 = fieldName2.substring(2, fieldName2.length);
            const submissionId2 = urlParams.get("_id") || urlParams.get("mainSubmissionId");

            this.updateDiv(fieldName3, submissionId2);

            const el = document.querySelector(`[data-modal-id=${this.id}]`);
            if (el instanceof HTMLElement) {
              el.style.display = "flex";
            }

            const theul = document.getElementById("comment-ul");

            if (theul !== null) {
              const height = 1000;

              $("#comment-ul").animate({ scrollTop: height }, "slow");
            }
          });
        element
          .querySelector(`[data-close=${this.id}]`)
          ?.addEventListener("click", (e) => {
            const el = document.querySelector(`[data-modal-id=${this.id}]`);
            if (el instanceof HTMLElement) {
              el.style.display = "none";
            }
          });

        element
          .querySelector(`[data-save=${this.id}]`)!
          .addEventListener("click", (e) => {
            const el = document.querySelector(`[data-modal-id=${this.id}]`);
            if (el instanceof HTMLElement) {
              // el.style.display = "none"
            }
            const textarea = document.querySelector(`[data-id=${this.id}]`);

            if (
              textarea instanceof HTMLTextAreaElement &&
              textarea.value !== "" &&
              textarea.value.trim() !== ""
            ) {
              // const user = localStorage.formioUser;
              // const user = loginHelper.getLoginUser();
              const user = loginHelper.getLoginUser();
              let username = "";
              if (user != undefined && user != null) {
                username = user.Name;
              }
              const fieldName = element.querySelector("label")!.innerText;
              const fieldName2 = fieldName.split("\n")[1];
              const fieldName3 = fieldName2.substring(2, fieldName2.length);
              const formId = window.location.href.split("/");
              const submissionId = window.location.href.endsWith("/")
                ? formId[formId.length - 2]
                : formId[formId.length - 1];
              const getsubmissionId = submissionId.split("=")[1];
              const sendsubmissionId = getsubmissionId.split("&")[0];
              let body = {
                Comment: textarea.value,
                OfficerId: "",
                ApplicantId: username,
                FieldName: fieldName3,
                SubmissionId: sendsubmissionId,
                Timestamp: new Date(),
              };
              this.comments.push(body);
              (async () => {
                let resp = await fetch(
                  `${config.appURL}/api/Comment/PostComment`,
                  "POST",
                  body
                );
                if (resp) {
                  this.updateDiv(fieldName3, submissionId2);
                }
              })();
              textarea.value = "";
            }
          });
        this.is_mounted = true;
      }
    }
    let label = element.querySelector("label");
    if (label != undefined && label instanceof HTMLElement) {
      label.style.display = "none";
    }
    return super.attach(element);
  }
  private static modal_template(id: string): HTMLElement {
    const container = document.createElement("div");
    container.setAttribute("data-modal-id", id);
    container.classList.add("comment-container");
    const modal = document.createElement("div");
    modal.classList.add("comment-modal");
    const header_container = document.createElement("div");
    header_container.classList.add("comment-header_container");
    const header = document.createElement("h4");
    header.classList.add("comment-header");
    // header.innerText = "Comments"
    header.innerText = "Comments to / from Officer";
    header.setAttribute("id", "applicant-comment-header");
    const close = document.createElement("i");
    close.classList.add("fa");
    close.classList.add("fa-times");
    close.classList.add("comment-close");
    close.setAttribute("data-close", id);
    header_container.appendChild(header);
    header_container.appendChild(close);
    modal.appendChild(header_container);
    const ul = document.createElement("ul");
    ul.classList.add("comment-ul");
    ul.setAttribute("data-preview", id);
    ul.setAttribute("id", "comment-ul");
    modal.appendChild(ul);
    const textarea = document.createElement("textarea");
    textarea.classList.add("comment-textarea");
    textarea.setAttribute("data-id", id);
    modal.appendChild(textarea);
    const save_and_close = document.createElement("button");
    // save_and_close.innerText = "Save & Close"
    save_and_close.innerText = "Save";
    save_and_close.classList.add("btn");
    save_and_close.classList.add("btn-primary");
    save_and_close.classList.add("comment-save_and_close");
    save_and_close.setAttribute("data-save", id);
    modal.appendChild(save_and_close);
    container.appendChild(modal);
    return container;
  }
}
