import axios from "axios";
import axiosRetry from 'axios-retry';
import loginHelper from './loginHelper'
import { config } from '../config';
import emitter from './EventEmitterUtil'
import { dispatchSessionExpiredDialogEvent, dispatchCloseLogOffAlertEvent } from "./DispatchEvent";
import { WHTS_CONSTANT } from "./CommonEnumTypes";

const instance = axios.create({
    baseURL: config.appURL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
    data: {}
});

axiosRetry(instance, {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 1000;
    },
    retryCondition: (error) => {
        return error.response.status === 409;
    },
});

instance.interceptors.request.use(
    async config => {
        const jwt = loginHelper.getLoginJwt();
        if (jwt) {
            config.headers['Authorization'] = 'Bearer ' + jwt;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (error) => {
        const originalConfig = error.config;

        //dulplicate login
        if (error.response.status === 409 && sessionStorage.getItem("error") !== "409") { 
            sessionStorage.setItem("error", "409");
            loginHelper.deleteLoginInfo();
            emitter.emit("duplicateLogin", 1);
            return Promise.reject(error);
        }

        if (error.response.status === 401) {
            dispatchCloseLogOffAlertEvent();
            if (loginHelper.getLoginJwt()) {
                dispatchSessionExpiredDialogEvent();
            }
            return Promise.reject("Forbidden");
        }

        if (error.response.status === 422) {
            loginHelper.setErrorMessage(WHTS_CONSTANT.AVOID_CHARACTERS_MESSAGE);
            return Promise.reject(error);
        }

        if (error.response.status === 405 && !originalConfig._retry) {
            loginHelper.deleteLoginInfo();
            dispatchSessionExpiredDialogEvent();
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export default instance;
