import { Card } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function WHTCard() {
    const history = useHistory();
    return (<>
        <Card className="scheme-card clickText" onClick={() => history.push('/WHTScheme')}>
            <div className="overlay-group">
                <div className="image-3" onClick={() => history.push('/WHTScheme')}>
                    {/* <div className="image-container"></div> */}
                </div>
                <div className="image-text-wht" onClick={() => history.push('/WHTScheme')}>Withholding Tax (WHT) Exemption</div>
            </div>
            <p className="scheme-description" onClick={() => history.push('/WHTScheme')}>
                Qualifying shipping enterprises will enjoy WHT exemption on interest and related payments made in respect of
                arrangements obtained to finance the purchase or construction of vessels, or the purchase of containers
                and intermodal equipment.
            </p>
            {/* <Stack
                direction="row"
                display="flex"
                alignItems="flex-start"
                columnGap={2}
            >
                <Chip
                    label="Corporate"
                    onClick={() => history.push('/WHTScheme')}
                    className="chip"
                />
                <Chip
                    label="Incentives"
                    onClick={() => history.push({
                        pathname: "/ExploreSchemes",
                        tab: 'Incentives'
                    })}
                    className="chip"
                />
            </Stack> */}
        </Card>
    </>)
}
