import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Container, IconButton, Snackbar } from '@mui/material';
import Applications from './Applications';
import "../ApplicationListing.css";

export default function NestedApplicationListing(props) {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const handleAction = (text) => {
    setAlert(text)
    setOpen(true);
  };

  const alertProp = props.location.alert;
  const caseID = props.location.caseID;
  useEffect(() => {
    if (alertProp === 'Form is submitted!'){
      setAlert(alertProp)
      setOpen(true);
    }
  }, [alertProp])

  const params = new URLSearchParams(window.location.search);
  const schema = params.get('schema');
  const applicationStatus = params.get('applicationStatus');

  return (
    <div className="scheme-background">
      <Container>
        <Applications schema={schema} applicationStatus={applicationStatus} handleAction={handleAction} />
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        sx={{ textAlign: 'center' }}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
      <Alert color="secondary"
        // onClose={() => { }}
        action={<IconButton
          sx={{ top: -2 }}
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>}
        variant='filled'
        icon={false}
        sx={{ borderRadius: 6, pl: 3, alignItems: 'center' }}>
        <span className="univers-55roman-normal-white-14px" >

          {alert === 'Form is submitted!' && (caseID !== null || caseID !== undefined) && `Application ${caseID} is submitted successfully.`}

          {alert === 'Deleted' && 'Draft is deleted successfully.'}

          {alert === 'Withdrawn' && 'Application is withdrawn successfully.'}
        </span>
      </Alert>
      </Snackbar>
    </div >
  )
}
