import { config } from "../config";

//-- Usage : 
// ConsoleEnvironment();                         // to log error in red, what environment is this
const ConsoleEnvironment = () => {
    console.error("Env is :", config.environment);
}

//-- Usage [Log]: 
// ConsoleLog(obj);                         // to log object only
// ConsoleLog("Click");                     // to log personal message only
// ConsoleLog("Show obj: ", obj);           // to log personal message and also object values
// ConsoleLog("Show variable: ", variable); // to log personal message and also object values
const ConsoleLog = (message, variable = "") => {
    if(config.environment !== "prod") {
        if(message !== null && message !== undefined && typeof message === "object") {
            let prefix = "[Log]: ";
            console.log(prefix, message);
        } else {
            let prefix = "[Log]: " + message;
            console.log(prefix, variable);
        }
    }
}


//-- Usage [Info]: 
// ConsoleInfo(obj);                         // to log object only
// ConsoleInfo("Click");                     // to log personal message only
// ConsoleInfo("Show obj: ", obj);           // to log personal message and also object values
// ConsoleInfo("Show variable: ", variable); // to log personal message and also object values
const ConsoleInfo = (message, variable = "") => {
    if(config.environment !== "prod") {
        if(message !== null && message !== undefined && typeof message === "object") {
            let prefix = "[Info]: ";
            console.info(prefix, message);
        } else {
            let prefix = "[Info]: " + message;
            console.info(prefix, variable);
        }
    }
}


//-- Usage [Warn]: 
// ConsoleWarn(obj);                         // to log object only
// ConsoleWarn("Click");                     // to log personal message only
// ConsoleWarn("Show obj: ", obj);           // to log personal message and also object values
// ConsoleWarn("Show variable: ", variable); // to log personal message and also object values
const ConsoleWarn = (message, variable = "") => {
    if(config.environment !== "prod") {
        if(message !== null && message !== undefined && typeof message === "object") {
            let prefix = "[Warn]: ";
            console.warn(prefix, message);
        } else {
            let prefix = "[Warn]: " + message;
            console.warn(prefix, variable);
        }
    }
}


//-- Usage [Error]: 
// ConsoleError(obj);                         // to log object only
// ConsoleError("Click");                     // to log personal message only
// ConsoleError("Show obj: ", obj);           // to log personal message and also object values
// ConsoleError("Show variable: ", variable); // to log personal message and also object values
const ConsoleError = (message, variable) => {
    if(config.environment !== "prod") {
        if(message !== null && message !== undefined && typeof message === "object") {
            let prefix = "[Error]: ";
            console.error(prefix, message);
        } else {
            let prefix = "[Error]: " + message;
            console.error(prefix, variable);
        }
    }
}
  
  // 👇️ named exports
  export {ConsoleLog, ConsoleInfo, ConsoleWarn, ConsoleError, ConsoleEnvironment};