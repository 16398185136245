import { Breadcrumbs, Button, Container, Link, useMediaQuery, } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import { GenerateSRI } from "../../Common/SRIUtils";

export default function SearchEngine({
  formname,
  formid,
  formstatus,
  formId,
  appFormBaseURL,
  schema,
  props,
}) {
  const history = useHistory();

  useEffect(() => {
    const script = document.createElement("script");
    document.head.appendChild(script);
    script.src = "https://cse.google.com/cse.js?cx=" + config.cseID;
  }, []);

  return (
        <Container>
          <br />
          <Button
            onClick={() => history.push("/Home")}
            className="nav-back-btn"
          >
            <IconArrowLeft color="#666" />
          </Button>
          <Breadcrumbs
            sx={{ display: "inline-block", verticalAlign: "middle" }}
            aria-label="breadcrumb"
          >
            <Link
              underline="hover"
              onClick={() => history.push("/Home")}
              mx={1}
              className="active-link univers-65-bold-scarpa-flow-12px"
            >
              Home
            </Link>
            <Link
              underline="none"
              mx={1}
              className="disabled-link univers-65-bold-scarpa-flow-12px"
            >
              Search
            </Link>
          </Breadcrumbs>
          <div style={{ width: "80%", padding: "0 auto" }}>
            <div class="gcse-search"></div>
          </div>
        </Container>
  );
}
