import { Form } from '@formio/react';
import { Container } from '@mui/material';
import { config } from '../../config';
import Formio from "formiojs/Formio";
export default function DevGuide() {

  const formId = "devguide";
  const appFormBaseURL = `${config.appFormBaseURL}`;
  const m = "https://mpa-omp-forms.sit.activate.sg/authoring-fqcnwskygtheazb";
  const appFormURL = `${m}/${formId}`;

  Formio.setProjectUrl("https://mpa-omp-forms.sit.activate.sg/authoring-fqcnwskygtheazb");
    return (
        <div className='applicationform'>
            <Container>
                <Form
                    form={appFormURL}
                    url={appFormURL}
                />
            </Container>
        </div>
    )
}
