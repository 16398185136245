import FormReview from "../../../Common/FormReview";
import Utils from 'formiojs/utils';
import loginHelper from '../../../Common/loginHelper';
import { useLocation } from "react-router-dom";
import { config } from "../../../config";

export default function IDPMainReview(props) {
  const formname = "Industry Digital Plan Grant Form";
  const formId = config.form_name_target_idp_main;
  const pathname = "/IDPMainForm";
  const schemaroute = "1";

  const params = new URLSearchParams(window.location.search);
  let _id = params.get("_id");
  let { state, refresh } = props.location;
  const location  = useLocation()
  const caseID = location.state.caseID
  const prevStatus = location.state.prevStatus
  const canSubmit = location.state.canSubmit

  return (
    <FormReview
      _id={_id}
      refresh={refresh}
      formname={formname}
      caseID={caseID}
      // formstatus={formstatus}
      formstatus={prevStatus}
      canSubmit={canSubmit}
      formId={formId}
      pathname={pathname}
      schemaroute={schemaroute}
      onReadyCallback={(form) => {
        let utils = Utils.getComponent(form.components, "agreeToAll", true);
        utils.component.label = `I, ${state.user}, am authorized to submit this on behalf of ${state.company}`
        window.scrollTo(0, 0);
        form.redraw();
      }}
    />
  );
}
