import { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { fetch } from '../../Common/API';
import loginHelper from '../../Common/loginHelper';
import { config } from '../../config';
import { validateAndSanitizeHost } from "../../Common/ValidateHost";
import AESUtil from "../../Common/AESUtil";
import LoginErrorDialog from "../../Common/LoginErrorDialog";
import LoginServerErrorDialog from '../../Common/LoginServerError';
import { dispatchDisplayNameEvent } from "../../Common/DispatchEvent";

export default function LoginRedirect({ setBackToLogin }) {
  const appURL = `${config.appURL}`;
  const params = new URLSearchParams(window.location.search);
  const authenticationRequestId = params.get('authenticationRequestId');
  const history = useHistory();
  const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false);
  const [openLoginErrorStatusDialog, setOpenLoginErrorStatusDialog] = useState(false);
  const [typeDialog, setTypeDialog] = useState();
  const isExecutedRef = useRef(false);

  useEffect(() => {
    async function logLastLoginDateTime(id, createdDateTime) {
      const todayDate = new Date();
      const dateTime = todayDate.getMonth() + 1 + "/"
        + (todayDate.getDate()) + "/"
        + todayDate.getFullYear() + " "
        + todayDate.getHours() + ":"
        + todayDate.getMinutes() + ":"
        + todayDate.getSeconds();
      const body = {
        "CreatedDateTime": createdDateTime,
        "LastLoginDateTime": dateTime
      };
      const infoResponse = await fetch(`${appURL}/api/InternetPublicUser/${encodeURIComponent(id)}`, 'PUT', body);
      if (infoResponse.status !== 200) {
        alert("Date of Login not saved.");
      }
    }

    async function loginFunction() {
      const url = `${appURL}/api/Auth/GetSPCPLoginInfo?authenticationRequestId=${encodeURIComponent(authenticationRequestId)}`;
      if (validateAndSanitizeHost(url)) {
        try {
          const getSPCPLoginInfoResponse = await fetch(url, 'GET', null);
          if (getSPCPLoginInfoResponse.status === 200) {
            logLastLoginDateTime(getSPCPLoginInfoResponse.data.PublicUser.Id, getSPCPLoginInfoResponse.data.PublicUser.CreatedDateTime);
            loginHelper.setLoginInfo(getSPCPLoginInfoResponse.data);
            await AESUtil.loadFEKey().then((originalPromiseResult) => {
              if (originalPromiseResult === false) {
                setOpenLoginErrorStatusDialog(true);
              } else {
                dispatchDisplayNameEvent();
                history.push({
                  pathname: "/Dashboard",
                  search: '',
                });
              }
            });
          }
          else {
            history.push({
              pathname: "/Home",
              search: '',
            });
          }
        } catch (error) {
          if (error.response && error.response.status === 406) {
            setTypeDialog("Your account is inactive/invalid. Please contact your Corppass Administrator.");
            setOpenErrorStatusDialog(true);
          } else {
            setOpenLoginErrorStatusDialog(true);
          }
        }

      }
    }
    
    if (authenticationRequestId && !isExecutedRef.current) {
      isExecutedRef.current = true;
      loginFunction();
    } else if (!authenticationRequestId) {
      history.push({
        pathname: "/Home",
        search: '',
      });
    }
  }, [authenticationRequestId, history]);

  return (
    <>
      <LoginErrorDialog
        open={openErrorStatusDialog}
        setOpen={setOpenErrorStatusDialog}
        type={typeDialog}
      />
      <LoginServerErrorDialog
        open={openLoginErrorStatusDialog}
        setOpen={setOpenLoginErrorStatusDialog}
        setBackToLogin={setBackToLogin}
      />
    </>

  );

}
