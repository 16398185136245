import React, { useState } from 'react'
import { Button, TextField } from "@mui/material";
import { fetch } from '../../Common/API';
import { config } from '../../config';
import SafariTextField from '../../Common/SafariTheme'
import detectBrowser from '../../Common/FindBrowser'

export default function MockLogin() {
    const findBrowser = detectBrowser()

  const appURL = config.appURL;
  const spcpReturnUrl = config.spcpReturnUrl;

  const [uen, setUen] = useState("");
  const [nric, setNRIC] = useState("");
  const [isCorppass, setIsCorppass] = useState("");

  const spcpURL = `${appURL}/api/Auth/GetSPCPLoginURL?target=${spcpReturnUrl}&clientState=s=${nric},u=${uen}&isCorppass=${isCorppass}`;

  const login = async () => {
      const getSPCPLoginInfoResponse = await fetch(spcpURL, 'GET', null);
      window.location.href = getSPCPLoginInfoResponse.data.spcPloginURL;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {findBrowser !== 'Safari' ?
            <>
            <div>
                <TextField
                    hiddenLabel
                    id="filled-hidden-label-small"
                    label="UEN"
                    variant="outlined"
                    size="small"
                    value={uen}
                    onChange={e => setUen(e.target.value)}
                />
            </div>
            <div>
                <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    label="NRIC"
                    variant="outlined"
                    value={nric}
                    onChange={e => setNRIC(e.target.value)}
                    margin="none"
                    sx={{ '& .MuiInputBase-root': { margin: 0, padding: 0}, '& .MuiFilledInput-root': {marginTop: 0}  }}
                />
            </div>
            <div>
                <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    label="IsCorppass"
                    variant="outlined"
                    value={isCorppass}
                    onChange={e => setIsCorppass(e.target.value)}
                />
                {/* <RedditTextField
                    id="filled-hidden-label-normal"
                    label="IsCorppass"
                    value={isCorppass}
                    onChange={e => setIsCorppass(e.target.value)}
                    variant="filled"
                    style={{ marginTop: 11 }}
                /> */}
            </div>
            </>
            :
            <>
            <div>
                <SafariTextField
                    id="filled-hidden-label-normal"
                    label="UEN"
                    value={uen}
                    onChange={e => setUen(e.target.value)}
                    variant="filled"
                    style={{ marginTop: 11 }}
                />
            </div>
            <div>
                <SafariTextField
                    id="filled-hidden-label-normal"
                    label="NRIC"
                    value={nric}
                    onChange={e => setNRIC(e.target.value)}
                    variant="filled"
                    style={{ marginTop: 11 }}
                />
            </div>
            <div>
                <SafariTextField
                    id="filled-hidden-label-normal"
                    // label="IsCorppass"
                    value={isCorppass}
                    onChange={e => setIsCorppass(e.target.value)}
                    variant="filled"
                    style={{ marginTop: 11 }}
                />
            </div>
            </>
            }
            <Button variant="contained" size="medium" onClick={login} >
                Login
            </Button>
        </div>
    )
}