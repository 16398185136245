import FormReview from '../../../Common/FormReview';
import Utils from 'formiojs/utils';
import 'datatables.net';
import 'datatables.net-dt';
import { config } from '../../../config';

export default function MSIAISMainReview(props) {

  const formname = 'Maritime Sector Incentive - Approved International Shipping Enterprise (MSI-AIS) Main Form';
  const formid = 'ASI-20200210-007';
  const formstatus = 'New';
  const formId = `${config.form_name_target_msi_main}`;
  const pathname = '/MSIAISMainForm';
  const schemaroute = "4";

  const params = new URLSearchParams(window.location.search);
  const _id = params.get('_id');
  let {state, refresh} = props.location;

  return <FormReview
        _id={_id}
        refresh={refresh}
        formname={formname}
        formid={formid}
        formstatus={formstatus}
        formId={formId}
        pathname={pathname}
        schemaroute={schemaroute}
        otherOptionsCallback={() => {
          // This function is intentionally empty. 
        }}
        onReadyCallback={(form) => {
          const utils = Utils.getComponent(form.components, "agreeToAll", true);
          utils.component.label = `I, ${state.user}, am authorized to submit this on behalf of ${state.company}`
            window.scrollTo(0, 0);
            form.redraw();
        }}
        />
}
