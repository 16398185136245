import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '767px',
        padding: '24px',
        borderRadius: '10px'
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        '& li': {
            listStyle: 'disc',
        },
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        justifyContent: 'space-between'
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '28px !important' }} {...other}>
            {children}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function LoginErrorDialog({ open, setOpen, type }) {
    const history = useHistory();
    const handleClose = () => {
        setOpen(false);
        history.push({
            pathname: "/Home",
            search: '?accountIsActive=0',
        });
    };

    return (<>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title"
                variant='h3'
                onClose={handleClose}>
                {type}
            </BootstrapDialogTitle>
            <DialogContent>
                <Typography gutterBottom
                    className='univers-55roman-normal-mine-shaft-16px'>

                </Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={handleClose}
                    className='button-large univers-65-bold-white-16px'
                >
                    OK
                </Button>
            </DialogActions>
        </BootstrapDialog>
    </>)
}
