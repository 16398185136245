const AssetLoader = (() => {
  let instance;

  const init = () => {
    const preloadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    };

    const preloadMultipleImages = (urls) => {
      const promises = urls.map(url => preloadImage(url));
      return Promise.all(promises);
    };

    return {
      preloadImage,
      preloadMultipleImages
    };
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = init();
      }
      return instance;
    }
  };
})();

export default AssetLoader;