
export const dispatchStartAutoLogOutTimerEvent = () => {
    const eventBus = document;
    const resetInputsEvent = new CustomEvent("StartAutoLogOutTimer");
    eventBus.dispatchEvent(resetInputsEvent);
}

export const dispatchSessionExpiredDialogEvent = () => {
    const eventBus = document;
    const resetInputsEvent = new CustomEvent("SessionExpiredDialog");
    eventBus.dispatchEvent(resetInputsEvent);
}

export const dispatchCloseLogOffAlertEvent = () => {
    const eventBus = document;
    const resetInputsEvent = new CustomEvent("CloseLogOffAlert");
    eventBus.dispatchEvent(resetInputsEvent);
}

export const dispatchDisplayNameEvent = () => {
    const eventBus = document;
    const resetInputsEvent = new CustomEvent("DisplayName");
    eventBus.dispatchEvent(resetInputsEvent);
}