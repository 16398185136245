import applicantcomment from './Comment/ApplicantComment';
import comment from './Comment/Comment';
import review from './Review/Review';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  comment,
  applicantcomment,
  review,
};
