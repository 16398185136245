import { Box, Divider, Stack, useMediaQuery } from "@mui/material";
import mpalogo from "../../Images/logo-mpa-white@1x.png";
import { Link } from "react-router-dom";
import { config } from "../../config";
import SocialMedia from "./SocialMedia";
import youtubeIcon from "../../Images/SocialMediaIcons/youtube.png";
import instagramIcon from "../../Images/SocialMediaIcons/instagram.png";
import facebookIcon from "../../Images/SocialMediaIcons/facebook.png";
import twitterIcon from "../../Images/SocialMediaIcons/twitter.png";
import tiktokIcon from "../../Images/SocialMediaIcons/tik-tok.png";
import linkedinIcon from "../../Images/SocialMediaIcons/linkedin.png";
import telegramIcon from "../../Images/SocialMediaIcons/telegram.png";

export default function Footer() {

  function SocialMediaFunction() {
    const defaultSizeWidth = "auto";
    const defaultSizeHeight = "21px";
    const socialMediaData = [
      {imageUrl: instagramIcon, linkUrl: "https://www.instagram.com/maritime_sg/?hl=en", iconWidth: defaultSizeWidth, iconHeight: defaultSizeHeight},
      {imageUrl: facebookIcon, linkUrl: "https://www.facebook.com/MPA.SG", iconWidth: defaultSizeWidth, iconHeight: "30px"},
      {imageUrl: twitterIcon, linkUrl: "https://twitter.com/MPA_Singapore", iconWidth: defaultSizeWidth, iconHeight: defaultSizeHeight},
      {imageUrl: youtubeIcon, linkUrl: "https://youtube.com/c/MPASingaporeOfficial", iconWidth: defaultSizeWidth, iconHeight: defaultSizeHeight},
      {imageUrl: linkedinIcon, linkUrl: "https://sg.linkedin.com/company/mpasingapore", iconWidth: defaultSizeWidth, iconHeight: defaultSizeHeight},
      {imageUrl: tiktokIcon, linkUrl: "https://www.tiktok.com/@maritimesg", iconWidth: defaultSizeWidth, iconHeight: defaultSizeHeight},
      {imageUrl: telegramIcon, linkUrl: "https://t.me/MPASingapore", iconWidth: defaultSizeWidth, iconHeight: defaultSizeHeight},
    ];

    return (
      <>
      {
        socialMediaData.map(({ imageUrl, linkUrl, iconWidth, iconHeight}, index) => (
          <SocialMedia 
          key={index}
          imageUrl={imageUrl} 
          linkUrl={linkUrl} 
          iconWidth={iconWidth} 
          iconHeight={iconHeight}
          />
        ))
      }
      </>
    )
  }

  return (
    <div
      className="noPrint footer-padding"
      style={{
        position: "unset",
        backgroundImage: "unset",
        backgroundColor: "#333333",
        width: "100%",
        display: "flex",
        minHeight: "471px",
        flexDirection: "column",
      }}
    >
      <div className="usp">
        <div className="one-maritime-portal">One Maritime Portal</div>
        <div className="description">
          A one-stop portal for individuals and businesses to search and apply
          for various grants and incentives administered by MPA.
        </div>
      </div>

      {useMediaQuery("(max-width: 590px)") ? (
        <div>
          {" "}
          <div className="flex-row-3" style={{ minWidth: "unset" }}>
            <div className="powered-by">
              <div className="power-by">Powered by</div>
              <a
                href="https://www.mpa.gov.sg"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="logompa-white" src={mpalogo} alt="mpa-logo" />
              </a>
            </div>
            <div style={{ flexGrow: 1 }} />
            <Stack direction="row" display="flex">
            <div className="social-media" style={{ height: "22px", textAlign: "right", width: "100%" }}>
              {SocialMediaFunction()}
            </div>
            </Stack>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex-row-3" style={{ minWidth: "unset" }}>
            <div className="powered-by">
              <div className="power-by">Powered by</div>
              <a
                href="https://www.mpa.gov.sg"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="logompa-white" src={mpalogo} alt="mpa-logo" />
              </a>
            </div>
            <div style={{ flexGrow: 1 }} />
            <Stack direction="row" display="flex">
              <div className="contact-us univers-55roman-normal-white-16px">
                <Link to="/ContactUs" className="footer-link">
                  Contact Us
                </Link>
              </div>
              <div className="social-media" style={{ height: "22px", textAlign: "right", width: "100%" }}>
                {SocialMediaFunction()}
              </div>
            </Stack>
          </div>
        </div>
      )}

      <Divider
        sx={{ backgroundColor: "#6a6a6a", width: "unset", mr: 0, mt: "43px" }}
      />

      {
        useMediaQuery("(max-width: 590px)") ? (
          <div
            style={{
              alignItems: "flex-start",
              marginTop: "25px",
              width: "100%",
            }}
          >
            <div
              className="contact-us univers-55roman-normal-white-16px"
              style={{ marginRight: "78px", marginBottom: "16px" }}
            >
              <Link to="/ContactUs" className="footer-link">
                Contact Us
              </Link>
            </div>
            <div
              className="report-vulnerability univers-55roman-normal-white-16px"
              style={{ marginRight: "78px", marginBottom: "16px" }}
            >
              <a
                href="https://www.tech.gov.sg/report_vulnerability"
                target="_blank"
                className="footer-link"
                rel="noreferrer noopener"
              >
                Report Vulnerability
              </a>
            </div>
            <div
              className="privacy-policy univers-55roman-normal-white-16px"
              style={{
                marginLeft: "unset",
                marginBottom: "16px",
              }}
            >
              <a
                href="https://www.mpa.gov.sg/footer/privacy-statement"
                target="_blank"
                className="footer-link"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
            </div>
            <div
              className="terms-of-use univers-55roman-normal-white-16px"
              style={{ marginLeft: "unset", marginBottom: "16px" }}
            >
              <a
                href="https://www.mpa.gov.sg/footer/terms-of-use"
                target="_blank"
                className="footer-link"
                rel="noreferrer noopener"
              >
                Terms of Use
              </a>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div
              className="copyright"
              style={{ marginLeft: "unset", height: "unset" }}
            >
              <p className="x2020-maritime-and univers-55roman-normal-white-16px">
                © 2023 Maritime and Port Authority of Singapore<br />
                Last Updated {config.deploymentDate}.
              </p>
            </div>
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "space-around",
              mt: "25px",
              width: "100%",
            }}
          >
            <Box className="report-vulnerability univers-55roman-normal-white-16px">
              <a
                href="https://www.tech.gov.sg/report_vulnerability"
                target="_blank"
                className="footer-link"
                rel="noreferrer noopener"
              >
                Report Vulnerability
              </a>
            </Box>
            <Box className="privacy-policy univers-55roman-normal-white-16px">
              <a
                href="https://www.mpa.gov.sg/footer/privacy-statement"
                target="_blank"
                className="footer-link"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
            </Box>
            <Box className="terms-of-use univers-55roman-normal-white-16px">
              <a
                href="https://www.mpa.gov.sg/footer/terms-of-use"
                target="_blank"
                className="footer-link"
                rel="noreferrer noopener"
              >
                Terms of Use
              </a>
            </Box>
            <Box className="copyright" sx={{ flexGrow: 1, ml: "unset" }}>
              <p className="x2020-maritime-and univers-55roman-normal-white-16px">
                © 2023 Maritime and Port Authority of Singapore <br />
                Last Updated {config.deploymentDate}.
              </p>
            </Box>
          </Box>
        )
      }
    </div>
  );
}
