import { Card, Typography, useMediaQuery } from "@mui/material";
import { IconArrowRight } from '@tabler/icons';
import { useHistory } from 'react-router-dom'
import "../GuidedJourney.css"

function MSICard() {
    const history = useHistory();
    return (
        <>
        {useMediaQuery('(max-width: 465px)') ?
        <Card className="resp465 journey-card" sx={{width: 300, padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <Typography variant='h4'>
                    Maritime Sector Incentive (MSI)
                </Typography>Enjoy tax exemption or concessionary tax rate on qualifying shipping income for a qualifying period.
                <br />
                <a className='clickContactText' onClick={() => history.push('/MSIScheme')}>Learn more <IconArrowRight /></a>

            </div>

        </Card>
        :
        <Card className="journey-card" sx={{ height: 400, width: 400, padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
                <Typography variant='h4'>
                    Maritime Sector Incentive (MSI)
                </Typography>
                <br />
                Enjoy tax exemption or concessionary tax rate on qualifying shipping income for a qualifying period.
                <br />
                <a className='clickContactText' onClick={() => history.push('/MSIScheme')}>Learn more <IconArrowRight /></a>
                {/* <a href="/MSIScheme" style={{fontWeight: 'bold', marginLeft:'5px'}}>Learn more <IconArrowRight /></a></>   */}
                {/* <br /><br />
                <a href="https://www.mpa.gov.sg/maritime-singapore/what-maritime-singapore-offers/pro-business-environment">https://www.mpa.gov.sg/maritime-singapore/what-maritime-singapore-offers/pro-business-environment</a> */}
                {/* <Button sx={{ mt: 1, textTransform: 'unset' }}>
                    <Typography align="left" variant='h6' color={'#0065bd'}><a href="https://www.mpa.gov.sg/maritime-singapore/what-maritime-singapore-offers/pro-business-environment">https://www.mpa.gov.sg/maritime-singapore/what-maritime-singapore-offers/pro-business-environment</a></Typography>

                </Button> */}
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant='contained'
                    className='univers-65-bold-white-16px'
                    sx={{
                        height: '48px',
                        background: 'linear-gradient(270deg, #0088ce 0%, #0065bd 100%)',
                        borderRadius: 6,
                        textTransform: 'unset'
                    }}>
                    Apply
                </Button>
            </div> */}

            {/* <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant='contained'
                    className='univers-65-bold-white-16px'
                    sx={{
                        height: '48px',
                        background: 'linear-gradient(270deg, #0088ce 0%, #0065bd 100%)',
                        borderRadius: 6,
                        textTransform: 'unset',
                        top: '25px',
                        marginRight: '25px'
                    }}>
                    Apply
                </Button>
            </div> */}

        </Card>
        }
        </>
    );
}

export default MSICard;
