import AxiosApiInstance from "./AxiosApiInstance";
import AxiosFileApiInstance from "./AxiosFileApiInstance";
import FileDownload from "js-file-download";

export async function fetch(url, method, data) {

	let request = {
		method: method,
		url: url,
		data: data
	}

	if (method === "GET") {
		request = {
			method: method,
			url: url
		}
	}

	return AxiosApiInstance(request)
		.then(res => {
			return res
		})
		.catch(error => {
			return Promise.reject(error)
		})
}

export async function postFormToServer(actionName, formData) {

	return AxiosApiInstance({
		method: 'post',
		url: actionName,
		data: formData,
		headers: {
			'Content-Type': `multipart/form-data, boundary=${formData._boundary}`
		}
	})
		.then(res => {
			return res;
		})
		.catch(error => {
			return error;
		})
}

export async function getDownload(url, fileName) {
	let request = {
		method: "GET",
		url: url,
	}

	return AxiosFileApiInstance(request)
		.then(response => {
			return FileDownload(response.data, fileName);
		})
		.catch(error => {
			return Promise.reject(error)
		})
}

export async function deleteDownload(url) {
	let request = {
		method: "DELETE",
		url: url,
	}

	return AxiosFileApiInstance(request)
		.then(response => {
			return response;
		})
		.catch(error => {
			return Promise.reject(error)
		})
}

export async function postDownload(actionName, formData, fileName) {

	return AxiosFileApiInstance({
		method: 'post',
		url: actionName,
		data: formData,
		headers: {
			'Content-Type': `multipart/form-data, boundary=${formData._boundary}`
		}
	})
		.then(response => {
			return FileDownload(response.data, fileName);
		})
		.catch(error => {
			return error;
		})
}