const ApplicationStatusOptions = {
    Draft: 1,
    Deleted : 2,
    Processing : 3,
    Approved : 4,
    Rejected : 5,
    Withdrawn : 6,
    Lapsed : 7,
    Acknowledged: 8,
    Returned: 9,
    Terminated: 10,
    Completed: 11,
    Disbursed: 12,
    Ceased: 13,
    Returnedforcomment: 14,
    ApprovedByMOF: 15,
    RejectedByMOF: 16,
    Closed: 17,
    PendingMOFAppeal: 18,
    MOFAppealSuccessful: 19,
    MOFAppealRejected: 20,
    Expired: 21,
    Void:22

}

export const ApplicationStatusTextOptions = {
    Draft: "Draft",
    Deleted: "Deleted",
    Processing: "Processing",
    Approved: "Approved",
    Rejected: "Rejected",
    Withdrawn: "Withdrawn",
    Lapsed: "Lapsed",
    Acknowledged:"Acknowledged",
    Returned: "Returned",
    Terminated: "Terminated",
    Completed: "Completed",
    Disbursed: "Disbursed",
    Ceased: "Ceased",
    Returnedforcomment: "Returnedforcomment",
    ApprovedByMOF: "ApprovedByMOF",
    RejectedByMOF: "RejectedByMOF",
    Closed: "Closed",
    PendingMOFAppeal: "PendingMOFAppeal",
    MOFAppealSuccessful: "MOFAppealSuccessful",
    MOFAppealRejected: "MOFAppealRejected",
    Expired: "Expired",
    Void: "Void"
}

export default ApplicationStatusOptions