import {
  AppBar,
  Backdrop,
  Box,
  Button,
  ClickAwayListener,
  Container,
  Grow,
  IconButton,
  Link as MuiLink,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { IconMenu2, IconX } from "@tabler/icons";
import PropTypes from "prop-types";
import { fetch } from "../../Common/API"
import { config } from "../../config"
import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import loginHelper from '../../Common/loginHelper';
import Merlion from "../../Images/logo-merlion-10@1x.png";
import "./Header.css";
import "@webcomponents/scoped-custom-element-registry"
import "@govtechsg/sgds-web-component";
import "@govtechsg/sgds-web-component/components/Masthead";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
          <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
          >
            {value === index && (
                    <Box sx={{p: 3}}>
                      <Typography>{children}</Typography>
                    </Box>
            )}
          </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTabs = styled((props) => (
        <Tabs
                {...props}
                TabIndicatorProps={{
                  children: <span className="MuiTabs-indicatorSpan"/>,
                }}
        />
))({
  "& .MuiTabs-indicator": {
    height: "5px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 55,
    width: "100%",
    backgroundColor: "#0065BD",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
        ({theme}) => ({
          fontWeight: "bold",
          marginRight: 15,
          fontSize: 16,
          lineHeight: 4.5,
          "&:hover": {},
          "&.Mui-selected": {
            color: "black",
          },
          "&.Mui-focusVisible": {},
        })
);

export default function ApplicantHeader() {
  let loginUser = loginHelper.getLoginUser();
  let role = loginUser?.CorppassRole;
  const location = useLocation();
  const history = useHistory();
  const eventBus = document;
  const [anchorEl, setAnchorEl] = useState(0);
  const [anchorEl2, setAnchorEl2] = useState(0);
  const [anchorElHamburger, setAnchorElHamburger] = useState(null);
  const [openHamburger, setOpenHamburger] = useState(false);
  const [userName, setUserName] = useState(loginUser?.Name?.length > 25 ? loginUser?.Name?.slice(0, 20) + "..." : loginUser?.Name);
  let companyName = loginUser?.CompanyName?.length > 35 ? loginUser?.CompanyName?.slice(0, 30) + "..." : loginUser?.CompanyName ?? "Company Name"

  function handleClose1() {
    setAnchorEl(null);
  }

  function handleOpen2(e) {
    setAnchorEl2(e.currentTarget);
  }

  function handleClose2() {
    setAnchorEl2(null);
  }

  const open2 = Boolean(anchorEl2);

  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  function checkPath() {
    const x = location.pathname;
    switch (x) {
      case '/Dashboard':
        return '/Dashboard';
      case '/PublicUser':
        return '/PublicUser';
      case '/Incentives':
        return '/Incentives';
      case '/Grants':
        return '/Grants';
      default:
        return false;
    }
  }

  function handleOpenHamburger(e) {
    if (openHamburger === false) {
      setAnchorElHamburger(e.currentTarget.parentElement.parentElement.parentElement);
      setOpenHamburger(true);
    } else
      setOpenHamburger(false);
  }

  function logoutAction() {
    loginHelper.deleteLoginInfo();
    window.location.pathname = "/";
  }

  function displayName() {
    const loginUser = loginHelper.getLoginUser(); 
    const name = loginUser?.Name?.length > 25 ? loginUser?.Name?.slice(0, 20) + "..." : loginUser?.Name;
    setUserName(name);
  }

  eventBus.removeEventListener("DisplayName", displayName);
  eventBus.addEventListener("DisplayName", displayName);

  return (
          <div>
            <AppBar position="sticky" elevation={0}>
              <div className='agencyheader1'>
                <div className='agencyheader2'>
                  <Container maxWidth={'1200px'} sx={{marginLeft: '0px', zIndex: 100}}>
                   
                    {/* <MuiLink href='https://www.gov.sg' underline='none' sx={{display: 'flex', alignItems: 'center'}}>
                      <img
                              src={Merlion}
                              height={16}
                              style={{marginRight: 22}}
                              alt="merlion"
                      />
                      <div className="univers-55roman-normal-mine-shaft-14px" style={{whiteSpace: "nowrap"}}>
                        A Singapore Government Agency Website
                      </div>
                    </MuiLink> */}
                  </Container>
                </div>
              </div>
              <Container className="top-navi" style={{paddingTop: 0, paddingBottom: 0}}>
                <Container
                        maxWidth={false}
                        className="header-inner-container">
                  <div style={{display: 'flex', cursor: 'pointer', paddingTop: 20, height: 96}}
                       onClick={() => {
                         if (location.pathname !== '/Home') history.push('/Home')
                       }}>
                    <img src={`${process.env.PUBLIC_URL}${config?.logoURL}`} height={55} style={{marginRight: 12.7}} alt="logo"/>
                    <Typography className="optima-bold-mine-shaft-16px OMP-right-margin"
                                sx={{lineHeight: 1.15, color: '#333333', cursor: 'pointer'}}>
                      One<br/>Maritime<br/>Portal
                    </Typography>
                  </div>
                  <div className="responsive-header2">
                    <StyledTabs
                            value={checkPath()}
                            onChange={handleChangeTab}
                            aria-label="header tabs"
                    >
                      <StyledTab label="DASHBOARD" value={'/Dashboard'} component={Link} to={'/Dashboard'}/>
                      {
                        (role === "Admin") ?
                                <StyledTab label="USERS MANAGEMENT" value={'/PublicUser'} component={Link}
                                           to={'/PublicUser'}/>
                                :
                                null
                      }
                    </StyledTabs>
                  </div>
                  <div style={{flexGrow: 1}}/>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Button sx={{
                      textTransform: "none",
                      ml: 3
                    }} onClick={handleOpen2}>
                      <Stack>
                        <Typography color="black">{userName}</Typography>
                        <Typography variant="caption" color="gray">
                          {companyName}
                        </Typography>
                      </Stack>
                    </Button>
                    <IconButton sx={{ml: 2}} className='responsive-hamburger2' onClick={handleOpenHamburger}>
                      {openHamburger ? <IconX/> : <IconMenu2/>}
                    </IconButton>
                  </div>
                </Container>
              </Container>
            </AppBar>
            <Backdrop open={open2} onClick={handleClose2} sx={{zIndex: 2}}>
              <Popper
                      anchorEl={anchorEl2}
                      open={open2}
                      placement="bottom-start"
                      transition
                      disablePortal
                      sx={{
                        transform: "translateY(38px)",
                      }}
              >
                {({TransitionProps, placement}) => (
                        <Grow
                                {...TransitionProps}
                                style={{
                                  transform: "translateY(38px)",
                                }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose1}>
                              <MenuList>
                                <MenuItem
                                        onClick={() => {
                                          let {Id, Name} = loginHelper.getLoginUser();
                                          fetch(`${config.appURL}/api/InternetPublicUser/RecordLogout`, "POST", {
                                            "Id": Id,
                                            "Name": Name
                                          }).then((resp) => {
                                            logoutAction();
                                          })
                                                  .catch((error) => {
                                                    logoutAction();
                                                  });
                                        }}
                                >
                                  LOG OUT
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                )}
              </Popper>
            </Backdrop>
            <Popper anchorEl={anchorElHamburger} open={openHamburger} sx={{zIndex: 1101, width: '100%', height: '80vh'}}
                    className='responsive-hamburger2' placement='bottom-start' disablePortal>
              <Paper sx={{height: '-webkit-fill-available', boxShadow: 'none'}}>
                <MenuList>
                  <MenuItem onClick={() => history.push('/Dashboard')}>DASHBOARD</MenuItem>
                  {
                    (role === "Admin") ?
                            <MenuItem onClick={() => history.push('/PublicUser')}>USERS MANAGEMENT</MenuItem>
                            : null
                  }
                </MenuList>
              </Paper>
            </Popper>
          </div>
  );
}
