import { configureStore } from '@reduxjs/toolkit';

import applicationListingReducer from "./ApplicationListing/ApplicationListingSlice";
import nestedApplicationListingReducer from "./NestedApplicationListing/ApplicationListingSlice";
import allDocumentsListReducer from "./AllDocumentsList/AllDocumentsListSlice";
import inboxListReducer from "./Inbox/InboxListSlice";
import AcceptanceListReducer from './Acceptance/AcceptanceSlice';
import PublicUserListingReducer from './PublicUserListing/PublicUserListingSlice';
import PublicUserReducer from './PublicUserListing/PublicUserSlice';
import WHTFormReducer from './CommonForm/CommonFormSlice.js.js';

export default configureStore({
	reducer: {
       applicationListing: applicationListingReducer,
       nestedApplicationListing: nestedApplicationListingReducer,
	   allDocumentsList: allDocumentsListReducer,
	   inboxList: inboxListReducer,
	   AcceptanceList: AcceptanceListReducer, 
	   publicUserList: PublicUserListingReducer,
	   publicUser: PublicUserReducer,
	   WHTForm : WHTFormReducer,
	},
});