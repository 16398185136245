import { Typography } from "@mui/material";
import eServices from "../../../Images/E-Services.png";

function GeneralPage() {
  return (
    <>
      <Typography variant="h3">General</Typography>
      <br />

      <Typography variant="h4" sx={{ textDecoration: "underline" }}>
        General
      </Typography>
      <br />

      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        <strong> 1. What are the supported browsers?</strong>
        <br />
        <br />
        <div className="IDPText">
          One Maritime Portal supports modern browsers that receive regular
          updates to improve performance and address security issues. We
          recommend that all our visitors ensure that they are using up-to-date
          versions of the following browsers for an optimal experience:
        </div>
        <div className="IDPText_NoJustify">
          <br />
          <br />
          • Google Chrome (Recommended)
          <br />
          • Mozilla Firefox
          <br />
          • Apple Safari
          <br />
          • Microsoft Edge
          <br />
          <br />
          <br />
        </div>

        <strong>2. How do I log in to One Maritime Portal?</strong>
        <br />
        <br />
        <div className="IDPText">
          To log in to One Maritime Portal, click on the Login button at the top
          right of the home page and click on{" "}
          <a href="/Dashboard">“Login As Organisation”</a>.
          <br />
          <br />
          <br />
        </div>

        <strong>3. How do I register for a Corppass Account?</strong>
        <br />
        <br />
        <div className="IDPText">
          To register for a Corppass account, please refer to the{" "}
          <a
            href="https://www.corppass.gov.sg/corppass/common/userguides"
            target="_blank"
            rel="noreferrer noopener"
          >
            Corppass Registration Guide.
          </a>{" "}
          Please select <b>One Maritime Portal</b> as the e-services as shown
          below.
          <br />
          <br />
          <br />
        </div>
        <div class="click-zoom">
          <label>
            <input type="checkbox" />
            <img
              src={eServices}
              className="WHTTable1Img imgZoom"
              alt="E-services"
            />
          </label>
        </div>
        <br />
        <br />
      </Typography>
    </>
  );
}
export default GeneralPage;
