import CheckMatrixEditDisplay from './editForm/Comment.edit.display';

const nestedComponentForm = require('formiojs/components/_classes/nested/NestedComponent.form');
export default function(...extend: any) {
  return nestedComponentForm([
    {
      key: 'display',
      components: CheckMatrixEditDisplay
    }
  ], ...extend);
}
