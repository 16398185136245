import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { rejectLetterOfOfferApplication_APIAsync } from "../../Redux/Acceptance/AcceptanceSlice";
import AcceptanceRejectDialog from './AcceptanceRejectDialog';
import { useState } from 'react';
import AutoFocus from "../../Common/AutoFocus";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '767px',
        padding: '24px',
        borderRadius: '10px'
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        '& li': {
            listStyle: 'disc',
        },
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        justifyContent: 'space-between'
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '28px !important' }} {...other}>
            {children}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AcceptanceConfirmRejectDialog({ open, setOpen, submissionId }) {
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [msg, setMsg] = useState('');
    const [btnStatus, setBtnStatus] = useState(true);
    const dispatch = useDispatch();
    const btn = AutoFocus();

    const handleClose = () => {
        setOpen(false);
    };

    const handleReject = () => {
        setOpen(false);
        setMsg("Reject operation in progress.");
        dispatch(rejectLetterOfOfferApplication_APIAsync({submissionId: submissionId, formId: 'idpmainform'}))
        .unwrap()
        .then(() => {
            setMsg("You have rejected the letter of offer.");
            setBtnStatus(0);
        })
        .catch(() => {
            setMsg("Reject application not successful. Please try again later.");
            setBtnStatus(0);
        });
        setOpenRejectDialog(true);
    };

    return (<>
        <AcceptanceRejectDialog
        open={openRejectDialog}
        setOpen={setOpenRejectDialog}
        submissionId={submissionId}
        msg={msg}
        btnStatus={btnStatus}
        />
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title"
                variant='h3'
                onClose={handleClose}>
                Do you want to irrevocably reject this letter of offer?
            </BootstrapDialogTitle>
            <DialogContent /*dividers*/>
                <Typography gutterBottom
                    className='univers-55roman-normal-mine-shaft-16px'>

                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus focusVisible onClick={handleClose} ref={btn}
                    className='button-cta read-more-text'
                >
                    Cancel
                </Button>
                <Button onClick={handleReject}
                    className='button-large univers-65-bold-white-16px'
                >
                    Proceed
                </Button>
            </DialogActions>
        </BootstrapDialog>
    </>)
}
