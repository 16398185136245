import loginHelper from '../../Common/loginHelper';
import PublicUserListing from "./PublicUserListing";
import "./GlobalCSS.css";
import "./PublicUserListing.css";

function PublicUser(props) {
    let loginUser = loginHelper.getLoginUser();
    let role = loginUser.CorppassRole;
    return (
        <div>
            {role.toUpperCase() !== "ADMIN" ? <></> : <PublicUserListing />}
        </div>
    )
}
export default PublicUser;
