import { config } from "../config";
import { fetch } from "./API";

const srsURL = `${config.appURL}/api/srs`;

export async function get_WHT_SRS_Info(imoNumber) {
  const vesselParticulars = await fetch(
    `${srsURL}/GetVesselParticularsAndCertificateInformationByImonumber?imoNumber=${imoNumber}`,
    "GET"
  ).then((response) => response.data);
  return [vesselParticulars];
}

//certificateNumber
export async function getVesselsByCertNum(certificateNumber) {
  const vesselParticulars = await fetch(
    `${srsURL}/GetVesselParticularsByCertificateNumber?certificateNumber=${certificateNumber}`,
    "GET"
  ).then((response) => response.data);
  return [vesselParticulars];
}
