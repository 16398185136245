import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { IconArrowNarrowRight } from "@tabler/icons";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "767px",
    padding: "24px",
    borderRadius: "10px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    "& li": {
      listStyle: "disc",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: "28px !important" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 32,
            top: 28,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function EligibleDialog({
  open,
  setOpen,
  isEligible,
  ineligibleMessage,
  goToForm,
}) {
  const badMessage = (
    <div>
      <b>
        You are <u>not eligible</u> for the withholding tax exemption.
      </b>
      <br />
      {ineligibleMessage}
    </div>
  );

  const eligibleMessage = (
    <div>
      <b>
        You are <u>eligible</u> for the withholding tax exemption.
      </b>
      <br />
      Please proceed to complete the Self-declaration form to enjoy withholding
      tax exemption.
    </div>
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          variant="h3"
          onClose={handleClose}
        >
          {/* Eligibility Check */}
        </BootstrapDialogTitle>
        <DialogContent /*dividers*/>
          <Typography
            gutterBottom
            className="univers-55roman-normal-mine-shaft-16px"
          >
            {isEligible ? eligibleMessage : badMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <>
            {isEligible ? (
              <Button
                onClick={handleClose}
                className="small button-large-with-arrow-outlined univers-65-bold-science-blue-16px"
              >
                No, later
              </Button>
            ) : (
              <Button
                onClick={handleClose}
                className="small button-small univers-65-bold-white-16px"
              >
                OK
              </Button>
            )}
          </>

          <Button
            sx={{
              display: isEligible ? "inherit" : "none",
              marginRight: "35px",
            }}
            autoFocus
            onClick={() => goToForm()}
            /* The arrow is inside MasterCSS*/
            className="button-large univers-65-bold-white-16px"
            // endIcon={<IconArrowNarrowRight />}
          >
            Proceed
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export function EligibleDialogGrant({
  open,
  setOpen,
  isEligible,
  ineligibleMessage,
  goToForm,
  grantType = "",
}) {
  const badMessage = <div>{ineligibleMessage}</div>;

  const handleClose = () => {
    setOpen(false);
  };

  const button = (
    <Button className="button-large univers-65-bold-white-16px"
      autoFocus
      onClick={handleClose}>
      OK
    </Button>
  );
  const secbutton = (
    <Button className="button-large-with-arrow-outlined univers-65-bold-science-blue-16px"
      sx={{ minWidth: 'unset !important', px: 4 }}
      autoFocus
      onClick={handleClose}>
      No, later
    </Button>
  );

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          variant="h3"
          onClose={handleClose}
        >
          Eligibility Check
        </BootstrapDialogTitle>
        <DialogContent /*dividers*/>
          <Typography
            variant="body1"
            style={{ whiteSpace: "pre-line" }}
            gutterBottom
            className="univers-55roman-normal-mine-shaft-16px"
          >
            {isEligible
              ? "You are eligible for the " +
              grantType +
              "grant, proceed to apply?"
              : badMessage}
          </Typography>
        </DialogContent>
        <DialogActions>

          {isEligible ? secbutton : button}

          <Button
            sx={{ display: isEligible ? "inherit" : "none" }}
            autoFocus
            onClick={() => goToForm()}
            className="button-large univers-65-bold-white-16px"
            endIcon={<IconArrowNarrowRight />}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
