import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import loginHelper from './loginHelper';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '470px',
        padding: '24px',
        borderRadius: '10px'
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        '& li': {
            listStyle: 'disc',
        },
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        justifyContent: 'space-between'
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '28px !important' }} {...other}>
            {children}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function SessionExpiredDialog({ open, setOpen }) {
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            sessionStorage.clear();
            loginHelper.deleteLoginInfo();
            window.location.pathname = "/";
        }
    };

    return (<>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            disableEscapeKeyDown
        >
            <BootstrapDialogTitle id="customized-dialog-title"
                variant='h3'
                onClose={handleClose}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    Your session has expired.
                </div>
            </BootstrapDialogTitle>
            <DialogContent /*dividers*/>
                <Typography gutterBottom
                    className='univers-55roman-normal-mine-shaft-16px'>
                    
                </Typography>
            </DialogContent>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <DialogActions>
                    <Button onClick={handleClose}
                        className='button-large univers-65-bold-white-16px'
                    >
                        OK
                    </Button>
                </DialogActions>
            </div>
        </BootstrapDialog>
    </>)
}