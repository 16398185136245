import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormHtmlForm,
  FormHtmlHead,
  onChangeGeneric,
  onErrorGeneric,
  onRenderGeneric,
  onSubmitDoneGeneric,
  onSubmitGeneric,
} from "../../../Common/ApplicationForm";
import $ from "jquery";
import { config } from "../../../config";
import "../../ApplicationForm/ApplicationForm.css";
import { EligibleDialog } from "./EligibleDialog";
import { fetch } from '../../../Common/API';
import loginHelper from "../../../Common/loginHelper";

SDContainerEligibility.defaultProps = {
  formname: "SD  Container Eligibility",
  formstatus: "New",
  formId: "sdcontainereligibility",
  appFormBaseURL: `${config.appFormBaseURL}`,
  schema: "sdcontainereligibility",
};

let allowScrolling = true;
export default function SDContainerEligibility({
  formname,
  formid,
  formstatus,
  formId,
  appFormBaseURL,
  schema,
  props,
}) {
  const [isBusy, setBusy] = useState(true)
  const [appFormJson, setAppFormJson] = useState({})
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  let _id = params.get("_id");
  let selected = params.get("selected");

  const radioInput = $("input[type='radio']:first-child");

  let createdData = {};

  if (!_id) {
    //new applcation
    _id = "";
  } else {
    // update existing application
    createdData._id = _id.slice(0);
    _id = "submission/" + _id;
  }

  let appFormURL = `${appFormBaseURL}/${formId}/${_id}`;
  let createURL = `${appFormBaseURL}/${formId}/submission`;
  let currentForm = {};
  const [thisForm, setThisForm] = useState();
  const [isEligible, setIsEligible] = useState();
  const [ineligibleMessage, setIneligibleMessage] = useState();
  const [openEligibleDialog, setOpenEligibleDialog] = useState(false);

  const [formReadOnly, setFormReadOnly] = useState(false);

  const [fetchingform, setFetchingform] = useState(true);

  const [disableScrollingFunc, setScrollState] = useState(() => { 
    // This function is intentionally empty. 
  });

  let alreadySubmitted = false;

  useEffect(() => {
    async function getform() {
      setBusy(true);
      let json = await fetch(appFormURL, 'GET', null);
      if (json) {
        setBusy(false);
        setAppFormJson(json.data);
      }
    };
    getform();
  }, [])

  const formReady = (form) => {
    setFetchingform(false);
    currentForm = form;
    currentForm.nosubmit = true;
    setThisForm(currentForm);

    if (createdData._id) {
      // load existing form and hide eligibility page
      currentForm.component.hidden = true;
      currentForm.setPage(1);
    }

    currentForm.on("proceed", async () => {
      history.push({
        pathname: "/WHTContainerForm",
        search: `?path=eligibility&selected=${selected}`,
        refresh: true,
      });
    });

    currentForm.on("cancel", async () => {
      let loginUser = loginHelper.getLoginUser();
      if(loginUser){
        history.push("/Dashboard");
      }
      else{
        history.push("/WHTScheme");
      }
    });
  };

  const onRender = async () => {
    onRenderGeneric(
      currentForm,
      alreadySubmitted,
      createdData,
      createURL,
      appFormBaseURL,
      formId
    );
  };

  const onChange = async (event) => {
    onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
  };

  const onSubmit = async (submission) => {
    onSubmitGeneric(
      submission,
      currentForm,
      alreadySubmitted,
      createdData,
      createURL,
      appFormBaseURL,
      formId,
      schema
    );
  };

  const onError = async (errors) => {
    onErrorGeneric(errors);
  };

  const onSubmitDone = (submission) => {
    onSubmitDoneGeneric(submission);
  };

  const onCustomEvent = ({ type, component, data, event }) => {
    switch (type) {
      case "checkEligibility": {
        if(currentForm.submission.data.questioner0["question1"] ==="no"){
          document.querySelectorAll('input[type=radio]')[0].click(function() {
            radioInput.prop("checked", true).trigger("click");
           });
           document.querySelectorAll('input[type=radio]')[1].click(function() {
            radioInput.prop("checked", true).trigger("click");
           });
        }
        else if(currentForm.submission.data.questioner0["question1"] ==="yes"){
          document.querySelectorAll('input[type=radio]')[1].click(function() {
            radioInput.prop("checked", true).trigger("click");
           });
           document.querySelectorAll('input[type=radio]')[0].click(function() {
            radioInput.prop("checked", true).trigger("click");
           });
        }
        let content_cp = currentForm.components[0].components[18];
        let q_data = [
          currentForm.submission.data.questioner0["question1"],
          currentForm.submission.data.questioner0["question2"],
          currentForm.submission.data.questioner0["question3"],
          currentForm.submission.data.questioner0["question4"],
          currentForm.submission.data.questioner0["question5"],
          currentForm.submission.data.questioner0["question6"],
          currentForm.submission.data.questioner0["question7"],
          currentForm.submission.data.questioner0["question8"],
          currentForm.submission.data.questioner0["question9"],
          currentForm.submission.data.questioner0["question10"],
        ];
        let message = "";
        if (q_data.every((val) => val === "yes")) {
          message = "Please proceed to complete the Self-declaration form to enjoy withholding exemption.";
          setIsEligible(true);
          setOpenEligibleDialog(true);
        } else {
          setIsEligible(false);
        }
        setIneligibleMessage("One or more question(s) was / were not answered with 'Yes'.");
        setOpenEligibleDialog(true);
        break;
      }
      default : {
        break;
      }
    }
    //  disable scrolling before redraw
    setScrollState(() => {
      allowScrolling = false;
    });
    currentForm.redraw();
  };

  function goToForm() {
    if (selected == null) {
      history.push({
        pathname: "/WHTContainerForm",
        search: `?path=eligibility&selected=MSI-ML-Container`,
        refresh: true,
      });
    } else {
      history.push({
        pathname: "/WHTContainerForm",
        search: `?path=eligibility&selected=${selected}`,
        refresh: true,
      });
    }
  }

  return (
    <div className="applicationform">
      <Container>
        <FormHtmlHead formname={formname} schema={schema}/>
        <div>
          <div style={{ display: "block" }}>
            <Typography variant="h2">
              Withholding Tax (WHT) Exemption Eligibility Checker (Container)
            </Typography>
          </div>
          <br />
          <Typography className="univers-55roman-normal-mine-shaft-22px">
            Answer a few questions below to check whether you are eligible for WHT exemption on interest
            and related payments before submitting the self-declaration form.
          </Typography>
          {/* <br />
          <div style={{ display: "flex" }}>
            <IconCheckupList />
            &nbsp;&nbsp;
            <Typography variant="h4">Check your eligibility</Typography>
          </div> */}
        </div>
        <br />
        <FormHtmlForm
          fetchingform={fetchingform}
          formReadOnly={formReadOnly}
          props={props}
          appFormURL={appFormURL}
          onChange={onChange}
          onError={onError}
          formReady={formReady}
          onSubmit={onSubmit}
          onSubmitDone={onSubmitDone}
          onRender={onRender}
          onCustomEvent={onCustomEvent}
          isBusy={isBusy}
          appFormJson={appFormJson}
        />
      </Container>
      <EligibleDialog
        open={openEligibleDialog}
        setOpen={setOpenEligibleDialog}
        isEligible={isEligible}
        ineligibleMessage={ineligibleMessage}
        goToForm={goToForm} />
    </div>
  );
}
