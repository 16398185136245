import * as FormIO from "./model"

type FlatArray = {
    [key: string]: {
        id: string | undefined
        label: string | undefined
    }
}

type AnyComponent = FormIO.FormIOComponent[] | FormIO.PurpleComponent[] | FormIO.StickyComponent[] | FormIO.IndecentComponent[] | FormIO.CunningComponent[] | FormIO.FluffyComponent[]

export default function flatComponent(data: any) {
    const obj: FormIO.FormIO = data
    let flatArray : FlatArray = {}
    const flatData = recursive_loader(obj.components, flatArray)
    return flatData
}

function recursive_loader(components: AnyComponent, ref: FlatArray) {
    for (const component of components) {
        if ('components' in component ) {
            if (component.components && component.components.length > 0) {
                recursive_loader(component.components, ref)
            }
        }
        if(component.key) {
            ref[`${component.id}-${component.key}`] = {
            // ref[`${component.id}`] = {
                id: component.id,
                label: component.label
            }
        }
    }

    return ref
}

