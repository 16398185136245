import { Button, Container, Grid, Paper, Typography, } from "@mui/material";
import { IconArrowNarrowRight, IconPrinter } from "@tabler/icons";
import Formio from "formiojs/Formio";
import $ from "jquery";
import loginHelper from "./loginHelper";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { createOrSave } from "../Components/../Common/ApplicationForm";
import { config } from "../Components/../config";
import StatusChip from "../Components/ApplicationForm/StatusChip";
import SubmissionErrorDialog from "../Common/SubmissionErrorDialog";
import DuplicateSubmissionErrorDialog from "../Common/DuplicateSubmissionErrorDialog";
import { getApplicationListingPaginationAsync } from "../Redux/NestedApplicationListing/ApplicationListingSlice";
import { useDispatch } from "react-redux";
import ActionDialog from "../Common/ActionDialog";
import ProgressBar from "../Common/progressBar";
import moment from 'moment';
import { GenericFileUploadOnRender } from "./GenericForm";

export default function FormReview({
  _id,
  refresh,
  formname,
  caseID,
  formstatus,
  canSubmit,
  formId,
  pathname,
  schemaroute,
  onReadyCallback = () => {
    // This function is intentionally empty. 
  },
  otherOptionsCallback = () => {
    // This function is intentionally empty. 
  },
  options = {},
}) {
  if (refresh === true) {
    // I'm using this to auto refresh the data
    window.location.reload(false); // if full validation checks done before review, then may not have to refresh to get updated answers
  }
  const appFormBaseURL = `${config.appFormBaseURL}`;
  let createdData = {_id: _id};
  const appFormURL = `${appFormBaseURL}/${formId}/submission/${_id}`;
  const createURL = `${appFormBaseURL}/${formId}/submission`;
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [typeDialog, setTypeDialog] = useState();
  const [actionName, setActionName] = useState();

  const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false);
  const [
    openDuplicateSubmissionStatusDialog,
    setOpenDuplicateSubmissionStatusDialog,
  ] = useState(false);
  const [currentForm, setCurrentForm] = useState();
  const [startSpinner, setStartSpinner] = useState(false);
  const [ceaseDate, setCeaseDate] = useState();
  let reload = false;

  const ref = useRef();

  let alreadySubmitted;

  function saveCreatedData(x) {
    createdData = x;
  }

  if (typeof formstatus === 'undefined' || formstatus === null || formstatus?.toLowerCase() === "all")
    formstatus = "Draft";

  useEffect(() => {
    setStartSpinner(true);
    const instance = new Formio(appFormURL);
    instance
      .loadForm(
        {},
        {
          headers: {
            Authorization: "Bearer " + loginHelper.getLoginJwt(),
          },
        }
      )
      .then((form) => {
        Formio.createForm(document.getElementById("formio"), form, {
          readOnly: true,
          viewAsHtml: true,
          renderMode: "flat",
          collapsed: true,
          sanitizeConfig: {
            allowedAttrs: [],
            allowedTags: [],
            addTags: [],
            addAttr: []
          },
          ...options,
        })
          .then((form) => {
            onReadyCallback(form);
            setCurrentForm(form);

            instance
              .loadSubmission(
                {},
                {
                  headers: {
                    Authorization: "Bearer " + loginHelper.getLoginJwt(),
                  },
                }
              )
              .then(function (submission) {
                // Modify form submission
                convertUTCDateToLocaleDate(submission);
                form.submission = submission;

                const cDate = submission.data.configContainer?.cessationDate;
                if (cDate) {
                  const formattedDate = moment(cDate)?.format('DD MMM YYYY');
                  setCeaseDate(formattedDate ?? '');
                }

                // Handle custom render event and call back after form submission data loaded
                otherOptionsCallback(form);
                hideReviewFirstPage(formId, form);

                // JQuery modification required to be placed here, whenever redraw event occurs
                form.on("redraw", (changed) => {
                    handleClick();
                    removeCustomComponents();
                    GenericFileUploadOnRender(true, form, setStartSpinner);
                    
                    // JQuery to add in readonly instruction for download vessel template button, needed this to overwrite the original review page remove all button components. 
                    const buttonVesselUploadTemplateForeignComp = form.getComponent("buttonVesselUploadTemplateForeign");
                    if(buttonVesselUploadTemplateForeignComp) {
                      const targetElement = $(buttonVesselUploadTemplateForeignComp.element);
                      if(targetElement) {
                        const targetLabel = buttonVesselUploadTemplateForeignComp?.component?.label;
                        const newElement = $(targetLabel);
                        if(newElement) {                        
                          targetElement.empty();
                          targetElement.append(newElement);
                        }
                      }
                    }
                });

                turnOffSpinner();
              })
              .catch((error) => {
                console.error("Unauthorized");
                setStartSpinner(false);
              });
          })
          .catch((error) => {
            setStartSpinner(false);
          });
      })
      .catch((error) => {
        setStartSpinner(false);
      });

    return () => {
      reload = true;
    };
  }, []);

  const turnOffSpinner = () => {
    if(reload) {
      reload = false;
    } else {
      setTimeout(() => {
        setStartSpinner(false);
      }, 0)
    }
  }

  const removeCustomComponents = () => {
    if (
      document.querySelectorAll(".formio-component-applicantcomment").length
    ) {
      $(".formio-component-applicantcomment").remove();
    }

    if (document.querySelectorAll(".formio-component-comment").length) {
      $(".formio-component-comment").remove();
    }

    if (document.querySelectorAll(".formio-component-review").length) {
      $(".formio-component-review").remove();
    }
  };

  const hideReviewFirstPage = (id, form) => {
    const formIdLowercase = id?.toLowerCase();

    form.components[0].component.hidden = ( formIdLowercase === config.form_name_target_container_main?.toLowerCase() ||
                                            formIdLowercase === config.form_name_target_container_mof?.toLowerCase() ||
                                            formIdLowercase === config.form_name_target_container_cr?.toLowerCase() ||
                                            formIdLowercase === config.form_name_target_ship_main?.toLowerCase() ||
                                            formIdLowercase === config.form_name_target_ship_mof?.toLowerCase() ||
                                            formIdLowercase === config.form_name_target_ship_cr?.toLowerCase() ||
                                            formIdLowercase === config.form_name_target_msi_main?.toLowerCase()); //important info is hidden
  }

  const convertUTCDateToLocaleDate = (submission) => {
    switch (formId.toLowerCase()) {
      case config.form_name_target_ship_main?.toLowerCase():
      case config.form_name_target_ship_cr?.toLowerCase():
      case config.form_name_target_ship_mof?.toLowerCase():
      case config.form_name_target_container_main?.toLowerCase():
      case config.form_name_target_container_mof?.toLowerCase():
      case config.form_name_target_container_cr?.toLowerCase():
        handleConvertWhtShipMainToLocalDate(submission);
        break;
      case config.form_name_target_idp_main?.toLowerCase():
      case config.form_name_target_idp_cr?.toLowerCase():
      case config.form_name_target_idp_claim?.toLowerCase():
        handleConvertIdpMainToLocalDate(submission);
        break;

      default:
        break;
    }
  };

  const handleConvertWhtShipMainToLocalDate = (submission) => {
    let showDebugDate = false; // Dont delete this variable, let it stay, it's for debugging. Set it to true if u want debug, set it to false to use realtime data
    let testDate = "2023-02-14T00:00:00+08:00";

    //----------------------------------------------------------//
    // Handle Datagrid
    //----------------------------------------------------------//
    if (
      submission.data.containerParticulars?.containerShipVessel
        ?.dataGridVessel1 !== undefined &&
      submission.data.containerParticulars?.containerShipVessel
        ?.dataGridVessel1 !== null
    ) {
      // Datagrid->Vessel Particular
      let size =
        submission.data.containerParticulars?.containerShipVessel
          ?.dataGridVessel1?.length ?? 0;
      for (let i = 0; i < size; ++i) {
        let index = i.toString();

        // Vessel Particular -> Delivery Date (Normal)
        {
          let dateUTC =
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["deliveryDate3"];
          if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
            let dateLocal = new Date(dateUTC);
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["deliveryDate3"] = showDebugDate
              ? testDate
              : dateLocal;
          }
        }

        // Vessel Particular -> dateOfRegistration (Normal)
        {
          let dateUTC =
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["dateOfRegistration"];
          if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
            let dateLocal = new Date(dateUTC);
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["dateOfRegistration"] = showDebugDate
              ? testDate
              : dateLocal;
          }
        }

        // Vessel Particular -> Delivery Date (Edit Vessel)
        {
          let dateUTC =
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["edit_deliveryDate"];
          if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
            let dateLocal = new Date(dateUTC);
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["edit_deliveryDate"] = showDebugDate
              ? testDate
              : dateLocal;
          }
        }

        // Vessel Particular -> dateOfRegistration (Edit Vessel)
        {
          let dateUTC =
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["edit_dateOfRegistration"];
          if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
            let dateLocal = new Date(dateUTC);
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["edit_dateOfRegistration"] =
              showDebugDate ? testDate : dateLocal;
          }
        }

        // Vessel Particular -> Delivery Date (Replace Vessel)
        {
          let dateUTC =
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["replacement_deliveryDate"];
          if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
            let dateLocal = new Date(dateUTC);
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["replacement_deliveryDate"] =
              showDebugDate ? testDate : dateLocal;
          }
        }

        // Vessel Particular -> dateOfRegistration (Replace Vessel)
        {
          let dateUTC =
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["replacement_dateOfRegistration"];
          if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
            let dateLocal = new Date(dateUTC);
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["replacement_dateOfRegistration"] =
              showDebugDate ? testDate : dateLocal;
          }
        }

        // Vessel Particular -> Delivery Date (Delete Vessel)
        {
          let dateUTC =
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["temp_delivery"];
          if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
            let dateLocal = new Date(dateUTC);
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["temp_delivery"] = showDebugDate
              ? testDate
              : dateLocal;
          }
        }

        // Vessel Particular -> dateOfRegistration (Delete Vessel)
        {
          let dateUTC =
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["dateTime"];
          if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
            let dateLocal = new Date(dateUTC);
            submission.data["containerParticulars"]["containerShipVessel"][
              "dataGridVessel1"
            ][index]["containerDataGrid"]["dateTime"] = showDebugDate
              ? testDate
              : dateLocal;
          }
        }
      }
    }

    if (
      submission.data.containerNonTax?.containerNonTaxResident
        ?.dataGridNonTaxResident !== undefined &&
      submission.data.containerNonTax?.containerNonTaxResident
        ?.dataGridNonTaxResident !== null
    ) {
      // Dategrid->Details of Non-Tax Resident(s) and the Agreement
      let size =
        submission.data?.containerNonTax?.containerNonTaxResident
          ?.dataGridNonTaxResident?.length ?? 0;
      for (let i = 0; i < size; ++i) {
        let index = i.toString();

        // Details of Non-Tax Resident(s) and the Agreement -> Date Of Signed Agreement
        let dateUTC =
          submission.data["containerNonTax"]["containerNonTaxResident"][
            "dataGridNonTaxResident"
          ][index]["containerNonTax"]["dateOfSignedAgreement"];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerNonTax"]["containerNonTaxResident"][
            "dataGridNonTaxResident"
          ][index]["containerNonTax"]["dateOfSignedAgreement"] = showDebugDate
            ? testDate
            : dateLocal;
        }
      }
    }

    //----------------------------------------------------------//
    // Handle Non-Datagrid
    //----------------------------------------------------------//
    // Handle Section 2
    {
      //-- [Date/Time Component] Handle Date Of Signed Agreement
      if (
        submission.data.containerNonTax?.containerLender
          ?.dateOfSignedAgreementTransferCertificate !== undefined &&
        submission.data.containerNonTax?.containerLender
          ?.dateOfSignedAgreementTransferCertificate !== null
      ) {
        let dateUTC =
          submission.data["containerNonTax"]["containerLender"][
            "dateOfSignedAgreementTransferCertificate"
          ];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerNonTax"]["containerLender"][
            "dateOfSignedAgreementTransferCertificate"
          ] = showDebugDate ? testDate : dateLocal;
        }
      }
      //-- [Date/Time Component] Handle Date of Transfer Certificate
      if (
        submission.data.containerNonTax?.containerLender?.dateOfTransferCert !==
          undefined &&
        submission.data.containerNonTax?.containerLender?.dateOfTransferCert !==
          null
      ) {
        let dateUTC =
          submission.data["containerNonTax"]["containerLender"][
            "dateOfTransferCert"
          ];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerNonTax"]["containerLender"][
            "dateOfTransferCert"
          ] = showDebugDate ? testDate : dateLocal;
        }
      }
      //-- [Date/Time Component] Handle Period of Financing (From)
      if (
        submission.data.containerNonTax?.containerLender
          ?.periodOfFinancingFrom !== undefined &&
        submission.data.containerNonTax?.containerLender
          ?.periodOfFinancingFrom !== null
      ) {
        let dateUTC =
          submission.data["containerNonTax"]["containerLender"][
            "periodOfFinancingFrom"
          ];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerNonTax"]["containerLender"][
            "periodOfFinancingFrom"
          ] = showDebugDate ? testDate : dateLocal;
        }
      }
      //-- [Date/Time Component] Handle Period of Financing (To)
      if (
        submission.data.containerNonTax?.containerLender
          ?.periodOfFinancingTo !== undefined &&
        submission.data.containerNonTax?.containerLender
          ?.periodOfFinancingTo !== null
      ) {
        let dateUTC =
          submission.data["containerNonTax"]["containerLender"][
            "periodOfFinancingTo"
          ];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerNonTax"]["containerLender"][
            "periodOfFinancingTo"
          ] = showDebugDate ? testDate : dateLocal;
        }
      }

      //-- [Date/Time Component] Handle Period Covered (From)
      if (
        submission.data.containerNonTax?.containerNonTaxResident
          ?.datePeriodCoveredFrom !== undefined &&
        submission.data.containerNonTax?.containerNonTaxResident
          ?.datePeriodCoveredFrom !== null
      ) {
        let dateUTC =
          submission.data.containerNonTax?.containerNonTaxResident
            ?.datePeriodCoveredFrom ?? "";
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerNonTax"]["containerNonTaxResident"][
            "datePeriodCoveredFrom"
          ] = showDebugDate ? testDate : dateLocal;
        }
      }

      //-- [Date/Time Component] Handle Period Covered (To)
      if (
        submission.data.containerNonTax?.containerNonTaxResident
          ?.datePeriodCoveredTo !== undefined &&
        submission.data.containerNonTax?.containerNonTaxResident
          ?.datePeriodCoveredTo !== null
      ) {
        let dateUTC =
          submission.data?.containerNonTax?.containerNonTaxResident
            ?.datePeriodCoveredTo ?? "";
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerNonTax"]["containerNonTaxResident"][
            "datePeriodCoveredTo"
          ] = showDebugDate ? testDate : dateLocal;
        }
      }
    }

    // Handle Section 3
    {
      //-- [Date/Time Component] Handle Date of earliest relevant qualifying payment made
      if (
        submission.data.containerQualifyingDetails?.containerQualifyingPayment
          ?.dateEarliestQualifyingPayment !== undefined &&
        submission.data.containerQualifyingDetails?.containerQualifyingPayment
          ?.dateEarliestQualifyingPayment !== null
      ) {
        let dateUTC =
          submission.data["containerQualifyingDetails"][
            "containerQualifyingPayment"
          ]["dateEarliestQualifyingPayment"];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerQualifyingDetails"][
            "containerQualifyingPayment"
          ]["dateEarliestQualifyingPayment"] = showDebugDate
            ? testDate
            : dateLocal;
        }
      }

      //-- [Date/Time Component] Handle Date of Effective date of WHT exemption
      if (
        submission.data.containerQualifyingDetails?.containerQualifyingPeriod
          ?.effectiveDateOfWHTExemption !== undefined &&
        submission.data.containerQualifyingDetails?.containerQualifyingPeriod
          ?.effectiveDateOfWHTExemption !== null
      ) {
        let dateUTC =
          submission.data["containerQualifyingDetails"][
            "containerQualifyingPeriod"
          ]["effectiveDateOfWHTExemption"];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerQualifyingDetails"][
            "containerQualifyingPeriod"
          ]["effectiveDateOfWHTExemption"] = showDebugDate
            ? testDate
            : dateLocal;
        }
      }

      //-- [Date/Time Component] Handle Date of End date
      if (
        submission.data.containerQualifyingDetails?.containerQualifyingPeriod
          ?.endDate !== undefined &&
        submission.data.containerQualifyingDetails?.containerQualifyingPeriod
          ?.endDate !== null
      ) {
        let dateUTC =
          submission.data["containerQualifyingDetails"][
            "containerQualifyingPeriod"
          ]["endDate"];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["containerQualifyingDetails"][
            "containerQualifyingPeriod"
          ]["endDate"] = showDebugDate ? testDate : dateLocal;
        }
      }
    }
  };

  const handleConvertIdpMainToLocalDate = (submission) => {
    let showDebugDate = false; // Dont delete this variable, let it stay, it's for debugging. Set it to true if u want debug, set it to false to use realtime data
    let testDate = "2022-02-12T00:00:00+08:00";

    //----------------------------------------------------------//
    // Handle Non-Datagrid
    //----------------------------------------------------------//
    // Handle Section 2
    {
      //-- [Date/Time Component] Handle estimatedCommencementDate
      if (
        submission.data?.estimatedCommencementDate !== undefined &&
        submission.data?.estimatedCommencementDate !== null
      ) {
        let dateUTC = submission.data["estimatedCommencementDate"];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["estimatedCommencementDate"] = showDebugDate
            ? testDate
            : dateLocal;
        }
      }
      //-- [Date/Time Component] Handle estimatedCompletionDate
      if (
        submission.data?.estimatedCompletionDate !== undefined &&
        submission.data?.estimatedCompletionDate !== null
      ) {
        let dateUTC = submission.data["estimatedCompletionDate"];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["estimatedCompletionDate"] = showDebugDate
            ? testDate
            : dateLocal;
        }
      }
      //-- [Date/Time Component] Handle implementationDate
      if (
        submission.data?.implementationDate !== undefined &&
        submission.data?.implementationDate !== null
      ) {
        let dateUTC = submission.data["implementationDate"];
        if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
          let dateLocal = new Date(dateUTC);
          submission.data["implementationDate"] = showDebugDate
            ? testDate
            : dateLocal;
        }
      }
    }
  };

  function handleClick() {
    $(".fa-plus-square-o").addClass("fa-angle-down");
    $(".fa-plus-square-o").removeClass("fa-plus-square-o");
    $(".fa-minus-square-o").addClass("fa-angle-up");
    $(".fa-minus-square-o").removeClass("fa-minus-square-o");
  }

  const [errorMessage, setErrorMessage] = useState();
  const [submitConfirm, setSubmitConfirm] = useState(false);

  async function onSubmit() {
    let response = await createOrSave(
      "submitted",
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );
    if (response.status === 200) {
      let skipLimit = {
        skip: 0,
        limit: 10,
      };

      dispatch(getApplicationListingPaginationAsync(skipLimit));
      caseID = response.message.data.configContainer.applicationCaseId;
      history.push({
        pathname: "/NestedApplicationListing",
        search: `?schema=${schemaroute}`,
        alert: "Form is submitted!",
        caseID: `${caseID}`,
      });
    } else if (response.status === 400) {
      let errorarray = response.message.details.map((x) => x.message);
      let errorul = errorarray.map((x, index) => <li key={index}>{x}</li>);
      let errordiv = (
        <div className="reviewerror">
          Please enter the following: <br />
          <ul>{errorul}</ul>
        </div>
      );
      setErrorMessage(errordiv);
      setStartSpinner(false);
    } else if (response.status === 500 && sessionStorage.getItem("error") !== "409") {
      setOpenErrorStatusDialog(true);
      setStartSpinner(false);
    } else if (response.status === 429) {
      setOpenDuplicateSubmissionStatusDialog(true);
      setStartSpinner(false);
    }
  }

  function theSubmit() {
    // setSubmitConfirm(true)

    setActionName(caseID);
    setOpenDialog(true);
    setTypeDialog("submit");
  }

  let allowScrolling = true;
  const handleDialog = async (text, type) => {
    if (text === "Yes" && type === "submit") {
      setSubmitConfirm(true);
      if (allowScrolling) window.scrollTo(0, 0);
      setStartSpinner(true);
      onSubmit();
    }

    if (text === "No") {
      return;
    }
  };

  return (
    <>
      <div className="applicationform">
        <Container>
          <SubmissionErrorDialog
            open={openErrorStatusDialog}
            setOpen={setOpenErrorStatusDialog}
          />
          <DuplicateSubmissionErrorDialog
            open={openDuplicateSubmissionStatusDialog}
            setOpen={setOpenDuplicateSubmissionStatusDialog}
          />
          <br />
          <Typography variant="h2">{formname}</Typography>
           {caseID !== null && (
              ceaseDate !== undefined ? ( 
              <Typography className="univers-55roman-normal-mine-shaft-22px">
                  {caseID} <StatusChip type={formstatus} /> Cessation Date: {ceaseDate}
              </Typography> ) :(
              <Typography className="univers-55roman-normal-mine-shaft-22px">
                  {caseID} <StatusChip type={formstatus} /> 
              </Typography> )
          )}
          <Grid container justifyContent={"space-between"} my={2}>
            <Grid item>
              {canSubmit && <Typography variant="h4">Review</Typography>}
            </Grid>
            <Grid item>
              {/* <IconDownload className="noPrint" style={{ marginRight: 24 }} /> */}

              <IconPrinter
                className="noPrint"
                onClick={(e) => {
                  e.preventDefault();
                  window.print();
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Paper id="formio" sx={{ py: 2.5, px: 5, my: 3 }} />
          <Grid container sx={{ justifyContent: "space-between" }} my={3}>
            {canSubmit && (
              <Button
                className="large-long button-large-with-arrow-outlined univers-65-bold-science-blue-16px noPrint"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: pathname,
                    search: `?_id=${_id}&status=${formstatus}`,
                  });
                }}
              >
                Back to Edit
              </Button>
            )}
            {canSubmit && (
              <Button
                className="large-long button-large-with-arrow-filled univers-65-bold-white-16px noPrint"
                endIcon={<IconArrowNarrowRight />}
                // onClick={onSubmit}
                onClick={theSubmit}
                disabled={submitConfirm}
              >
                Submit
              </Button>
            )}
            {!canSubmit && (
              <Button
                className="large-long button-large-with-arrow-outlined univers-65-bold-science-blue-16px noPrint"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`NestedApplicationListing?schema=${schemaroute}`);
                }}
              >
                Back
              </Button>
            )}
          </Grid>
          {errorMessage}
        </Container>
      </div>
      <div>
        <ActionDialog
          open={openDialog}
          setOpenDialog={setOpenDialog}
          actionName={actionName}
          response={handleDialog}
          type={typeDialog}
        ></ActionDialog>
        <ProgressBar
          ref={ref}
          startSpinner={startSpinner}
          setStartSpinner={setStartSpinner}
        />
      </div>
    </>
  );
}
