import { Alert, AlertTitle, CircularProgress, Modal } from '@mui/material';
import React, { forwardRef, useImperativeHandle } from "react";

const ProgressBar = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        changeOpen
      }))

    const changeOpen = () => {
        props.setStartSpinner(false)
        props.setBar(!props.bar)
    }


    return (
        <>
            <Modal
            open={props.startSpinner}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                {/* <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={100}
                >
                    <CircularProgress />
                </Box> */}
                <Alert
                // color="secondary"
                severity="info"
                variant='filled'
                // icon={false}
                // sx={{ width: 250, borderRadius: 6, pl: 3, alignItems: 'center', justifyContent: "center" }}>
                sx={{ borderRadius: 6, pl: 3, padding: 10, justifyContent: 'center', borderColor: "white" }}>
                    <AlertTitle>Please Wait</AlertTitle>
                    <strong>Form is loading</strong>
                    <br />
                    <CircularProgress color="secondary" />

                </Alert>

            </Modal>
        </>
    )

})

export default ProgressBar
