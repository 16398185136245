import axios from "axios";
import axiosRetry from 'axios-retry';
import loginHelper from './loginHelper'
import { config } from '../config';

const instance = axios.create({
  baseURL: config.appURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/octet-stream",
  },
  responseType: 'blob',
});

axiosRetry(instance, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    return error.response.status === 409;
  },
});

instance.interceptors.request.use(
  async config => {
    let jwt = loginHelper.getFormioToken() || loginHelper.getLoginJwt();
    if (jwt) {
      config.headers['Authorization'] = 'Bearer ' + jwt;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
